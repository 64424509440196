<template>
  <div>
    <list-section
      :title="`Кар'єра`"
      :addActionDisabled="readonly"
      :addActionTitle="`Додати кар'єру`"
      :addAction="() => $emit('openDialog', ['addCareer'])">
      <template #body>
        <v-data-table
          :headers="careerHeaders"
          :items="contactData.careers || []"
          hide-default-footer
          :hide-default-header="!contactData.careers"
          class="pb-2 pt-1 custom-table head-line"
          dense>
          <template #item.isActive="{ item }">
            <div class="d-flex align-center gap-1">
              <v-menu open-on-hover offset="5">
                <template #activator="{ props }">
                  <v-chip
                    class="cursor-pointer"
                    density="compact"
                    :color="item.isActive ? 'success' : 'error'"
                    v-bind="props">
                    <span>
                      {{ item.isActive ? 'Активний' : 'Неактивний' }}
                    </span>
                  </v-chip>
                </template>

                <v-card>
                  <v-card-text>
                    <div>
                      <div>
                        <div class="text-disabled mb-1">
                          Дата прийняття на посаду:
                        </div>
                        <div>
                          {{ new CustomDate(item.startDate).toString() }}
                        </div>
                      </div>
                      <v-divider class="mb-2 mt-2"></v-divider>
                      <div>
                        <div class="text-disabled mb-1">Дата звільнення:</div>
                        <div>
                          {{
                            new CustomDate(item.finishDate).toString() || '---'
                          }}
                        </div>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-menu>
            </div>
          </template>
          <template #item.contractor="{ item }">
            <a
              class="cursor-pointer"
              @click="
                $router.push({
                  name: 'contractors-form',
                  params: { id: item.employee.contractor.id },
                })
              ">
              {{ getSafe(() => item.employee.contractor.shortName, '---') }}
            </a>
          </template>
          <template #item.dateStart="{ item }">
            {{ item.startDate }}
          </template>
          <template #item.dateEnd="{ item }">
            {{ item.startDate }}
          </template>
        </v-data-table>
      </template>
    </list-section>
    <!-- Education -->
    <list-section
      :title="'Освіта'"
      :addActionDisabled="readonly"
      :addActionTitle="'Додати оствіту'"
      :addAction="
        () =>
          $store.commit('setDialog', {
            title: 'Освіта',
            action: 'createEducation',
            params: {
              contactId: contactId,
              cb: () => $emit('tableChange'),
            },
          })
      ">
      <template #body>
        <v-data-table
          v-if="contactData.education"
          :headers="educationHeaders"
          :items="contactData.education"
          hide-default-footer
          :hide-default-header="!contactData.education"
          class="pb-2 pt-1 custom-table head-line"
          dense>
          <!-- eslint-disable-next-line -->
          <template #no-data>
            <no-data></no-data>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.actions="{ item }">
            <TableCrud
              :actionsArray="[
                {
                  action: () =>
                    $store.commit('setDialog', {
                      title: 'Освіта',
                      action: 'deleteEducation',
                      params: {
                        educationId: item.id,
                        cb: () => $emit('tableChange'),
                      },
                    }),
                  disabled: readonly,
                  text: 'Видалити',
                  icon: 'mdi-close',
                },
                {
                  action: () =>
                    $store.commit('setDialog', {
                      title: 'Освіта',
                      action: 'updateEducation',
                      params: {
                        contactId: contactId,
                        cb: () => $emit('tableChange'),
                      },
                      dialogItem: item,
                    }),
                  disabled: readonly,
                  text: 'Редагувати',
                  icon: 'EditPencil',
                  customIcon: true,
                },
              ]">
            </TableCrud>
          </template>
        </v-data-table>
      </template>
    </list-section>

    <list-section title="Зв'язок бенефіціара">
      <template #body>
        <v-data-table
          v-if="contact.beneficiaries"
          :headers="beneficiaryConnectionHeaders"
          :items="contact.beneficiaries"
          hide-default-footer
          :hide-default-header="!contact.beneficiaries"
          class="pb-2 pt-1 custom-table head-line"
          dense>
          <template #no-data>
            <no-data></no-data>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.contractor="{ item }">
            <ALink
              :text="item.contractor.shortName"
              :route="{
                name: 'contractors-form',
                params: { id: item.contractor.id },
              }"></ALink>
          </template>
        </v-data-table>
      </template>
    </list-section>
  </div>
</template>
<script>
import listSection from '@/components/list-section.vue'
import noData from '@/components/no-table-data.vue'
import TableCrud from '@/components/table-crud.vue'
import { urlDeleteContactCareer } from '@/pages/request'
import { mapState } from 'vuex'
import { editCareer, getSafe } from '@/utils/helperFunc.js'
import ALink from '@/components/Link.vue'
import {
  educationHeaders,
  careerHeaders,
  beneficiaryConnectionHeaders,
} from '@/utils/tableHeaders'
import { CustomDate } from 'best-modules/utils'

export default {
  emits: ['openDialog', 'tableChange'],
  components: { ALink, listSection, noData, TableCrud },
  props: {
    contact: { type: Object },
    readonly: { type: Boolean },
  },
  data: () => ({
    educationHeaders,
    careerHeaders,
    beneficiaryConnectionHeaders,
    CustomDate,
  }),
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    contactId() {
      return parseInt(this.$route.params.id)
    },
    contactData() {
      return this.contact
    },
    contactAgent() {
      return this.contact.contactTypeId.includes(2)
    },
  },
  methods: {
    getSafe,
    urlDeleteContactCareer,
    editCareer,
    deleteCareer(item) {
      return this.$axios
        .delete(this.urlDeleteContactCareer(item.id))
        .then(res => {
          this.$setSnackbar({ text: "Кар'єру видалено" })
          this.$emit('tableChange')
          return res
        })
        .catch(this.$err)
    },
  },
}
</script>
