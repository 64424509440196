<template>
  <v-card :elevation="0">
    <div v-if="!routeScoringSingle">
      <v-fade-transition hide-on-leave>
        <div>
          <Filters :filters="filters" class="ml-2" />
          <SectionLoader
            v-show="asyncLoading('getScoringList')"></SectionLoader>
          <v-row>
            <v-col :cols="12">
              <div v-show="!asyncLoading('getScoringList')">
                <v-data-table
                  :headers="scoringListHeaders"
                  :items="scoringList"
                  class="calc-table application-table --cust-cell-pd"
                  mobileBreakpoint="750"
                  dense
                  hide-default-footer
                  :items-per-page="-1"
                  :hide-default-header="!scoringList.length"
                  @dblclick:row="navigateToScoring">
                  <!-- eslint-disable-next-line -->
                  <template #item.lessee="{ item }">
                    <ALink
                      :text="item.lessee.name"
                      :route="
                        getEntityRoute(
                          item.lesseeId.leasingClientType,
                          item.lesseeId.contactId || item.lesseeId.contractorId
                        )
                      ">
                    </ALink>
                  </template>
                  <!-- eslint-disable-next-line -->
                  <template #item.created_at="{ item }">
                    <span>{{
                      toFormatDate(item.created_at, false) || 'Відсутня'
                    }}</span>
                  </template>
                  <!-- eslint-disable-next-line -->
                  <template #item.frontManagerResponsible="{ item }">
                    {{
                      getContactInitials(item.frontManagerResponsible, {
                        isResponsible: true,
                      }) || 'Відсутній'
                    }}
                  </template>
                  <!-- eslint-disable-next-line -->
                  <template #item.responsible="{ item }">
                    <span>
                      {{ getResponsibleShortName(item.responsible) }}
                    </span>
                  </template>
                  <!-- eslint-disable-next-line -->
                  <template #item.isAgree="{ item }">
                    <span>
                      {{
                        item.isAgree && item.endTime
                          ? 'Погоджено'
                          : item.isAgree === false && item.endTime
                          ? 'Не погоджено'
                          : item.isAgree === false && !item.endTime
                          ? '---'
                          : 'На розглядi'
                      }}</span
                    >
                    <span v-if="item.endTime"
                      >({{ toFormatDate(item.endTime, false) }})</span
                    >
                  </template>
                  <!-- eslint-disable-next-line -->
                  <template #item.actions="{ item }">
                    <TableCrud
                      :actionsArray="[
                        {
                          action: () =>
                            $router.push({
                              name: `${service}-scoring-single`,
                              params: { id: item.id },
                            }),
                          text: 'Відкрити',
                          icon: 'mdi-folder-open-outline',
                        },
                      ]">
                    </TableCrud>
                  </template>
                </v-data-table>
                <Pagination
                  :getItems="getScoringList"
                  :trigger="paginationTrigger" />
              </div>
            </v-col>
          </v-row>
        </div>
      </v-fade-transition>
    </div>
    <router-view v-if="routeScoringSingle"> </router-view>
  </v-card>
</template>
<script>
import SectionLoader from '@/components/section-loader.vue'
import ALink from '@/components/Link.vue'
import Pagination from '@/components/Pagination.vue'
import {
  urlScoringSecurityList,
  urlScoringRiskList,
  urlScoringFinList,
} from '@/pages/request'
import {
  toFormatDate,
  getEntityRoute,
  getContactInitials,
  getResponsibleShortName,
} from '@/utils/helperFunc'
import Filters from '@/components/Filters.vue'
import { useSelect } from '@/utils/mixins/useSelect'
import { useFilter } from '@/utils/mixins/useFilter'
import { reactive } from 'vue'
import TableCrud from '@/components/table-crud.vue'
import { scoringListHeaders } from '@/utils/tableHeaders'
export default {
  components: {
    TableCrud,
    SectionLoader,
    ALink,
    Pagination,
    Filters,
  },
  setup() {
    const filters = reactive({
      dates: {
        value: null,
        component: 'DatePicker',
        attrs: { label: 'Період', range: true },
      },
      frontManagerResponsibleId: {
        value: null,
        component: 'v-autocomplete',
        attrs: {
          label: 'Відповідальний фронт',
          items: 'frontManagers',
          itemTitle: u => getContactInitials(u, { isResponsible: true }),
        },
        focus: { action: 'addFrontManagers' },
      },
      isAgree: {
        value: null,
        component: 'v-checkbox',
        attrs: { label: 'Погоджено' },
      },
      search: { value: null },
    })
    return {
      ...useSelect(),
      ...useFilter(filters),
      filters,
    }
  },
  data() {
    return {
      scoringList: [],
      scoringListHeaders,
    }
  },
  computed: {
    routeScoringSingle() {
      return this.$route.name.includes('-sc-')
    },
    service() {
      switch (true) {
        case this.$route.name.includes('security'):
          return 'security'
        case this.$route.name.includes('risk'):
          return 'risk'
        case this.$route.name.includes('financial'):
          return 'fin'
        default:
          throw new Error('incorrect route name')
      }
    },
  },
  methods: {
    toFormatDate,
    getEntityRoute,
    getContactInitials,
    urlScoringSecurityList,
    urlScoringRiskList,
    urlScoringFinList,
    getResponsibleShortName,
    getScoringList(page = 1) {
      return this.asyncAction('getScoringList', null, () => {
        const url = this[`urlScoring${this.service.capitalize()}List`]
        if (!url) return
        return this.$axios
          .get(url(page, this.filtersQueryObj))
          .then(res => {
            this.scoringList.splice(0)
            this.scoringList.push(...res.data.data)
            return res
          })
          .catch(this.$err)
      })
    },
    navigateToScoring(e, row) {
      return this.$router.push({
        name: `${this.service}-scoring-single`,
        params: { id: row.item.id },
      })
    },
  },
}
</script>
