<template>
  <v-card :elevation="0">
    <div v-if="verificationList">
      <Filters :filters="filters" class="ml-2" />
      <SectionLoader
        v-if="asyncLoading('getFinancialDepartmentAnalyzes')"></SectionLoader>
      <v-fade-transition hide-on-leave style="margin-top: 10px">
        <div v-show="!asyncLoading('getFinancialDepartmentAnalyzes')">
          <v-data-table
            :headers="financialListHeaders"
            :items="financialDepartmentAnalyzes"
            class="mb-2 calc-table application-table --cust-cell-pd"
            mobileBreakpoint="860"
            dense
            hide-default-footer
            :items-per-page="-1"
            :hide-default-header="!financialDepartmentAnalyzes.length"
            @dblclick:row="navigateToAnalyze">
            <!-- eslint-disable-next-line -->
            <template #item.lessee="{ item }">
              <div class="d-flex align-center">
                <Benefit v-if="item.bestBenefit" class="mr-1" />
                <ALink :text="item.lesseeName" :route="getEntityRoute(item)">
                </ALink>
              </div>
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.created_at="{ item }">
              {{ humanDate(formatDate(item.created_at, true)) }}
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.status="{ item }">
              <div
                class="status d-flex justify-start pa-0"
                :style="{
                  color: statusColors[getSafe(() => item.status.id)],
                  width: '165px',
                }">
                <span v-if="item.status && item.status.id === 1"
                  >Не розглянуто</span
                >
                <span v-if="item.status && item.status.id === 2"
                  >На розгляді</span
                >
                <span v-if="item.status && item.status.id === 3"
                  >Доопрацювання</span
                >
                <span v-if="item.status && item.status.id === 4"
                  >Завершено</span
                >
                <span v-if="!item.status">---</span>
              </div>
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.frontManager="{ item }">
              {{ getContactInitials(item.frontManagerFio, { byName: true }) }}
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.analysisType="{ item }">
              <div>
                <div
                  v-if="item.analysisType && [1].includes(item.analysisType.id)"
                  class="analys">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 66 77"
                    fill="none">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M33 62C49.0163 62 62 49.0163 62 33C62 16.9837 49.0163 4 33 4C16.9837 4 4 16.9837 4 33C4 49.0163 16.9837 62 33 62ZM33 66C51.2254 66 66 51.2254 66 33C66 14.7746 51.2254 0 33 0C14.7746 0 0 14.7746 0 33C0 51.2254 14.7746 66 33 66Z"
                      fill="#FC7247" />
                    <path
                      d="M43 63.5L54.2583 57L61.5001 69.5432L50.2418 76.0432L43 63.5Z"
                      fill="#FC7247" />
                    <path
                      d="M27.8632 38.4431L47.4417 19.8659C48.0501 19.2886 48.8244 19 49.7646 19C50.7048 19 51.4791 19.2886 52.0874 19.8659C52.6958 20.4431 53 21.1778 53 22.07C53 22.9621 52.6958 23.6968 52.0874 24.2741L30.1861 45.0554C29.5224 45.6851 28.7481 46 27.8632 46C26.9783 46 26.204 45.6851 25.5404 45.0554L16.9126 36.8688C16.3042 36.2915 16 35.5569 16 34.6647C16 33.7726 16.3042 33.0379 16.9126 32.4606C17.5209 31.8834 18.2952 31.5948 19.2354 31.5948C20.1756 31.5948 20.9499 31.8834 21.5583 32.4606L27.8632 38.4431Z"
                      fill="#FC7247" />
                  </svg>
                </div>
                <div
                  v-if="item.analysisType && [2].includes(item.analysisType.id)"
                  class="analys">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 66 77"
                    fill="none">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M33 62C49.0163 62 62 49.0163 62 33C62 16.9837 49.0163 4 33 4C16.9837 4 4 16.9837 4 33C4 49.0163 16.9837 62 33 62ZM33 66C51.2254 66 66 51.2254 66 33C66 14.7746 51.2254 0 33 0C14.7746 0 0 14.7746 0 33C0 51.2254 14.7746 66 33 66Z"
                      fill="#FC7247" />
                    <path
                      d="M43 63.5L54.2583 57L61.5001 69.5432L50.2418 76.0432L43 63.5Z"
                      fill="#FC7247" />
                    <path
                      d="M33.049 14.9266C33.3483 14.0054 34.6517 14.0054 34.951 14.9266L38.7152 26.5101C38.8491 26.9221 39.233 27.201 39.6662 27.201L51.846 27.2015C52.8146 27.2015 53.2174 28.4411 52.4337 29.0105L42.5804 36.1699C42.2299 36.4246 42.0833 36.8759 42.2171 37.2879L45.9804 48.8717C46.2798 49.7929 45.2253 50.559 44.4416 49.9897L34.5878 42.831C34.2373 42.5764 33.7627 42.5764 33.4122 42.831L23.5584 49.9897C22.7747 50.559 21.7202 49.793 22.0195 48.8717L25.7829 37.2879C25.9167 36.8759 25.7701 36.4246 25.4196 36.1699L15.5663 29.0105C14.7826 28.4411 15.1854 27.2015 16.154 27.2015L28.3338 27.201C28.767 27.201 29.1509 26.9221 29.2848 26.5101L33.049 14.9266Z"
                      fill="#FC7247" />
                  </svg>
                </div>
                <div
                  v-if="item.analysisType && [3].includes(item.analysisType.id)"
                  class="analys">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 66 77"
                    fill="none">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M33 62C49.0163 62 62 49.0163 62 33C62 16.9837 49.0163 4 33 4C16.9837 4 4 16.9837 4 33C4 49.0163 16.9837 62 33 62ZM33 66C51.2254 66 66 51.2254 66 33C66 14.7746 51.2254 0 33 0C14.7746 0 0 14.7746 0 33C0 51.2254 14.7746 66 33 66Z"
                      fill="#FC7247" />
                    <path
                      d="M53.828 21.3427C53.6099 20.8135 53.1926 20.393 52.6674 20.1733C52.409 20.0623 52.1315 20.0034 51.8506 20H41.1037C40.5337 20 39.987 20.2282 39.5839 20.6343C39.1808 21.0404 38.9544 21.5913 38.9544 22.1656C38.9544 22.74 39.1808 23.2908 39.5839 23.6969C39.987 24.1031 40.5337 24.3312 41.1037 24.3312H46.6706L34.6556 36.4371L27.5842 29.2905C27.3843 29.0875 27.1466 28.9264 26.8847 28.8165C26.6228 28.7065 26.3418 28.6499 26.0581 28.6499C25.7744 28.6499 25.4934 28.7065 25.2315 28.8165C24.9696 28.9264 24.7319 29.0875 24.532 29.2905L11.6358 42.2842C11.4343 42.4856 11.2744 42.7251 11.1653 42.989C11.0562 43.2529 11 43.5359 11 43.8218C11 44.1077 11.0562 44.3908 11.1653 44.6547C11.2744 44.9186 11.4343 45.1581 11.6358 45.3594C11.8356 45.5624 12.0733 45.7235 12.3352 45.8335C12.5972 45.9434 12.8781 46 13.1618 46C13.4456 46 13.7265 45.9434 13.9884 45.8335C14.2504 45.7235 14.4881 45.5624 14.6879 45.3594L26.0581 33.8816L33.1296 41.0282C33.3294 41.2312 33.5671 41.3923 33.829 41.5022C34.0909 41.6122 34.3719 41.6688 34.6556 41.6688C34.9394 41.6688 35.2203 41.6122 35.4822 41.5022C35.7441 41.3923 35.9819 41.2312 36.1817 41.0282L49.7012 27.3848V32.9937C49.7012 33.5681 49.9277 34.1189 50.3308 34.525C50.7339 34.9312 51.2806 35.1593 51.8506 35.1593C52.4207 35.1593 52.9674 34.9312 53.3705 34.525C53.7736 34.1189 54 33.5681 54 32.9937V22.1656C53.9966 21.8826 53.9382 21.603 53.828 21.3427Z"
                      fill="#FC7247" />
                    <path
                      d="M43 63.5L54.2583 57L61.5001 69.5432L50.2418 76.0432L43 63.5Z"
                      fill="#FC7247" />
                  </svg>
                </div>
                <span class="analysis-type">{{ item.analysisType?.name }}</span>
              </div>
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.isConfirmation="{ item }">
              {{
                item.isConfirmation
                  ? `Підтверджено (${humanDate(
                      formatDate(item.dateConfirmation, true)
                    )})`
                  : 'Ні'
              }}
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.financialRiskLevel="{ item }">
              {{
                (item.financialRiskLevel
                  ? item.financialRiskLevel.name
                  : '---') +
                ` (${humanDate(formatDate(item.finishDate, true))})`
              }}
            </template>
            <!-- eslint-disable-next-line -->
            <template #item.actions="{ item }">
              <TableCrud
                style="margin-right: -10px !important"
                :actionsArray="[
                  {
                    action: () =>
                      $router.push({
                        name: 'fd-project-verification',
                        params: { id: item.id },
                      }),
                    text: 'Відкрити',
                    icon: 'mdi-folder-open-outline',
                  },
                ]">
              </TableCrud>
            </template>
          </v-data-table>
          <Pagination
            :getItems="getFinancialDepartmentAnalyzes"
            :trigger="paginationTrigger" />
        </div>
      </v-fade-transition>
    </div>
    <router-view v-if="!verificationList"> </router-view>
  </v-card>
</template>
<script>
import SectionLoader from '@/components/section-loader.vue'
import ALink from '@/components/Link.vue'
import Pagination from '@/components/Pagination.vue'
import Filters from '@/components/Filters.vue'
import { urlGetFinancialMonitoringAnalyzes } from '@/pages/request'
import { formatDate, humanDate } from '@/utils/formatFunc'
import {
  getContactInitials,
  getResponsibleShortName,
  getSafe,
} from '@/utils/helperFunc'
import Benefit from '@/assets/svg/benefit.vue'
import { useSelect } from '@/utils/mixins/useSelect'
import { useFilter } from '@/utils/mixins/useFilter'
import { reactive } from 'vue'
import TableCrud from '@/components/table-crud.vue'
import { financialListHeaders } from '@/utils/tableHeaders'
export default {
  components: {
    TableCrud,
    Benefit,
    SectionLoader,
    ALink,
    Pagination,
    Filters,
  },
  setup() {
    const filters = reactive({
      statusId: {
        value: null,
        component: 'v-autocomplete',
        attrs: { label: 'Статус', items: 'financingMonitoringStatuses' },
      },
      financialRiskLevelId: {
        value: null,
        component: 'v-autocomplete',
        attrs: { label: 'Оберіть рівень ризику', items: 'financialRiskLevels' },
      },
      dates: {
        value: null,
        component: 'DatePicker',
        attrs: { label: 'Період', range: true },
      },
      datesAnalysis: {
        value: null,
        component: 'DatePicker',
        attrs: {
          label: 'Період фiн монiторингу',
          range: true,
          keys: { startDate: 'startDateAnalysis', endDate: 'endDateAnalysis' },
        },
      },
      datesConfirmation: {
        value: null,
        component: 'DatePicker',
        attrs: {
          label: 'Період пiдтвердження',
          range: true,
          keys: {
            startDate: 'startDateConfirmation',
            endDate: 'endDateConfirmation',
          },
        },
      },
      frontManagerId: {
        value: null,
        component: 'v-autocomplete',
        attrs: {
          label: 'Фронт менеджер',
          items: 'frontManagers',
          itemTitle: u => getContactInitials(u, { isResponsible: true }),
        },
      },
      responsibleId: {
        value: null,
        component: 'v-autocomplete',
        attrs: {
          label: 'Відповідальний',
          items: 'leadAccessUsers',
          itemTitle: u => getContactInitials(u, { isResponsible: true }),
        },
        focus: { action: 'addAllUsers' },
      },
      search: { value: null },
    })
    return {
      ...useSelect(),
      ...useFilter(filters),
      filters,
    }
  },
  data() {
    return {
      financialListHeaders,
      financialDepartmentAnalyzes: [],
      statusColors: {
        1: '#62C14A',
        2: '#0098EE',
        3: '#FC7247',
        4: '#D30000',
        5: '#D30000',
        6: '#D30000',
      },
    }
  },
  computed: {
    verificationList() {
      return this.$route.name === 'financial-department'
    },
  },
  methods: {
    getContactInitials,
    getSafe,
    formatDate,
    humanDate,
    urlGetFinancialMonitoringAnalyzes,
    getResponsibleShortName,
    getEntityRoute(item) {
      const typeContractor = item.lessee.typeId === 2
      const name = typeContractor ? 'contractors-form' : 'individuals-form'
      const id = item.lessee.id
      return { name, params: { id } }
    },
    navigateToAnalyze(e, row) {
      this.$router.push({
        name: 'fd-project-verification',
        params: { id: row.item.id },
      })
    },
    getFinancialDepartmentAnalyzes(page = 1) {
      const params = {
        page,
        filters: this.filtersQueryObj,
      }
      return this.asyncAction('getFinancialDepartmentAnalyzes', null, () => {
        return this.$axios
          .get(this.urlGetFinancialMonitoringAnalyzes(params))
          .then(res => {
            this.financialDepartmentAnalyzes.splice(0)
            this.financialDepartmentAnalyzes.push(...res.data.data)

            return res
          })
          .catch(this.$err)
      })
    },
  },
}
</script>

<style lang="scss">
.status {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border-radius: 15px;
  padding: 0 16px;
  color: #fff;
}
.analys {
  position: relative;
}
.analys:hover + .analysis-type {
  display: block;
}
.analysis-type {
  display: none;
  position: absolute;
  background: white;
  padding: 7px;
  border-radius: 4px;
  z-index: 10000;
}
</style>
