<template>
  <div
    class="d-flex align-center justify-space-between"
    style="background: white">
    <div
      class="d-flex align-center"
      style="padding-left: 10px; padding-top: 10px">
      <div class="d-flex align-center">
        <span class="title-label mr-2 d-block">Статус:</span>
        <span class="title-label d-block">{{
          calculation?.status?.name || '---'
        }}</span>
        <div
          v-if="calculation?.status && [4, 5].includes(calculation?.status?.id)"
          class="ml-2">
          <span v-if="calculation?.status?.id === 4" class="title-label d-block"
            >({{ toFormatDate(calculation?.shipment?.registrationDate) }})</span
          >
          <span v-if="calculation?.status?.id === 5" class="title-label d-block"
            >({{ toFormatDate(calculation?.shipment?.shipmentDate) }})</span
          >
        </div>
      </div>
      <div
        v-if="
          calculation?.statusId === 3 && ![9, 10].includes(project.statusId)
        "
        class="ml-3">
        <v-tooltip right text="Перевести калькуляцію в статус Відмова клієнта">
          <template #activator="{ props }">
            <v-btn
              data-readonly="hide"
              :disabled="
                (!admin && !middleHead) ||
                (project.status.id === 6 &&
                  (calculation.contract || calculation.dkp))
              "
              icon
              class="rounded-circle"
              size="x-small"
              v-bind="props"
              @click="confirmationDialog = true">
              <v-icon>{{ 'mdi-close-circle-multiple-outline' }}</v-icon>
            </v-btn>
          </template>
          <span>Перевести калькуляцію в статус Відмова клієнта</span>
        </v-tooltip>
      </div>
      <Confirmation
        v-if="confirmationDialog"
        :text="'Ви дійсно бажаєте перевести калькуляцію в статус Відмова клієнта?'"
        @reject="confirmationDialog = false"
        @submit="clientReject" />
    </div>
    <div
      style="margin-bottom: -10px; padding-right: 10px"
      class="d-flex align-center justify-end position-relative">
      <div v-if="singleScoring" class="scoring-result">
        <span class="link mr-4" @click="scoringBlock = true"
          >Попереднє рішення</span
        >
        <v-dialog v-model="scoringBlock" width="900">
          <v-card elevation="0">
            <div class="d-flex justify-end">
              <v-btn
                size="small"
                variant="text"
                icon
                @click="scoringBlock = false">
                <v-icon>{{ 'mdi-close' }}</v-icon>
              </v-btn>
            </div>
            <v-card-text>
              <div>
                <OpenableList
                  v-for="(sc, idx) in singleScoring"
                  :key="idx"
                  :title="`результат по рішенню №${idx + 1}`">
                  <v-data-table
                    :headers="getTHead(sc)"
                    :items="sc"
                    class="calc-table application-table --cust-cell-pd mb-3"
                    mobileBreakpoint="750"
                    dense
                    hide-default-footer
                    :items-per-page="-1"
                    :hide-default-header="!sc.length">
                    <!-- eslint-disable-next-line -->
                    <template #item.responsible="{ item }">
                      {{
                        getSafe(() => getResponsibleShortName(item.responsible))
                      }}
                    </template>
                    <template #item.scoringServiceComment="{ item }">
                      <v-menu v-if="item.scoringServiceComment" left offset-x>
                        <template #activator="{ props }">
                          <v-btn icon class="conclusion" v-bind="props">
                            <EyeIcon />
                          </v-btn>
                        </template>
                        <div class="pa-5" style="background: #fff">
                          {{ item.scoringServiceComment }}
                        </div>
                      </v-menu>
                      <span v-else>---</span>
                    </template>
                    <template #item.isAgree="{ item }">
                      <div class="d-flex align-center">
                        <span>
                          {{
                            item.isAgree && item.endTime
                              ? 'Погоджено '
                              : item.isAgree === false && item.endTime
                              ? 'Не погоджено'
                              : item.isAgree === false && !item.endTime
                              ? '---'
                              : 'На розглядi'
                          }}</span
                        >
                        <div v-if="item.endTime">
                          <span class="ml-2"
                            >( {{ toFormatDate(item.endTime, false) }})</span
                          >
                        </div>
                      </div>
                    </template>
                    <!-- eslint-disable-next-line -->
                  </v-data-table>
                </OpenableList>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
      <div class="d-flex align-center">
        <TableCrud
          class="mr-3"
          customIcon="Printer"
          :actionsArray="printedForms" />
        <v-menu v-model="historyBlock" :close-on-content-click="false">
          <template #activator="{ props }">
            <span class="link" v-bind="props">Історія змін</span>
          </template>
          <div class="history-block">
            <SectionLoader v-if="logsLoading" />
            <div v-else>
              <div class="d-flex justify-end">
                <v-btn
                  size="small"
                  variant="text"
                  icon
                  @click="historyBlock = false">
                  <v-icon>{{ 'mdi-close' }}</v-icon>
                </v-btn>
              </div>
              <div v-if="logs.length" class="wrapper" style="padding: 5px">
                <v-data-table
                  :headers="leasingObjectLogHeaders"
                  :items="logs"
                  class="pb-2 custom-table head-line"
                  dense>
                  <!-- eslint-disable-next-line -->
                  <template #item.dateTo="{ item }">
                    {{ toFormatDate(item.dateTo) }}
                  </template>
                  <!-- eslint-disable-next-line -->
                  <template #item.field="{ item }">
                    <div class="d-flex align-center">
                      <span style="font-size: 13px">
                        <span>{{
                          `${item.name || '---'} (${item.field})`
                        }}</span>
                      </span>
                    </div>
                  </template>
                  <!-- eslint-disable-next-line -->
                  <template #item.userName="{ item }">
                    {{ getContactInitials(item.userName, { byName: true }) }}
                  </template>
                  <!-- eslint-disable-next-line -->
                  <template #item.changes="{ item }">
                    <div class="d-flex align-center">
                      <span style="display: inline-block; min-width: 6px">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6"
                          height="6"
                          viewBox="0 0 6 6"
                          fill="none">
                          <circle cx="3" cy="3" r="3" fill="#08487A" />
                        </svg>
                      </span>
                      <span style="padding-left: 10px; font-size: 13px">
                        <span v-if="isDocument(item.oldValue)">
                          <span
                            v-for="(doc, idx) in Array.isArray(item.oldValue)
                              ? item.oldValue
                              : [item.oldValue]"
                            :key="idx">
                            <span
                              class="link"
                              @click="() => openDocument(doc)"
                              >{{ doc.text }}</span
                            >
                            <span
                              v-if="
                                idx <
                                (Array.isArray(item.oldValue)
                                  ? item.oldValue
                                  : [item.oldValue]
                                ).length -
                                  1
                              "
                              >,
                            </span>
                          </span>
                        </span>
                        <span v-else :class="{ disabled: !item.oldValue }">{{
                          item.oldValue || 'Не вказано'
                        }}</span>
                        {{ '&#8594;' }}<br />
                        <span v-if="isDocument(item.newValue)">
                          <span
                            v-for="(doc, idx) in Array.isArray(item.newValue)
                              ? item.newValue
                              : [item.newValue]"
                            :key="idx">
                            <span
                              class="link"
                              @click="() => openDocument(doc)"
                              >{{ doc.text }}</span
                            >
                            <span
                              v-if="
                                idx <
                                (Array.isArray(item.newValue)
                                  ? item.newValue
                                  : [item.newValue]
                                ).length -
                                  1
                              "
                              >,
                            </span>
                          </span>
                        </span>
                        <span v-else :class="{ disabled: !item.newValue }">{{
                          item.newValue || 'Не вказано'
                        }}</span>
                      </span>
                    </div>
                  </template>
                </v-data-table>
              </div>
              <span v-else>Змін не знайдено</span>
            </div>
          </div>
        </v-menu>
      </div>
    </div>
  </div>
</template>

<script>
import EyeIcon from '@/assets/svg/eye-icon.vue'
import Confirmation from '@/components/Confirmation.vue'
import OpenableList from '@/components/OpenableList.vue'
import TableCrud from '@/components/table-crud.vue'
import { mapState } from 'vuex'
import {
  projectCalculationStatusReject,
  urlDownloadDraftDfl,
  urlGetCalculationHistory,
  urlGetGuaranteeLetter,
  urlGetInfoForDflDoc,
  urlGetLesseeSpouseDflAgreement,
  urlGetRequirementNoRegistrMvs,
  urlGetSellCarLetter,
} from '@/pages/request'
import { setSnackbar } from 'best-modules/plugins'
import { leasingObjectLogHeaders } from '@/utils/tableHeaders'
import {
  getContactInitials,
  getResponsibleShortName,
  getSafe,
  openDocument,
  toFormatDate,
} from '@/utils/helperFunc'
import SectionLoader from '@/components/section-loader.vue'

export default {
  components: { SectionLoader, TableCrud, OpenableList, Confirmation, EyeIcon },
  props: {
    calculation: { type: Object },
    project: { type: Object },
    slider: { type: Object },
    readonly: { type: Boolean, default: true },
  },
  data() {
    return {
      confirmationDialog: false,
      scoringBlock: false,
      historyBlock: false,
      logsLoading: false,
      logs: {},
      leasingObjectLogHeaders,
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
    }),
    admin() {
      return this.user.roleId === 1
    },
    middleHead() {
      return this.user.roleId === 6
    },
    scoring() {
      return this.slider?.scoring
    },
    singleScoring() {
      if (this.scoring) {
        const arr = []
        for (let i = 0; i < this.scoring.length; i += 3) {
          const group = this.scoring.slice(i, i + 3)
          arr.push(group)
        }
        return arr
      } else return null
    },
    printedForms() {
      const baseForms = [
        {
          action: () =>
            this.downloadPf(
              'Гарантійний лист постачальнику',
              urlGetGuaranteeLetter
            ),
          text: 'Гарантійний лист постачальнику',
          icon: 'mdi-file',
        },
        {
          action: () =>
            this.downloadPf(
              'Лист про продаж авто постачальника',
              urlGetSellCarLetter
            ),
          text: 'Лист про продаж авто постачальника',
          icon: 'mdi-file',
        },
      ]

      const forms = [...baseForms]

      if (this.project?.leasingClientType !== 2) {
        forms.push({
          action: () =>
            this.downloadPf(
              'Інфо для ФО при укладенні ДФЛ',
              urlGetInfoForDflDoc
            ),
          text: 'Інфо для ФО при укладенні ДФЛ',
          icon: 'mdi-file',
        })
      }

      // maybe if has calc has contract
      if (this.project?.statusId === 6) {
        forms.push({
          action: () => this.downloadPf('Драфт ДФЛ', urlDownloadDraftDfl),
          text: 'Драфт ДФЛ',
          icon: 'mdi-file',
        })
      }

      if (this.calculation?.contract && this.calculation?.contract?.id) {
        forms.push({
          action: () =>
            this.downloadPf(
              'Вимога без реєстрації сервісного центр МВС',
              urlGetRequirementNoRegistrMvs
            ),
          text: 'Вимога без реєстрації сервісного центру МВС',
          icon: 'mdi-file',
        })
      }

      if (
        this.project?.leasingClientType !== 2 &&
        this.project?.lessee?.marriedId
      ) {
        forms.push({
          action: () =>
            this.downloadPf(
              'Згода подружжя на укладання договору',
              urlGetLesseeSpouseDflAgreement
            ),
          text: 'Згода подружжя на укладання договору',
          icon: 'mdi-file',
        })
      }

      if (
        this.project?.leasingClientType !== 2 &&
        !this.project?.lessee?.marriedId
      ) {
        forms.push({
          action: () =>
            this.downloadPf(
              'Заява сімейний стан',
              urlGetLesseeSpouseDflAgreement
            ),
          text: 'Заява сімейний стан',
          icon: 'mdi-file',
        })
      }

      return forms
    },
  },
  methods: {
    getSafe,
    getResponsibleShortName,
    openDocument,
    getContactInitials,
    toFormatDate,
    getTHead() {
      return [
        {
          title: 'Служба',
          key: 'entityType.name',
          align: 'start',
          sortable: false,
        },
        {
          title: 'Відповідальний',
          key: 'responsible',
          align: 'start',
          sortable: false,
        },
        { title: 'Рішення', key: 'isAgree', align: 'start', sortable: false },
        {
          title: 'Коментар',
          key: 'scoringServiceComment',
          align: 'start',
          sortable: false,
        },
      ]
    },
    isDocument(value) {
      const isDoc = v => {
        return !!v && typeof v === 'object' && 'url' in v && 'text' in v
      }
      if (Array.isArray(value)) {
        return value.reduce((acc, cur) => isDoc(cur), false)
      } else {
        return isDoc(value)
      }
    },
    clientReject() {
      this.confirmationDialog = false
      return this.$axios
        .get(projectCalculationStatusReject(this.calculation.id))
        .then(res => {
          setSnackbar({ text: 'Клієнта переведено в статус Відмова' })
          this.$store.commit('refreshPage')

          return res
        })
    },
    getSingleLogs() {
      this.logsLoading = true
      return this.$axios
        .get(urlGetCalculationHistory(this.calculation.id))
        .then(res => {
          this.logs = res.data.data
          this.logsLoading = false
          return res
        })
        .catch(err => console.error(err))
    },
    downloadPf(title, url) {
      return this.$axios.get(url(this.calculation.id)).then(res => {
        return openDocument({
          text: title,
          url: res.data.url,
        })
      })
    },
  },
  mounted() {
    this.getSingleLogs()
  },
}
</script>

<style scoped lang="scss">
.history-block {
  background-color: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 0 10px -8px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0 0 10px -8px rgba(0, 0, 0, 1);
  box-shadow: 0 0 10px -8px rgba(0, 0, 0, 1);
  max-width: 700px !important;
  min-width: 700px !important;
  min-height: 600px;
  overflow: scroll;
}
.wrapper {
  padding-top: 0;
}
</style>
