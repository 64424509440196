<template>
  <div>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-x>
      <template #activator="{ props }">
        <v-btn
          :style="{ color: btnState.color === 'grey' ? '#000' : '#fff' }"
          :color="btnState.color"
          variant="flat"
          :disabled="btnState.disabled"
          v-bind="props">
          {{ btnState.label }}
        </v-btn>
      </template>

      <v-card>
        <v-divider></v-divider>
        <v-data-table
          :headers="vizHeader"
          :items="agreements"
          class="header-upper-case remove-borders"
          hide-default-footer>
          <!-- eslint-disable-next-line -->
          <template #item.isAgreed="{ item }">
            {{ item.isAgreed ? 'Завізовано' : 'Очікується' }}
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.user="{ item }">
            {{ item.user.fullName }} ({{ item.user.post }})
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.date="{ item }">
            {{ item.isAgreed ? toFormatDate(item.date) : '—' }}
          </template>
        </v-data-table>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { humanDate } from '@/utils/formatFunc'
import { getContactInitials, toFormatDate } from '@/utils/helperFunc'
export default {
  props: {
    agreements: { type: Array },
  },
  data: () => ({
    fav: true,
    menu: false,
    message: false,
    hints: true,
    viz: [],
    vizHeader: [
      { title: 'Статус', key: 'isAgreed', sortable: false, align: 'center' },
      { title: 'ПIБ', key: 'user', sortable: false, align: 'left' },
      { title: 'Дата', key: 'date', sortable: false, align: 'center' },
    ],
  }),
  computed: {
    btnState() {
      switch (true) {
        case (this.agreements || 0).length === 0:
          return {
            color: 'grey',
            label: 'Не завізовано',
            disabled: true,
            status: 1,
          }
        case !!this.agreements
          ?.map(a => a.isAgreed)
          .reduce((acc, cur) => (acc ? cur : acc), true):
          return { color: 'green', label: 'Завізовано', status: 3 }
        case this.agreements?.map(a => a.isAgreed).includes(false):
          return { color: 'warning', label: 'На візуваннi', status: 2 }
        default:
          return {
            color: 'grey',
            label: 'Не завізовано',
            disabled: true,
            status: 1,
          }
      }
    },
  },
  methods: {
    getContactInitials,
    humanDate,
    toFormatDate,
  },
}
</script>

<style></style>
