<template>
  <div>
    <v-text-field
      v-model="video.name"
      label="Назва"
      :error-messages="nameErr"
      dense
      :readonly="faqDeleteVideo"
      :disabled="faqDeleteVideo"
      @blur="v$.video.name.$touch()">
    </v-text-field>
    <v-select
      v-if="faqCreateVideo"
      v-model="video.typeId"
      :error-messages="typeIdErr"
      :items="types"
      item-title="name"
      item-value="id"
      label="Тип"
      @blur="v$.video.typeId.$touch()">
    </v-select>
    <v-textarea
      v-if="video.typeId === 2 && faqCreateVideo"
      v-model="video.url"
      :error-messages="urlErr"
      :rows="2"
      label="Посилання на youtube"
      placeholder="http://..."
      variant="outlined"
      dense
      @blur="v$.video.url.$touch()">
    </v-textarea>
    <FilesUploader
      v-if="video.typeId === 1 && faqCreateVideo"
      v-model:documents="video.url"
      label="Відео"
      acceptedExt="video/*"
      single
      @blur="v$.video.url.$touch()">
    </FilesUploader>
    <h4 v-if="faqDeleteVideo" class="pt-2">Відео буде видалено!</h4>
  </div>
</template>
<script>
import FilesUploader from '@/components/FilesUploader.vue'
import { required } from '@vuelidate/validators'

import { setErrHandler } from '@/utils/helperFunc'
import { urlFaqVideoCreate } from '@/pages/request'
import { useVuelidate } from '@vuelidate/core'
export default {
  components: { FilesUploader },
  props: { dialog: { type: Object } },
  setup() {
    return { v$: useVuelidate() }
  },
  validations() {
    const typeId = this.faqCreateVideo ? { required } : {}
    const url = this.faqCreateVideo ? { required } : {}
    const name = this.faqCreateVideo ? { required } : {}

    return {
      video: {
        typeId: typeId,
        url: url,
        name: name,
      },
    }
  },
  data: () => ({
    video: {
      typeId: null,
      url: null,
      name: null,
    },
    types: [
      { name: 'Файл', id: 1 },
      { name: 'Посилання', id: 2 },
    ],
  }),
  computed: {
    faqCreateVideo() {
      return this.dialog.action === 'faqCreateVideo'
    },
    faqDeleteVideo() {
      return this.dialog.action === 'faqDeleteVideo'
    },
    validation() {
      return { v$: this.v$.video, name: 'faqVideo' }
    },
    typeIdErr() {
      return setErrHandler(this.v$?.video?.typeId)
    },
    nameErr() {
      return setErrHandler(this.v$?.video?.name)
    },
    urlErr() {
      return setErrHandler(this.v$?.video?.url)
    },
  },
  methods: {
    submit() {
      const create = () =>
        this.$axios.post(urlFaqVideoCreate(), {
          ...this.video,
          url: this.video.url.url || this.video.url,
        })

      switch (true) {
        case this.faqCreateVideo:
          return create()
        case this.faqDeleteVideo:
          return this.dialog.params.deleteVideo(this.video.id)
      }
    },
  },
  mounted() {
    Object.assign(this.video, this.dialog.dialogItem)
  },
}
</script>
