<template>
  <div>
    <div
      class="d-flex align-center justify-space-between"
      style="
        background: #fff;
        padding: 13px;
        margin-bottom: 10px;
        margin-top: -20px;
        padding-top: 25px;
      ">
      <div class="pb-0 d-flex">
        <div class="data-card">
          <span style="font-size: 13px; color: #838282" class="pb-1 d-block"
            >Тип звернення</span
          >
          <span class="subtitle" style="font-size: 14px !important"
            >{{
              sourceTypeId === 3 ? 'Повторне звернення' : 'Первинне звернення'
            }}
          </span>
        </div>
        <div class="data-card">
          <span style="font-size: 13px; color: #838282" class="pb-1 d-block"
            >Дата звернення</span
          >
          <span class="subtitle" style="font-size: 14px !important">{{
            applicationDate
          }}</span>
        </div>
        <div class="data-card">
          <span style="font-size: 13px; color: #838282" class="pb-1 d-block"
            >Тип аналізу</span
          >
          <span class="subtitle" style="font-size: 14px !important">{{
            rdAnalyze.project.slider.analysisType?.name
          }}</span>
        </div>
        <div v-if="showBenefit" class="data-card">
          <v-tooltip bottom text="BEST BENEFITS">
            <template #activator="{ props }">
              <Benefit width="44px" height="44px" v-bind="props" />
            </template>
            <span>BEST BENEFITS</span>
          </v-tooltip>
        </div>
      </div>
      <div>
        <div>
          <span style="display: inline-block; margin-right: 10px">
            <TableCrud
              v-if="isInWork"
              data-readonly="hide"
              color="white"
              customIcon="Printer"
              :actionsArray="[
                {
                  action: downloadPaymentParameters,
                  text: 'Параметри угоди',
                  icon: 'mdi-file-document-edit-outline',
                  disabled: false,
                },
                ...protocols,
              ]">
            </TableCrud>
          </span>
          <span>
            <TableCrud
              color="white"
              :actionsArray="[
                {
                  action: takeToWork,
                  text: 'Взяти в роботу',
                  icon: 'mdi-timer-outline',
                  disabled: !rdAnalyze.responsibleId || disabled,
                },
                {
                  action: revision,
                  text: 'Відправити на доопрацювання',
                  icon: 'mdi-timer-off-outline',
                  disabled: checkRevision,
                },
              ]">
            </TableCrud>
          </span>
        </div>
      </div>
    </div>
    <ContractorCard
      v-if="contractorType"
      :contractor="rdAnalyze.project.lessee"
      finDocs
      legalDocs
      :isNew="rdAnalyze.status?.id === 1 && roleAccess">
      <template #after>
        <div style="margin-top: 10px" class="white-block">
          <span class="card-label d-block" style="margin-bottom: -20px"
            >Відповідальні:</span
          >
          <div class="section-card">
            <div>
              <v-row>
                <v-col :cols="12" :sm="3" class="pt-0 pb-0"> </v-col>
                <v-col :cols="12" :sm="9" class="pt-0 pb-0 text-start">
                  <span class="card-sublable mr-1">Фронт менеджер:</span>
                  <span class="card-value">{{ frontManager }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col :cols="12" :sm="3" class="pt-0 pb-0"> </v-col>
                <v-col :cols="12" :sm="9" class="pt-0 pb-0 text-start">
                  <span class="card-sublable mr-1">Мiдл менеджер:</span>
                  <span class="card-value">{{ middleManager }}</span>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </template>
    </ContractorCard>
    <ContactCard
      v-if="contactType"
      :contact="rdAnalyze.project.lessee"
      finDocs
      :isNew="rdAnalyze.status?.id === 1 && roleAccess"
      legalDocs
      marriedDocs>
      <template #after>
        <div style="background: #fff; margin-top: 10px">
          <span class="card-label d-block" style="margin-bottom: -20px"
            >Відповідальні:</span
          >
          <div class="section-card">
            <div>
              <v-row>
                <v-col :cols="12" :sm="3" class="pt-0 pb-0"> </v-col>
                <v-col :cols="12" :sm="9" class="pt-0 pb-0 text-start">
                  <span class="card-sublable mr-1">Фронт менеджер:</span>
                  <span class="card-value">{{ frontManager }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col :cols="12" :sm="3" class="pt-0 pb-0"> </v-col>
                <v-col :cols="12" :sm="9" class="pt-0 pb-0 text-start">
                  <span class="card-sublable mr-1">Мiдл менеджер:</span>
                  <span class="card-value">{{ middleManager }}</span>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </template>
    </ContactCard>
    <div class="white-block" style="margin-top: 10px">
      <div class="mt-0 pr-2">
        <div>
          <div class="link-section pb-2">
            <v-row style="padding-left: 13px">
              <v-col :cols="12" class="pl-0 pt-0 pb-0">
                <OpenableSection title="Документи по Ризик-департаменту">
                  <FilesUploader
                    v-model:documents="rdAnalyzeData.templateClientAnalysis"
                    label="Аналіз клієнта"
                    :required="true"
                    :loading="fileLoad === 'templateClientAnalysis'"
                    :readonly="!isInWork">
                  </FilesUploader>
                  <FilesUploader
                    v-model:documents="rdAnalyzeData.nbuRanking"
                    label="Рейтинг НБУ"
                    :loading="fileLoad === 'nbuRanking'"
                    :readonly="!isInWork">
                  </FilesUploader>
                  <FilesUploader
                    v-model:documents="rdAnalyzeData.agreementParameters"
                    label="Параметри угоди"
                    :required="true"
                    :loading="fileLoad === 'agreementParameters'"
                    :readonly="!isInWork">
                  </FilesUploader>
                  <FilesUploader
                    v-model:documents="
                      rdAnalyzeData.project.slider.applicationForm
                    "
                    label="Заявка анкета"
                    acceptedExt="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    single
                    readonly>
                  </FilesUploader>
                  <FilesUploader
                    v-model:documents="
                      rdAnalyzeData.project.slider.questionnaire
                    "
                    label="Опитувальник"
                    acceptedExt="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    single
                    readonly>
                  </FilesUploader>
                  <FilesUploader
                    v-if="rdAnalyze.project.slider.benefitsQuestionnaire"
                    :documents="
                      rdAnalyze.project.slider.benefitsQuestionnaire
                        ? rdAnalyze.project.slider.benefitsQuestionnaire
                            .questionnaire
                        : null
                    "
                    label="Опитувальник BEST BENEFITS"
                    acceptedExt="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    single
                    readonly>
                  </FilesUploader>
                  <FilesUploader
                    v-model:documents="rdAnalyzeData.additionallyDocuments"
                    label="Додаткові матеріали"
                    :loading="fileLoad === 'additionallyDocuments'"
                    acceptedExt="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    icon="mdi-file-document-multiple-outline"
                    :readonly="!isInWork">
                  </FilesUploader>
                  <FilesUploader
                    v-model:documents="rdAnalyzeData.project.projectScheme"
                    label="Схема реалізації"
                    single
                    readonly>
                  </FilesUploader>
                </OpenableSection>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ContractorCard from '@/components/ContractorCard.vue'
import ContactCard from '@/components/ContactCard.vue'
import FilesUploader from '@/components/FilesUploader.vue'
import {
  urlRiskAnalyzeSetDocuments,
  urlRiskTradeParams,
  urlTakeToWorkRiskAnalyze,
  urlGetKlpProtocolPath,
  urlRiskAnalyzeSetRevision,
} from '@/pages/request'
import OpenableSection from '@/components/OpenableSection.vue'

import { humanDate, formatDate } from '@/utils/formatFunc'
import { download, downloadPrintedForm } from '@/utils/helperFunc'
import Benefit from '@/assets/svg/benefit.vue'
import TableCrud from '@/components/table-crud.vue'

export default {
  emits: ['update'],
  components: {
    TableCrud,
    OpenableSection,
    ContractorCard,
    ContactCard,
    FilesUploader,
    Benefit,
  },
  props: {
    rdAnalyze: { type: Object },
    isInWork: { type: Boolean },
    loading: { type: Boolean, default: false },
  },
  data() {
    return {
      fileLoad: null,
    }
  },
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
      user: state => state.user,
    }),
    rdAnalyzeData() {
      return this.rdAnalyze
    },
    roleAccess() {
      return [2, 7, 8].includes(this.user?.role?.id)
    },
    sourceTypeId() {
      return this.rdAnalyze?.project?.slider?.application?.sourceTypeId
    },
    disabled() {
      return [2, 3, 4].includes(this.rdAnalyze.statusId)
    },
    checkRevision() {
      if ([1, 3].includes(this.rdAnalyze.statusId) && !this.rdAnalyze.isKlp) {
        return true
      }
      if (
        this.rdAnalyze.statusId === 4 &&
        this.rdAnalyze?.project?.klp?.result
      ) {
        return true
      }
      if (this.rdAnalyze.statusId === 4 && !this.rdAnalyze.isKlp) {
        return false
      } else return false
    },
    applicationDate() {
      const date = this.rdAnalyze?.created_at
      if (!date) return
      return humanDate(formatDate(date, true))
    },
    protocols() {
      return this.rdAnalyze?.project?.slider.calculations.map(c => ({
        action: () => {
          downloadPrintedForm(
            urlGetKlpProtocolPath(c.id, c.leasingObjectName),
            url => url.split('/')[2]
          )
        },
        text: `Протокол КЛП (${c.leasingObjectName})`,
        icon: 'mdi-download',
        disabled: false,
      }))
    },
    client() {
      return this.rdAnalyze?.project.lessee
    },
    contractorType() {
      return this.rdAnalyze?.project?.leasingClientType === 2
    },
    contactType() {
      return [1, 3].includes(this.rdAnalyze?.project?.leasingClientType)
    },
    frontManager() {
      const m = this.rdAnalyze.project?.frontManagerResponsible?.fullName
      if (!m) return
      return m
    },
    middleManager() {
      const m = this.rdAnalyze.project?.middleManagerResponsible?.fullName
      if (!m) return
      return m
    },
    showBenefit() {
      if (
        this.rdAnalyze?.project &&
        this.rdAnalyze?.project?.slider?.calculations
      ) {
        return this.rdAnalyze?.project?.slider?.calculations.some(
          t => t.leasingProgram === 34
        )
      }
      return false
    },
  },
  methods: {
    urlRiskAnalyzeSetRevision,
    urlRiskAnalyzeSetDocuments,
    urlTakeToWorkRiskAnalyze,
    takeToWork() {
      return this.$axios
        .get(this.urlTakeToWorkRiskAnalyze(this.$route.params.id))
        .then(res => {
          if (res.data.message) {
            this.$setSnackbar({ text: res.data.message, color: 'warning' })
          } else {
            this.$setSnackbar({ text: 'Статус: "В роботi"' })
            this.$emit('update')
          }
          return res
        })
        .catch(this.$err)
    },
    revision() {
      const serviceObj = {
        url: this.urlRiskAnalyzeSetRevision(this.rdAnalyze.id),
        name: 'risk',
      }
      return this.$store.commit('setDialog', {
        title: 'Відправити на доопрацювання',
        dialogItem: { serviceObj },
        params: {
          cb: () => this.$emit('update'),
        },
        action: 'sendToRevision',
      })
    },
    joinStr(...args) {
      return args.filter(str => str).join(' ')
    },
    async downloadPaymentParameters() {
      const { data } = await this.$axios.get(
        urlRiskTradeParams(this.rdAnalyze.projectId)
      )

      download(data.path, 'Параметри угоди')
    },
    addDocuments(doc) {
      if (Object.keys(this.rdAnalyze).length) {
        console.log('doc', doc)
        this.fileLoad = doc.replace('.length', '')
        return this.$axios
          .post(this.urlRiskAnalyzeSetDocuments(this.$route.params.id), {
            templateClientAnalysis:
              this.rdAnalyze.templateClientAnalysis || null,
            nbuRanking: this.rdAnalyze.nbuRanking || null,
            agreementParameters: this.rdAnalyze.agreementParameters || null,
            additionallyDocuments: this.rdAnalyze.additionallyDocuments || null,
          })
          .catch(this.$err)
          .finally(() => (this.fileLoad = null))
      }
    },
  },
  created() {
    const docs = [
      'rdAnalyze.templateClientAnalysis.length',
      'rdAnalyze.nbuRanking.length',
      'rdAnalyze.agreementParameters.length',
      'rdAnalyze.additionallyDocuments.length',
    ]
    docs.forEach(doc => {
      this.$watch(doc, () => this.addDocuments(doc))
    })
  },
}
</script>

<style scoped>
.data-card {
  display: flex;
  flex-direction: column;
  margin-right: 25px;
}
.card-value {
  color: #000000 !important;
}
.card-label {
  color: #000 !important;
}
.card-sublable {
  color: #838282;
  font-weight: 500;
  font-size: 13px;
}
.subtitle {
  color: #000;
}
</style>
