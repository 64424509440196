import { createVuetify } from 'vuetify'
import { VCalendar } from 'vuetify/labs/VCalendar'
import '@mdi/font/css/materialdesignicons.css'
import { VOtpInput } from 'vuetify/components'
import 'vuetify/styles'
import { uk } from './i18n'

const vuetify = createVuetify({
  components: {
    VOtpInput,
    VCalendar,
  },
  defaults: {
    VBtn: {
      variant: 'text',
      density: 'default',
    },
    VDataTable: {
      density: 'comfortable',
    },
    VTextField: {
      density: 'compact',
    },
    VSelect: {
      density: 'compact',
      slim: true,
    },
    VAutocomplete: {
      density: 'compact',
    },
    VTextarea: {
      density: 'compact',
    },
    VTooltip: {
      location: 'bottom',
    },
    vCheckbox: {
      density: 'compact',
    },
    vCombobox: {
      density: 'compact',
    },
  },
  locale: {
    locale: 'uk',
    fallback: 'en',
    messages: { uk },
  },
  lang: {
    locales: { uk },
    current: 'uk',
  },
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        primary: '#09487a',
        primaryBtn: '#fc7247',
        secondary: '#b0bec5',
        accent: '#8c9eff',
        error: '#b71c1c',
      },
    },
  },
  display: {
    mobileBreakpoint: 'sm',
    thresholds: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
})

export default vuetify
