<template>
  <div>
    <v-row>
      <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
        <span class="label">Тип особи</span>
        <v-select
          v-model="throughResponsible.entityTypeId"
          :items="selectTypes"
          :error-messages="entityTypeIdErr"
          item-value="id"
          placeholder="Оберіть зі списку"
          item-title="text"
          @blur="v$.throughResponsible.entityTypeId.$touch()"
          @update:model-value="() => (throughResponsible.entityId = null)">
        </v-select>
      </v-col>
      <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
        <span class="label">{{
          typeContractor ? 'Юридична особа' : 'Фізична особа'
        }}</span>
        <b-server-autocomplete
          v-model="throughResponsible.entityId"
          :url="typeContractor ? urlGetAllContractors() : urlContactsGetAll()"
          class="icon-contact"
          placeholder="Введіть текст (мін. 3 символи)"
          :error-messages="entityIdErr"
          :item-title="getEntityName"
          item-value="id"
          @blur="v$.throughResponsible.entityId.$touch()">
        </b-server-autocomplete>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getEntityName, setErrHandler } from '@/utils/helperFunc'
import {
  urlContactsGetAll,
  urlCreateThrough,
  urlGetAllContractors,
} from '@/pages/request'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { BServerAutocomplete } from 'best-modules/components'

export default {
  name: 'ThroughResponsible',
  components: { BServerAutocomplete },
  props: { dialog: { type: Object } },
  setup() {
    return { v$: useVuelidate() }
  },
  validations() {
    const throughResponsible = {
      entityTypeId: { required },
      entityId: { required },
    }

    return { throughResponsible }
  },
  data() {
    return {
      selectTypes: [
        { id: 1, text: 'Фізична особа' },
        { id: 2, text: 'Юридична особа' },
      ],
      mePayment: true,
      throughResponsible: {
        agentId: null,
        entityId: null,
        entityTypeId: 1,
      },
    }
  },
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
    }),
    validation() {
      return {
        v$: this.v$.throughResponsible,
        name: 'throughResponsible',
      }
    },
    entityTypeIdErr() {
      return setErrHandler(this.v$.throughResponsible.entityTypeId)
    },
    entityIdErr() {
      return setErrHandler(this.v$.throughResponsible.entityId)
    },
    typeContractor() {
      return this.throughResponsible.entityTypeId === 2
    },
  },
  methods: {
    urlGetAllContractors,
    urlContactsGetAll,
    getEntityName,
    submit() {
      const req = {
        agentId: this.dialog.dialogItem.id,
        entityId: this.throughResponsible.entityId,
        entityTypeId: this.throughResponsible.entityTypeId,
      }
      return this.$axios.post(urlCreateThrough(), req).then(res => {
        this.$setSnackbar({ text: 'Контактну особу додано' })
        this.$store.commit('refreshPage')

        return res
      })
    },
  },
}
</script>

<style scoped></style>
