<template>
  <div>
    <v-fade-transition hide-on-leave>
      <SectionLoader v-if="loading" style="height: 530px"></SectionLoader>
    </v-fade-transition>
    <v-fade-transition hide-on-leave>
      <div v-if="!loading" class="project-content-wrapper">
        <div style="margin-bottom: -10px" class="white-block">
          <div class="pb-2 pt-0">
            <div class="d-flex" style="justify-content: space-between">
              <div class="d-flex align-center">
                <div class="d-flex flex-column mr-10" style="height: 60px">
                  <span
                    class="d-block"
                    style="
                      font-size: 13px;
                      color: rgb(0, 0, 0);
                      margin-bottom: 7px;
                    "
                    >№ протоколу</span
                  >
                  <span
                    class="d-block"
                    style="font-size: 14px; color: #1c1d21"
                    >{{ project.number }}</span
                  >
                </div>
                <div class="d-flex flex-column mr-10" style="height: 60px">
                  <div>
                    <span
                      class="d-block"
                      style="font-size: 13px; color: rgb(0, 0, 0)">
                      Проект</span
                    >
                    <span
                      class="d-block"
                      style="
                        font-size: 14px;
                        color: #1c1d21;
                        margin-left: -10px;
                      ">
                      <InputPusher
                        v-if="project.projectId"
                        :routeObject="{
                          name: 'project',
                          params: { projectId: project.projectId },
                        }"
                        text="Перейти до проекту">
                      </InputPusher>
                      {{ project?.project?.projectNumber || '---' }}</span
                    >
                  </div>
                </div>
                <div class="d-flex flex-column mr-10" style="height: 60px">
                  <span
                    class="d-block"
                    style="
                      font-size: 13px;
                      color: rgb(0, 0, 0);
                      margin-bottom: 7px;
                    "
                    >Дата звернення</span
                  >
                  <span
                    class="d-block"
                    style="font-size: 14px; color: #1c1d21"
                    >{{ toFormatDate(project.date) }}</span
                  >
                </div>
                <div class="d-flex flex-column mr-10" style="height: 60px">
                  <span
                    class="d-block"
                    style="font-size: 13px; color: rgb(0, 0, 0)"
                    >Клієнт</span
                  >
                  <div class="d-flex align-center">
                    <span
                      class="d-block"
                      style="
                        font-size: 14px;
                        margin-left: -10px;
                        color: #1c1d21;
                      ">
                      <InputPusher
                        v-if="project.project.leasingClientId"
                        :routeObject="
                          getEntityRoute(
                            project.project.leasingClientId,
                            project.project.leasingClientType
                          )
                        "
                        text="Перейти до клієнта">
                      </InputPusher>
                    </span>
                    <v-tooltip bottom :text="project.project.lesseeName">
                      <template #activator="{ props }">
                        <span
                          style="
                            font-size: 14px;
                            color: rgb(28, 29, 33);
                            width: 420px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                          "
                          v-bind="props"
                          >{{ project.project.lesseeName }}</span
                        >
                      </template>
                      <span>{{ project.project.lesseeName }}</span>
                    </v-tooltip>
                  </div>
                </div>
              </div>
              <div>
                <span style="font-size: 0.93rem">
                  <TableCrud
                    data-readonly="hide"
                    class="mr-2"
                    :actionsArray="[
                      {
                        action: takeToWork,
                        text: 'Взяти в роботу',
                        icon: 'mdi-timer-outline',
                        disabled: project.statusId > 1,
                      },
                      {
                        action: reworkProject,
                        text: 'Передати проект на доопрацювання',
                        icon: 'mdi-timer-outline',
                        disabled: [1, 3, 4].includes(project.statusId),
                      },
                    ]">
                  </TableCrud>
                  <TableCrud
                    class="mr-2"
                    icon="mdi-printer"
                    :actionsArray="protocols">
                  </TableCrud>
                </span>
              </div>
            </div>

            <div
              class="d-flex align-end"
              style="justify-content: space-between">
              <div class="d-flex align-center gap-3">
                <div
                  v-if="project.limitOfFinancing && project.resultId === 1"
                  class="d-flex flex-column">
                  <span
                    class="d-block"
                    style="
                      font-size: 13px;
                      color: rgb(0, 0, 0);
                      margin-bottom: 7px;
                    "
                    >Ліміт фінансування</span
                  >
                  <span class="d-block" style="font-size: 14px; color: #1c1d21"
                    >{{
                      getBeautyNum(project.limitOfFinancing, { float: 2 })
                    }}
                    грн</span
                  >
                </div>
                <div v-if="project.status.id !== 4" class="d-flex">
                  <div class="mr-5">
                    <v-btn
                      :disabled="readonly"
                      color="success"
                      variant="flat"
                      class="text-white mr-1"
                      @click="accept">
                      Затвердити
                    </v-btn>
                  </div>
                  <div>
                    <v-btn
                      :disabled="readonly"
                      color="#cd5c5c"
                      variant="flat"
                      class="text-white"
                      @click="rejectProject">
                      Відмовити
                    </v-btn>
                  </div>
                </div>
                <div v-if="project.status.id === 4">
                  <v-btn style="background: #ff5722; color: #fff" readonly>{{
                    `${project.result?.name || '---'} (${toFormatDate(
                      project.finishDate
                    )}) `
                  }}</v-btn>
                </div>
              </div>
              <div class="mr-5 d-flex" style="justify-content: end">
                <Agreements :agreements="project.agreements" />
              </div>
            </div>
          </div>
        </div>
        <v-tabs
          v-model="currentTab"
          show-arrows
          :direction="$vuetify.breakpoint?.xs ? 'vertical' : 'horizontal'"
          :height="$vuetify.breakpoint?.xs ? null : 38"
          background-color="grey darken-2"
          color="white"
          dark
          slider-color="red"
          dense
          class="mt-5">
          <v-tab
            v-for="(calc, idx) in calculations"
            :key="calc.id"
            replace
            :to="{
              name: 'leasing-committee-leasing-object',
              params: { id: $route.params.id, idx: idx + 1 },
            }">
            {{ calc.leasingObjectName || '---' }}
          </v-tab>
          <v-tab
            replace
            :to="{
              name: 'leasing-committee-meeting',
              params: { id: $route.params.id },
            }">
            Засідання
          </v-tab>
          <v-tab
            replace
            :to="{
              name: 'leasing-committee-analyze',
              params: { id: $route.params.id },
            }">
            Аналіз
          </v-tab>
          <v-tab
            replace
            :to="{
              name: 'leasing-committee-documents',
              params: { id: $route.params.id },
            }">
            Документи
          </v-tab>
        </v-tabs>
        <div style="background: #fff; padding: 13px">
          <div class="pt-3" style="min-height: 120px">
            <v-scroll-x-reverse-transition hide-on-leave>
              <RouterView
                :project="project"
                :projectId="project.id"
                :applicationId="project.project.slider.application.id"
                :calculation="calculations[$route.params.idx - 1]"
                :readonly="readonly"
                :submit="submit"
                :klpAnalyze="project"
                @data-changed="getProject">
              </RouterView>
            </v-scroll-x-reverse-transition>
          </div>
          <v-card-actions class="pt-0">
            <ActionButtons
              :confirm="submit"
              cancelBtnText="Скасувати"
              :confirmDisable="cache === projectCache"
              :confirmLoading="loading || smallLoading"
              :cancel="getProject"
              :cancelDisable="cache === projectCache || loading">
            </ActionButtons>
          </v-card-actions>
        </div>
      </div>
    </v-fade-transition>
  </div>
</template>
<script>
import Agreements from '@/components/Agreements.vue'
import {
  changeInputVal,
  formatDate,
  formutSum,
  humanDate,
  keepDigit,
} from '@/utils/formatFunc'
import SectionLoader from '@/components/section-loader.vue'
import ActionButtons from '@/components/action-buttons.vue'

import InputPusher from '@/components/InputPusher.vue'
import { mapState } from 'vuex'

import {
  urlGetLeasingCommitteeProject,
  urlPostLeasingCommitteeDecision,
  urlGetKlpProtocolPath,
  urlKlpReworkProject,
  urlKlpTakeToWork,
  urlKlpSetResult,
} from '@/pages/request'
import {
  setCache,
  getEntityRoute,
  setErrHandler,
  v$Notify,
  toFormatDate,
  LODescription,
  getContactInitials,
  downloadPrintedForm,
  getEntityName,
  getBeautyNum,
} from '@/utils/helperFunc'
import { required, minLength } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import TableCrud from '@/components/table-crud.vue'
export default {
  emits: ['update'],
  components: {
    TableCrud,
    SectionLoader,
    ActionButtons,
    InputPusher,
    Agreements,
  },
  setup() {
    return { v$: useVuelidate() }
  },
  validations() {
    const date = this.project.date ? { required, minLength: minLength(10) } : {}

    return {
      project: {
        date: date,
      },
    }
  },
  data: () => ({
    currentTab: 0,
    loading: false,
    smallLoading: false,
    project: {},
    cache: null,
    fav: true,
    menu: false,
    message: false,
    hints: true,
    viz: [],
    vizHeader: [
      { text: 'ПIБ', key: 'user', sortable: false, align: 'center' },
      { text: 'Дата', key: 'date', sortable: false, align: 'center' },
    ],
  }),
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    projectId() {
      return this.$route?.params?.id
    },
    client() {
      return this.project.project
    },
    projectCache() {
      return this.setCache([this.project])
    },
    dateErr() {
      return setErrHandler(this.v$.project.date)
    },
    calculations() {
      return this.project.project.slider?.calculations || []
    },
    inWork() {
      return this.project?.statusId === 2
    },
    readonly() {
      return !this.inWork
    },
    protocols() {
      return this.calculations.map(c => ({
        action: () => {
          downloadPrintedForm(
            urlGetKlpProtocolPath(c.id, c.leasingObjectName),
            url => url.split('/')[2]
          )
        },
        text: `Протокол КЛП (${c.leasingObjectName})`,
        icon: 'mdi-download',
        disabled: false,
      }))
    },
  },
  methods: {
    getBeautyNum,
    formutSum,
    keepDigit,
    changeInputVal,
    getEntityName,
    setCache,
    getEntityRoute,
    toFormatDate,
    LODescription,
    urlKlpReworkProject,
    urlKlpTakeToWork,
    getContactInitials,
    urlKlpSetResult,
    humanDate,
    formatDate,
    takeToWork() {
      return this.$axios
        .get(this.urlKlpTakeToWork(this.project.id))
        .then(this.getProject)
        .then(() => this.$setSnackbar({ text: 'Проект взято в роботу' }))
    },
    reworkProject() {
      const serviceObj = {
        url: this.urlKlpReworkProject(this.project.id),
        name: 'klp',
      }
      return this.$store.commit('setDialog', {
        title: 'Відправити на доопрацювання',
        dialogItem: { serviceObj, klpRework: true },
        params: {
          cb: () => this.getProject(),
        },
        action: 'sendToRevision',
      })
    },
    accept() {
      return this.$store.commit('setDialog', {
        title: 'Затвердження КЛП',
        dialogItem: { project: this.project },
        params: {
          cb: () => {
            this.project.statusId = 6
            this.submit()
          },
        },
        action: 'klpLimitOfFinancing',
      })
    },
    rejectProject() {
      return this.$store.commit('setDialog', {
        title: 'Відмова',
        dialogItem: { projectId: this.project.projectId, id: 2, statusId: 8 },
        params: {
          cb: () => this.getProject().then(() => this.$emit('update')),
        },
        action: 'rejectProjectResult',
      })
    },
    getProject() {
      this.loading = true
      return this.$axios
        .get(urlGetLeasingCommitteeProject(this.projectId))
        .then(res => {
          if (!res.data.signedKlp) res.data.signedKlp = []
          if (!res.data.transactionParameters)
            res.data.transactionParameters = []
          Object.assign(this.project, res.data)
          this.setBreadScrumb()
          this.v$.$reset()
          this.cache = this.setCache([this.project])
          return res
        })
        .catch(err => this.$err(err, () => (this.loading = false)))
        .finally(() => (this.loading = false))
    },
    setBreadScrumb() {
      return this.$store.commit('setBreadScrumbPart', [null, null, null, null])
    },
    submit(o) {
      console.log(o)
      if (this.v$.$invalid) {
        this.v$.$anyError
        this.v$.$touch()
        v$Notify(this.v$, 'leasingCommittee')
        return
      }
      this.loading = true
      this.project.members &&
        (this.project.members = this.project.members.map(m => m.id))
      this.project.invitations = this.project.invitations
        ? this.project.invitations.map(i => i.id)
        : []
      this.project.headId = this.project?.head?.id || null

      const req = {
        customerAnalysisTemplate: this.project.customerAnalysisTemplate,
        nbuRanking: this.project.nbuRanking,
        transactionParameters: this.project.transactionParameters,
        signedKlp: this.project.signedKlp,
        additionallyDocuments: this.project.additionallyDocuments,
      }

      return this.$axios
        .post(urlPostLeasingCommitteeDecision(this.projectId), req)
        .then(this.successHanlder)
        .catch(err => () => this.$err(err, () => (this.loading = false)))
    },
    successHanlder(res) {
      this.$setSnackbar({ text: 'Проект оновлено' })
      this.loading = false
      this.getProject()
      return res
    },
  },
  watch: {
    '$route.name': function () {
      this.setBreadScrumb()
    },
  },
  created() {
    this.$store.dispatch('addContacts')
    this.getProject()
  },
}
</script>
<style scoped lang="scss">
.v-tab-item--selected.v-tab {
  font-weight: 700 !important;
  color: #08487a !important;
  font-size: 13px;
}
.v-tab {
  color: #838282 !important;
  font-size: 13px;
  letter-spacing: normal;
  text-transform: uppercase;
}
</style>
