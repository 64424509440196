<template>
  <div>
    <v-checkbox
      v-if="
        calculationData.modification &&
        calculationData.modification.maintenanceId
      "
      v-model="calculationData.hasMaintenance"
      :input-value="calculationData.hasMaintenance"
      :false-value="false"
      :true-value="true"
      class="d-inline-block"
      color="red"
      dense
      label="Додати стандартний сервіс"
      :disabled="!carObjType"
      @update:model-value="hasMaintenanceHandler">
    </v-checkbox>
    <v-alert v-else type="warning" elevation="2" dense width="max-content">
      <span v-if="calculationData.modification">
        Для даної модифікації не створено карту ТО
      </span>
      <span v-else>Оберіть модифікацію</span>
    </v-alert>
    <div class="blur" :class="{ active: calculationData.hasMaintenance }">
      <div class="absolute-screen"></div>
      <v-row class="d-flex justify-space-between">
        <v-col :cols="12" :md="6" class="pb-0 mb-3">
          <h3 class="crm-subtitle mt-0">TО</h3>
          <v-row class="align-center">
            <v-col :cols="12" :sm="5" class="pb-0 flex-end">
              <span
                class="card-label calc"
                :class="{
                  'required-value':
                    !!!calculationData.maintenanceRequest.scheduledMileage,
                }">
                Пробiг в рiк:
              </span>
            </v-col>
            <v-col :cols="12" :sm="7" class="pb-0">
              <v-text-field
                id="ss-mil-km"
                v-model="calculationData.maintenanceRequest.scheduledMileage"
                :error-messages="scheduledMileageErr"
                placeholder="км"
                dense
                hide-details
                @input="inputNumber($event)"
                @blur="
                  $event => {
                    $v.calculation.maintenanceRequest.scheduledMileage.$touch()
                    inputNumber($event, {
                      minValue: getSafe(
                        () =>
                          calculationData.modification.maintenance.intervalStep,
                        0
                      ),
                    })
                  }
                "
                @focusout="Input.restoreData('ss-mil-km')"
                @focusin="Input.hideData('ss-mil-km')">
                <template #append-inner>
                  <span>км.</span>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="align-center">
            <v-col :cols="12" :sm="5" class="pb-0 flex-end">
              <span
                class="card-label calc"
                :class="{
                  'required-value':
                    !!!calculationData.maintenanceRequest.servicePackage,
                }">
                Пакет:
              </span>
            </v-col>
            <v-col :cols="12" :sm="7" class="pb-0">
              <v-select
                v-model="calculationData.maintenanceRequest.servicePackage"
                :items="servicePackages"
                item-title="name"
                item-value="id"
                placeholder="оберіть пакет"
                :error-messages="servicePackageErr"
                return-object
                dense
                hide-details
                :loading="$loading.isLoading('servicePackages')"
                @focus="$directory.fill('servicePackages')"
                @blur="
                  $v.calculation.maintenanceRequest.servicePackage.$touch()
                ">
                <template
                  v-if="
                    $loading.isLoading('servicePackages') &&
                    !$directory.get(
                      'servicePackages',
                      calculationData.maintenanceRequest.servicePackage
                    ).length
                  "
                  #no-data>
                  <loader></loader>
                </template>
                <template
                  v-if="
                    $loading.isLoading('servicePackages') &
                    !!$directory.get(
                      'servicePackages',
                      calculationData.maintenanceRequest.servicePackage
                    ).length
                  "
                  #append-item>
                  <loader></loader>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row class="align-center">
            <v-col :cols="12" :sm="5" class="pb-0 flex-end">
              <span
                class="card-label calc"
                :class="{
                  'required-value':
                    !!!calculationData.maintenanceRequest.paymentsBind,
                }">
                Прив'язка до валюти:
              </span>
            </v-col>
            <v-col :cols="12" :sm="7" class="pb-0">
              <v-text-field
                v-model="calculationData.maintenanceRequest.paymentsBind"
                :error-messages="paymentsBindErr"
                dense
                hide-details
                readonly
                @blur="$v.calculation.maintenanceRequest.paymentsBind.$touch()">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="align-center">
            <v-col :cols="12" :sm="5" class="pb-0 flex-end">
              <span
                class="card-label calc"
                :class="{
                  'required-value':
                    !!!calculationData.maintenanceRequest.contractTerm,
                }">
                Термін договору сервісу:
              </span>
            </v-col>
            <v-col :cols="12" :sm="7" class="pb-0">
              <v-text-field
                id="contract-term"
                v-model="calculationData.maintenanceRequest.contractTerm"
                :error-messages="contractTermErr"
                placeholder="Введіть текст"
                dense
                hide-details
                readonly
                @blur="$v.calculation.maintenanceRequest.contractTerm.$touch()">
                <template #append-inner>
                  <span>міс</span>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="align-center">
            <v-col :cols="12" :sm="5" class="pb-0 flex-end">
              <span
                class="card-label calc"
                :class="{
                  'required-value':
                    !!!calculationData.maintenanceRequest.additionalCosts,
                }">
                Додаткові витрати:
              </span>
            </v-col>
            <v-col :cols="12" :sm="7" class="pb-0">
              <v-text-field
                id="additional-сosts"
                v-model="calculationData.maintenanceRequest.additionalCosts"
                :error-messages="additionalCostsErr"
                placeholder="Введіть грн/рік"
                dense
                hide-details
                @input="inputNumber($event, { float: 2 })"
                @blur="
                  $v.calculation.maintenanceRequest.additionalCosts.$touch()
                "
                @focusout="Input.restoreData('additional-сosts')"
                @focusin="Input.hideData('additional-сosts')">
                <template #append-inner>
                  <span style="width: 43px">грн/рік</span>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <h3 class="crm-subtitle d-flex gap-3 align-center">
            <span>Гума</span>
            <v-checkbox
              v-model="calculationData.maintenanceRequest.hasTire"
              color="red"
              dense
              class="mt-0"
              hide-details
              @update:model-value="clearTire($event)">
            </v-checkbox>
          </h3>
          <v-scroll-x-transition>
            <div v-show="calculationData.maintenanceRequest.hasTire">
              <v-row class="align-center">
                <v-col :cols="12" :sm="5" class="pb-0 flex-end">
                  <span
                    class="card-label calc"
                    :class="{
                      'required-value':
                        !!!calculationData.maintenanceRequest.tireSize,
                    }">
                    Розмiр гуми:
                  </span>
                </v-col>
                <v-col :cols="12" :sm="7" class="pb-0">
                  <v-select
                    v-model="calculationData.maintenanceRequest.tireSize"
                    :error-messages="tireSizeErr"
                    :items="
                      directoryItems(
                        calculationData.tires,
                        calculationData.maintenanceRequest.tireSize
                      )
                    "
                    item-value="id"
                    item-title="name"
                    placeholder="оберіть розмiр"
                    dense
                    return-object
                    hide-details
                    @blur="$v.calculation.maintenanceRequest.tireSize.$touch()">
                  </v-select>
                </v-col>
              </v-row>
              <v-row class="align-center">
                <v-col :cols="12" :sm="5" class="pb-0 flex-end">
                  <span
                    class="card-label calc"
                    :class="{ 'required-value': !!!calculationData.tireSets }">
                    Кiлькiсть комплектів:
                  </span>
                </v-col>
                <v-col :cols="12" :sm="7" class="pb-0">
                  <v-text-field
                    id="ss-tire-am"
                    v-model="calculationData.maintenanceRequest.tireSets"
                    :error-messages="tireSetsErr"
                    placeholder="Введіть кількість"
                    dense
                    hide-details
                    @input="maxNum('ss-tire-am', 60)"
                    @focusout="Input.restoreData('ss-tire-am')"
                    @focusin="Input.hideData('ss-tire-am')"
                    @blur="$v.calculation.maintenanceRequest.tireSets.$touch()">
                    <template #append-inner>
                      <span>шт</span>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="align-center">
                <v-col :cols="12" :sm="5" class="pb-0 flex-end">
                  <span
                    class="card-label calc"
                    :class="{
                      'required-value':
                        !!!calculationData.maintenanceRequest.tireCost,
                    }">
                    Виробник:
                  </span>
                </v-col>
                <v-col :cols="12" :sm="7" class="pb-0">
                  <v-select
                    v-model="calculationData.maintenanceRequest.tiresCatalog"
                    :error-messages="tiresCatalogErr"
                    :items="
                      directoryItems(
                        tiresCatalogList,
                        calculationData.maintenanceRequest.tiresCatalog
                      )
                    "
                    :item-title="
                      t =>
                        `${t.manufacturer} ${t.name} (${
                          t.season
                        }) - ${getBeautyNum(t.cost, {
                          float: 2,
                        })}`
                    "
                    item-value="id"
                    placeholder="Оберіть зі списку"
                    dense
                    hide-details
                    return-object
                    @focus="!tiresCatalogList.length && getTireCatalogList()"
                    @blur="
                      $v.calculation.maintenanceRequest.tiresCatalog.$touch()
                    ">
                  </v-select>
                </v-col>
              </v-row>
              <v-row class="align-center">
                <v-col :cols="12" :sm="5" class="pb-0 flex-end">
                  <span
                    class="card-label calc"
                    :class="{
                      'required-value':
                        !!!calculationData.maintenanceRequest.tireCost,
                    }">
                    Цiна:
                  </span>
                </v-col>
                <v-col :cols="12" :sm="7" class="pb-0">
                  <v-text-field
                    :model-value="
                      getBeautyNum(
                        calculationData.maintenanceRequest.tireCost || 0,
                        {
                          float: 2,
                        }
                      )
                    "
                    :error-messages="tireCostErr"
                    placeholder="Введіть грн/шт"
                    dense
                    hide-details
                    readonly>
                    <template #append-inner>
                      <span style="width: 45px">грн/шт</span>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="align-center">
                <v-col :cols="12" :sm="5" class="pb-0 flex-end">
                  <span class="card-label calc"> Сезонний шиномонтаж: </span>
                </v-col>
                <v-col :cols="12" :sm="7" class="pb-0">
                  <v-select
                    v-model="calculationData.maintenanceRequest.tireFitting"
                    :error-messages="tireFittingErr"
                    :items="$directory.get('yesOrNo')"
                    item-title="text"
                    item-value="value"
                    dense
                    hide-details
                    @blur="
                      $v.calculation.maintenanceRequest.tireFitting.$touch()
                    ">
                  </v-select>
                </v-col>
              </v-row>
              <v-row class="align-center">
                <v-col :cols="12" :sm="5" class="pb-0 flex-end">
                  <span class="card-label calc"> Зберiгання шин: </span>
                </v-col>
                <v-col :cols="12" :sm="7" class="pb-0">
                  <v-select
                    v-model="calculationData.maintenanceRequest.tireStorage"
                    :error-messages="tireStorageErr"
                    :items="$directory.get('yesOrNo')"
                    item-title="text"
                    item-value="value"
                    dense
                    hide-details
                    @blur="
                      $v.calculation.maintenanceRequest.tireStorage.$touch()
                    ">
                  </v-select>
                </v-col>
              </v-row>
            </div>
          </v-scroll-x-transition>
        </v-col>

        <v-col :cols="12" :md="6" class="pb-0 pl-3 pl-lg-10">
          <h3 class="crm-subtitle d-flex align-center mt-0">
            <span class="mr-2">Підмінний авто</span>
            <v-menu open-on-hover offset-y>
              <template #activator="{ props }">
                <v-icon size="17" class="pb-3" v-bind="props">
                  mdi-information
                </v-icon>
              </template>

              <v-card style="width: max-content; background: #fff !important">
                <v-card-text>
                  <div class="d-flex">
                    <div style="width: 150px">
                      <div class="disabled">Клас</div>
                      <div class="mt-2">Бюджет</div>
                      <div class="mt-2">Стандарт</div>
                      <div class="mt-2">Преміум</div>
                    </div>
                    <div>
                      <div class="disabled">Рекомендована к-сть днів</div>
                      <div class="mt-2">7</div>
                      <div class="mt-2">14</div>
                      <div class="mt-2">21</div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-menu>
            <v-checkbox
              v-model="calculationData.maintenanceRequest.replacementCarSupport"
              color="red"
              dense
              class="mt-0 ml-3"
              hide-details
              @update:model-value="clearReplacementCarSupport">
            </v-checkbox>
          </h3>

          <v-scroll-x-transition>
            <div
              v-show="calculationData.maintenanceRequest.replacementCarSupport"
              class="mt-2">
              <v-row class="align-center">
                <v-col :cols="12" :sm="5" class="pb-0 flex-end">
                  <span
                    class="card-label calc"
                    :class="{
                      'required-value':
                        !!!calculationData.maintenanceRequest
                          .replacementCarClass,
                    }">
                    Пiдмiнний авто класу:
                  </span>
                </v-col>
                <v-col :cols="12" :sm="7" class="pb-0">
                  <v-select
                    v-model="
                      calculationData.maintenanceRequest.replacementCarClass
                    "
                    :error-messages="replacementCarClassErr"
                    :items="
                      $directory.get(
                        'replacementCarClasses',
                        calculationData.maintenanceRequest.replacementCarClasses
                      )
                    "
                    item-value="id"
                    item-title="name"
                    placeholder="Оберіть класс"
                    dense
                    hide-details
                    return-object
                    @focus="$directory.fill('replacementCarClasses')"
                    @blur="
                      $v.calculation.maintenanceRequest.replacementCarClass.$touch()
                    ">
                    <template
                      v-if="
                        $loading.isLoading('replacementCarClasses') &&
                        !$directory.get(
                          'replacementCarClasses',
                          calculationData.maintenanceRequest
                            .replacementCarClasses
                        ).length
                      "
                      #no-data>
                      <loader></loader>
                    </template>
                    <template
                      v-if="
                        $loading.isLoading('replacementCarClasses') &&
                        !!$directory.get(
                          'replacementCarClasses',
                          calculationData.maintenanceRequest
                            .replacementCarClasses
                        ).length
                      "
                      #append-item>
                      <loader></loader>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row class="align-center">
                <v-col :cols="12" :sm="5" class="pb-0 flex-end">
                  <span
                    class="card-label calc"
                    :class="{
                      'required-value':
                        !!!calculationData.maintenanceRequest.numberDays,
                    }">
                    Пiдмiн в рiк:
                  </span>
                </v-col>
                <v-col :cols="12" :sm="7" class="pb-0">
                  <v-text-field
                    id="ss-day-am"
                    v-model="calculationData.maintenanceRequest.numberDays"
                    :error-messages="numberDaysErr"
                    placeholder="Введіть текст"
                    dense
                    hide-details
                    @input="maxNum('ss-day-am', 366)"
                    @focusout="Input.restoreData('ss-day-am')"
                    @focusin="Input.hideData('ss-day-am')"
                    @blur="
                      $v.calculation.maintenanceRequest.numberDays.$touch()
                    ">
                  </v-text-field>
                </v-col>
              </v-row>
            </div>
          </v-scroll-x-transition>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { maxNum, inputNumber } from '@/utils/formatFunc'
import { setErrHandler, Input, getBeautyNum, getSafe } from '@/utils/helperFunc'
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import { urlGetTiresCatalogList } from '@/pages/request'
import { useSelect } from '@/utils/mixins/useSelect'
import { getDirectory } from '@/plugins/directory'

export default {
  components: { Loader },
  setup() {
    return {
      ...useSelect(),
    }
  },
  props: {
    readOnly: { type: Boolean },
    calculation: { type: Object },
    truck: { type: Boolean, default: false },
    agricultural: { type: Boolean, default: false },
    car: { type: Boolean, default: false },
    busShort: { type: Boolean, default: false },
    special: { type: Boolean, default: false },
    equipment: { type: Boolean, default: false },
    carObjType: { type: Boolean, default: false },
    v: { type: Object },
  },
  data: () => ({
    Input: new Input(),
    tiresCatalogList: [],
  }),
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    $v() {
      return this.v
    },
    calculationData() {
      return this.calculation
    },
    servicePackages() {
      return getDirectory(
        'servicePackages',
        this.calculationData.maintenanceRequest.servicePackage
      ).filter(p => {
        if (p.id === 2) {
          return !!this.calculationData.maintenanceRequest.class
        }
        if (p.id === 3) {
          return !!this.calculationData.maintenanceRequest.maintenancePremiumId
        }
        return true
      })
    },
    scheduledMileageErr() {
      return setErrHandler(
        this.$v.calculation.maintenanceRequest.scheduledMileage
      )
    },
    servicePackageErr() {
      return setErrHandler(
        this.$v.calculation.maintenanceRequest.servicePackage
      )
    },
    paymentsBindErr() {
      return setErrHandler(this.$v.calculation.maintenanceRequest.paymentsBind)
    },
    contractTermErr() {
      return setErrHandler(this.$v.calculation.maintenanceRequest.contractTerm)
    },
    additionalCostsErr() {
      return setErrHandler(
        this.$v.calculation.maintenanceRequest.additionalCosts
      )
    },
    tireSizeErr() {
      return setErrHandler(this.$v.calculation.maintenanceRequest.tireSize)
    },
    tireSetsErr() {
      return setErrHandler(this.$v.calculation.maintenanceRequest.tireSets)
    },
    tiresCatalogErr() {
      return setErrHandler(this.$v.calculation.maintenanceRequest.tiresCatalog)
    },
    tireCostErr() {
      return setErrHandler(this.$v.calculation.maintenanceRequest.tireCost)
    },
    tireFittingErr() {
      return setErrHandler(this.$v.calculation.maintenanceRequest.tireFitting)
    },
    tireStorageErr() {
      return setErrHandler(this.$v.calculation.maintenanceRequest.tireStorage)
    },
    replacementCarClassErr() {
      return setErrHandler(
        this.$v.calculation.maintenanceRequest.replacementCarClass
      )
    },
    numberDaysErr() {
      return setErrHandler(this.$v.calculation.maintenanceRequest.numberDays)
    },
  },
  methods: {
    getSafe,
    maxNum,
    inputNumber,
    getBeautyNum,
    getTireCatalogList() {
      if (this.calculationData.maintenanceRequest.tireSize) {
        return this.$axios
          .post(urlGetTiresCatalogList(), {
            name: this.calculationData.maintenanceRequest.tireSize.name,
          })
          .then(res => {
            this.tiresCatalogList.splice(0)
            this.tiresCatalogList.push(...res.data)
          })
      } else {
        this.tiresCatalogList.splice(0)
      }
    },
    hasMaintenanceHandler(e) {
      if (!e) {
        this.calculationData.maintenanceRequest = {}
        this.$v.$reset()
      } else {
        this.calculationData.maintenanceRequest = {
          scheduledMileage:
            this.calculationData.modification.maintenance?.intervalStep,
          servicePackage: null,
          paymentsBind: this.calculationData.currency,
          contractTerm: this.calculationData.leasingTerm,
          class: this.calculationData.leasedAssertModel?.class || null,
          maintenancePremiumId:
            this.calculationData.modification?.maintenancePremiumId || null,
          additionalCosts: null,
          hasTire: false,
          tireSize: null,
          tireSets: null,
          tiresCatalog: null,
          tireCost: null,
          tireFitting: 2,
          tireStorage: 2,
          replacementCarSupport: false,
          replacementCarClass: null,
          numberDays: null,
        }
      }
    },
    clearTire(e) {
      console.log(e)
      if (!e) {
        this.calculationData.maintenanceRequest.tireSize = null
        this.calculationData.maintenanceRequest.tireFitting = 2
        this.calculationData.maintenanceRequest.tireStorage = 2
        this.calculationData.maintenanceRequest.tireSets = null
        this.calculationData.maintenanceRequest.tiresCatalog = null
        this.calculationData.maintenanceRequest.tireCost = null
        this.$v.$reset()
      }
    },
    clearReplacementCarSupport(e) {
      if (!e) {
        this.calculationData.maintenanceRequest.replacementCarClass = null
        this.calculationData.maintenanceRequest.numberDays = null
        this.$v.$reset()
      }
    },
  },
  watch: {
    'calculationData.maintenanceRequest.tireSize': function () {
      this.getTireCatalogList()
    },
    'calculationData.maintenanceRequest.tiresCatalog': {
      handler() {
        if (this.calculationData.maintenanceRequest.tiresCatalog) {
          this.calculationData.maintenanceRequest.tireCost =
            this.calculationData.maintenanceRequest.tiresCatalog.cost
        } else {
          this.calculationData.maintenanceRequest.tireCost = null
        }
      },
    },
    tiresCatalogList() {
      if (!this.tiresCatalogList.length) {
        this.calculationData.maintenanceRequest.tiresCatalog = null
      }
    },
    servicePackages() {
      const isAvailablePackage = this.servicePackages
        .map(p => p.id)
        .includes(this.calculationData.maintenanceRequest.servicePackage?.id)
      if (!isAvailablePackage) {
        this.calculationData.maintenanceRequest.servicePackage = null
      }
    },
  },
}
</script>
<style lang="scss">
.blur {
  position: relative;
  &:not(.active) {
    filter: blur(0.1rem);
    .absolute-screen {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 500;
    }
  }
}
</style>
