<template>
  <div class="pb-0" style="padding: 10px !important">
    <div>
      <v-row>
        <v-col
          v-show="
            !sourceReTreatment ||
            (sourceReTreatment &&
              applicationData.clientTypeId &&
              (applicationData.edrpou ||
                applicationData.inn ||
                (application.sourceTypeId === 3 &&
                  application.lessee.contact) ||
                application.lessee.contractorId))
          "
          :cols="12"
          :md="12"
          class="pt-0 pb-0">
          <v-row class="mt-3 mb-3">
            <div class="projectRow">
              <PersonalIcon class="mr-2" />
              <span class="projectTitle">Основні дані</span>
            </div>
          </v-row>
          <v-fade-transition hide-on-leave>
            <v-row>
              <v-col
                v-show="
                  !sourceReTreatment ||
                  (sourceReTreatment &&
                    applicationData.clientTypeId &&
                    (applicationData.edrpou ||
                      applicationData.inn ||
                      (application.sourceTypeId === 3 &&
                        application.lessee.contact) ||
                      application.lessee.contractorId))
                "
                class="pt-0"
                :cols="12"
                :md="4">
                <span class="subtitle">Тип клієнта</span>
                <v-select
                  v-model="applicationData.clientTypeId"
                  :error-messages="clientTypeIdErr"
                  :items="
                    $directory
                      .get('clientTypes', applicationData.client_type)
                      .filter(t => {
                        if (application?.lessee?.contact?.fop) {
                          return t.id !== 2
                        } else {
                          return true
                        }
                      })
                  "
                  item-title="name"
                  item-value="id"
                  placeholder="оберіть тип клієнта"
                  dense
                  :loading="$loading.isLoading('clientTypes')"
                  :readonly="
                    !(sourceReTreatment && application?.lessee?.contact?.fop) &&
                    (!!application.lessee.contactId ||
                      !!mainContactId ||
                      !!application.lessee.contractorId ||
                      !!mainContractorId ||
                      sourceReTreatment)
                  "
                  :disabled="
                    !(sourceReTreatment && application?.lessee?.contact?.fop) &&
                    (!!application.lessee.contactId ||
                      !!mainContactId ||
                      !!application.lessee.contractorId ||
                      !!mainContractorId ||
                      !!applicationData.readOnly ||
                      sourceReTreatment)
                  "
                  @update:model-value="clearClientFields"
                  @blur="
                    () => {
                      $v.application.clientTypeId?.$touch()
                    }
                  "
                  @focus="$directory.fill('clientTypes')">
                  <template #no-data><Loader /></template>
                  <template #append>
                    <span
                      v-if="
                        !!application.lessee.contactId ||
                        !!mainContactId ||
                        !!application.lessee.contractorId ||
                        !!mainContractorId ||
                        !!applicationData.readOnly ||
                        sourceReTreatment
                      "></span>
                  </template>
                </v-select>
              </v-col>
              <v-col
                v-if="
                  (sourceReTreatment && applicationData.inn) ||
                  [1, 3].includes(applicationData.clientTypeId)
                "
                :cols="12"
                :md="4"
                class="pt-0">
                <span class="subtitle">Iдентифiкацiйний код</span>
                <div v-if="!sourceReTreatment || applicationData.inn">
                  <v-text-field
                    id="new-application-inn"
                    v-model="applicationData.inn"
                    :error-messages="innErr"
                    placeholder="10 цифр"
                    dense
                    :loading="edrpouLoading"
                    :disabled="
                      !!applicationData.readOnly ||
                      sourceReTreatment ||
                      hasContactContractor
                    "
                    @blur="
                      () => {
                        $v.application.inn.$touch()
                        searchDuplicates()
                      }
                    "
                    @input="maxNumLength('new-application-inn', 10)">
                  </v-text-field>
                </div>
                <span v-else>
                  <v-text-field placeholder="Відсутнє" readonly> </v-text-field>
                </span>
              </v-col>
              <v-col
                v-if="applicationData.clientTypeId === 2"
                :cols="12"
                :md="4"
                class="pt-0">
                <div
                  v-show="
                    !sourceReTreatment ||
                    (sourceReTreatment && applicationData.edrpou)
                  ">
                  <span class="subtitle">ЄДРПОУ</span>
                  <v-text-field
                    id="new-application-edrpou"
                    v-model="applicationData.edrpou"
                    :error-messages="edrpouErr"
                    placeholder="8 цифр"
                    dense
                    :disabled="!!applicationData.readOnly || sourceReTreatment"
                    @blur="
                      () => {
                        $v.application.edrpou.$touch()
                        searchDuplicates()
                      }
                    "
                    @input="maxNumLength('new-application-edrpou', 8)">
                    <template #append>
                      <v-btn
                        v-show="edrpouLoading"
                        icon
                        size="x-small"
                        :loading="edrpouLoading">
                      </v-btn>
                    </template>
                  </v-text-field>
                </div>
              </v-col>
              <v-col
                v-if="applicationData.clientTypeId === 2"
                :cols="12"
                :md="4"
                class="pt-0">
                <div
                  v-show="
                    !sourceReTreatment ||
                    (sourceReTreatment && applicationData.companyName)
                  ">
                  <span class="subtitle">Назва компанії</span>

                  <v-tooltip
                    v-if="
                      applicationData.companyName &&
                      applicationData.companyName.length > 15
                    "
                    :text="applicationData.companyName"
                    bottom>
                    <template #activator="{ props }">
                      <v-text-field
                        id="company-name"
                        v-model="applicationData.companyName"
                        :error-messages="companyNameErr"
                        placeholder="Вкажіть назву компанiї"
                        dense
                        :disabled="
                          !!applicationData.readOnly || sourceReTreatment
                        "
                        v-bind="props"
                        @blur="$v.application.companyName.$touch()">
                      </v-text-field>
                    </template>
                    <span>{{ applicationData.companyName }}</span>
                  </v-tooltip>

                  <v-text-field
                    v-else
                    id="company-name"
                    v-model="applicationData.companyName"
                    :error-messages="companyNameErr"
                    placeholder="Вкажіть назву компанiї"
                    dense
                    :disabled="!!applicationData.readOnly || sourceReTreatment"
                    @blur="$v.application.companyName.$touch()">
                  </v-text-field>
                </div>
              </v-col>
            </v-row>
          </v-fade-transition>
        </v-col>
      </v-row>
      <div
        v-show="
          !sourceReTreatment ||
          (sourceReTreatment &&
            applicationData.clientTypeId &&
            (applicationData.edrpou ||
              applicationData.inn ||
              (application.sourceTypeId === 3 && application.lessee.contact) ||
              application.lessee.contractorId))
        "
        class="projectRow mt-3 mb-3"
        style="padding-left: 0 !important">
        <GuarantorIcon class="mr-2" />
        <span class="projectTitle">Контактна особа</span>
      </div>
      <div
        v-if="
          applicationData.sourceTypeId === 3 &&
          application.clientTypeId === 2 &&
          application.lessee.contractor
        ">
        <v-row v-if="applicationData.readonly" class="mb-4">
          <v-col :cols="12" :md="8" class="pt-0 pb-0 pl-3">
            <v-btn
              class="text-white"
              style="background-color: #fc7247; border-radius: 4px"
              small
              @click="openAddContactDialog">
              Додати контакт
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="12" :md="4" style="padding-top: 0; padding-bottom: 0">
            <v-select
              v-model="applicationData.lessee.contactPersonId"
              :items="selectItems.applicationContacts"
              :readonly="applicationData.readonly"
              placeholder="Оберіть контактну особу"
              item-title="contact.fullName"
              item-value="contact.id">
            </v-select>
          </v-col>
        </v-row>
      </div>
      <v-row v-if="applicationData.sourceTypeId !== 3">
        <v-col :cols="12" :md="4">
          <span class="subtitle">Прізвище</span>
          <v-text-field
            v-model="applicationData.surname"
            :error-messages="surnameErr"
            placeholder="Введіть текст"
            hide-details
            dense
            :disabled="
              !!application.lessee.contactId ||
              !!mainContactId ||
              !!applicationData.readOnly ||
              (sourceReTreatment && mainContactId)
            "
            :readonly="
              !!application.lessee.contactId ||
              !!mainContactId ||
              (sourceReTreatment && mainContactId)
            "
            @blur="getSafe(() => $v.application.surname.$touch())">
          </v-text-field>
        </v-col>
        <v-col :cols="12" :md="4">
          <span class="subtitle">Iм`я</span>
          <v-text-field
            v-model="applicationData.name"
            :error-messages="nameErr"
            placeholder="Введіть текст"
            hide-details
            dense
            :disabled="
              !!application.lessee.contactId ||
              !!mainContactId ||
              !!applicationData.readOnly ||
              (sourceReTreatment && mainContactId)
            "
            :readonly="
              !!application.lessee.contactId ||
              !!mainContactId ||
              (sourceReTreatment && mainContactId)
            "
            @blur="getSafe(() => $v.application.name.$touch())">
          </v-text-field>
        </v-col>
        <v-col :cols="12" :md="4">
          <span class="subtitle">По батькові</span>
          <v-text-field
            v-model="applicationData.patronymic"
            :error-messages="patronymicErr"
            :placeholder="
              applicationData.isPatronymic ? 'Введіть текст' : 'Відсутнє'
            "
            hide-details
            dense
            :disabled="
              !applicationData.isPatronymic ||
              !!application.lessee.contactId ||
              !!mainContactId ||
              !!applicationData.readOnly ||
              (sourceReTreatment && mainContactId)
            "
            :readonly="
              !!application.lessee.contactId ||
              !!mainContactId ||
              (sourceReTreatment && mainContactId)
            "
            @blur="getSafe(() => $v.application.patronymic.$touch())">
          </v-text-field>
        </v-col>
      </v-row>
    </div>
    <div
      v-if="application.sourceTypeId === 3 && application.clientTypeId === 1">
      <v-row>
        <v-col :cols="12" :md="4">
          <span class="subtitle">Прізвище</span>
          <v-text-field :model-value="applicationContactSurname" readonly>
          </v-text-field>
        </v-col>
        <v-col :cols="12" :md="4">
          <span class="subtitle">Імʼя</span>
          <v-text-field :model-value="applicationContactName" readonly>
          </v-text-field>
        </v-col>
        <v-col :cols="12" :md="4">
          <span class="subtitle">По батькові</span>
          <v-text-field
            :model-value="applicationContactNamePatronymic"
            placeholder="Відсутнє"
            readonly>
          </v-text-field>
        </v-col>
      </v-row>
    </div>
    <v-row>
      <v-col :cols="12" :md="4" class="pt-0">
        <div
          v-if="
            application.sourceTypeId !== 3 ||
            application.lessee.contactPersonId ||
            application.lessee.contact
          "
          class="d-flex align-center mr-3">
          <div v-if="applicationData.sourceTypeId === 1">
            <v-checkbox
              v-model="applicationData.isMissingData"
              hide-details
              color="red"
              class="mt-2"
              dense>
              <template #label>
                <span style="font-size: 12px">Контактні дані відсутні</span>
              </template>
            </v-checkbox>
          </div>
        </div>
      </v-col>
      <v-col :cols="12" :md="4" class="pt-0"> </v-col>
      <v-col :cols="12" :md="4" class="pt-0">
        <div v-if="application.sourceTypeId !== 3" class="d-flex align-center">
          <div>
            <v-checkbox
              v-model="applicationData.isPatronymic"
              :true-value="false"
              :false-value="true"
              hide-details
              color="red"
              class="mt-2"
              dense>
              <template #label>
                <span style="font-size: 12px">Відсутнє по батькові</span>
              </template>
            </v-checkbox>
          </div>
        </div>
      </v-col>
    </v-row>
    <div>
      <v-row
        v-if="
          (!applicationData.isMissingData && application.sourceTypeId !== 3) ||
          application.lessee.contactPersonId ||
          application.lessee.contact
        "
        class="pb-2 d-flex">
        <v-col :cols="12" :md="4" class="pt-0 pb-0 pr-3">
          <span class="subtitle">Телефон</span>
          <Contact
            v-model="applicationData.phones"
            title="Телефони"
            placeholder="Вкажіть номер"
            itemKey="phone"
            :readonly="sourceReTreatment || application.readOnly"
            :error="phoneErr"
            :isValidItem="isValidPhone"
            :mask="phoneMask"
            :deleteItem="deletePhone"
            :checkItem="checkPhone"
            @blur="
              () => {
                $v.application.phones.$touch()
              }
            ">
          </Contact>
        </v-col>
        <v-col :cols="12" :md="4" class="pt-0 pb-0 pr-3">
          <span class="subtitle">Email</span>
          <Contact
            v-model="applicationData.emails"
            title="Emails"
            placeholder="Вкажіть email"
            itemKey="email"
            :error="emailErr"
            :readonly="sourceReTreatment || application.readOnly"
            :isValidItem="isEmail"
            :mask="emailMask"
            :deleteItem="deleteEmail"
            :checkItem="checkEmail"
            @blur="$v.application.emails.$touch()">
          </Contact>
        </v-col>
        <v-col
          v-if="applicationData.other"
          cols="12"
          md="4"
          sm="12"
          class="pt-0 pb-0 pr-3 d-flex align-center"
          style="position: relative">
          <span class="text__link" @click="dialog = true">Деталі</span>
          <div v-if="dialog" class="dialog applicationDetailsMobileDialog">
            <div class="dialogWrapper">
              <v-btn class="dialogButton" icon @click="dialog = false">
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 18 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.7693 12.2692L6.23084 6.73072M6.23084 12.2692L11.7693 6.73072M17.3078 9.49995C17.3078 4.91341 13.5866 1.19226 9.00007 1.19226C4.41354 1.19226 0.692383 4.91341 0.692383 9.49995C0.692383 14.0865 4.41354 17.8076 9.00007 17.8076C13.5866 17.8076 17.3078 14.0865 17.3078 9.49995Z"
                    stroke="#C0C1C7"
                    stroke-linecap="round" />
                </svg>
              </v-btn>
              <OtherTable
                :otherInfo="applicationData.other"
                :source="applicationData.sourceName" />
            </div>
          </div>
        </v-col>
      </v-row>
      <div
        v-show="
          !sourceReTreatment ||
          (sourceReTreatment &&
            applicationData.clientTypeId &&
            (applicationData.edrpou ||
              applicationData.inn ||
              (application.sourceTypeId === 3 && application.lessee.contact) ||
              application.lessee.contractorId))
        "
        class="projectRow mb-3 mt-5"
        style="padding-left: 0 !important">
        <CommentIcon v-if="!createApplicationMode" class="mr-2" />
        <span v-if="!createApplicationMode" class="projectTitle">Коментар</span>
      </div>
      <Comments
        v-if="$route.params.applicationId"
        style="margin-top: -20px !important"
        :label="false"
        :comments="application.comments"
        :entityId="application.id"
        :entityTypeId="3" />
    </div>
  </div>
</template>
<script>
import CommentIcon from '@/assets/svg/comment-icon.vue'
import PersonalIcon from '@/assets/svg/personal-icon.vue'
import GuarantorIcon from '@/assets/svg/guarantor-icon.vue'
import Contact from '@/components/Contact.vue'
import Comments from '@/components/comments/Index.vue'
import OtherTable from '@/pages/leads/components/otherTable.vue'
import {
  isEmail,
  getContactInitials,
  setErrHandler,
  getSafe,
} from '@/utils/helperFunc'
import { formatDate } from '@/utils/formatFunc'
import { phoneMask, maxNumLength, emailMask } from '@/utils/masks'
import {
  urlApplicationDeletePhone,
  urlApplicationDeleteEmail,
  urlApplicationCheckEmail,
  urlApplicationCheckPhone,
  urlApplicationAddComment,
  urlApplicationUpdateComment,
  urlDeleteApplicationComment,
  urlSearchDuplicatesByCode,
} from '@/pages/request'
import Loader from '@/components/Loader.vue'
import { mapState } from 'vuex'
import { useSelect } from '@/utils/mixins/useSelect'
import { setSnackbar } from 'best-modules/plugins'
export default {
  components: {
    OtherTable,
    Contact,
    Comments,
    Loader,
    GuarantorIcon,
    PersonalIcon,
    CommentIcon,
  },
  props: {
    application: { type: Object },
    mainContactId: { type: Number },
    mainContractorId: { type: Number },
    sourceReTreatment: { type: Boolean },
    createApplicationMode: { type: Boolean },
    v: { type: Object },
  },
  setup() {
    return {
      ...useSelect(),
    }
  },
  data: () => ({
    edrpouLoading: false,
    addPhoneMode: false,
    addEmailMode: false,
    currentPhone: null,
    currentEmail: null,
    emails: [],
    phones: [],
    emailToDelete: null,
    phoneToDelete: null,
    dialog: false,
  }),
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
      user: state => state.user,
    }),
    applicationContactName() {
      return this.application.lessee.contact?.fullName.split(' ')[1] || ''
    },
    applicationContactSurname() {
      return this.application.lessee.contact?.fullName.split(' ')[0] || ''
    },
    applicationContactNamePatronymic() {
      return this.application.lessee.contact?.fullName.split(' ')[2] || ''
    },
    applicationData() {
      return this.application
    },
    $v() {
      return this.v
    },
    clientTypeIdErr() {
      return this.setErrHandler(this.$v.application?.clientTypeId)
    },
    nameErr() {
      return this.setErrHandler(this.$v.application?.name)
    },
    surnameErr() {
      return this.setErrHandler(this.$v.application?.surname)
    },
    patronymicErr() {
      return this.setErrHandler(this.$v.application?.patronymic)
    },
    companyNameErr() {
      return this.setErrHandler(this.$v.application?.companyName)
    },
    edrpouErr() {
      return this.setErrHandler(this.$v.application?.edrpou)
    },
    innErr() {
      return this.setErrHandler(this.$v.application?.inn)
    },
    emailErr() {
      return this.setErrHandler(this.$v.application.emails)
    },
    phoneErr() {
      return this.setErrHandler(this.$v.application.phones)
    },
    hasContactContractor() {
      return !!(
        this.applicationData.lessee?.contact ||
        this.applicationData.lessee?.contractor
      )
    },
  },
  methods: {
    getSafe,
    formatDate,
    isEmail,
    phoneMask,
    emailMask,
    maxNumLength,
    setErrHandler,
    getContactInitials,
    urlSearchDuplicatesByCode,
    clearClientFields() {
      this.applicationData.edrpou = null
      this.applicationData.companyName = null
      this.applicationData.surname = null
      this.applicationData.name = null
      this.applicationData.patronymic = null
    },
    async searchDuplicates() {
      const isContractor = this.applicationData.clientTypeId === 2
      const code = isContractor
        ? this.applicationData.edrpou
        : this.applicationData.inn
      const codeName = isContractor ? 'ЄДРПОУ' : 'ІПН'
      const codeLength = isContractor ? 8 : 10

      if (code?.length === codeLength) {
        this.edrpouLoading = true
        return this.$axios(this.urlSearchDuplicatesByCode(code))
          .then(res => {
            if (res.data.length) {
              const msg = [
                {
                  tag: 'div',
                  text: `Знайдені збіги по ${codeName} - ${code}`,
                  class: 'mb-3',
                },
                {
                  tag: 'ol',
                  children: res.data
                    .map(item => {
                      const listItem = [
                        {
                          tag: 'li',
                          text: `Назва: ${
                            item.inn
                              ? getContactInitials(item)
                              : item.name ||
                                item.shortName ||
                                'Назву не знайдено'
                          }`,
                        },
                        {
                          tag: 'li',
                          text: `Відповідальний: ${getContactInitials(
                            item.responsible
                          )}`,
                        },
                      ]

                      if (item.isClient) {
                        listItem.push({
                          tag: 'li',
                          text: `Статус клієнта: ${item?.clientStatus?.name}`,
                        })
                      }

                      return listItem
                    })
                    .flat(),
                },
              ]

              setSnackbar({ text: msg, color: 'warning' })
            }
          })
          .finally(() => (this.edrpouLoading = false))
      }
    },
    getDuplicateNotifyHTML(entity) {
      const isContractor = entity.typeId === 2
      const name = isContractor ? entity.name : this.getContactInitials(entity)
      return `<div style="margin-top: 15px; font-size: 14px">
                <div><b>${
                  entity.typeId === 2 ? 'Назва компанії' : 'ПІБ'
                }: </b>${name}</div>
                <div><b>Відповідальний: </b>${this.getContactInitials(
                  entity.responsible,
                  { isResponsible: true }
                )}</div>
              </div>`
    },
    openAddContactDialog() {
      this.$store.commit('setDialog', {
        title: 'Контакт',
        dialogItem: {
          surname: null,
          name: null,
          contractorId: this.application.lessee.contractor.id,
          applicationId: this.application.id,
        },
        params: {
          cb: () => {
            this.$store.dispatch(
              'addApplicationContacts',
              this.application.lessee.contractorId
            )
            this.$setSnackbar({ text: 'Створено контакт' })
          },
        },
        action: 'addContactFromApplication',
      })
    },
    addComment(item) {
      return this.$axios
        .post(urlApplicationAddComment(), {
          ...item,
          applicationId: this.$route.params.applicationId,
        })
        .then(res => {
          this.$emit('dataChanged')
          return res
        })
    },
    deleteComment(item) {
      return this.$axios
        .delete(urlDeleteApplicationComment(item.id))
        .then(res => {
          this.$emit('dataChanged')
          return res
        })
    },
    editComment(item) {
      return this.$axios
        .post(urlApplicationUpdateComment(item.id), {
          ...item,
          applicationId: this.$route.params.applicationId,
        })
        .then(res => {
          this.$emit('dataChanged')
          return res
        })
    },
    isValidPhone(phone) {
      return phone?.length === 23
    },
    deleteEmail(item, withMessage = true) {
      item = this.getItem(item, 'email')

      if (this.$route.params.applicationId && item?.id) {
        this.$axios
          .get(urlApplicationDeleteEmail(this.application.id, item.id))
          .then(res => {
            withMessage && this.$setSnackbar({ text: 'Email успiшно видалено' })
            return res
          })
          .catch(this.$err)
      }

      return Promise.resolve(item)
    },
    deletePhone(item, withMessage = true) {
      item = this.getItem(item, 'phone')

      if (this.$route.params.applicationId && item?.id) {
        this.$axios
          .get(urlApplicationDeletePhone(this.application.id, item.id))
          .then(res => {
            withMessage &&
              this.$setSnackbar({ text: 'Телефон успiшно видалено' })
            return res
          })
          .catch(this.$err)
      }

      return Promise.resolve(item)
    },
    getItem(value, key) {
      switch (key) {
        case 'email':
          return (
            this.application.emails.find(item => item.email === value) || value
          )
        case 'phone':
          return (
            this.application.phones.find(item => item.phone === value) || value
          )
      }
    },
    checkPhone(phone) {
      return this.$axios
        .post(urlApplicationCheckPhone(), phone)
        .then(res => {
          if (
            res.data.status === true &&
            !this.applicationData.phones.map(item => item.phone.includes(phone))
          ) {
            phone = null
            this.contactAlreadyInUse(res.data)
            return phone
          }

          return phone
        })
        .catch(this.$err)
    },
    clearContacts() {
      this.$emit('clearContacts')
    },
    checkEmail(email) {
      return this.$axios
        .post(urlApplicationCheckEmail(), email)
        .then(res => {
          if (
            res.data.status === true &&
            !this.applicationData.emails.map(item => item.email.includes(email))
          ) {
            email = null
            this.contactAlreadyInUse(res.data)
            return email
          }

          return email
        })
        .catch(this.$err)
    },
    contactAlreadyInUse(res) {
      const message = `${res.message}: ${res.name}. Відповідальний: ${res.responsible}`
      this.$setSnackbar({ text: message, color: 'warning' })
    },
  },
  watch: {
    'selectItems.applicationContacts': function (val) {
      if (val.length === 0) {
        this.applicationData.lessee.contactPersonId = null
      }
    },
    'applicationData.isPatronymic': function (val) {
      if (val === false) {
        this.applicationData.patronymic = null
      }
    },
  },
}
</script>

<style scoped>
.subtitle {
  color: #838282;
  font-weight: 500;
  font-size: 13px;
  display: block;
  margin-bottom: 2px;
}
.projectRow {
  display: flex;
  align-items: center;
  padding: 0 12px;
}

.projectTitle {
  display: block;
  color: #08487a;
  font-weight: 600;
  font-size: 16px;
}

.dialog {
  position: absolute;
  background: white;
  left: -500px;
  right: 50px;
  top: 0;
  z-index: 1000000;
  border: 1px solid #d1d1d1;
}

.dialogWrapper {
  padding: 15px;
  position: relative;
  padding-top: 30px;
  height: 100vh;
  overflow: scroll;
}

.dialogButton {
  position: absolute;
  right: 0;
  top: 0;
}
.text__link {
  cursor: pointer;
  color: #08487a;
  text-decoration-line: underline;
  font-weight: 500;
  font-size: 12px;
}
</style>
