<template>
  <div>
    <div v-if="routeSuretyAgreements">
      <Filters :filters="filters" class="ml-2" />
      <SectionLoader v-if="loading" />
      <div v-show="!loading" class="pt-5">
        <v-row>
          <v-col :cols="12">
            <v-data-table
              :headers="suretyAgreementListHeaders"
              :items="suretyAgreements"
              class="calc-table --cust-cell-pd"
              mobileBreakpoint="750"
              hide-default-footer
              :items-per-page="-1"
              :hide-default-header="!suretyAgreements.length">
              <!-- eslint-disable-next-line -->
              <template #item.actions="{ item }">
                <TableCrud
                  :actionsArray="[
                    {
                      action: () =>
                        $router.push({
                          name: 'surety-agreement',
                          params: { id: item.id },
                        }),
                      text: 'Відкрити',
                      icon: 'mdi-folder-open-outline',
                    },
                  ]">
                </TableCrud>
              </template>
              <!-- eslint-disable-next-line -->
              <template #item.debtor="{ item }">
                <ALink
                  v-if="item.debtor.name"
                  :text="item.debtor.name"
                  :route="getEntityRoute(item.debtor.id, item.debtor.typeId)">
                </ALink>
                <span v-else>---</span>
              </template>
              <!-- eslint-disable-next-line -->
              <template #item.guarantor="{ item }">
                <ALink
                  v-if="item.guarantor"
                  :text="item.guarantor.name"
                  :route="
                    getEntityRoute(item.guarantor.id, item.guarantor.typeId)
                  ">
                </ALink>
                <span v-else>---</span>
              </template>
              <!-- eslint-disable-next-line -->
              <template #item.date="{ item }">
                {{ toFormatDate(item.date, true) }}
              </template>
              <!-- eslint-disable-next-line -->
              <template #item.number="{ item }">
                <ALink
                  :text="item.number"
                  :route="{
                    name: 'surety-agreement',
                    params: { id: item.id },
                  }">
                </ALink>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <Pagination
          :getItems="getSuretyAgreements"
          :trigger="paginationTrigger" />
      </div>
    </div>
    <router-view v-if="!routeSuretyAgreements"></router-view>
  </div>
</template>
<script>
import TableCrud from '@/components/table-crud.vue'
import SectionLoader from '@/components/section-loader.vue'
import ALink from '@/components/Link.vue'
import Pagination from '@/components/Pagination.vue'
import Filters from '@/components/Filters.vue'
import { urlGetSuretyAgreements } from '@/pages/request'
import { getEntityRoute, toFormatDate } from '@/utils/helperFunc'
import { formatDate } from '@/utils/formatFunc'
import { useFilter } from '@/utils/mixins/useFilter'
import { reactive } from 'vue'
import { suretyAgreementListHeaders } from '@/utils/tableHeaders'
export default {
  components: {
    Pagination,
    TableCrud,
    SectionLoader,
    ALink,
    Filters,
  },
  setup() {
    const filters = reactive({
      statusId: {
        value: null,
        component: 'v-autocomplete',
        attrs: { items: 'dflStatuses', label: 'Статус' },
        focus: { action: 'addDFLStatuses' },
      },
      startDate: {
        value: null,
        component: 'DatePicker',
        attrs: { label: 'Період', range: true },
      },
      search: { value: null },
    })
    return {
      ...useFilter(filters),
      filters,
    }
  },
  data() {
    return {
      suretyAgreements: [],
      loading: false,
      suretyAgreementListHeaders,
    }
  },
  computed: {
    routeSuretyAgreements() {
      return this.$route.name === 'surety-agreements'
    },
  },
  methods: {
    getEntityRoute,
    toFormatDate,
    formatDate,
    getSuretyAgreements(page = 1) {
      this.loading = true
      return this.$axios
        .get(urlGetSuretyAgreements(page, this.filtersQueryObj))
        .then(res => {
          this.suretyAgreements = res.data.data
          return res
        })
        .catch(this.$err)
        .finally(() => (this.loading = false))
    },
  },
}
</script>
