<template>
  <div>
    <div class="mb-6 d-flex justify-end" style="height: 36px">
      <v-btn
        v-if="!calculationData.carForSale"
        variant="flat"
        class="text-white"
        color="#fb7146"
        @click="carForSaleSelect">
        <v-icon class="mr-2">mdi-car</v-icon>
        Обрати з автопарку
      </v-btn>
      <div v-else class="d-flex align-center gap-1">
        <v-btn
          v-tooltip:bottom="'Очистити'"
          variant="text"
          color="#fb7146"
          icon
          density="compact"
          @click="carForSaleRemove">
          <v-icon size="sm">mdi-delete</v-icon>
        </v-btn>
        <v-btn
          v-tooltip:bottom="'Обрати інше авто'"
          variant="text"
          color="#fb7146"
          icon
          density="compact"
          @click="carForSaleSelect">
          <v-icon size="sm">mdi-pencil</v-icon>
        </v-btn>
        <span
          class="link"
          @click="
            $router.push({
              name: 'car-for-sale-single',
              params: { id: calculationData.carForSale.id },
            })
          ">
          {{
            [
              calculationData.carForSale.mark.name,
              calculationData.carForSale.model.name,
              calculationData.carForSale.modification.name,
            ].join(' ')
          }}
          ({{ calculationData.carForSale.carNumber }})
        </span>
      </div>
    </div>
    <v-row class="d-flex justify-space-between">
      <v-col :cols="12" :md="6" class="pt-0 pb-0">
        <v-row class="mt-3 mb-5">
          <div class="projectRow">
            <svg
              class="mr-2"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="18"
              viewBox="0 0 20 18"
              fill="none">
              <path
                d="M17.4967 1.51067C17.28 0.871504 16.6733 0.416504 15.9583 0.416504H4.04167C3.32667 0.416504 2.73083 0.871504 2.50333 1.51067L0.369167 7.65317C0.293333 7.88067 0.25 8.119 0.25 8.36817V16.1248C0.25 17.024 0.975833 17.7498 1.875 17.7498C2.77417 17.7498 3.5 17.024 3.5 16.1248V15.5832H16.5V16.1248C16.5 17.0132 17.2258 17.7498 18.125 17.7498C19.0133 17.7498 19.75 17.024 19.75 16.1248V8.36817C19.75 8.12984 19.7067 7.88067 19.6308 7.65317L17.4967 1.51067ZM4.04167 12.3332C3.1425 12.3332 2.41667 11.6073 2.41667 10.7082C2.41667 9.809 3.1425 9.08317 4.04167 9.08317C4.94083 9.08317 5.66667 9.809 5.66667 10.7082C5.66667 11.6073 4.94083 12.3332 4.04167 12.3332ZM15.9583 12.3332C15.0592 12.3332 14.3333 11.6073 14.3333 10.7082C14.3333 9.809 15.0592 9.08317 15.9583 9.08317C16.8575 9.08317 17.5833 9.809 17.5833 10.7082C17.5833 11.6073 16.8575 12.3332 15.9583 12.3332ZM2.41667 6.9165L3.7925 2.77817C3.94417 2.34484 4.35583 2.0415 4.82167 2.0415H15.1783C15.6442 2.0415 16.0558 2.34484 16.2075 2.77817L17.5833 6.9165H2.41667Z"
                fill="#08487A" />
            </svg>
            <span class="projectTitle">Об'єкт лізингу</span>
          </div>
        </v-row>
        <CarApi
          :model-value="calculationData"
          :v="$v.calculation"
          @update:model-value="Object.assign(calculationData, $event)" />
        <v-row v-show="car" class="pt-1 align-center">
          <v-col :cols="12" :sm="5" class="pt-0 pb-0 flex-end">
            <span class="card-label calc">Пeнсiйний фонд</span>
          </v-col>
          <v-col :cols="12" :sm="7" class="pt-0 pb-0">
            <v-checkbox
              v-model="calculationData.paymentPf"
              class="mt-0"
              color="red"
              :false-value="2"
              :true-value="1"
              hide-details
              dense>
            </v-checkbox>
          </v-col>
        </v-row>
        <v-scroll-x-transition hide-on-leave>
          <div v-show="agricultural || special || busShort || car || truck">
            <v-row class="pt-1">
              <v-col :cols="12" :sm="5" class="flex-end">
                <span class="card-label calc">Додаткове обладнання</span>
              </v-col>
              <v-col :cols="12" :sm="7" class="pt-0 pb-0">
                <v-checkbox
                  v-model="calculationData.additionalEquipment"
                  :input-value="calculationData.additionalEquipment"
                  class="mt-0"
                  color="red"
                  hide-details
                  dense
                  :disabled="
                    !!!calculationData.leasingAmount ||
                    calculationData.programFSN
                  "
                  @update:model-value="
                    clearAdditionalEquipment(
                      calculationData.additionalEquipment
                    )
                  ">
                </v-checkbox>
              </v-col>
            </v-row>
            <v-scroll-x-transition hide-on-leave>
              <v-row
                v-show="
                  calculationData.additionalEquipment &&
                  calculationData.leasingAmount
                "
                class="pb-3">
                <v-col :cols="12" :sm="5" class="pt-0 flex-end">
                  <span class="card-label calc"></span>
                </v-col>
                <v-col :cols="12" :sm="7" class="pt-0 mb-3">
                  <v-row>
                    <v-col :cols="12" :sm="6" class="pt-0 pb-0">
                      <span class="label">Сума</span>
                      <v-text-field
                        id="ae-equip-sum"
                        v-model="calculationData.addEquipmentSum"
                        class="pt-0 mt-0"
                        placeholder="0"
                        hide-details
                        dense
                        @input="
                          changeInputVal('ae-equip-sum', 'input', [
                            keepDigit,
                            formutSum,
                          ])
                        "
                        @focusout="Input.restoreData('ae-equip-sum')"
                        @focusin="Input.hideData('ae-equip-sum')">
                      </v-text-field>
                    </v-col>
                    <v-col :cols="12" :sm="6" class="pt-0 pb-0">
                      <span class="label">Знижка %</span>
                      <v-text-field
                        id="ae-disc"
                        v-model="calculationData.equipmentDiscount"
                        class="pt-0 mt-0"
                        placeholder="0"
                        type="number"
                        min="0"
                        max="15"
                        hide-details
                        dense
                        @input="
                          () => {
                            maxNum('ae-disc', 15)
                            minNum('ae-disc', 0)
                          }
                        "
                        @focusout="Input.restoreData('ae-disc')"
                        @focusin="Input.hideData('ae-disc')">
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-scroll-x-transition>
          </div>
        </v-scroll-x-transition>
      </v-col>
      <!-- Right Column -->
      <v-col :cols="12" :md="6" class="pt-0 pb-0 pl-3 pl-lg-10">
        <v-row class="mt-3 mb-5">
          <div class="projectRow">
            <FinanceIcon class="mr-2" />
            <span class="projectTitle">Вартiсть</span>
          </div>
        </v-row>
        <v-row class="mt-2 align-center">
          <v-col :cols="12" :sm="5" class="pt-0 pb-0 flex-center">
            <span
              class="card-label calc"
              :class="{ 'required-value': !!!calculationData.leasingAmount }">
              Цiна об'єкту за ДФЛ
            </span>
          </v-col>
          <v-col :cols="12" :sm="7" class="pt-0 pb-0">
            <v-row class="row">
              <v-col :cols="7" class="pt-0 pb-0 pr-0">
                <v-text-field
                  id="dfl-obj-price"
                  v-model="calculationData.leasingAmount"
                  :error-messages="leasingAmountErr"
                  class="pt-0 mt-0"
                  placeholder="Вкажіть вартiсть"
                  hide-details
                  @input="
                    $event => {
                      inputNumber($event, { float: 2, minValue: 1 })
                      calculationData.leasingAmountDkp = dkpWithDiscountSum
                    }
                  "
                  @blur="
                    $event => {
                      inputNumber($event, { float: 2, saveFloat: true })
                      $v.calculation.leasingAmount.$touch()
                    }
                  "
                  @focusin="Input.hideData('dfl-obj-price')"
                  @focusout="Input.restoreData('dfl-obj-price')">
                </v-text-field>
              </v-col>
              <v-col :cols="5" class="pt-0 pb-0">
                <v-select
                  v-model="calculationData.leasingCurrency"
                  :error-messages="leasingCurrencyErr"
                  :items="$directory.get('currencies')"
                  class="pt-0 mt-0"
                  item-title="name"
                  item-value="name"
                  placeholder="Вкажіть валюту"
                  :disabled="!calculation.leasingAmount"
                  hide-details
                  @update:model-value="
                    $event => {
                      changeCourse($event)
                      $emit('changeLP')
                    }
                  "
                  @blur="$v.calculation.leasingCurrency.$touch()">
                </v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-scroll-x-transition hide-on-leave>
          <v-row v-if="calculation.leasingAmount" class="align-center">
            <v-col :cols="12" :sm="5" class="pb-0 flex-center">
              <span class="card-label calc">Ціна об'єкту за ДКП</span>
            </v-col>
            <v-col :cols="12" :sm="7" class="pb-0">
              <v-row class="row">
                <v-col :cols="7" class="pt-0 pb-0 pr-0">
                  <v-text-field
                    id="dflDkpLeasAmDkp"
                    v-model="calculationData.leasingAmountDkp"
                    class="pt-0 mt-0"
                    placeholder="цiна"
                    :disabled="!calculation.leasingAmount"
                    dense
                    hide-details
                    @input="
                      $event => {
                        inputNumber($event, {
                          float: 2,
                          minValue: 1,
                          maxValue: dflAmountNum,
                        })
                        discountNum <= 15 &&
                          (calculationData.dkpDiscount = discountNum)
                      }
                    "
                    @blur="
                      inputNumber($event, {
                        minValue: dkpMinNum,
                        float: 2,
                        saveFloat: true,
                      })
                    "
                    @focusin="Input.hideData('dflDkpLeasAmDkp')"
                    @focusout="Input.restoreData('dflDkpLeasAmDkp')">
                  </v-text-field>
                </v-col>
                <v-col :cols="5" class="pt-0 pb-0">
                  <v-select
                    v-model="calculationData.leasingCurrency"
                    :error-messages="leasingCurrencyErr"
                    :items="$directory.get('currencies')"
                    class="pt-0 mt-0"
                    item-title="name"
                    item-value="name"
                    placeholder="Вкажіть валюту"
                    :disabled="!calculation.leasingAmount"
                    hide-details
                    dense
                    readonly
                    @update:model-value="changeCourse($event)"
                    @blur="$v.calculation.leasingCurrency.$touch()">
                  </v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-scroll-x-transition>
        <v-row
          v-if="calculation.leasingCurrency !== 'UAH'"
          class="align-center">
          <v-col :cols="12" :sm="5" class="pb-0 flex-end">
            <span
              class="card-label calc"
              :class="{
                'required-value': !!!calculationData.leasingCurrencyCourse,
              }">
              Курс
            </span>
          </v-col>
          <v-col :cols="12" :sm="7" class="pb-0">
            <v-text-field
              id="dflDkpCourse"
              v-model="calculationData.leasingCurrencyCourse"
              :error-messages="leasingCurrencyCourseErr"
              placeholder="курс"
              dense
              hide-details
              @input="
                inputNumber($event, {
                  float: 4,
                  minValue: 1,
                  rankSpacing: false,
                })
              "
              @focusout="Input.restoreData('dflDkpCourse')"
              @focusin="Input.hideData('dflDkpCourse')"
              @blur="
                $event => {
                  inputNumber($event, { float: 4, saveFloat: true })
                  $v.calculation.leasingCurrencyCourse.$touch()
                }
              ">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="align-center">
          <v-col :cols="6" :sm="4" class="pb-0 flex-end">
            <span class="card-label calc">Ціна включає ПДВ</span>
          </v-col>
          <v-col :cols="1" class="pb-0 flex-end">
            <v-checkbox
              v-model="calculationData.isVat"
              style="font-size: 15px; color: rgba(0, 0, 0, 0.7)"
              color="red"
              :false-value="false"
              :true-value="true"
              hide-details
              dense>
            </v-checkbox>
          </v-col>
          <v-col :cols="12" :sm="7" class="pb-0">
            <v-text-field
              :model-value="`${getBeautyNum(vatValue, { float: 2 })} грн`"
              readonly
              dense
              hide-details />
          </v-col>
        </v-row>
        <v-scroll-x-transition hide-on-leave>
          <v-row v-if="calculation.leasingAmount" class="align-center">
            <v-col :cols="12" :sm="5" class="pt-2 pb-0 flex-end">
              <span class="card-label calc"></span>
            </v-col>
            <v-col :cols="12" :sm="7" class="pt-2 pb-0">
              <v-text-field
                id="dfl-obj-total-cost"
                :model-value="totalPriceSum"
                label="Загальна варт. грн"
                readonly
                dense
                hide-details>
                <template #append-inner>
                  <span class="append-text">грн</span>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-scroll-x-transition>
        <v-scroll-x-transition hide-on-leave>
          <v-row
            v-if="calculation.leasingAmount && calculationData.dkpDiscount"
            class="pt-2">
            <v-col :cols="12" :sm="5" class="pt-0 pb-0 flex-end">
              <span class="card-label calc"></span>
            </v-col>
            <v-col :cols="12" :sm="7" class="pt-0 pb-0">
              <v-text-field
                :model-value="dkpTotalPriceSum"
                label="Загальна варт. зi знижкою"
                placeholder="грн"
                readonly
                dense
                hide-details>
                <template #append-inner>
                  <span class="append-text">грн</span>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-scroll-x-transition>
        <v-row v-if="!dronFsnProgram" class="align-center mb-2">
          <v-col :cols="12" :sm="5" class="pb-0 flex-end">
            <span class="card-label calc">Знижка</span>
          </v-col>
          <v-col :cols="12" :sm="7" class="pb-0">
            <v-text-field
              id="dkp-percent-discount"
              v-model="calculationData.dkpDiscount"
              placeholder="0"
              :disabled="!calculation.leasingAmount"
              hide-details
              dense
              :min="0"
              :max="15"
              @input="
                inputNumber($event, {
                  float: 3,
                  minValue: 0,
                  maxValue: 45,
                  rankSpacing: false,
                })
              "
              @focusout="Input.restoreData('dkp-percent-discount')"
              @focusin="Input.hideData('dkp-percent-discount')">
              <template #append-inner>
                <span class="append-text"> % </span>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="5" class="pt-0 pb-0 flex-end">
            <span class="card-label calc"> Стан </span>
          </v-col>
          <v-col :cols="7" class="pt-0 pb-0">
            <v-radio-group
              v-model="calculationData.isNew"
              :error-messages="isNewErr"
              class="mt-1"
              row
              inline
              hide-details
              @change="
                $event => {
                  $emit('changeLP')
                  trackIssueState($event)
                }
              ">
              <div class="d-flex align-center">
                <span class="cust-label"> Новий </span>
                <v-radio :value="true" color="red" :disabled="!allowNewCar">
                </v-radio>
              </div>
              <div class="d-flex align-center">
                <span class="cust-label"> Б/в </span>
                <v-radio :value="false" color="red" :disabled="!allowUsedCar">
                </v-radio>
              </div>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import CarApi from '@/components/car-api/index.vue'
import FinanceIcon from '@/assets/svg/finance-icon.vue'
import {
  humanDate,
  formutSum,
  keepDigit,
  changeInputVal,
  maxNum,
  minNum,
  inputNumber,
} from '@/utils/formatFunc'
import {
  setErrHandler,
  Input,
  getBeautyNum,
  LODescription as getLoDescription,
} from '@/utils/helperFunc'
import {
  calculation,
  insuranceProgram,
  insuranceFranchise, // in percents
} from '@/type-ids'
import { mapState } from 'vuex'
import {
  CAR,
  MOTO,
  WATER_TRANSPORT,
  TRAILER,
  TRUCK,
  BUS,
  AIR_TRANSPORT,
  RAILWAY_TRANSPORT,
  COMMERCIAL_PROPERTY,
  EQUIPMENT,
  SPECIAL,
} from '@/pages/calculations/object-types'
import { isMatch, pick } from 'lodash'
import { useSelect } from '@/utils/mixins/useSelect'
import { getDirectory } from '@/plugins/directory/index.ts'

export default {
  components: { FinanceIcon, CarApi },
  emits: [
    'changeLP',
    'clearFsn',
    'update:grnTotalPrice',
    'update:applyCorp',
    'leasingTypeChange',
    'update:applyBenefits',
  ],
  setup() {
    return {
      ...useSelect(),
    }
  },
  props: {
    pageLoad: { type: Boolean },
    routeEditCalculation: { type: Boolean },
    readOnly: { type: Boolean },
    calculation: { type: Object },
    calculationGeneralObject: { type: Object },
    hasPatrolKpp: { type: Boolean },
    truck: { type: Boolean },
    agricultural: { type: Boolean },
    car: { type: Boolean },
    busShort: { type: Boolean },
    special: { type: Boolean },
    airDron: { type: Boolean },
    equipment: { type: Boolean },
    waterTransport: { type: Boolean },
    airTransport: { type: Boolean },
    railwayTransport: { type: Boolean },
    commercialProperty: { type: Boolean },
    insuranceFranchises: { type: Array },
    moto: { type: Boolean },
    loading: { type: Boolean },
    corporativeST: { type: Boolean },
    calcLoading: { type: Boolean, default: false },
    truckMassUpToThree: { type: Boolean },
    truckMassThreeMore: { type: Boolean },
    applyCorp: { type: Boolean },
    applyBenefits: { type: Boolean },
    v: { type: Object },
    carFsnMinSum: { type: Number },
    agriculturalFsnMinSum: { type: Number },
    agriculturanFsnProgramMinSum: { type: Number },
    truckFsnProgramMinSum: { type: Number },
    grnTotalPrice: { type: String },
    equipmentFsnMinSum: { type: Number },
    specialFsnMinSum: { type: Number },
    airDronFsnMinSum: { type: Number },
    airDronFsnProgramSum: { type: Boolean },
  },
  data: () => ({
    Input: new Input(),
    T: calculation,
  }),
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    calculationData() {
      return this.calculation
    },
    $v() {
      return this.v
    },
    busError() {
      return setErrHandler(this.$v.calculation.isOver20Seats)
    },
    isNewErr() {
      return setErrHandler(this.$v.calculation.isNew)
    },
    LO() {
      return getLoDescription(this.calculation)
    },
    vatValue() {
      if (!this.calculationData.isVat) return 0
      else return parseFloat(this.keepDigit(this.totalPriceSum) / 6)
    },
    allowNewCar() {
      if (!this.calculationData.leasingObjectYear) return true

      return (
        new Date().getFullYear() - 2 <= this.calculationData.leasingObjectYear
      )
    },
    allowUsedCar() {
      if (!this.calculationData.leasingObjectYear) return true

      return new Date().getFullYear() > this.calculationData.leasingObjectYear
    },
    // DFL/DKP
    leasingCurrencyCourseNum() {
      return parseFloat(this.calculation.leasingCurrencyCourse)
    },
    dflAmountNum() {
      return parseFloat(this.keepDigit(this.calculation.leasingAmount || 0))
    },
    dflPriceNum() {
      return this.dflAmountNum * this.leasingCurrencyCourseNum
    },
    dkpWithDiscountNum() {
      const dkpDiscountNum = parseFloat(this.calculationData.dkpDiscount)
      return (
        this.dflAmountNum -
        (dkpDiscountNum
          ? ((this.dflAmountNum / 100) * dkpDiscountNum).toFixed(2)
          : 0)
      )
    },
    dkpMinNum() {
      return this.dflAmountNum - ((this.dflAmountNum / 100) * 15).toFixed(2)
    },
    discountNum() {
      return 100 - ((this.dkpAmountNum || 0) / (this.dflAmountNum || 0)) * 100
    },
    dkpAmountNum() {
      return parseFloat(this.keepDigit(this.calculation.leasingAmountDkp || 0))
    },
    dkpPriceNum() {
      return this.dkpAmountNum * this.leasingCurrencyCourseNum
    },
    totalPriceSum() {
      const sum = this.dflPriceNum + this.calculation.equipSumWithDiscount || 0

      this.$emit('update:grnTotalPrice', sum.toString())
      return getBeautyNum(sum, { float: 2 })
    },
    dkpWithDiscountSum() {
      return getBeautyNum(this.dkpWithDiscountNum, { float: 2 })
    },
    dkpTotalPriceSum() {
      const sum = this.dkpPriceNum + this.calculation.equipSumWithDiscount || 0
      return getBeautyNum(sum, { float: 2 })
    },
    leasingAmountErr() {
      return setErrHandler(this.$v.calculation.leasingAmount)
    },
    leasingCurrencyErr() {
      return setErrHandler(this.$v.calculation.leasingCurrency)
    },
    leasingCurrencyCourseErr() {
      return setErrHandler(this.$v.calculation?.leasingCurrencyCourse)
    },
    // additional equipment
    equipmentSum() {
      return parseFloat(
        this.keepDigit(this.calculationData.addEquipmentSum || 0)
      )
    },
    equipmentDiscountSum() {
      return (
        (this.equipmentSum / 100) *
        (parseFloat(this.calculationData.equipmentDiscount) || 0)
      )
    },
    compEquipSumWithDiscount() {
      return this.equipmentSum - this.equipmentDiscountSum
    },
    finGraphType() {
      return this.calculationData.leasingType === 'Фiнансовий'
    },
    dronFsnProgram() {
      return (
        this.calculationData.isNew &&
        this.airTransport &&
        this.airDron &&
        this.finGraphType &&
        this.airDronFsnProgramSum
      )
    },
  },
  methods: {
    inputNumber,
    maxNum,
    minNum,
    humanDate,
    formutSum,
    keepDigit,
    changeInputVal,
    getLoDescription,
    getBeautyNum,
    carForSaleSelect() {
      this.$store.commit('setDialog', {
        action: 'carForSaleSelect',
        title: 'Автопарк',
        dialogItem: {
          carForSale: this.calculationData.carForSale,
        },
        params: {
          dialogWidth: '950px',
          submitText: 'Обрати',
          cb: carForSale => {
            this.calculationData.carForSale = pick(carForSale, [
              'id',
              'category',
              'mark',
              'model',
              'modification',
              'carNumber',
            ])
            const course =
              getDirectory('nbuCurrencyCourses').find(v => {
                return v.currency.name === 'USD'
              })?.rate || 1
            const leasingAmount = getBeautyNum(carForSale.price * course, {
              float: 2,
            })
            Object.assign(this.calculationData, {
              leasingObjectType: carForSale.category,
              leasedAssertMark: carForSale.mark,
              leasedAssertModel: carForSale.model,
              leasingObjectYear: carForSale.year,
              generation: carForSale.generation,
              body: carForSale.body,
              modification: carForSale.modification,
              specification: carForSale.specification,
              engine: carForSale.engine,
              transmission: carForSale.transmission,
              leasingAmount: leasingAmount,
              leasingAmountDkp: leasingAmount,
            })
            this.calculationData.leasingAmountDkp = this.dkpWithDiscountSum
          },
        },
      })
    },
    carForSaleRemove() {
      this.calculationData.carForSale = null
      this.calculationData.leasingObjectType = {
        id: 1,
        name: 'Легковий автомобіль',
      }
      this.calculationData.leasedAssertMark = null
      this.calculationData.leasedAssertModel = null
      this.calculationData.leasingObjectYear = null
      this.calculationData.generation = null
      this.calculationData.body = null
      this.calculationData.modification = null
      this.calculationData.specification = null
      this.calculationData.engine = null
      this.calculationData.transmission = null
      this.calculationData.leasingAmount = null
      this.calculationData.leasingAmountDkp = this.dkpWithDiscountSum
    },
    setMin(id) {
      const input = document.getElementById(id)
      if (!input) return

      if (input.value < 1) {
        input.value = '1'
        input.dispatchEvent(new Event('input'))
      }
    },
    trackLeasingObject(id) {
      this.calculationData.hasMaintenance = false
      this.clearAdditionalEquipment()
      // this.clearTruckMass()
      // this.clearTruckLiftingCapacity()
      this.clearBus()
      this.clearFuelCard()
      this.clearRegistrationPlace()
      this.setProperties(id)
      this.trackFsnProgram(id)
    },
    setProperties(id) {
      this.setPlaceOfRegistration(id)
      this.setPensionFund(id)
      this.setInsuranceProgram(id)
      this.setNotarized(id)
      this.setVehicleOwnerTax(id)
      this.setCorporativeLp(id)
      this.setGpsBeacon(id)
      this.setSpecification(id)
      this.setAdditionalEquipment(id)
    },
    setAdditionalEquipment(id) {
      const clearAdditionalEquipment = () => {
        this.calculationData.additionalEquipment = false
        this.calculationData.addEquipmentSum = null
        this.calculationData.equipmentDiscount = null
      }
      switch (id) {
        case EQUIPMENT:
          return clearAdditionalEquipment()
        case TRAILER:
          return clearAdditionalEquipment()
      }
    },
    setSpecification(id) {
      if (id === AIR_TRANSPORT) {
        this.calculationData.carModification = null
      }
    },
    setGpsBeacon(id) {
      const disableGpsBeacon = () => {
        this.calculationData.gpsBeacon = 0
        this.calculationData.beaconAmount = '0'
      }

      switch (id) {
        case TRAILER:
          {
            this.calculationData.gpsBeacon = 2
            this.calculationData.beaconAmount = '1'
          }
          break
        case EQUIPMENT:
          return disableGpsBeacon()
        case RAILWAY_TRANSPORT:
          {
            this.calculationData.gpsBeacon = 2
            this.calculationData.beaconAmount = '1'
          }
          break

        default: {
          this.calculationData.gpsBeacon = 1
          this.calculationData.beaconAmount = '1'
        }
      }
    },
    setCorporativeLp(id) {
      this.$nextTick(() => {
        // if (id !== CAR && !(id === TRUCK && this.truckMassUpToThree)) {
        //   this.$emit('update:applyCorp', false)
        //   this.$emit('changeLP')
        // }
        if (id !== CAR) {
          if (!(id === TRUCK && this.truckMassUpToThree)) {
            this.$emit('update:applyCorp', false)
          }
          if (
            this.calculationData.isNew &&
            id === TRUCK &&
            this.truckMassThreeMore
          ) {
            this.$emit('changeLP', 1)
          } else {
            this.$emit('changeLP')
          }
        }
      })
    },
    setVehicleOwnerTax(/* id */) {
      this.calculationData.vehicleOwnerTax = 2
    },
    setNotarized(id) {
      if (id === COMMERCIAL_PROPERTY) {
        this.calculationData.notarized = 1
      } else {
        this.calculationData.notarized = 2
      }
    },
    setMark(body) {
      if (this.commercialProperty) {
        this.calculationData.leasedAssertMark = { name: body.name, id: 0 }
      }
    },
    setInsuranceProgram() {
      this.calculationData.insuranceProgram = insuranceProgram.STANDARD
      this.$nextTick(() => {
        if (
          !this.insuranceFranchises
            .map(p => p.value)
            .includes(insuranceFranchise['1'])
        ) {
          this.calculationData.insuranceFranchise =
            this.insuranceFranchises[0]?.value || 1
        } else {
          this.calculationData.insuranceFranchise = insuranceFranchise['1']
        }
      })
    },
    setPensionFund(id) {
      const disablePF = () => (this.calculationData.paymentPf = 2)
      const enablePF = () => (this.calculationData.paymentPf = 1)

      switch (id) {
        case CAR:
          return enablePF()
        default:
          return disablePF()
      }
    },
    setPlaceOfRegistration(id) {
      const enableCustomPlaceOfReg = () => {
        this.calculationData.placeOfRegistration = 4
        this.calculationData.registration = '0'
        this.calculationData.annualExpenses = '0'
        this.calculationData.removalRegistration = '0'
      }

      console.log('id', id)
      switch (true) {
        case [EQUIPMENT, AIR_TRANSPORT, WATER_TRANSPORT].includes(id):
          return enableCustomPlaceOfReg()
        case COMMERCIAL_PROPERTY:
          return (this.calculationData.placeOfRegistration = 5)
        case RAILWAY_TRANSPORT:
          return (this.calculationData.placeOfRegistration = 6)
        case [MOTO, CAR, TRUCK, BUS, TRAILER, SPECIAL].includes(id):
          return (this.calculationData.placeOfRegistration = 1)
        default:
          return (this.calculationData.placeOfRegistration = null) /* default */
      }
    },
    clearAdditionalEquipment(val) {
      this.calculationData.additionalEquipment = val
      this.calculationData.addEquipmentSum = null
      this.calculationData.equipmentDiscount = null
    },
    clearBus() {
      this.calculationData.isOver20Seats = null
    },
    clearGpsBeacon() {
      this.calculationData.gpsBeacon = 0
      this.calculationData.beaconAmount = 0
    },
    clearFuelCard() {
      this.calculationData.patrolCardsSupport = 2
      this.calculationData.patrolCardQuantity = 0
    },
    clearRegistrationPlace() {
      this.calculationData.placeOfRegistration = null
      this.calculationData.registration = null
      this.calculationData.removalRegistration = null
      this.calculationData.annualExpenses = null
    },
    clearVehicleOwnerTax() {
      this.calculationData.vehicleOwnerTax = 2
    },
    clearNotaraized() {
      this.calculationData.notarized = 2
    },
    trackFsnProgram(id) {
      const type = calculation.leasingObjectType

      const defaultAction = () => {
        this.calculationData.programFSN = false
        this.$emit('clearFsn')
      }
      const carAction = () => {
        return (this.calculationData.increaseAmount = '0')
      }

      switch (id) {
        case id === type.CAR:
          return carAction()
        default:
          return defaultAction()
      }
    },
    trackState() {
      if (this.equipment || this.special) {
        this.clearVehicleOwnerTax()
      }
      if (
        this.special ||
        this.equipment ||
        this.commercialProperty ||
        this.railwayTransport ||
        this.airTransport ||
        this.waterTransport
      ) {
        this.clearFuelCard()
      }
      if (this.commercialProperty) {
        this.clearGpsBeacon()
      }
    },
    maxValue(id, max) {
      const el = document.getElementById(id)
      const val = parseInt(this.keepDigit(el.value))
      if (val > max) {
        el.value = this.formutSum(max)
        el.dispatchEvent(new Event('input', { bubbles: true }))
      }
    },
    trackIssueState(isNew) {
      if (!isNew) {
        this.calculationData.programFSN = false
        this.$emit('clearFsn')
      }
    },
    changeCourse(currency) {
      let course

      if (currency !== 'UAH') {
        course = getDirectory('nbuCurrencyCourses').find(v => {
          return v.currency.name === currency
        })?.rate
      } else course = 1

      if (!course) {
        console.error('[LeasingObject.value] changeCourse() - invalid rate')
        course = 1
      }

      return Promise.resolve(
        (this.calculationData.leasingCurrencyCourse = course.toString())
      )
    },
  },
  watch: {
    'calculationData.dkpDiscount': function () {
      this.calculationData.leasingAmountDkp = this.dkpWithDiscountSum
    },
    grnTotalPrice: function (amount) {
      const toggleFsnProgram = (minSum, cb) => {
        const currentSum = parseFloat(this.keepDigit(amount || '0'))

        if (typeof cb === 'function') {
          cb(currentSum)
        }
      }

      const disableAntenor = currentLoTotalSum => {
        const antenorMinSum = 1200000
        if (
          currentLoTotalSum < antenorMinSum &&
          !this.$route.path.includes('additional-terms')
        ) {
          /** TEMPORARY disabled remove gps tracker when OL price less then 1 200 000 */
          // this.calculationData.gpsTracker = 2
        }
      }
      switch (true) {
        case this.car:
          return toggleFsnProgram(this.carFsnMinSum)
        case this.agricultural:
          return toggleFsnProgram(this.agriculturalFsnMinSum, disableAntenor)
        case this.truck:
          return toggleFsnProgram(this.truckFsnProgramMinSum, disableAntenor)
        case this.equipment:
          return toggleFsnProgram(this.equipmentFsnMinSum)
        case this.special:
          return toggleFsnProgram(this.specialFsnMinSum, disableAntenor)
        case this.airDron:
          return toggleFsnProgram(this.airDronFsnMinSum)
      }
    },
    'calculationData.leasingObjectType': {
      handler(newVal, oldVal) {
        if (
          !this.pageLoad &&
          !isMatch(newVal || { empty: 1 }, oldVal || { empty: 1 })
        ) {
          if (!this.$route.path.includes('additional-terms')) this.trackState()
          this.$emit('changeLP')
          this.trackLeasingObject(this.calculationData.leasingObjectType?.id)
        }
      },
    },
    'calculationData.body': {
      handler(newVal, oldVal) {
        if (
          !this.pageLoad &&
          !isMatch(newVal || { empty: 1 }, oldVal || { empty: 1 })
        ) {
          this.setMark(this.calculationData.body)
        }
      },
    },
    'calculationData.leasedAssertMark': {
      handler(newVal, oldVal) {
        if (
          !this.pageLoad &&
          !isMatch(newVal || { empty: 1 }, oldVal || { empty: 1 })
        ) {
          !this.corporativeST && this.$emit('changeLP')
        }
      },
    },
    'calculationData.leasedAssertModel': {
      handler() {
        if (!this.pageLoad && this.calculationData.hasMaintenance) {
          /* set class to maintenanceRequest */
          this.calculationData.maintenanceRequest.class =
            this.calculationData.leasedAssertModel?.class || null
        }
      },
    },
    'calculationData.leasingObjectYear': {
      handler() {
        if (!this.pageLoad) {
          if (this.calculationData.leasingObjectYear >= 2022) {
            this.calculationData.isNew = true
          }

          this.$emit('changeLP')
        }
      },
    },
    'calculationData.weight': {
      handler(newVal, oldVal) {
        if (
          !this.pageLoad &&
          !isMatch(newVal || { empty: 1 }, oldVal || { empty: 1 })
        ) {
          if (this.calculationData.weight) {
            this.calculationData.truckMass =
              this.calculationData.weight.totalWeight <= 3000 ? 1 : 2
            this.calculationData.liftingCapacity =
              this.calculationData.weight.loadCapacity <= 2000 ? 1 : 2
            this.setCorporativeLp(
              this.$getSafe(() => this.calculationData.leasingObjectType.id)
            )
          } else {
            this.calculationData.truckMass = 1
            this.calculationData.liftingCapacity = null
          }
        }
      },
    },
    'calculationData.engine': {
      handler(newVal, oldVal) {
        if (
          !this.pageLoad &&
          !isMatch(newVal || { empty: 1 }, oldVal || { empty: 1 })
        ) {
          if (this.calculationData.engine) {
            this.calculationData.leasedAssertEngine =
              this.calculationData.engine.engineCm3 || '0'
          } else {
            this.calculationData.leasedAssertEngine = null
          }
        }
      },
    },
    'calculationData.modification': {
      handler(newVal, oldVal) {
        if (
          !this.pageLoad &&
          !isMatch(newVal || { empty: 1 }, oldVal || { empty: 1 })
        ) {
          /* remove maintenance if maintenanceId is not exist */
          if (
            !this.calculationData.modification?.maintenanceId &&
            this.calculationData.hasMaintenance
          ) {
            this.calculationData.hasMaintenance = false
          }

          /* set maintenancePremiumId to maintenanceRequest */
          this.calculationData.maintenanceRequest.maintenancePremiumId =
            this.calculationData.modification?.maintenancePremiumId || null

          this.calculationData.isOver20Seats =
            typeof this.calculationData.modification?.isOver20Seats ===
            'boolean'
              ? this.calculationData.modification?.isOver20Seats
              : null
        }
      },
    },
    allowNewCar(v) {
      if (this.calculationData.isNew && !v) {
        this.calculationData.isNew = false
      }
    },
    allowUsedCar(v) {
      if (!this.calculationData.isNew && !v) {
        this.calculationData.isNew = true
      }
    },
    busShort(val) {
      !val && this.clearBus()
    },
    'calculation.leasingType': function (val) {
      val === 'Оперативний'
        ? this.$emit('leasingTypeChange', true)
        : this.$emit('leasingTypeChange', false)
    },
    // Additional equipment
    'calculation.addEquipmentSum': function () {
      this.calculationData.equipSumWithDiscount = this.compEquipSumWithDiscount
    },
    'calculation.equipmentDiscount': function () {
      this.calculationData.equipSumWithDiscount = this.compEquipSumWithDiscount
    },
    vatValue: function (val) {
      this.calculationData.vat = this.calculationData.isVat ? val : null
    },
    'calculation.isNew': function (newValue, oldValue) {
      console.log(newValue, oldValue)
      if (!this.pageLoad) {
        this.$emit('update:applyBenefits', false)
      }
    },
  },
  mounted() {
    this.dkpDiscount = this.calculationData.dkpDiscount
    if (this.$route.name.includes('create-calculator')) {
      this.trackLeasingObject(this.calculationData.leasingObjectType.id)
    }
  },
}
</script>

<style scoped>
.projectRow {
  display: flex;
  align-items: center;
  padding: 0 12px;
}

.projectTitle {
  display: block;
  color: #08487a;
  font-weight: 600;
  font-size: 16px;
}
</style>
