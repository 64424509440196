<template>
  <div>
    <v-tabs v-model="curTab">
      <v-tab>Контакти</v-tab>
      <v-tab>Контрагенти</v-tab>
      <v-tab>Торгові точки</v-tab>
      <v-tab>Ліди</v-tab>
      <v-tab>Проекти</v-tab>
    </v-tabs>
    <SectionLoader v-if="loading" height="200px" />
    <div v-else class="pa-2">
      <v-row class="align-center">
        <v-col cols="12" md="4" sm="12">
          <span class="label">Перезакріпити з</span>
          <v-autocomplete
            v-model="requestData.oldResponsibleId"
            class="align-end"
            placeholder="Відповідальний"
            :items="allUsers.filter(u => u.id !== requestData.newResponsibleId)"
            :item-title="getResponsibleShortName"
            item-value="id"
            hide-details>
            <template #prepend-inner>
              <v-btn
                v-show="requestData.oldResponsibleId"
                icon
                size="x-small"
                @click="requestData.oldResponsibleId = null">
                <v-icon class="custom-select-icon" small>
                  {{ 'mdi-close' }}
                </v-icon>
              </v-btn>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="4" sm="12">
          <span class="label">Перезакріпити на</span>
          <v-autocomplete
            v-model="requestData.newResponsibleId"
            class="align-end"
            placeholder="Відповідальний"
            :items="allUsers.filter(u => u.id !== requestData.oldResponsibleId)"
            :item-title="getResponsibleShortName"
            item-value="id"
            hide-details>
            <template #prepend-inner>
              <v-btn
                v-show="requestData.newResponsibleId"
                icon
                size="x-small"
                @click="requestData.newResponsibleId = null">
                <v-icon class="custom-select-icon" small>
                  {{ 'mdi-close' }}
                </v-icon>
              </v-btn>
            </template>
          </v-autocomplete>
        </v-col>

        <v-col v-if="[0, 1, 3, 4].includes(curTab)" cols="12" md="4" sm="12">
          <span class="label">Кількість</span>
          <v-text-field
            v-model="requestData.quantity"
            hide-details
            type="number"
            dense
            placeholder="Всі">
          </v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col v-if="![2, 3, 4].includes(curTab)" cols="12" md="4" sm="12">
          <span class="label">Статус</span>
          <v-autocomplete
            v-model="requestData.clientStatuses"
            placeholder="Оберіть статус"
            :items="$directory.get('clientStatuses')"
            item-value="id"
            multiple
            item-title="name"
            hide-details
            deletable-chips
            :loading="$loading.isLoading('clientStatuses')"
            @focus="$directory.fill('clientStatuses')">
          </v-autocomplete>
        </v-col>
        <v-col v-if="curTab === 0" cols="12" md="4" sm="12">
          <span class="label">Тип</span>
          <v-autocomplete
            v-model="requestData.contactTypes"
            placeholder="Оберіть тип"
            :items="[
              ...$directory.get('contactTypes', requestData.contactTypes),
              { id: 99, name: 'Без типу' },
            ]"
            :loading="$loading.isLoading('contactTypes')"
            item-value="id"
            multiple
            item-title="name"
            hide-details
            deletable-chips
            @focus="$directory.fill('contactTypes')">
          </v-autocomplete>
        </v-col>
        <v-col v-if="curTab === 1" cols="12" md="4" sm="12">
          <span class="label">Тип</span>
          <v-autocomplete
            v-model="requestData.contractorTypes"
            placeholder="Оберіть тип"
            item-value="id"
            multiple
            :items="[
              ...$directory.get('contractorTypes', requestData.contractorTypes),
              { id: 99, name: 'Без типу' },
            ]"
            item-title="name"
            hide-details
            deletable-chips
            :loading="$loading.isLoading('contractorTypes')"
            @focus="$directory.fill('contractorTypes')">
          </v-autocomplete>
        </v-col>
        <v-col v-if="curTab === 3" cols="12" md="4" sm="12">
          <span class="label">Статус</span>
          <v-autocomplete
            v-model="requestData.leadStatuses"
            placeholder="Оберіть статус"
            :items="$directory.get('leadStatuses')"
            :loading="$loading.isLoading('leadStatuses')"
            item-value="id"
            multiple
            item-title="name"
            hide-details
            deletable-chips
            @focus="$directory.fill('leadStatuses')">
          </v-autocomplete>
        </v-col>
        <v-col v-if="curTab === 4" cols="12" md="4" sm="12">
          <span class="label">Статус проекта</span>
          <v-autocomplete
            v-model="requestData.statusId"
            placeholder="Оберіть статус"
            :items="$directory.get('projectStatuses', requestData.statusId)"
            item-value="id"
            multiple
            item-title="name"
            hide-details
            deletable-chips
            :loading="$loading.isLoading('projectStatuses')"
            @focus="$directory.fill('projectStatuses')">
          </v-autocomplete>
        </v-col>
      </v-row>

      <v-checkbox
        v-if="![0, 3].includes(curTab)"
        v-model="requestData.cascade"
        label="Перезакріпити пов'язані контакти"
        hide-details>
      </v-checkbox>
      <v-select
        v-if="curTab === 2"
        v-model="requestData.region"
        style="width: 200px"
        placeholder="Регіон"
        :items="regions"
        item-title="name"
        item-value="name"
        hide-details>
      </v-select>
      <v-btn
        :disabled="
          !requestData.newResponsibleId || !requestData.oldResponsibleId
        "
        style="margin-top: 20px; background: rgb(9 72 122)"
        class="text-white"
        color="#cd5c5c"
        size="small"
        :loading="loading"
        @click="changeResponsible()">
        Перезакріпити
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SectionLoader from '@/components/section-loader.vue'
import { getContactInitials, getResponsibleShortName } from '@/utils/helperFunc'
import { urlGetDealerRegions } from '@/pages/request'
import { useSelect } from '@/utils/mixins/useSelect'

export default {
  components: {
    SectionLoader,
  },
  setup() {
    return {
      ...useSelect(),
    }
  },
  data: () => {
    return {
      curTab: 0,
      loading: false,
      requestData: {
        oldResponsibleId: null,
        newResponsibleId: null,
        cascade: false,
        region: null,
        quantity: null,
        clientStatuses: [],
        leadStatuses: [],
        contactTypes: [],
        contractorTypes: [],
      },
      regions: [],
    }
  },
  computed: {
    ...mapState({
      allUsers: state => state.selectItems.allUsers,
    }),
    entity() {
      switch (this.curTab) {
        case 0:
          return { enName: 'contact', ruName: 'Контакти' }
        case 1:
          return { enName: 'contractor', ruName: 'Контрагенти' }
        case 2:
          return { enName: 'dealerPoint', ruName: 'Торгові точки' }
        case 3:
          return { enName: 'lead', ruName: 'Ліди' }
        case 4:
          return { enName: 'project', ruName: 'Проекти' }
        default:
          return { enName: '', ruName: '' }
      }
    },
  },
  methods: {
    getResponsibleShortName,
    getContactInitials,
    changeResponsible() {
      const req = { ...this.requestData }
      req.quantity = +req.quantity

      const tabSpecificFields = {
        0: ['contractorTypes', 'region', 'cascade', 'leadStatuses'],
        1: ['contactTypes', 'leadStatuses', 'region'],
        2: [
          'quantity',
          'clientStatuses',
          'contactTypes',
          'contractorTypes',
          'leadStatuses',
        ],
        3: [
          'clientStatuses',
          'contactTypes',
          'contractorTypes',
          'cascade',
          'region',
        ],
        4: [
          'contractorTypes',
          'leadStatuses',
          'clientStatuses',
          'region',
          'contactTypes',
        ],
      }

      const fieldsToDelete = tabSpecificFields[this.curTab] || []

      fieldsToDelete.forEach(field => {
        delete req[field]
      })

      const isValidContractorTypes = !!(
        req.contractorTypes && req.contractorTypes.length
      )
      const isValidContactTypes = !!(
        req.contactTypes && req.contactTypes.length
      )

      if (this.curTab === 0 && !isValidContactTypes) {
        return this.$setSnackbar({ text: 'Оберіть тип', color: 'warning' })
      }
      if (this.curTab === 1 && !isValidContractorTypes) {
        return this.$setSnackbar({ text: 'Оберіть тип', color: 'warning' })
      }

      this.loading = true
      this.$axios
        .post(`/json/admin/changeResponsible/${this.entity.enName}`, req)
        .then(() => {
          this.loading = false
          this.clearRequestData()
          this.$setSnackbar({ text: `${this.entity.ruName} перезакріплено` })
        })
        .catch(error => {
          console.error(error)
          this.loading = false
        })
    },
    getRegions() {
      if (this.regions.length) return
      this.loading = true
      return this.$axios
        .get(urlGetDealerRegions())
        .then(res => (this.regions = res.data))
        .finally(() => (this.loading = false))
    },
    clearRequestData() {
      this.requestData.oldResponsibleId = null
      this.requestData.newResponsibleId = null
      this.requestData.cascade = false
      this.requestData.region = null
      this.requestData.quantity = null
      this.requestData.contactTypes = []
      this.requestData.contractorTypes = []
      this.requestData.clientStatuses = []
      this.requestData.leadStatuses = []
      this.requestData.statusId = []
    },
  },
  watch: {
    curTab: function (val) {
      this.clearRequestData()
      if (val === 4) {
        this.requestData.cascade = true
      }
      val === 2 && this.getRegions()
    },
  },
  async created() {
    this.loading = true
    await this.$store.dispatch('addAllUsers')
    this.loading = false
  },
}
</script>

<style scoped></style>
