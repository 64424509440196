<template>
  <div>
    <list-section
      title="Адреса"
      :addAction="() => openDialog({}, 'addAddress')"
      :addActionDisabled="readonly || hideActionBtn">
      <template #body>
        <v-data-table
          v-if="localAddresses"
          :headers="tHeadAddresses"
          :items="localAddresses"
          hide-default-footer
          :hide-default-header="true"
          class="pb-2 pt-1 custom-table head-line type-cell adress-table"
          dense>
          <!-- eslint-disable-next-line -->
          <template #no-data>
            <no-data></no-data>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.item="{ item }">
            <span style="font-size: 13px !important">{{
              getAddress(item)
            }}</span>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.type.name="{ item }">
            <div
              class="d-flex align-center gap-1 mr-4 ml-2"
              style="
                color: dimgrey;
                border-right: 1px solid #c0c1c7;
                width: 215px;
                font-size: 13px !important;
              ">
              {{ item && item.type && item.type.name ? item.type.name : '---' }}
            </div>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.actions="{ item }">
            <TableCrud
              data-readonly="hide"
              :actionsArray="[
                {
                  action: () => openDialog(item, 'copyAddress'),
                  text: 'Копiювати',
                  icon: 'mdi-content-copy',
                  disabled: readonly || (single && localAddresses.length === 1),
                },
                {
                  action: () => openDialog(item, 'editAddress'),
                  text: 'Редагувати',
                  icon: 'EditPencil',
                  customIcon: true,
                  disabled: readonly,
                },
                {
                  action: () => deleteAddress(item),
                  text: 'Видалити',
                  icon: 'mdi-close',
                  disabled: readonly,
                },
              ]">
            </TableCrud>
          </template>
        </v-data-table>
      </template>
    </list-section>
  </div>
</template>
<script>
import noData from '@/components/no-table-data.vue'
import TableCrud from '@/components/table-crud.vue'
import ListSection from '@/components/list-section.vue'
import { getAddress } from '@/utils/helperFunc'
import { urlAddressDelete } from '@/pages/request'
import { mapState } from 'vuex'
export default {
  components: {
    ListSection,
    TableCrud,
    noData,
  },
  emits: ['change'],
  props: {
    readonly: { type: Boolean },
    hideActionBtn: { type: Boolean },
    addresses: { type: Array, default: () => [] },
    entityId: { type: Number, required: true },
    entityTypeId: { type: Number, required: true },
    single: { type: Boolean },
  },
  data: () => ({
    tHeadAddresses: [
      { title: 'Тип', key: 'type.name', sortable: false, align: 'start' },
      { title: 'Адреса', key: 'item', sortable: false, align: 'start' },
      { title: 'Дiї', key: 'actions', sortable: false, align: 'end' },
    ],
    localAddresses: [],
  }),
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    contractor() {
      return this.$getSafe(() => this.$route.name.includes('contractor'))
    },
    contact() {
      return this.$getSafe(() => this.$route.name.includes('contact'))
    },
  },
  methods: {
    getAddress,
    deleteAddress(item) {
      return this.$axios
        .delete(urlAddressDelete(item.id))
        .then(() => {
          this.localAddresses = this.localAddresses.filter(
            a => a.id !== item.id
          )
          this.$setSnackbar({ text: 'Адресу видалено' })
        })
        .catch(this.$err)
    },
    openDialog(item, action) {
      this.$store.commit('setDialog', {
        title: 'Адреса',
        dialogItem: {
          address: item,
          existAddresses: this.localAddresses,
          entityId: this.entityId,
          entityTypeId: this.entityTypeId,
        },
        params: {
          cb: address => {
            if (action === 'editAddress') {
              this.localAddresses = this.localAddresses.map(a =>
                a.id === address.id ? address : a
              )
              this.$setSnackbar({ text: 'Адресу оновлено' })
            } else if (action === 'addAddress') {
              this.localAddresses = [...this.localAddresses, address]
              this.$setSnackbar({ text: 'Адресу додано' })
            } else if (action === 'copyAddress') {
              this.localAddresses = [...this.localAddresses, ...address]
              const text = address.length > 1 ? 'Адреси' : 'Адресу'
              this.$setSnackbar({ text: `${text} скопійовано` })
            }
          },
        },
        action: action,
      })
    },
  },
  watch: {
    localAddresses: {
      handler(val) {
        return this.$emit('change', val)
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.localAddresses = this.addresses || []
  },
}
</script>
