<template>
  <div>
    <signature-basis-form
      v-model:signature-basis="editedSignatureBasis"
      class="mb-3"
      :employee-id="dialog.dialogItem.employeeId"
      @create="signatureBasisList.push($event)"
      @update="
        signatureBasisList = signatureBasisList.map(s =>
          s.id === $event.id ? $event : s
        )
      "></signature-basis-form>
    <div v-for="(item, idx) in signatureBasisList" :key="item.id">
      <div class="d-flex justify-space-between align-center">
        <div>
          <div>{{ item.basis_document?.name || item.basisDocument?.name }}</div>
          <div v-if="item.documentComment" class="text-disabled">
            {{ item.documentComment }}
          </div>
        </div>
        <div class="d-flex gap-2">
          <v-btn
            icon="mdi-pencil"
            density="compact"
            color="red"
            @click="Object.assign(editedSignatureBasis, item)">
          </v-btn>
          <v-btn
            icon="mdi-delete"
            density="compact"
            color="error"
            :loading="$loading.isLoading(`deleteSignatureBasis_${item.id}`)"
            @click="deleteSignatureBasis(item.id)">
          </v-btn>
        </div>
      </div>
      <v-divider
        v-if="idx < signatureBasisList.length - 1"
        class="mt-2 mb-2"></v-divider>
    </div>
  </div>
</template>

<script lang="ts">
import { reactive, ref } from 'vue'
import { SignatureBasis } from '@/utils/types'
import SignatureBasisForm from './SignatureBasisForm.vue'
import { handleAsync, setSnackbar } from 'best-modules/plugins'
import axios from '@/plugins/axios'
import { urlSignatureBasisDelete } from '@/pages/request'
import { cloneDeep } from 'lodash'

export default {
  name: 'SignatureBasis',
  components: { SignatureBasisForm },
  props: {
    dialog: {
      type: Object,
    },
  },
  setup(props) {
    const editedSignatureBasis = reactive<SignatureBasis>({
      basisDocumentId: null,
      documentComment: null,
    } as unknown as SignatureBasis)
    const signatureBasisList = ref<SignatureBasis[]>(
      props.dialog.dialogItem.employeeSignatoryBases
        ? cloneDeep(props.dialog.dialogItem.employeeSignatoryBases)
        : []
    )

    const deleteSignatureBasis = (signatureBasisId: number) => {
      return handleAsync(`deleteSignatureBasis_${signatureBasisId}`, () => {
        return axios
          .delete(urlSignatureBasisDelete(signatureBasisId))
          .then(() => {
            signatureBasisList.value = signatureBasisList.value.filter(
              s => s.id !== signatureBasisId
            )

            setSnackbar({ text: 'Підставу успішно видалено' })
          })
      })
    }

    const submit = () => {
      return Promise.resolve(signatureBasisList.value)
    }
    const reject = () => {
      return Promise.resolve(signatureBasisList.value)
    }
    const close = () => {
      return Promise.resolve(signatureBasisList.value)
    }

    return {
      signatureBasisList,
      submit,
      deleteSignatureBasis,
      editedSignatureBasis,
      reject,
      close,
    }
  },
}
</script>

<style scoped></style>
