import { getBeautyNum } from '@/utils/helperFunc'

/**
 * Sorts array of objects by updated date
 * @function sortByDate
 * @param {Array<Object>} items array of objects width updated_at props
 * @return {Array<Object>} sorted array of objects
 */
function sortByDate(items) {
  return items.sort((a, b) => {
    return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
  })
}

/**
 * Return string in "yyyy/mm/dd" || "yyyy/mm/dd hh:mm:ss" format from ISO string
 * @param {string} str ISO string to format
 * @param {boolean} onlyDate Boolean specifies to return only date without time
 * @return {string} Formated to human readible string of date (time)
 */
function formatDate(str, onlyDate = false) {
  if (str && !onlyDate) {
    return (str.substring(0, 10) + ' ' + str.substring(11, 16)).replace(
      /-/g,
      '.'
    ) // eslint-disable-line
  } else if (str && onlyDate) {
    return str.substring(0, 10).replace(/\-/g, '.') // eslint-disable-line
  } else return '--'
}

/**
 * Create date from current date width subtracting by year, month, day
 * @param {number} y Year
 * @param {number} m Month
 * @param {number} d Day
 * @return {string} String
 */
function setDate(y, m, d) {
  const currentDate = new Date()
  const month = currentDate.getUTCMonth()
  const day = currentDate.getUTCDay()
  const year = currentDate.getUTCFullYear()
  return new Date(year - y || year, month - m || month, day - d || day)
    .toISOString()
    .substr(0, 10)
}

function createDoc(html, fileName) {
  const header =
    "<html xmlns:o='urn:schemas-microsoft-com:office:office' " +
    "xmlns:w='urn:schemas-microsoft-com:office:word' " +
    "xmlns='http://www.w3.org/TR/REC-html40'>" +
    "<head><meta charset='utf-8'><title>Договір купiвлi продажу</title></head><body>"
  const footer = '</body></html>'
  const sourceHTML = header + html + footer

  const source =
    'data:application/vnd.ms-word;charset=utf-8,' +
    encodeURIComponent(sourceHTML)
  const fileDownload = document.createElement('a')
  document.body.appendChild(fileDownload)
  fileDownload.href = source
  fileDownload.download = fileName || 'default.doc'
  fileDownload.click()
  document.body.removeChild(fileDownload)
}

/**
 * Create String with Ukrainian locale date
 * @param {string} date String to convert
 * @return {string} String in format yyyy.mm.dd
 */
function humanDate(date) {
  if (!date || (!date.includes('-') && !date.includes('.'))) return '--'

  const dashParts = date.split('-')
  const dotParst = date.split('.')

  if (dashParts.length >= 3) {
    const [year, month, day] = dashParts
    return `${day}.${month}.${year}`
  } else {
    const [year, month, day] = dotParst
    return `${day}.${month}.${year}`
  }
}

/**
 * Get file name from path
 * @param {string} url String to extract the filename
 * @return String with filename
 */
function getImgName(url) {
  if (!url) return
  return url.replace(/^.*[\\\/]/, '') // eslint-disable-line
}

/**
 * Convert input value to string width indentations
 * @param {number|string} str Input value to convert
 * @return {string} String with numbers split into thousands
 */
function formutSum(str = '', printedForm = false /*printedForm*/) {
  if (str === '0.00' || str === '0') return printedForm ? '0.00' : '0'

  if (!str && str !== '0') return '0'

  str = keepDigit(str)

  const tempStr = str
  const delimiterIndex = str.indexOf('.')
  const options = {}
  if (printedForm) {
    options.minimumFractionDigits = 2
  }

  // @ts-ignore
  // eslint-disable-next-line
  str = new Intl.NumberFormat('ru-RU', options).format(str).replace(/\,/g, '.')
  if (!printedForm && delimiterIndex !== -1) {
    const differenceNum = str?.length + 1 - tempStr?.length
    str = (
      str.substring(0, delimiterIndex + differenceNum) +
      '.' +
      str.substring(delimiterIndex + differenceNum)
    ).replace(/\.(?=.*\.)/, '')
  }

  return str
}

/**
 * Keeps only digit from input value
 * @param {string|number} str Input value to replace not digit values
 * @return {string} String width digits
 */
function keepDigit(str, params = {}) {
  const { float = null, positive = false } = params

  /** @type {string} */
  let numStr = str
    .toString()
    .replace(/\.(?=.*\.)/, '')
    .replace(/[^\d.-]/g, '')
    .replace(/(?!^)-/, '')
  if (positive) numStr = keepPositive(numStr)
  if (numStr && numStr[0] === '.') return numStr.replace(/\D/g, '')
  if (!numStr && numStr !== '0') return ''
  if (numStr.match(/^0\d/g)) {
    numStr = parseFloat(
      (numStr.substring(0, 1) + '.' + numStr.substring(1)).replace(
        /\.(?=.*\.)/,
        ''
      )
    ).toString()
  }
  if (numStr.match(/^0\.$/g)) return '0'

  if (float && numStr.includes('.') && numStr.split('.')[1].length > 2) {
    numStr = Number(numStr).toFixed(float)
    numStr = numStr.toString()
  }

  return numStr
}

function keepPositive(str) {
  return str.replace(/[-]/g, '')
}

/**
 * Modifies the input value using defined callbacks || stop callback
 * @param {string} id Selector id
 * @param {string} event String with specified input event
 * @param {Array<function>} arrCb Callbacks that modify value
 * @param {function} stopCb Callback that trigger if statement valid (true)
 */
function changeInputVal(id, event, arrCb, stopCb) {
  if (stopCb) {
    stopCb
    return
  }

  const [el, val] = getSelector(id)

  if (!val) return

  const e = new Event(event, { bubbles: true })
  setTimeout(() => {
    arrCb.forEach(cb => {
      /** @type {string} */
      let cbVal = cb(val)
      if (['-0', 'не число'].includes(cbVal)) cbVal = '0'
      if (cbVal) el.value = cbVal
    })

    if (val !== el.value) el.dispatchEvent(e)
  }, 0)
}

/**
 * Set max number of input element
 * @param {string} id Input element id
 * @param {number} maxNum
 */
function maxNum(id, maxNum, params = {}) {
  const { float = null, positive = false } = params
  const [el, val] = getSelector(id)
  const event = new Event('input', { bubbles: true })

  const str = keepDigit(val, { float, positive })

  if (parseFloat(str) > maxNum) {
    el.value = maxNum.toString()
  } else el.value = str

  // if (float && el.value.includes('.') && el.value.split('.')[1].length > 2) {
  //   el.value = Number(el.value).toFixed(float)
  //   el.value = el.value.toString()
  // }

  if (val !== el.value) {
    setTimeout(() => {
      el.dispatchEvent(event)
    }, 0)
  }
}

function minNum(id, minNum, eventName = 'input') {
  const [el, val] = getSelector(id)
  const event = new Event(eventName, { bubbles: true })

  const str = keepDigit(val)

  if (parseFloat(str) < minNum) {
    el.value = minNum.toString()
  } else el.value = str

  if (val !== el.value)
    setTimeout(() => {
      el.dispatchEvent(event)
    }, 0)
}

function num(id, float) {
  const [el, val] = getSelector(id)
  const event = new Event('input', { bubbles: true })

  el.value = keepDigit(el.value, { float })

  if (val !== el.value)
    setTimeout(() => {
      el.dispatchEvent(event)
    }, 0)
}

/**
 * Get selector element & value
 * @param {string} id Input selector id
 * @return {[HTMLElement, string]} Array [HTMLElement, valueOfInput]
 */
function getSelector(id) {
  const el = document.getElementById(id)
  return [el, el.value]
}

// event - native event object
// use with @input.native
function inputNumber(event, params = {}) {
  const {
    float = 0,
    saveFloat = false,
    minValue = null,
    maxValue = null,
    rankSpacing = true, // number format - '12 345 678',
    trailingZeros = true,
  } = params
  const input = event.target
  if (input.value === '') return
  const sanitizeNum = str => {
    let negative = ''
    if (str[0] === '-') {
      negative = '-'
      str = str.slice(1)
    }

    return negative + str.replace(/[^\d.]/g, '')
  }

  let value = sanitizeNum(input.value) // remove all symbols expect digits and points
  const getFloat = val => {
    let floatPart = String(val).split('.')[1]

    // remove trailing zeros
    if (!trailingZeros && floatPart) {
      let i = floatPart.length - 1
      while (i >= 0 && floatPart.charAt(i) === '0') {
        i--
      }
      floatPart = floatPart.substring(0, i + 1)
    }

    return saveFloat ? float : Math.min(floatPart?.length || 0, float)
  }
  const hasFloat = value.includes('.')
  const localFloat = hasFloat || saveFloat ? getFloat(value) : 0

  value = getBeautyNum(value, { float: localFloat })

  // safe point
  if (hasFloat && localFloat === 0 && float > 0) {
    value = `${value}.`
    // remove all points except the first
    const firstDotIndex = value.indexOf('.')
    if (firstDotIndex !== -1) {
      value =
        value.slice(0, firstDotIndex + 1) +
        value.slice(firstDotIndex + 1).replace(/\./g, '')
    }
  }
  // set value limit
  if (value.split('').at(-1) !== '.') {
    const numValue = sanitizeNum(value)
    if (typeof minValue === 'number' && numValue < minValue)
      value = getBeautyNum(minValue, { float: getFloat(minValue) })
    if (typeof maxValue === 'number' && numValue > maxValue)
      value = getBeautyNum(maxValue, { float: getFloat(maxValue) })
  }
  if (!rankSpacing) value = sanitizeNum(value)

  if (value !== input.value) {
    setTimeout(() => {
      input.value = value
      input.dispatchEvent(new Event('input'))
    })
  }
}

export {
  sortByDate,
  formatDate,
  setDate,
  getImgName,
  humanDate,
  keepDigit,
  keepPositive,
  formutSum,
  changeInputVal,
  maxNum,
  minNum,
  num,
  createDoc,
  inputNumber,
}
