<template>
  <div class="mt-4">
    <v-card-text style="padding: 0">
      <v-row>
        <v-col :cols="12" :sm="6" class="pt-0 pb-0">
          <v-checkbox
            v-model="dealerData.posTerminals"
            class="mt-2"
            color="#FC7247"
            dense
            hide-details
            :readonly="readOnly"
            @update:model-value="dealerData.posTerminalsComment = null">
            <template #label>
              <span style="color: #1c1d21; font-weight: 400; font-size: 14px"
                >Можливість розміщувати наші POS матеріали</span
              >
            </template>
          </v-checkbox>
        </v-col>
      </v-row>
      <v-fade-transition hide-on-leave>
        <v-col v-if="dealerData.posTerminals" :cols="12" :sm="8" class="pl-0">
          <v-text-field
            v-model="dealerData.posTerminalsComment"
            :error-messages="posTerminalsCommentErr"
            placeholder="Введіть текст"
            hide-details
            dense
            @blur="getSafe(() => $v.dealerData.posTerminalsComment.$touch())">
          </v-text-field>
        </v-col>
      </v-fade-transition>
      <v-row>
        <v-col :cols="12" :sm="6" class="pt-0 pb-0">
          <v-checkbox
            v-model="dealerData.generalProgram"
            class="mt-2"
            dense
            color="#FC7247"
            hide-details
            :readonly="readOnly"
            @update:model-value="dealerData.generalProgramComment = null">
            <template #label>
              <span style="color: #1c1d21; font-weight: 400; font-size: 14px"
                >Готовнiсть до спiльної програми</span
              >
            </template>
          </v-checkbox>
        </v-col>
        <v-fade-transition hide-on-leave>
          <v-col v-if="dealerData.generalProgram" :cols="12" :sm="8">
            <v-text-field
              v-model="dealerData.generalProgramComment"
              :error-messages="generalProgramCommentErr"
              placeholder="Введіть текст"
              hide-details
              dense
              @blur="
                getSafe(() => $v.dealerData.generalProgramComment.$touch())
              ">
            </v-text-field>
          </v-col>
        </v-fade-transition>
      </v-row>
      <v-row>
        <v-col :cols="12" :sm="6" class="pt-0 pb-0">
          <v-checkbox
            v-model="dealerData.approvedDiscount"
            class="mt-2"
            dense
            color="#FC7247"
            hide-details
            :readonly="readOnly"
            @update:model-value="dealerData.approvedDiscountComment = null">
            <template #label>
              <span style="color: #1c1d21; font-weight: 400; font-size: 14px"
                >Погоджена знижка</span
              >
            </template>
          </v-checkbox>
        </v-col>
      </v-row>
      <v-fade-transition hide-on-leave>
        <v-col
          v-if="dealerData.approvedDiscount"
          :cols="12"
          :sm="8"
          class="pl-0">
          <v-text-field
            id="approvedDiscountComment"
            v-model="dealerData.approvedDiscountComment"
            :error-messages="approvedDiscountCommentErr"
            placeholder="Введіть значення у %"
            hide-details
            dense
            @input="maxNum('approvedDiscountComment', 100)"
            @blur="$v.dealerData.approvedDiscountComment.$touch()">
          </v-text-field>
        </v-col>
      </v-fade-transition>
      <v-row>
        <v-col :cols="12" :sm="6" class="pt-0 pb-0">
          <v-checkbox
            v-model="dealerData.individualPrograms"
            class="mt-2"
            dense
            color="#FC7247"
            hide-details
            :readonly="readOnly"
            @update:model-value="dealerData.individualProgramsComment = null">
            <template #label>
              <span style="color: #1c1d21; font-weight: 400; font-size: 14px"
                >Iндивiдуальнi програми з БЕСТ лізинг</span
              >
            </template>
          </v-checkbox>
        </v-col>
      </v-row>
      <v-fade-transition hide-on-leave>
        <v-col
          v-if="dealerData.individualPrograms"
          :cols="12"
          :sm="8"
          class="pl-0">
          <v-text-field
            v-model="dealerData.individualProgramsComment"
            :error-messages="individualProgramsCommentErr"
            placeholder="Введіть текст"
            hide-details
            dense
            @blur="$v.dealerData.individualProgramsComment.$touch()">
          </v-text-field>
        </v-col>
      </v-fade-transition>
    </v-card-text>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { dealer as D } from '@/type-ids'
import { getSafe, setErrHandler } from '@/utils/helperFunc'
import { maxNum } from '@/utils/formatFunc'
export default {
  props: {
    dealer: { type: Object },
    pageLoad: { type: Boolean },
    readOnly: { type: Boolean },
    v: { type: Object },
  },
  data: () => ({
    D: D, // dealer ids
  }),
  methods: { getSafe, maxNum },
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    dealerData() {
      return this.dealer
    },
    $v() {
      return this.v
    },
    posTerminalsCommentErr() {
      return setErrHandler(this.$v.dealerData?.posTerminalsComment)
    },
    generalProgramCommentErr() {
      return setErrHandler(this.$v.dealerData?.generalProgramComment)
    },
    approvedDiscountCommentErr() {
      return setErrHandler(this.$v.dealerData?.approvedDiscountComment)
    },
    individualProgramsCommentErr() {
      return setErrHandler(this.$v.dealerData?.individualProgramsComment)
    },
  },
}
</script>
