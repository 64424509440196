<template>
  <div>
    <v-row v-if="agreement.pledgeTypeId === 2" class="px-3 py-5">
      <v-col cols="12" md="12" sm="12" class="pt-0">
        <span class="label">Об'єкт</span>
        <v-text-field
          v-model="agreementData.pledgeObject"
          hide-details
          placeholder="Введіть текст">
        </v-text-field>
      </v-col>
      <v-col cols="12" md="12" sm="12" class="pt-0">
        <span class="label">Серійний номер</span>
        <v-text-field
          v-model="agreementData.pledgeObjectSerialNumber"
          hide-details
          placeholder="Введіть номер">
        </v-text-field>
      </v-col>
      <v-col cols="12" md="12" sm="12" class="pt-0">
        <span class="label">Державний номер</span>
        <v-text-field
          v-model="agreementData.pledgeObjectStateNumber"
          hide-details
          placeholder="Введіть номер">
        </v-text-field>
      </v-col>
      <v-col cols="12" md="12" sm="12" class="pt-0">
        <span class="label">Вартість</span>
        <v-text-field
          v-model="agreementData.pledgeObjectPrice"
          hide-details
          placeholder="Введіть вартість"
          @input="inputNumber($event, { float: 2, minValue: 1 })"
          @blur="inputNumber($event, { float: 2, saveFloat: true })">
        </v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="[1, 3].includes(agreement.pledgeTypeId)" class="px-3 py-5">
      <v-col cols="12" md="12" sm="12">
        <div class="card-label d-flex justify-space-between">
          <span class="d-block">Пiдписанi ДФЛ з боржником:</span>
          <v-btn
            v-if="agreement.pledgeTypeId === 1"
            style="background: #09487a; color: #fff"
            variant="text"
            :loading="loading"
            @click="getDflList">
            Оновити
          </v-btn>
        </div>
      </v-col>
      <v-dialog v-if="oneCDialog" v-model="oneCDialog" width="450px">
        <div style="height: 85vh; overflow: scroll">
          <div style="padding: 10px; background: #fff">
            <div class="d-flex justify-end">
              <v-btn icon size="small" @click="oneCDialog = false">
                <CloseIcon />
              </v-btn>
            </div>
            <div class="pa-2" style="background: #fff">
              <ModalInfo :info="oneCInfo" />
            </div>
          </div>
        </div>
      </v-dialog>
      <v-col v-if="agreement.contracts" cols="12" md="12" sm="12">
        <v-data-table
          :headers="mortgagorPledgePropertyHeaders"
          :items="agreement.contracts"
          class="calc-table --cust-cell-pd ml-2 mr-2"
          mobileBreakpoint="750"
          dense
          :hide-default-footer="
            !agreement.contracts || !agreement.contracts.length
          "
          :hide-default-header="
            !agreement.contracts || !agreement.contracts.length
          ">
          <!-- eslint-disable-next-line -->
          <template #item.number="{ item }">
            <span class="link" @click="setDialog(item.oneCId)">{{
              item?.number || '---'
            }}</span>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.isActive="{ item }">
            <span v-if="item.isActive === true">Дiючий</span>
            <span v-if="item.isActive === false">Новий</span>
          </template>
          <!-- eslint-disable-next-line -->
          <template #item.date="{ item }">
            {{ humanDate(formatDate(item.date, true)) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { formatDate, humanDate, inputNumber } from '@/utils/formatFunc'
import { urlGetOneCDflInfo, urlGetPledgeContract } from '@/pages/request.js'
import ModalInfo from '@/components/OneC/Modal-info.vue'
import CloseIcon from '@/assets/svg/close-icon.vue'
import { mortgagorPledgePropertyHeaders } from '@/utils/tableHeaders'
export default {
  components: { CloseIcon, ModalInfo },
  props: { agreement: { type: Object }, v: { type: Object } },
  data() {
    return {
      oneCDialog: false,
      oneCInfo: null,
      contracts: [],
      loading: false,
      mortgagorPledgePropertyHeaders,
    }
  },
  computed: {
    agreementData() {
      return this.agreement
    },
    $v() {
      return this.v
    },
  },
  methods: {
    formatDate,
    humanDate,
    inputNumber,
    urlGetPledgeContract,
    getDflList() {
      this.loading = true
      return this.$axios
        .get(this.urlGetPledgeContract(this.agreementData.id))
        .then(res => {
          this.contracts = res.data
          this.loading = false
          this.$store.commit('refreshPage')
          this.$setSnackbar({ text: 'Дані оновлено' })
        })
    },
    setDialog(oneCId) {
      if (!oneCId) {
        return this.$setSnackbar({
          text: "Зв'язку з 1с не знайдено",
          color: 'error',
        })
      }
      setTimeout(() => {
        this.getOneCInfo(oneCId).then(() => {
          this.oneCDialog = true
        })
      }, 700)
    },
    getOneCInfo(oneCId) {
      if (!oneCId) {
        return this.$setSnackbar({
          text: "Зв'язку з 1с не знайдено",
          color: 'error',
        })
      }
      return this.$axios
        .post(urlGetOneCDflInfo(), { oneCId: oneCId })
        .then(res => {
          this.oneCInfo = res.data
        })
    },
  },
}
</script>
