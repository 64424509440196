<template>
  <v-row>
    <v-col style="padding-top: 0px">
      <v-data-table
        :headers="msgHeader"
        :items="messages"
        :custom-sort="$sortByDate"
        mobileBreakpoint="750"
        class="--cust-cell-pd"
        dense
        hide-default-footer>
        <!-- eslint-disable-next-line -->
        <template #item.esputnikStatusId="{ item }">
          <span v-if="[1].includes(item.esputnikStatusId)">Доставленно</span>
          <span v-if="[2].includes(item.esputnikStatusId)">Прочитано</span>
          <span v-if="[3].includes(item.esputnikStatusId)">Не відправлено</span>
          <span v-if="[4].includes(item.esputnikStatusId)">Відправлено</span>
          <span v-else>Відправлено</span>
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.created_at="{ item }">
          {{ toFormatDate(item.created_at) }}
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.message="{ item }">
          <span class="curText">{{ item.message }}</span>
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.type="{ item }">
          <PhoneSmsIcon
            v-if="item.type.name === 'Sms'"
            style="
              padding-top: 3px !important;
              width: 20px !important;
              height: 20px !important;
            " />
          <ViberIcon
            v-if="item.type.name === 'Viber'"
            style="
              padding-top: 3px !important;
              width: 20px !important;
              height: 20px !important;
            " />
          <EmailIcon
            v-if="item.type.name === 'Email'"
            style="
              padding-top: 3px !important;
              width: 20px !important;
              height: 20px !important;
            " />
          <!-- {{ (item.type.name || '').toUpperCase() }} -->
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.responsible="{ item }">
          {{ getResponsibleShortName(item.responsible) }}
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.action="{ item }">
          <TableCrud
            data-readonly="hide"
            :actionsArray="[
              {
                action: () => openSmsDialog(item),
                text: 'Переглянути',
                icon: 'EyeIcon',
                customIcon: true,
                disabled: readonly,
              },
            ]">
          </TableCrud>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import ViberIcon from '@/assets/svg/viber-icon.vue'
import EmailIcon from '@/assets/svg//social/email-icon.vue'
import PhoneSmsIcon from '@/assets/svg/phoneSms-icon.vue'
import {
  toFormatDate,
  getContactInitials,
  getResponsibleShortName,
} from '@/utils/helperFunc'
import TableCrud from '@/components/table-crud.vue'
export default {
  components: {
    TableCrud,
    ViberIcon,
    PhoneSmsIcon,
    EmailIcon,
  },
  props: {
    messages: { type: Array, default: () => [] },
    leadId: { type: Number, default: null },
    applicationId: { type: Number, default: null },
    readonly: { type: Boolean, default: false },
  },
  data: () => ({
    msgHeader: [
      {
        text: 'Статус',
        key: 'esputnikStatusId',
        sortable: false,
        align: 'start',
      },
      { text: 'Дата', key: 'created_at', sortable: false, align: 'start' },
      { text: 'Метод', key: 'type', sortable: false, align: 'center' },
      {
        text: 'Повідомлення',
        key: 'message',
        sortable: false,
        align: 'start',
      },
      {
        text: 'Відповідальний',
        key: 'responsible',
        sortable: false,
        align: 'start',
      },
      { text: 'Дії', key: 'action', sortable: false, align: 'center' },
    ],
  }),
  methods: {
    getContactInitials,
    toFormatDate,
    getResponsibleShortName,
    openSmsDialog(item) {
      this.$store.commit('setDialog', {
        title: 'Деталі повідомлення',
        params: { hideActionsButtons: true },
        dialogItem: item,
        action: 'view-sms-dialog',
      })
    },
  },
}
</script>

<style scoped>
.crm-layout .crm .crm-content .v-data-table-header {
  background: transparent !important;
  border-bottom: none !important;
}
tbody > tr > td:nth-child(1):nth-child(1) {
  width: 300px !important;
}
.curText {
  display: block;
  width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
