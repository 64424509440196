<template>
  <div>
    <span id="closeModal" style="display: none"></span>
    <v-menu offset-y :nudge-bottom="8" :close-on-content-click="false">
      <template #activator="{ props }">
        <v-btn variant="text" color="secondary" v-bind="props">
          <v-icon color="grey darken-2"> {{ buttonIcon }} </v-icon>&nbsp;
          {{ action ? 'Завершити' : 'Скасувати' }}
        </v-btn>
      </template>
      <v-card :min-width="420">
        <v-toolbar
          color="rgb(252, 114, 71)"
          class="mb-0"
          :height="39"
          style="font-size: 0.9rem"
          dark>
          <v-toolbar-title class="d-flex" style="font-size: 0.9rem">
            {{ titleName }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <div class="d-inline-block">
            {{ currentDate }} {{ currentTime }}
            <v-btn size="small" icon @click="closeModal()">
              <v-icon :size="17">mdi-close</v-icon>
            </v-btn>
          </div>
        </v-toolbar>
        <v-card-text class="pb-0">
          <span class="label">Результат</span>
          <v-select
            v-model="resultId"
            :error-messages="resultIdErr"
            item-title="name"
            class="mb-2 mt-0"
            placeholder="Оберіть зі списку"
            item-value="id"
            :items="
              action
                ? eventStatuses.filter(v => v.id !== 5)
                : eventStatuses.filter(v => v.id === 5)
            "
            hide-details
            @update:model-value="$emit('change', $event)"
            @blur="v$.resultId.$touch()">
          </v-select>
          <span class="label"
            >Опишіть результат детальніше (мін. 50 символів)</span
          >
          <v-textarea
            v-model="resultComment"
            :error-messages="resultCommentErr"
            :rows="2"
            class="mt-0 mb-2"
            placeholder="Введіть текст"
            dense
            hide-details
            @input="$emit('input', $event)"
            @blur="v$.resultComment.$touch()">
          </v-textarea>
          <FilesUploader
            v-model:documents="resultFiles"
            class="mb-3"
            label="Фотофіксація зустрічі">
          </FilesUploader>
        </v-card-text>
        <v-card-actions class="justify-end pt-1">
          <v-btn
            size="small"
            variant="tonal"
            :loading="loading"
            @click="submit">
            Завершити
          </v-btn>
          <v-btn
            size="small"
            variant="tonal"
            class="no-transform"
            @click="closeModal">
            Скасувати
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
import { required, minLength } from '@vuelidate/validators'
import { formatDate } from '@/utils/formatFunc'
import { toFormatDate } from '@/utils/helperFunc'
import { urlCompleteEvent } from '@/pages/request'
import { setErrHandler } from '@/utils/helperFunc'
import FilesUploader from '@/components/FilesUploader.vue'
import { useVuelidate } from '@vuelidate/core'
export default {
  components: { FilesUploader },
  emits: ['input', 'change'],
  setup() {
    return { v$: useVuelidate() }
  },
  props: {
    action: { type: Number },
    buttonIcon: { type: String },
    titleName: { type: String, default: 'Зустріч скасовано' },
    event: { type: Object },
    eventStatuses: { type: Array },
    rejected: { type: Boolean, default: false },
  },
  validations() {
    return {
      resultComment: { required, minLength: minLength(50) },
      resultId: { required },
      resultFiles:
        (this.action === 1 && [2, 5].includes(this.resultId)) ||
        (this.action === 0 && this.resultId === 5)
          ? {}
          : { required },
    }
  },
  data: () => ({
    resultComment: null,
    resultId: null,
    resultFiles: null,
    loading: false,
  }),
  computed: {
    canceled() {
      return this.action === 0
    },
    completed() {
      return this.action === 1
    },
    currentDate() {
      return this.toFormatDate(new Date().toISOString().substr(0, 10))
    },
    currentTime() {
      return this.formatDate(new Date().toString().substring(16, 21))
    },
    resultCommentErr() {
      const v = this.v$.resultComment
      const customMsg =
        v.required && !v.minLength
          ? `Мінімальна довжина поля ${v.$params.minLength.min} символів`
          : null
      return setErrHandler(v, customMsg)
    },
    resultIdErr() {
      return setErrHandler(this.v$.resultId)
    },
  },
  methods: {
    toFormatDate,
    formatDate,
    finishEvent() {
      this.loading = true
      const activity = this.event
      activity.resultComment = this.resultComment
      activity.resultId = this.resultId
      activity.resultFiles = this.resultFiles
      this.rejected ? (activity.statusId = 3) : (activity.statusId = 2)
      return this.$axios
        .post(urlCompleteEvent(activity.id), activity)
        .then(res => {
          this.$setSnackbar({ text: 'Данi оновлено' })
          this.$store.commit('refreshPage')
          this.loading = false
          return res
        })
        .catch(err => this.$err(err, () => (this.loading = false)))
    },
    submit() {
      !this.v$.$invalid ? this.finishEvent() : this.highlightErrors()
    },
    highlightErrors() {
      this.v$.$anyError
      this.v$.$touch()
    },
    setDefaultResult() {
      if (!this.action) {
        this.resultId = 5
      }
    },
    closeModal() {
      const el = document.getElementById('closeModal')
      el.click()
    },
  },
  mounted() {
    this.setDefaultResult()
  },
}
</script>
