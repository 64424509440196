<template>
  <div>
    <div v-show="dealerListRoute" class="pa-0">
      <v-fade-transition hide-on-leave>
        <div>
          <Filters :filters="filters" class="ml-2">
            <template #header-action>
              <v-btn
                v-if="salesDirector || sysAdmin || userCanCreateDealerPoint"
                data-readonly="hide"
                class="mr-3"
                style="
                  background: rgb(252, 114, 71);
                  border-radius: 4px;
                  color: #fff;
                "
                @click="
                  $store.commit('setDialog', {
                    title: 'Торгова точка',
                    dialogItem: {},
                    params: {
                      cb: () => {
                        updateList()
                        setSnackbar({ text: 'Створено диллера' })
                      },
                    },
                    action: 'addDealer',
                  })
                ">
                <v-icon style="margin-right: 5px; color: #fff !important">
                  {{ 'mdi-plus' }}
                </v-icon>
                Додати торгову точку
              </v-btn>
            </template>
          </Filters>
          <v-row class="mt-5">
            <v-col class="pt-0">
              <div v-show="!pageLoad" ref="card" class="pt-0 pl-0">
                <v-data-table
                  :headers="dealerHeaders"
                  :items="selectItems.dealerOutletsList"
                  class="calc-table --cust-cell-pd"
                  mobileBreakpoint="750"
                  dense
                  :items-per-page="-1"
                  hide-default-footer
                  :hide-default-header="!selectItems.dealerOutletsList.length"
                  :custom-sort="tableDateSort()">
                  <!-- eslint-disable-next-line -->
                  <template #item.contractor="{ item }">
                    <div v-if="item.contractor">
                      <v-hover>
                        <template #default="{ isHovering, props }">
                          <div v-bind="props">
                            <div class="contractor-block">
                              <ALink
                                v-if="item.contractor"
                                :text="item.contractor.shortName"
                                :route="{
                                  name: 'contractors-form',
                                  params: { id: item.contractor.id },
                                }">
                              </ALink>
                              <span v-else>{{ '---' }}</span>
                            </div>
                            <div
                              v-if="isHovering && item.contractorId"
                              class="contractor-block-info">
                              <div class="block-wrapper">
                                <span style="font-size: 13px" class="d-block"
                                  >Адреса</span
                                >
                                <span
                                  v-if="
                                    item.contractor &&
                                    item.contractor.legalAddress
                                  "
                                  style="font-weight: 600; margin-bottom: 7px"
                                  class="d-block"
                                  >{{
                                    getAddress(item.contractor.legalAddress)
                                  }}</span
                                >
                                <span
                                  v-else
                                  style="font-weight: 600"
                                  class="d-block"
                                  >Адреса вiдсутня</span
                                >
                                <span
                                  style="font-size: 13px; margin-top: 7px"
                                  class="d-block"
                                  >Відповідальний</span
                                >
                                <span
                                  v-if="
                                    item.contractor &&
                                    item.contractor.responsible
                                  "
                                  style="font-weight: 600"
                                  class="d-block"
                                  >{{
                                    getSafe(() =>
                                      getResponsibleShortName(
                                        item.contractor.responsible
                                      )
                                    )
                                  }}</span
                                >
                                <span
                                  v-else
                                  style="font-weight: 600"
                                  class="d-block"
                                  >Відповідальний Відсутній
                                </span>
                              </div>
                            </div>
                            <span v-else></span>
                          </div>
                        </template>
                      </v-hover>
                    </div>
                    <div v-if="!item.contractorId">
                      <span>---</span>
                    </div>
                  </template>
                  <!-- eslint-disable-next-line -->
                  <template #item.isDeactivated="{ item }">
                    <span>{{
                      item?.isDeactivated ? 'Деактивована' : 'Активна'
                    }}</span>
                  </template>
                  <!-- eslint-disable-next-line -->
                  <template #item.type="{ item }">
                    <span
                      v-if="item.type?.name === 'Дилер'"
                      style="width: 200px; display: block">
                      Дилер
                      <span v-if="getSafe(() => item.category.id === 1)"
                        >(офіційний)</span
                      >
                      <span v-if="getSafe(() => item.category.id === 2)"
                        >(не офіційний)</span
                      >
                      <span v-if="getSafe(() => item.category.id === 3)"
                        >(комісійний майданчик)</span
                      >
                    </span>
                    <span v-else style="width: 140px; display: block">{{
                      dealerType(item.typeId)
                    }}</span>
                  </template>
                  <!-- eslint-disable-next-line -->
                  <template #item.actions="{ item }">
                    <TableCrud
                      data-readonly="hide"
                      style="width: 20px"
                      :actionsArray="[
                        {
                          action: () =>
                            $router.push({
                              name: 'dealer-form',
                              params: { id: item.id },
                            }),
                          text: 'Відкрити',
                          icon: 'mdi-folder-open-outline',
                        },
                        {
                          action: () =>
                            $store.commit('setDialog', {
                              title: 'Диллер',
                              dialogItem: {
                                contractorId: getSafe(() => item.contractorId),
                                typeId: getSafe(() => item.type?.id),
                                categoryId: getSafe(() => item.category?.id),
                                name: item.name,
                                id: item.id,
                              },
                              params: {
                                cb: res => {
                                  if (res) {
                                    updateList()
                                  }
                                },
                              },
                              action: 'deleteDealer',
                            }),
                          text: 'Видалити',
                          icon: 'DeleteIcon',
                          customIcon: true,
                        },
                      ]">
                    </TableCrud>
                  </template>
                  <!-- eslint-disable-next-line -->
                  <template #item.responsibleId="{ item }">
                    {{
                      getSafe(
                        () => getResponsibleShortName(item.responsible),
                        '---'
                      )
                    }}
                  </template>
                  <!-- eslint-disable-next-line -->
                  <template #item.name="{ item }">
                    <ALink
                      v-if="getSafe(() => item.name)"
                      :text="item.name"
                      :route="{ name: 'dealer-form', params: { id: item.id } }">
                    </ALink>
                    <span v-else>{{ '---' }}</span>
                  </template>
                  <!-- eslint-disable-next-line -->
                  <template #item.contractor.shortName="{ item }">
                    <ALink
                      v-if="item.contractor.shortName"
                      :text="item.contractor.shortName"
                      :route="{
                        name: 'contractors-form',
                        params: { id: item.contractor.id },
                      }">
                    </ALink>
                    <span v-else>{{ '---' }}</span>
                  </template>
                </v-data-table>
                <Pagination
                  :getItems="getDealers"
                  :trigger="paginationTrigger" />
              </div>
            </v-col>
          </v-row>
        </div>
      </v-fade-transition>
    </div>
    <SectionLoader v-if="pageLoad && dealerListRoute"> </SectionLoader>
    <v-fade-transition hide-on-leave>
      <RouterView v-if="!dealerListRoute" @data-changed="updateList">
      </RouterView>
    </v-fade-transition>
  </div>
</template>
<script>
import ALink from '@/components/Link.vue'

import SectionLoader from '@/components/section-loader.vue'
import Pagination from '@/components/Pagination.vue'
import { mapState, mapGetters, useStore } from 'vuex'
import {
  tableDateSort,
  getContactInitials,
  getResponsibleShortName,
  getAddress,
  getSafe,
} from '@/utils/helperFunc'
import Filters from '@/components/Filters.vue'
import { useFilter } from '@/utils/mixins/useFilter'
import { useSelect } from '@/utils/mixins/useSelect'
import { reactive } from 'vue'
import { setSnackbar } from 'best-modules/plugins/index'
import TableCrud from '@/components/table-crud.vue'
import { dealerHeaders } from '@/utils/tableHeaders'
export default {
  components: {
    TableCrud,
    Filters,
    SectionLoader,
    ALink,
    Pagination,
  },
  setup() {
    const store = useStore()
    const addCarMarks = loTypeId => {
      filters.markId.value = null
      store.commit('clearCarMarks')
      return store.dispatch('addCarMarks', loTypeId)
    }
    const filters = reactive({
      responsibleId: {
        value: null,
        component: 'v-autocomplete',
        attrs: {
          label: 'Відповідальний',
          itemTitle: u => getContactInitials(u, { isResponsible: true }),
          items: 'activeUsers',
        },
        focus: { action: 'addAllUsers' },
      },
      leasingObjectTypeId: {
        value: null,
        component: 'v-autocomplete',
        attrs: { label: 'Тип ОЛ', items: 'leasingObjectTypes' },
        change: addCarMarks,
      },
      markId: {
        value: null,
        component: 'v-autocomplete',
        attrs: { label: 'Марка', items: 'carMarks' },
      },
      typeId: {
        value: null,
        component: 'v-autocomplete',
        attrs: { label: 'Тип', items: 'dealerTypes' },
      },
      isDeactivated: {
        value: null,
        component: 'v-checkbox',
        attrs: { label: 'Активні', trueValue: false, falseValue: true },
      },
      categoryId: {
        value: null,
        component: 'v-autocomplete',
        attrs: { label: 'Категорiя', items: 'dealerCategories' },
        show: { filterName: 'typeId', acceptedValue: [1] },
      },
      search: { value: null },
    })
    return {
      ...useSelect(),
      ...useFilter(filters),
      filters,
    }
  },
  data() {
    return {
      marks: [],
      markId: null,
      filterTimeout: null,
      pageLoad: false,
      dealerHeaders,
    }
  },
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
      user: state => state.user,
    }),
    ...mapGetters({
      activeUsers: 'activeUsers',
    }),
    salesDirector() {
      return this.user.roleId === 4
    },
    sysAdmin() {
      return this.user.roleId === 1
    },
    userCanCreateDealerPoint() {
      return this.user.canCreateDealerPoint
    },
    dealerListRoute() {
      return this.$route.name === 'dealer-list'
    },
  },
  methods: {
    setSnackbar,
    getSafe,
    tableDateSort,
    getContactInitials,
    getResponsibleShortName,
    getAddress,
    getDealers(page = 1) {
      this.pageLoad = true
      return this.$store
        .dispatch('addDealerOutletList', {
          page,
          pagination: true,
          filters: this.filtersQueryObj,
        })
        .then(res => {
          this.pageLoad = false
          return res
        })
        .catch(err => this.$err(err, () => (this.pageLoad = false)))
    },
    getResponsible(responsibleId) {
      const r = this.$getSafe(() =>
        this.selectItems.allUsers.find(u => u.id === responsibleId)
      )
      if (!r) return '---'
      return `${r?.surname || '-'} ${r?.name || '-'} ${r?.patronymic || '-'}`
    },
    dealerType(typeId) {
      return this.$getSafe(
        () =>
          this.selectItems.contractorDealerTypes.find(t => t.id === typeId)
            .name,
        '---'
      )
    },
  },
}
</script>

<style scoped lang="scss">
.contractor-block {
  position: relative;
}
.contractor-block-info {
  background-color: #fff !important;
  border-radius: 4px !important;
  position: absolute;
  width: 320px;
  z-index: 100000;
}

.contractor-block-info .block-wrapper {
  padding: 10px !important;
}
</style>
