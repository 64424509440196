<template>
  <div>
    <div>
      <div class="white-block">
        <!-- SIMPLE DATA -->
        <div v-if="simple">
          <v-row>
            <v-col :cols="12" :sm="3" class="pt-1 pb-1">
              <div class="card-label">Об’єкт лізингу</div>
            </v-col>
            <v-col :cols="12" :sm="9" class="pt-1 pb-1 text-start">
              <span class="card-value">{{ vehicleInfo }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="12" :sm="3" class="pt-1 pb-1">
              <div class="card-label">Програма лізингу</div>
            </v-col>
            <v-col :cols="12" :sm="9" class="pt-1 pb-1 text-start">
              <span class="card-value">{{
                calculation?.leasingProgram?.name ||
                calculation?.leasingProgramType?.name ||
                '---'
              }}</span>
            </v-col>
          </v-row>
          <!-- legal department -->
          <v-row v-if="$route.name === 'ld-leasing-object'">
            <v-col :cols="12" :sm="3" class="pb-1 pt-0">
              <span class="card-label">Умови лізингу</span>
            </v-col>
            <v-col :cols="12" :sm="9" class="pb-1 pt-0">
              <span class="card-label">Термін лізингу:</span>
              <span class="card-value mr-1"
                >{{ calculation?.leasingTerm || '---' }} мiс</span
              >
              <span class="card-label">Аванс:</span>
              <span class="card-value mr-1"
                >{{ `${calculation.advance}%` }}
                {{ `(${formutSum(offerAdvance, true)} грн)` }}</span
              >
              <span class="card-label">Вартість предмета лізингу:</span>
              <span class="card-value mr-1">{{
                currentSum(
                  calculation?.exportData?.export?.totalAmount ||
                    calculation?.totalAmount
                )
              }}</span>
              <div>
                <span class="card-label"
                  >Загальна сума лізингових платежів:</span
                >
                <span class="card-value mr-1">{{
                  currentSum(
                    calculation?.exportData?.export?.totalPayment ||
                      calculation?.totalPayment
                  )
                }}</span>
              </div>
            </v-col>
          </v-row>
          <!-- other -->
          <v-row v-else>
            <v-col :cols="12" :sm="3" class="pt-1 pb-1">
              <span class="card-label">Умови лізингу</span>
            </v-col>
            <v-col :cols="12" :sm="9" class="pt-1 pb-1">
              <span class="card-label">Термін лізингу:</span>
              <span class="card-value mr-1"
                >{{ calculation?.leasingTerm || '---' }} мiс</span
              >
              <span class="card-label">Аванс:</span>
              <span class="card-value mr-1"
                >{{ `${calculation.advance}%` }}
                {{ `(${formutSum(offerAdvance, true)} грн)` }}</span
              >
              <span class="card-label">Вартість предмета лізингу:</span>
              <span class="card-value mr-1">{{
                currentSum(
                  calculation?.exportData?.export?.totalAmount ||
                    calculation?.totalAmount
                )
              }}</span>
            </v-col>
          </v-row>
          <v-row class="pb-3">
            <v-col :cols="12" :sm="3" class="pt-0 pb-1">
              <span class="card-label">GPS-навігація</span>
            </v-col>
            <v-col :cols="12" :sm="9" class="pt-0 pb-1">
              <div>
                <span class="card-label">GPS:</span>
                <span class="card-value">{{
                  calculation?.gpsTrackerType?.name || '---'
                }}</span>
              </div>
              <div>
                <span class="card-label">GPS маячок:</span>
                <span class="card-value">{{
                  calculation?.gpsBeaconType?.name || '---'
                }}</span>
              </div>
            </v-col>
          </v-row>
        </div>

        <!-- DETAILS DATA -->
        <div v-else>
          <div
            v-if="calculation.contract"
            class="d-flex align-center mb-5 mt-2">
            <div class="dfl mr-3 d-flex align-center">
              <InputPusher
                text="Перейти до ДФЛ"
                :routeObject="{
                  name: 'dfl-contract',
                  params: { id: calculation.contract.id },
                }" />
              <svg
                class="mr-2"
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="8"
                viewBox="0 0 15 8"
                fill="none">
                <path
                  d="M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5V3.5ZM14.3536 4.35355C14.5488 4.15829 14.5488 3.84171 14.3536 3.64645L11.1716 0.464466C10.9763 0.269204 10.6597 0.269204 10.4645 0.464466C10.2692 0.659728 10.2692 0.976311 10.4645 1.17157L13.2929 4L10.4645 6.82843C10.2692 7.02369 10.2692 7.34027 10.4645 7.53553C10.6597 7.7308 10.9763 7.7308 11.1716 7.53553L14.3536 4.35355ZM1 4.5H14V3.5H1V4.5Z"
                  fill="#08487A" />
              </svg>
              <ALink
                :text="`ДФЛ ${calculation.contract.number}`"
                :route="{
                  name: 'dfl-contract',
                  params: { id: calculation.contract.id },
                }" />
            </div>
            <div v-if="calculation.dkp" class="dkp d-flex align-center">
              <InputPusher
                text="Перейти до ДКП"
                :routeObject="{
                  name: 'dkp-contract',
                  params: { id: calculation.dkp.id },
                }" />
              <svg
                class="mr-2"
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="8"
                viewBox="0 0 15 8"
                fill="none">
                <path
                  d="M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5V3.5ZM14.3536 4.35355C14.5488 4.15829 14.5488 3.84171 14.3536 3.64645L11.1716 0.464466C10.9763 0.269204 10.6597 0.269204 10.4645 0.464466C10.2692 0.659728 10.2692 0.976311 10.4645 1.17157L13.2929 4L10.4645 6.82843C10.2692 7.02369 10.2692 7.34027 10.4645 7.53553C10.6597 7.7308 10.9763 7.7308 11.1716 7.53553L14.3536 4.35355ZM1 4.5H14V3.5H1V4.5Z"
                  fill="#08487A" />
              </svg>
              <ALink
                :text="`ДКП ${calculation.dkp.number}`"
                :route="{
                  name: 'dkp-contract',
                  params: { id: calculation.dkp.id },
                }" />
            </div>
          </div>
          <div class="d-flex align-center">
            <div class="text-field" style="margin-bottom: 0">
              <span class="text-title">Об'єкт лізингу:</span>
              <span class="text-desc">{{
                LO.description({ detailed: true })
              }}</span>
            </div>
          </div>
          <v-row class="mt-4 align-center">
            <v-col cols="12" md="3" sm="12" class="pt-0 pb-0">
              <div class="text-field">
                <span class="text-title">Стан:</span>
                <span class="text-desc">{{
                  LO.state === 'Б.в.' ? 'Вживаний' : LO.state
                }}</span>
              </div>
            </v-col>
            <v-col cols="12" md="5" sm="12" class="pt-0 pb-0">
              <div class="d-flex align-center">
                <div v-if="calculation.leasingProgram === 34" class="mr-1">
                  <Benefit />
                </div>
                <div class="text-field">
                  <span class="text-title">Програма:</span>
                  <span class="text-desc" style="font-size: 0.93rem">
                    {{ leasingProgramName }}
                  </span>
                </div>
              </div>
            </v-col>
            <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
              <div class="text-field">
                <span class="text-title">Країна виробник:</span>
                <span class="text-desc" style="font-size: 0.93rem">
                  {{ calculation.leasingObjectCountry?.name || 'Відсутня' }}
                </span>
              </div>
            </v-col>
          </v-row>

          <div class="section">
            <div class="mb-5 d-flex align-center">
              <FinanceIcon />
              <span class="card-block-title">Фінансування</span>
            </div>
            <div class="section-content" style="padding-left: 0">
              <v-row>
                <v-col :cols="12" :md="6" class="pt-0 pb-0">
                  <div class="text-field">
                    <span class="text-title">Сума фiнансування:</span>
                    <span class="text-desc">{{ LOAmountOfFinancing }}</span>
                  </div>
                </v-col>
                <v-col :cols="12" :md="6" class="pt-0 pb-0">
                  <div class="text-field">
                    <span class="text-title">Графік погашення:</span>
                    <span class="text-desc">{{
                      getSafe(() => calculation.acceptedGraph.name || '---')
                    }}</span>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col :cols="12" :md="3" class="pt-0 pb-0">
                  <div class="data-card">
                    <span class="area-label">Вартiсть</span>
                    <p class="title-label">{{ LO.amountDkp }}</p>
                  </div>
                </v-col>
                <v-col :cols="12" :md="3" class="pt-0 pb-0">
                  <div class="data-card">
                    <span class="area-label">Сума ПДВ</span>
                    <p class="title-label">
                      {{ getBeautyNum(calculation.vat || 0, { float: 2 }) }} грн
                    </p>
                  </div>
                </v-col>
                <v-col :cols="12" :md="3" class="pt-0 pb-0">
                  <div class="data-card">
                    <span class="area-label">Аванс</span>
                    <p class="title-label">{{ LO.advance }}</p>
                  </div>
                </v-col>
                <v-col :cols="12" :md="2" class="pt-0 pb-0">
                  <div class="data-card">
                    <span class="area-label">Термін лізингу</span>
                    <p class="title-label">{{ LO.leasingTerm }}</p>
                  </div>
                </v-col>
                <v-col :cols="12" :md="1" class="pt-0 pb-0">
                  <div class="data-card">
                    <span class="area-label">Валюта</span>
                    <p class="title-label">{{ LO.financingCurrency }}</p>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>

          <v-row class="section mb-5">
            <v-col :cols="12" :md="6">
              <div class="mb-5 d-flex align-center">
                <InsuranceIcon />
                <span class="card-block-title">Страхування</span>
              </div>
              <div class="section-content" style="padding-left: 0">
                <v-row>
                  <v-col :cols="12" :md="4" class="pt-0 pb-0">
                    <div class="data-card">
                      <span class="area-label">Програма КАСКО</span>
                      <p class="title-label">
                        {{
                          (calculation?.insuranceProgramType?.name || '---') +
                          ` (${
                            calculation?.insuranceFranchiseType?.name || '0%'
                          })`
                        }}
                      </p>
                    </div>
                  </v-col>
                  <v-col :cols="12" :md="8" class="pt-0 pb-0">
                    <div class="data-card">
                      <span class="area-label">Умови страхування</span>
                      <p class="title-label">
                        {{ calculation?.stockType?.name }}
                      </p>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-col :cols="12" :md="6">
              <div class="mb-5 d-flex align-center">
                <GpsIcon />
                <span class="card-block-title">GPS-навігація</span>
              </div>
              <v-row>
                <v-col
                  v-if="calculation.gpsBeaconType"
                  :cols="12"
                  :md="6"
                  class="pt-0 pb-0">
                  <div class="data-card">
                    <span class="area-label">GPS-маяк</span>
                    <p class="title-label">
                      {{ calculation?.gpsBeaconType?.name }}
                    </p>
                  </div>
                </v-col>
                <v-col :cols="12" :md="6" class="pt-0 pb-0">
                  <div class="data-card">
                    <span class="area-label">GPS-трекер</span>
                    <p class="title-label">
                      {{ calculation?.gpsTrackerType?.name || '---' }}
                    </p>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <div class="section mt-5 mb-5">
            <div class="mb-5 d-flex align-center">
              <GeneralInfoIcon />
              <span class="card-block-title">Додаткові послуги</span>
            </div>
            <div class="section-content" style="padding-left: 0">
              <v-row>
                <v-col :cols="12" :md="2" class="pt-0 pb-0">
                  <div class="data-card">
                    <span class="area-label">Реєстрація</span>
                    <v-menu
                      v-if="requestData.placeOfRegistration === 4"
                      right
                      offset-x>
                      <template #activator="{ props }">
                        <span class="link" v-bind="props">{{
                          requestData.placeOfRegistration === 4
                            ? 'Індивідуальна'
                            : requestData.placeOfRegistrationType?.name
                        }}</span>
                      </template>
                      <div class="pa-5" style="background: #fff">
                        <div class="text-field">
                          <span class="text-title">Постановка на облiк - </span>
                          <span class="text-desc">
                            {{ getBeautyNum(requestData.registration) }} грн
                          </span>
                        </div>
                        <div class="text-field">
                          <span class="text-title">Щорiчнi витрати - </span>
                          <span class="text-desc">
                            {{ getBeautyNum(requestData.annualExpenses) }} грн
                          </span>
                        </div>
                        <div class="text-field">
                          <span class="text-title">Зняття з облiку - </span>
                          <span class="text-desc">
                            {{ getBeautyNum(requestData.removalRegistration) }}
                            грн
                          </span>
                        </div>
                      </div>
                    </v-menu>
                    <p v-else class="title-label">
                      {{ requestData.placeOfRegistrationType?.name }}
                    </p>
                  </div>
                </v-col>
                <v-col :cols="12" :md="2" class="pt-0 pb-0">
                  <div class="data-card">
                    <span class="area-label">Асистанс</span>
                    <p class="title-label">{{ assistance }}</p>
                  </div>
                </v-col>
                <v-col :cols="12" :md="2" class="pt-0 pb-0">
                  <div class="data-card">
                    <span class="area-label">Дані про поставку</span>
                    <p class="title-label">
                      {{ LO.inStock ? 'В наявності' : 'В поставці' }}
                    </p>
                  </div>
                </v-col>
                <v-col :cols="12" :md="3" class="pt-0 pb-0">
                  <div class="data-card">
                    <span class="area-label"
                      >Передоплата постачальнику (%)</span
                    >
                    <p class="title-label">{{ LO.providerPrepay }}%</p>
                  </div>
                </v-col>
                <v-col :cols="12" :md="3" class="pt-0 pb-0">
                  <div class="data-card">
                    <span class="area-label">Знижка постачальника</span>
                    <p class="title-label">{{ LO.providerSale }}</p>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col :cols="12" :md="6" class="pt-0 pb-0">
                  <div class="data-card">
                    <span class="area-label">Попереднє фінансування</span>
                    <p class="title-label">{{ preFinancing }}</p>
                  </div>
                </v-col>
                <v-col :cols="12" :md="4" class="pt-0 pb-0">
                  <div class="data-card">
                    <span class="area-label">Паливна картка</span>
                    <p class="title-label">
                      {{ LO.patrolCardSupport === 1 ? 'Так' : 'Ні' }}
                    </p>
                  </div>
                </v-col>
              </v-row>
              <v-row v-if="!LO.inStock">
                <v-col :cols="12" :md="6" class="pt-0 pb-0">
                  <div class="data-card">
                    <span class="area-label">Дані про поставку</span>
                    <p class="title-label">{{ LO.productStatusText }}</p>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>

          <div v-if="!calculation.leaseReverse" class="section mt-5 mb-5">
            <div class="mb-5 d-flex align-center">
              <ContactIcon />
              <span class="card-block-title">Постачальник</span>
            </div>
            <div class="section-content" style="padding-left: 0">
              <v-row>
                <v-col :cols="12" :md="4" class="pt-0 pb-0">
                  <div class="data-card">
                    <span class="area-label">Постачальник</span>
                    <v-tooltip
                      bottom
                      :text="getEntityName(calculation.provider)">
                      <template #activator="{ props }">
                        <span class="title-label hide-line" v-bind="props">
                          <ALink
                            :text="getEntityName(calculation.provider)"
                            :route="
                              getEntityRoute(
                                calculation.providerId,
                                calculation.providerTypeId
                              )
                            ">
                          </ALink>
                        </span>
                      </template>
                      <span>{{ getEntityName(calculation.provider) }}</span>
                    </v-tooltip>
                  </div>
                </v-col>
                <v-col
                  v-if="calculation.providerEmployee"
                  :cols="12"
                  :md="4"
                  class="pt-0 pb-0">
                  <div class="data-card" style="position: relative">
                    <div class="d-flex align-center">
                      <span class="area-label" style="margin-right: 5px"
                        >Контактна особа</span
                      >
                      <div class="text-center d-inline-flex align-center">
                        <v-menu
                          v-model="menu"
                          :close-on-content-click="false"
                          :nudge-width="300"
                          offset-x>
                          <template #activator="{ props }">
                            <v-icon v-bind="props" size="17" class="pb-3">
                            </v-icon>
                            <div v-bind="props">
                              <InformIcon />
                            </div>
                          </template>
                          <div class="popup">
                            <div class="popup-wrapper">
                              <div class="menu-header mb-2">
                                <span class="menu-title"
                                  >Дані постачальника:</span
                                >
                              </div>
                              <div>
                                <div>
                                  <div class="data-card">
                                    <span class="area-label">Посада</span>
                                    <p class="title-label">
                                      {{
                                        calculation.providerEmployee
                                          .fullPostName || 'Iнформацiя відсутня'
                                      }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div>
                                  <div class="data-card">
                                    <span
                                      class="area-label d-flex align-center">
                                      <PhoneIcon class="mr-1" />
                                      Телефон
                                    </span>
                                    <p class="title-label">
                                      {{
                                        getSafe(() =>
                                          calculation.providerEmployee.contact.phones.join(
                                            ', '
                                          )
                                        ) || 'Iнформацiя відсутня'
                                      }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div>
                                  <div class="data-card">
                                    <span
                                      class="area-label d-flex align-center">
                                      <EmailIcon class="mr-1" />
                                      Email
                                    </span>
                                    <p class="title-label">
                                      {{
                                        getSafe(() =>
                                          calculation.providerEmployee.contact.emails.join(
                                            ', '
                                          )
                                        ) || 'Iнформацiя відсутня'
                                      }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </v-menu>
                      </div>
                    </div>

                    <v-tooltip
                      bottom
                      :text="calculation?.providerEmployee?.contact?.fullName">
                      <template #activator="{ props }">
                        <div class="title-label hide-line" v-bind="props">
                          <ALink
                            :text="
                              calculation?.providerEmployee?.contact?.fullName
                            "
                            :route="{
                              name: 'individuals-form',
                              params: { id: calculation.providerEmployeeId },
                            }">
                          </ALink>
                        </div>
                      </template>
                      <span>{{
                        calculation?.providerEmployee?.contact?.fullName
                      }}</span>
                    </v-tooltip>
                  </div>
                </v-col>
                <v-col
                  v-if="calculation.dealerPoint"
                  :cols="12"
                  :md="4"
                  class="pt-0 pb-0">
                  <div class="data-card">
                    <span class="area-label">Торгова точка</span>
                    <v-tooltip bottom :text="calculation.dealerPoint.name">
                      <template #activator="{ props }">
                        <div class="title-label hide-line" v-bind="props">
                          <ALink
                            :text="calculation.dealerPoint.name"
                            :route="{
                              name: 'dealer-form',
                              params: { id: calculation.dealerPoint.id },
                            }">
                          </ALink>
                        </div>
                      </template>
                      <span>{{ calculation.dealerPoint.name }}</span>
                    </v-tooltip>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
          <div class="section mt-5 mb-5">
            <div class="mb-2 d-flex align-center">
              <ContactIcon />
              <span class="card-block-title">Агент</span>
            </div>
            <div class="section-content" style="padding-left: 0">
              <v-row v-if="calculation.agent">
                <v-col :cols="12" :md="4" style="position: relative">
                  <div class="data-card">
                    <div class="d-flex align-center">
                      <span class="area-label" style="margin-right: 5px"
                        >ПІБ</span
                      >
                      <div class="text-center d-inline-flex align-center">
                        <v-menu
                          v-model="agentMenu"
                          :close-on-content-click="false"
                          :nudge-width="300"
                          offset-x>
                          <template #activator="{ props }">
                            <div v-bind="props">
                              <InformIcon />
                            </div>
                          </template>
                          <div class="popup">
                            <div class="popup-wrapper">
                              <div class="menu-header mb-2">
                                <span class="menu-title">Дані агента:</span>
                              </div>
                              <div>
                                <div>
                                  <div class="data-card">
                                    <span class="area-label">Мiсце роботи</span>
                                    <p class="title-label">
                                      {{
                                        calculationAgentCareer ||
                                        'Iнформацiя відсутня'
                                      }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div>
                                  <div class="data-card">
                                    <span
                                      class="area-label d-flex align-center">
                                      <PhoneIcon class="mr-1" />
                                      Телефон
                                    </span>
                                    <p class="title-label">
                                      {{
                                        calculationAgentPhone ||
                                        'Iнформацiя відсутня'
                                      }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div>
                                  <div class="data-card">
                                    <span
                                      class="area-label d-flex align-center">
                                      <EmailIcon class="mr-1" />
                                      Email
                                    </span>
                                    <p class="title-label">
                                      {{
                                        calculationAgentEmail ||
                                        'Iнформацiя відсутня'
                                      }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </v-menu>
                      </div>
                    </div>
                    <p class="title-label">
                      <ALink
                        :text="calculation?.agent?.contact?.fullName"
                        :route="{
                          name: 'agent-form',
                          params: { id: calculation.agent.id },
                        }">
                      </ALink>
                    </p>
                  </div>
                </v-col>
              </v-row>
              <div v-else class="mb-3" style="font-size: 0.93rem">
                Агент відсутній
              </div>
            </div>
          </div>
        </div>

        <v-row v-if="showFields.length" class="mt-3 mb-5">
          <v-col :cols="12" :md="12" class="pt-0">
            <OpenableList
              title="документи"
              style="
                margin-left: -5px !important;
                margin-bottom: -20px;
                margin-top: -20px;
              ">
              <div style="padding-left: 0 !important">
                <FilesUploader
                  v-if="showFields.includes('specificationDocuments')"
                  v-model:documents="calculationData.specificationDocuments"
                  readonly
                  label="Специфікація" />
                <FilesUploader
                  v-if="
                    !LO.stateId && showFields.includes('agreedMarketValuePL')
                  "
                  v-model:documents="calculationData.agreedMarketValuePL"
                  readonly
                  label="Погоджена ринкова вартість" />
                <FilesUploader
                  v-if="
                    !LO.stateId && showFields.includes('leasingObjectPhotos')
                  "
                  v-model:documents="calculationData.leasingObjectPhotos"
                  readonly
                  label="Фото ОЛ" />
              </div>
            </OpenableList>
          </v-col>
        </v-row>

        <slot name="append">
          <!-- Additional content -->
        </slot>
      </div>
    </div>
    <slot name="after">
      <!-- Additional content -->
    </slot>
  </div>
</template>

<script>
import EmailIcon from '@/assets/svg//social/email-icon.vue'
import PhoneIcon from '@/assets/svg/phone-icon.vue'
import InformIcon from '@/assets/svg/information-icon.vue'
import ALink from '@/components/Link.vue'
import InputPusher from '@/components/InputPusher.vue'
import OpenableList from '@/components/OpenableList.vue'
import ContactIcon from '@/assets/svg/contact-icon.vue'
import GeneralInfoIcon from '@/assets/svg/general-info.vue'
import GpsIcon from '@/assets/svg/gps-icon.vue'
import InsuranceIcon from '@/assets/svg/insurance-icon.vue'
import FinanceIcon from '@/assets/svg/finance-icon.vue'
import FilesUploader from './FilesUploader.vue'
import { getEntityName, getEntityRoute, getSafe } from '@/utils/helperFunc'
import {
  LODescription as getLoDescription,
  getBeautyNum,
} from '@/utils/helperFunc'
import { mapState } from 'vuex'
import { formutSum, keepDigit } from '@/utils/formatFunc'
import Benefit from '@/assets/svg/benefit.vue'
import { getDirectory } from '@/plugins/directory/index.ts'

export default {
  components: {
    Benefit,
    FilesUploader,
    FinanceIcon,
    InsuranceIcon,
    GpsIcon,
    GeneralInfoIcon,
    ContactIcon,
    OpenableList,
    ALink,
    InputPusher,
    InformIcon,
    EmailIcon,
    PhoneIcon,
  },
  props: {
    calculation: { type: Object },
    readonly: { type: Boolean, default: true },
    showFields: { type: [Array, String], default: '' },
    simple: { type: Boolean, default: false },
  },
  data: () => ({
    fav: true,
    menu: false,
    agentMenu: false,
    message: false,
    hints: true,
  }),
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
    }),
    vehicleInfo() {
      const isVehicleNew =
        this.calculation?.requestData?.isNew || this.calculation?.isNew
      const leasingObjectType =
        this.calculation?.leasingObjectType?.name ||
        this.calculation?.requestData?.leasingObjectType?.name ||
        'Невідомий тип'
      const body =
        this.calculation?.body?.name ||
        this.calculation?.requestData?.body?.name ||
        ''
      const mark =
        this.calculation?.leasedAssertMark?.name ||
        this.calculation?.requestData?.leasedAssertMark?.name
      const model =
        this.calculation?.leasedAssertModel?.name ||
        this.calculation?.requestData?.leasedAssertModel?.name
      const modification =
        this.calculation?.modification?.name ||
        this.calculation?.requestData?.modification?.name ||
        ''
      const specification =
        this.calculation?.specification?.name ||
        this.calculation?.requestData?.specification?.name ||
        ''
      const vehYear =
        this.calculation?.leasingObjectYear ||
        this.calculation?.requestData?.leasingObjectYear ||
        ''

      const vehicleType = isVehicleNew ? 'Новий' : 'Вживаний'

      return `${vehicleType} ${leasingObjectType.toLowerCase()} ${mark} ${model} ${body} ${modification} ${specification} ${vehYear} року`
    },
    offerAdvance() {
      return (
        this.calculation?.exportData?.export?.['offer-advance'] ||
        this.calculation?.offerAdvance
      )
    },
    calculationData() {
      return this.calculation
    },
    requestData() {
      return this.calculation.requestData
    },
    calcRequestData() {
      return this.calculation?.calcRequestData
    },
    calculationAgentEmail() {
      return this.calculationData.agent?.contact?.emails?.join(', ')
    },
    calculationAgentPhone() {
      return this.calculationData.agent?.contact?.phones?.join(', ')
    },
    calculationAgentCareer() {
      return this.calculationData.agent?.contact?.contractor?.shortName
    },
    LO() {
      return getLoDescription(this.calculation)
    },
    leasingProgramName() {
      const leaseReverse = this.calculation.leaseReverse
        ? ' (Зворотній лізинг)'
        : ''
      return `${this.calculation.leasingProgram?.name}${leaseReverse}`
    },
    fundRaising() {
      if (!getDirectory('nbuCurrencyCourses').length) return
      const currency = getDirectory('nbuCurrencyCourses').find(cur => {
        return cur.currency.name === 'USD'
      })
      if (currency) return currency.rate
      return 0
    },
    LOAmountOfFinancing() {
      const amount = +this.calculation?.exportFinancing
      if (!amount) return

      const formattedAmount = this.formutSum(amount, true)
      const formattedAmountInUSD = this.formutSum(
        (amount / this.fundRaising).toFixed()
      )

      return `${formattedAmount} (${formattedAmountInUSD} $)`
    },
    preFinancing() {
      const c = this.calcRequestData || {}
      return `Кількість днів: ${c['pre-financing-days'] || '0'} | Сума: ${
        c['pre-financing-amount'] || '0'
      } грн`
    },
    assistance() {
      const map = { 1: 'Класік +', 2: 'Ultra All' }
      const c = this.calcRequestData || null
      return c ? map[c['assist-service']] : '---'
    },
  },
  methods: {
    getSafe,
    getEntityName,
    getLoDescription,
    formutSum,
    keepDigit,
    getEntityRoute,
    getBeautyNum,
    currentSum(amount) {
      if (!amount) return 'Сума не визначена'

      const formattedAmount = formutSum(amount.toFixed())
      const formattedFundraisingAmount = formutSum(
        (amount / this.fundRaising).toFixed()
      )

      return `${formattedAmount} (${formattedFundraisingAmount} $)`
    },
  },
}
</script>

<style lang="scss" scoped>
.popup {
  background: #ffffff;
  box-shadow: 0 2px 8px rgb(65 65 65 / 20%);
  border-radius: 4px;
  z-index: 1000;
  width: 500px;
}

.popup-wrapper {
  padding: 15px 25px;
}

.menu-title {
  color: #08487a;
  font-size: 17px;
  font-weight: 700;
}
.section {
  margin-top: 10px;
}
.section-title {
  padding: 0;
}
.section-content {
  padding-left: 30px;
}
.text {
  &-field {
    margin-bottom: 15px;
  }
  &-title {
    color: #1c1d21;
    font-weight: 600;
    font-size: 15px;
    margin-right: 6px;
  }
  &-desc {
    font-weight: 400;
    font-size: 16px;
    color: #1c1d21;
  }
}
.title-label {
  font-size: 15px;
  color: #1c1d21;
  margin-top: 5px;
  font-weight: 400 !important;
}
.data-card {
  display: flex;
  flex-direction: column;
}
.card-block-title {
  display: block;
  color: #08487a;
  font-weight: 600;
  font-size: 16px;
  margin-left: 9px;
}
.hide-line {
  display: block;
  width: 230px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
