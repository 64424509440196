<template>
  <div>
    <v-row class="d-flex justify-space-between">
      <v-col :cols="12" :sm="6" class="pt-0 pb-0">
        <v-btn
          variant="flat"
          color="green"
          :loading="loading"
          @click="() => getFinancingAmount().then(clearValues)">
          {{
            financingAmount
              ? 'Оновити суму фiнансування'
              : 'Отримати суму фiнансування'
          }}
        </v-btn>
      </v-col>
      <v-col :cols="12" :sm="3" class="d-flex flex-column">
        <span class="label">Вартiсть обьекту лізингу</span>
        <v-text-field
          placeholder="Введіть суму"
          :model-value="toLocalSum(calculation.grnTotalPrice, true)"
          readonly
          hide-details>
        </v-text-field>
        <span class="label">Сума фінансування</span>
        <v-text-field
          placeholder="Введіть суму"
          :model-value="toLocalSum(financingAmount)"
          readonly
          hide-details>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="12" :sm="1" class="pt-0 pb-0">
        <span class="card-label">№</span>
      </v-col>
      <v-col :cols="12" :sm="4" class="pt-0 pb-0">
        <span class="card-label">Тип платежу</span>
      </v-col>
      <v-col :cols="12" :sm="3" class="pt-0 pb-0">
        <span class="card-label">Дата</span>
      </v-col>
      <v-col :cols="12" :sm="4" class="pt-0 pb-0">
        <span class="card-label">Сума</span>
      </v-col>
    </v-row>
    <div v-for="(item, key) in monthsKeys" :key="key">
      <v-row
        :class="`amount-funding-row ${activeInput(key) ? 'row-focus' : ''}`">
        <v-col :cols="12" :sm="1" class="pb-0" style="align-self: center">
          {{ key + 1 }}
        </v-col>
        <v-col :cols="12" :sm="4" class="pb-0" style="align-self: center">
          {{ 'Черговий лізинговий платiж' }}
        </v-col>
        <v-col :cols="12" :sm="3" class="pb-0" style="align-self: center">
          <span class="card-label">{{ getDateByKey(key) }}</span>
        </v-col>
        <v-col :cols="12" :sm="4" :class="`pb-0 col-left-border`">
          <v-text-field
            :id="'row-' + key"
            v-model="months[item].reimbursement"
            class="mb-1 border-bottom-none"
            :readonly="monthsKeys.length === key + 1 || !!!financingAmount"
            :disabled="monthsKeys.length === key + 1 || !!!financingAmount"
            :placeholder="monthsKeys.length === key + 1 ? 'автоматично' : '0'"
            hide-details
            dense
            @input="setInput($event)"
            @focusout="Input.restoreData('row-' + key)"
            @focusin="Input.hideData('row-' + key)">
          </v-text-field>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { setErrHandler, Input } from '@/utils/helperFunc'
import { inputNumber, keepDigit } from '@/utils/formatFunc'
import { urlGetFinancingAmount } from '@/pages/request'
import { useVuelidate } from '@vuelidate/core'
export default {
  props: { dialog: { type: Object } },
  validations() {
    return { months: {} }
  },
  setup() {
    return { v$: useVuelidate() }
  },
  data: () => ({
    item: {},
    months: {},
    financingAmount: 0,
    loading: false,
    cache: null,
    initialized: false,
    Input: new Input(),
  }),
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    dialogData() {
      return this.dialog
    },
    inputsSum() {
      return this.getInputsSum(false)
    },
    allSum() {
      return this.getInputsSum()
    },
    calculation() {
      return this.dialog.dialogItem.calculation
    },
    lastModel() {
      const keys = this.monthsKeys
      const lastModelName = keys[keys.length - 1]
      return this.months[lastModelName]
    },
    monthsKeys() {
      return Object.keys(this.months)
    },
    leasingStartDate() {
      if (!this.calculation.leasingStartDate) return
      const [d, m, y] = this.calculation.leasingStartDate.split('.')
      return `${y}-${m}-${d}`
    },
    leasingTerm() {
      return Array.from(Array(this.item.calculation.leasingTerm).keys())
    },
    stockFiftyFifty() {
      return this.calculation.stock === 7
    },
  },
  methods: {
    inputNumber,
    setErrHandler,
    activeInput(key) {
      return this.monthsKeys.length !== key + 1 && key !== 0
    },
    getDateByKey(key) {
      const date = new Date(this.leasingStartDate).setMonth(
        new Date(this.leasingStartDate).getMonth() + key + 1
      )
      return new Date(date).toLocaleString('ru-RU').substring(0, 10)
    },
    toNum(e) {
      if (this.stockFiftyFifty && e === '-') return '-' // [CRUTCH] обработка кейса с вводом негативного числа
      return parseFloat(parseFloat(keepDigit(e || 0)).toFixed(2))
    },
    toLocalSum(str, floatString) {
      const val = floatString ? +str : str
      // if (floatString) {
      //   return getBeautyNum(floatString)
      // }
      return (val ?? 0).toLocaleString('en-EN').replace(/,/g, ' ')
    },
    getFinancingAmount() {
      this.loading = true
      const tempGraphTypes = JSON.parse(
        JSON.stringify(this.calculation.graphTypes)
      )
      this.calculation.graphTypes.length = 0
      this.calculation.graphTypes.push('source')

      const obj = this.calculation
      obj.gpsTracker = this.calculation.gpsTrackerType?.id

      if (this.calculation.programFSN) {
        obj.uird = this.dialog.dialogItem.localNbuDeposit
        obj.workplace = this.calculation.workplaceType?.id
        obj.target = this.calculation.targetType?.id
        obj.customerType = this.calculation.customerTypeObject?.id
      } else {
        delete obj.uird
      }

      return this.$axios
        .post(urlGetFinancingAmount(), obj)
        .then(res => {
          this.$setSnackbar({
            text:
              'Cума фiнансування: ' +
              this.toLocalSum(
                res.data?.financingSum?.source?.Amount_of_financing
              ),
          })
          this.calculation.graphTypes.length = 0
          this.calculation.graphTypes.push(...tempGraphTypes)
          this.financingAmount =
            res.data?.financingSum?.source?.Amount_of_financing
          this.dialogData.params.$data.financingAmount = this.financingAmount
          this.loading = false
          return res
        })
        .catch(err => this.$err(err, () => (this.loading = false)))
    },
    getInputsSum(lastIndex = true) {
      const arr = Object.values(this.months)
      if (!arr?.length) return 0
      return arr.reduce((a, b, i) => {
        i++
        return !lastIndex && i === arr.length
          ? { reimbursement: parseFloat(keepDigit(a.reimbursement || 0)) + 0 }
          : {
              reimbursement:
                (parseFloat(keepDigit(a.reimbursement || 0)) || 0) +
                (parseFloat(keepDigit(b.reimbursement || 0)) || 0),
            }
      }).reimbursement
    },
    setInput(e) {
      const el = e.target
      const inputEvent = new Event('input', { bubbles: true })
      const maxSum = this.financingAmount

      if (this.inputsSum > maxSum) {
        this.lastModel.reimbursement = 0
        el.value = this.toLocalSum(
          maxSum - (this.inputsSum - this.toNum(el.value)).toFixed(2)
        )
        el.dispatchEvent(inputEvent)
      } else {
        inputNumber(e, { float: 2, minValue: this.stockFiftyFifty ? null : 0 })
        this.lastModel.reimbursement = this.toLocalSum(
          this.toNum(this.lastModel.reimbursement) + (maxSum - this.allSum)
        )
      }
    },
    createObject(arr, object = {}, update = false) {
      arr.forEach((v, i) => {
        i++
        if (v.row) {
          v.reimbursement = update ? '0' : this.toLocalSum(v.reimbursement)
          object['row' + i] = v
        } else {
          object['row' + i] = {
            row: i,
            reimbursement:
              arr.length === i
                ? this.toLocalSum(this.toNum(this.financingAmount))
                : '0',
          }
        }
      })
      return object
    },
    initValues() {
      Object.assign(this.item, this.dialog.dialogItem)
      this.financingAmount = this.calculation.financingAmount
      !this.financingAmount && this.getFinancingAmount()
      const months = {}

      if (
        this.calculation?.manual &&
        this.leasingTerm.length === this.calculation.manual.length
      ) {
        const manuals = this.calculation.manual
        this.createObject(manuals, months)
        Object.assign(this.months, months)
        setTimeout(() => {
          this.initialized = true
        }, 0)
        return
      }

      this.createObject(this.leasingTerm, months)
      Object.assign(this.months, months)
      setTimeout(() => {
        this.initialized = true
      }, 0)
    },
    clearValues() {
      const object = this.createObject(this.leasingTerm, {}, /* update */ true)
      Object.assign(this.months, object)
    },
    submit() {
      if (!this.calculation.graphTypes.includes('source')) {
        this.calculation.graphTypes.push('source')
      }

      this.calculation.financingAmount = this.financingAmount
      const manual = Object.values(this.months).map(v => ({
        row: v.row,
        reimbursement: this.toNum(v.reimbursement),
      }))
      const calculation = this.calculation
      calculation.gpsTracker = this.calculation.gpsTrackerType?.id
      calculation.manual = [...manual]

      return this.dialog.params.submit(calculation)
    },
  },
  watch: {
    months: {
      handler: function (val) {
        if (val && this.initialized) {
          this.dialogData.disabled = false
        }
      },
      deep: true,
    },
  },
  created() {
    this.initValues()
  },
}
</script>
<style lang="scss">
.col-left-border {
  border-left: 1px solid goldenrod;
}
.amount-funding-row {
  border-bottom: 1px solid lightblue;
  font-size: 13px;
  &:hover {
    background: #25212107;
  }
  &:focus-within {
    background: #25212107;
  }
}
</style>
