<template>
  <v-card ref="eventCard" min-width="440px" flat>
    <v-toolbar color="rgb(252, 114, 71)" :height="44" dark>
      <v-btn
        v-if="editable"
        :disabled="selectedEvent.status.id === 2"
        icon
        size="small"
        @click="$emit('eventEdit', selectedEvent)">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-toolbar-title>{{ selectedEvent.name }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon size="small" @click="closeCard()">
        <v-icon>{{ 'mdi-close' }}</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <div class="info-block">
        <div>
          <span class="event-title">Менеджер:&nbsp;</span>
          {{ getContactInitials(selectedEvent.responsible) }}
        </div>
        <div>
          <span class="event-title">Категорiя:&nbsp;</span>
          {{ selectedEvent.type.name }}
        </div>
        <div>
          <span class="event-title">Мiсце проведення:</span>
          {{ selectedEvent.address }}
        </div>
        <div v-if="selectedEvent.result">
          <span class="event-title">Статус:</span>
          {{ selectedEvent.result.name }}
        </div>
      </div>
      <div class="time-block">
        <v-tooltip top color="grey darken-3" text="Дата та час початку подiї">
          <template #activator="{ props }">
            <div style="cursor: pointer" v-bind="props">
              <start-icon :width="20" :height="20" />
              &nbsp;
              <span class="event-date">
                {{ selectedEvent.startDate }}
              </span>
            </div>
          </template>
          <span>Дата та час початку подiї</span>
        </v-tooltip>
        <v-tooltip
          top
          color="grey darken-3"
          text="Дата та час завершення подiї">
          <template #activator="{ props }">
            <div style="cursor: pointer" v-bind="props">
              <finish-icon :width="20" :height="20" />
              &nbsp;
              <span class="event-date">
                {{ selectedEvent.endDate }}
              </span>
            </div>
          </template>
          <span>Дата та час завершення подiї</span>
        </v-tooltip>
      </div>
      <div class="event-related-entities mt-7">
        <h4 class="text-center" style="line-height: 2rem">Зустрiч з:</h4>
        <div class="application-entities">
          <span class="d-inline-block">
            <v-icon>{{ 'mdi-account' }}</v-icon
            >&nbsp;
          </span>
          <span style="vertical-align: bottom"> Контакт: </span>
          <a
            class="event-relateds-link"
            @click="
              $router.push({
                name: 'individuals-form',
                params: { id: selectedEvent.contact.id },
              })
            ">
            {{ $string(() => selectedEvent.contact.fullName) }}
          </a>
        </div>
        <div
          v-if="getContactContractor(selectedEvent.contact)"
          class="application-entities pt-2">
          <span class="d-inline-block">
            <v-icon>{{ 'mdi-office-building' }}</v-icon
            >&nbsp;
          </span>
          <span style="vertical-align: bottom"> Контрагент: </span>
          <a
            class="event-relateds-link"
            @click="
              $router.push({
                name: 'contractors-form',
                params: { id: getContactContractor(selectedEvent.contact).id },
              })
            ">
            {{ getContactContractor(selectedEvent.contact).shortName }}
          </a>
        </div>
        <div v-if="dealerOutletName" class="application-entities pt-2">
          <span class="d-inline-block">
            <v-icon>{{ 'mdi-office-building' }}</v-icon
            >&nbsp;
          </span>
          <span style="vertical-align: bottom"> Торгова точка: </span>
          <a
            class="event-relateds-link"
            @click="
              $router.push({
                name: 'dealer-form',
                params: { id: contactDealerOutlet },
              })
            ">
            {{ dealerOutletName }}
          </a>
        </div>
      </div>
      <div class="description pt-3">
        <h4 class="text-center" style="line-height: 2rem">Завдання</h4>
        <div>
          <p class="mb-0">{{ selectedEvent.description }}</p>
        </div>
        <div>
          <p>
            <FilesUploader
              v-model:documents="selectedEventData.files"
              label="Документи"
              class="mt-2"
              disabled>
            </FilesUploader>
          </p>
        </div>
      </div>
      <div class="description pt-3">
        <h4 class="text-center" style="line-height: 2rem">Запрошені</h4>
        <v-row v-for="(item, key) in selectedEventInvitations" :key="key">
          <v-col :cols="12" :sm="6" class="pb-0">
            {{ getInvitedName(item.invited) }}
          </v-col>
          <v-col :cols="12" :sm="6" class="pb-0">
            <v-tooltip bottom :text="getInvitedStatus(item).text">
              <template #activator="{ props }">
                <v-icon
                  :color="getInvitedStatus(item).color"
                  small
                  v-bind="props">
                  {{ getInvitedStatus(item).icon }}
                </v-icon>
              </template>
              <span>{{ getInvitedStatus(item).text }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </div>
      <div v-if="selectedEvent.result">
        <div class="description pt-3">
          <h4 class="text-center" style="line-height: 2rem">Результат</h4>
          <div>
            <p class="mb-0">{{ selectedEvent.resultComment }}</p>
          </div>
          <FilesUploader
            v-model:documents="selectedEventData.resultFiles"
            label="Документи"
            readonly
            class="mt-2">
          </FilesUploader>
        </div>
      </div>
    </v-card-text>
    <v-card-actions v-if="routeInvitations">
      <v-btn
        variant="text"
        color="secondary"
        @click="$emit('changeSelectedOpen', false)">
        Закрити
      </v-btn>
    </v-card-actions>
    <v-card-actions
      v-if="(routeMyMeetings || routeContact) && !selectedEvent.result"
      class="d-flex justify-space-between ml-3 mr-3">
      <finish-event
        :submit="() => false"
        :action="1"
        buttonIcon="mdi-checkbox-marked-circle"
        titleName="Зустріч завершена"
        :event="selectedEvent"
        :eventStatuses="eventStatuses"
        @change="$emit('change', $event)"
        @input="$emit('input', $event)">
      </finish-event>
      <finish-event
        :submit="() => false"
        :action="0"
        buttonIcon="mdi-close-circle"
        titleName="Зустріч скасована"
        :event="selectedEvent"
        :eventStatuses="eventStatuses"
        :rejected="true"
        @change="$emit('change', $event)"
        @input="$emit('input', $event)">
      </finish-event>
    </v-card-actions>
  </v-card>
</template>
<script>
import startIcon from '@/assets/svg/time-start.vue'
import finishIcon from '@/assets/svg/time-finish.vue'
import finishEvent from './finish-event-card.vue'
import FilesUploader from '@/components/FilesUploader.vue'
import { humanDate } from '@/utils/formatFunc'
import { urlGetFinishActivityStatuses } from '@/pages/request'
import { getContactContractor } from './helper'
import { mapState } from 'vuex'
import { getContactInitials } from '@/utils/helperFunc'
export default {
  components: {
    startIcon,
    finishIcon,
    finishEvent,
    FilesUploader,
  },
  emits: ['eventEdit', 'close', 'change', 'input', 'changeSelectedOpen'],
  props: {
    selectedEvent: { type: Object, default: () => ({}) },
    selectedOpen: { type: Boolean, default: false },
    editable: { type: Boolean, default: true },
    deletable: { type: Boolean, default: true },
  },
  data: () => ({
    eventStatuses: [],
  }),
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
    }),
    selectedEventData() {
      return this.selectedEvent
    },
    contactDealerOutlet() {
      if (!this.selectedEvent?.contactId) return

      const contact = this.selectItems.contacts.find(
        contact => contact.id === this.selectedEvent?.contactId
      )
      const dealerPoint = (contact?.careers || [])
        .filter(career => career.dealerPointId)
        .map(career => career.dealerPointId)
        .pop()

      return dealerPoint
    },
    dealerOutletName() {
      if (!this.contactDealerOutlet) return
      const dealerOutlet = this.selectItems.dealerOutletsList.find(
        outlet => outlet.id === this.contactDealerOutlet
      )
      return dealerOutlet?.name
    },
    routeInvitations() {
      return this.$route.name === 'invitations'
    },
    routeMyMeetings() {
      return this.$route.name === 'my-meetings'
    },
    routeContact() {
      return this.$route.name === 'contact-activity'
    },
    selectedEventInvitations() {
      return this.selectedEvent?.sourceInvitations
        ? this.selectedEvent?.sourceInvitations
        : this.selectedEvent.invitations
    },
  },
  methods: {
    getContactInitials,
    humanDate,
    urlGetFinishActivityStatuses,
    getContactContractor,
    getInvitedName(invited) {
      return `${invited.surname || '-'} ${invited.name || '-'} ${
        invited.patronymic || '-'
      }`
    },
    getInvitedStatus(activity) {
      switch (activity.inviteStatusId) {
        case 1:
          return {
            icon: 'mdi-account-question',
            color: 'orange darken-2',
            text: 'Очікування',
          } /* waiting */
        case 2:
          return {
            icon: 'mdi-account-check',
            color: 'green darken-2',
            text: 'Погодився',
          } /* accepted */
        case 3:
          return {
            icon: 'mdi-account-cancel',
            color: 'red darken-2',
            text: 'Відмовився',
          } /* denied */
        default:
          return {
            icon: 'mdi-help',
            color: 'grey darken-2',
            text: 'Невідомий статус',
          } /* undefined */
      }
    },
    closeCard() {
      this.$emit('close')
      const el = document.getElementById('app')
      el.click()
    },
    getFinishStatuses() {
      return this.$axios
        .get(this.urlGetFinishActivityStatuses())
        .then(res => {
          this.eventStatuses.push(...res.data)
          return res
        })
        .catch(this.$err)
    },
  },
  created() {
    this.getFinishStatuses()
    this.$store.dispatch('addContacts')
    this.$store.dispatch('addDealerOutletList')
  },
}
</script>

<style lang="scss">
.event-related-entities {
  .event-relateds-link {
    vertical-align: bottom;
    &:not(:last-child) {
      &:after {
        content: ', ';
        color: black !important;
      }
    }
  }
}
.time-block {
  width: 45%;
  display: inline-block;
  vertical-align: top;
  text-align: right;
  .event-date {
    vertical-align: super;
  }
}
.info-block {
  width: 50%;
  display: inline-block;
  vertical-align: top;
}
.event-title {
  color: slategrey;
}
</style>
