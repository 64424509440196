import { socket } from '@/socket'
import axios from 'axios'
import { playSound } from '@/utils/helperFunc'

const callNotifications = {
  state() {
    return {
      callNotificationSoundUrl: null,
      isCurrentCallActive: false,
      currentCallItem: {},
    }
  },
  mutations: {
    setCallSingleNotificationSoundUrl(state, blob) {
      state.callNotificationSoundUrl = URL.createObjectURL(blob)
    },
    changeCurrentCallActive(state, activeStatus) {
      state.isCurrentCallActive = activeStatus
    },
    setCurrentCallItem(state, item) {
      state.currentCallItem = item
    },
  },
  actions: {
    // incoming call
    listenCallNotification({ state, commit, rootState }) {
      // get notification sound
      return axios.get('/callSong.mp3', { responseType: 'blob' }).then(res => {
        commit('setCallSingleNotificationSoundUrl', res.data)
        socket.on(
          // @ts-ignore
          'erm_lead_call_single',
          res => {
            if (rootState.user.sipLine) {
              commit('changeCurrentCallActive', true)
              commit('setCurrentCallItem', res)

              playSound(state.callNotificationSoundUrl)
            }
          }
        )
      })
    },
  },
}

export { callNotifications }
