<template>
  <div style="margin-left: 15px">
    <div class="pt-3 pb-3" style="font-size: 16px; font-weight: 600">
      Прикріплення документів
    </div>
    <div style="min-height: 200px">
      <FilesUploader
        v-model:documents="agreementData.signedExtraAgreement"
        label="Договір додаткової угоди (пiдписаний)"
        :iconSize="26"
        single
        acceptedExt=".pdf"
        :readonly="readonly">
      </FilesUploader>
      <FilesUploader
        v-model:documents="agreementData.otherDocuments"
        label="Необов`язкові документи"
        :readonly="readonly"
        icon="mdi-file-document-multiple-outline">
      </FilesUploader>
    </div>
  </div>
</template>
<script>
import FilesUploader from '@/components/FilesUploader.vue'
import { mapState } from 'vuex'

export default {
  components: { FilesUploader },
  props: { agreement: { type: Object } },
  computed: {
    ...mapState({
      user: state => state.user,
    }),
    readonly() {
      return ![1, 5, 6].includes(this.user.roleId)
    },
    agreementData() {
      return this.agreement
    },
  },
}
</script>
