/* eslint no-control-regex: 0 */
import ErrorKeys from '../pages/error-keys'
import {
  urlNbuCurrencyCourses,
  urlGetContactsDuplicatesBySurname,
  urtCheckExistPhone,
  urtCheckExistEmail,
  urlOpenWithMicrosoft,
} from '@/pages/request'
import store from '../store/index'
import { entityTypeId as ET } from '@/type-ids'
import axios from 'axios'
import { formutSum } from '@/utils/formatFunc'
import { setSnackbar } from 'best-modules/plugins'
import { cloneDeep, get } from 'lodash'
import { getAddressPartName } from '@/dialogs/components/address/utils.ts'

function objClearEpmtyField(obj) {
  obj = JSON.parse(JSON.stringify(obj))
  for (const i in obj) {
    /* object property not exist or object property is array and array is empty */
    const isArr = Array.isArray(obj[i]) && !obj[i].length
    const notEmpty = !obj[i] && obj[i] !== 0 && obj[i] !== false

    if (notEmpty || isArr) {
      delete obj[i]
    }
  }
  return obj
}

function imageUrlCheck(url) {
  if (!url) {
    return
  }
  return `${window.location.origin}/${url}`
}

function trimExceededLength(elId, maxLength, callback) {
  const el = document.getElementById(elId)
  if (!el) return
  const event = new Event('input', { bubbles: true })
  if (el.value && typeof callback === 'function') {
    if (el.value !== callback(el.value)) {
      el.value = callback(el.value)
      el.dispatchEvent(event)
    }
  }
  if (el.value && el.value.length > maxLength) {
    el.value = el.value.substring(0, maxLength)
    el.dispatchEvent(event)
  }
}

function sortItemsByText(items = [], topItems = [], name, separator = true) {
  if (!items) return []
  if (!items.length || !topItems.length) return items

  const marksNames = items.map(m => m[name].toUpperCase())
  const marksNameSequence = topItems
    .map(name => name.toUpperCase())
    .filter(name => marksNames.includes(name.toUpperCase()))
  const sortedData = items.sort(compare)

  function addSeparator(items) {
    const firstIndex = 0
    const socondIndex = marksNameSequence.length + 1

    if (marksNameSequence.length && separator) {
      items.splice(firstIndex, 0, {
        id: 0,
        name: 'ТОП марки',
        props: { disabled: true },
      })
      items.splice(socondIndex, 0, {
        id: -1,
        name: 'Усi марки',
        props: { disabled: true },
      })
    }

    return items
  }

  function compare(a, b) {
    const aIndex = marksNameSequence.indexOf(a[name].toUpperCase())
    const bIndex = marksNameSequence.indexOf(b[name].toUpperCase())

    if (aIndex !== -1 && bIndex !== -1) {
      return aIndex > bIndex ? 1 : -1
    }
    if (aIndex === -1 && bIndex === -1) {
      return a[name].localeCompare(b[name])
    }
    if (bIndex !== -1) {
      return 1
    }
    return -1
  }

  return addSeparator(sortedData)
}

function tableDateSort(dateKey) {
  return function (items, index, isDesc) {
    return items.sort((a, b) => {
      if (dateKey && index[0] === dateKey) {
        const fd = date => {
          const getDate = date => {
            date = date.substring(0, 10)

            switch (true) {
              case date.includes('.'):
                return date.split('.')
              case date.includes('/'):
                return date.split('/')
              case date.includes('-'):
                return date.split('-')
              default:
                return date
            }
          }
          const [dd, mm, yyyy] = getDate(date || '')
          return new Date([yyyy, mm, dd].join('-'))
        }

        return !isDesc[0]
          ? fd(b[index]) - fd(a[index])
          : fd(a[index]) - fd(b[index])
      } else if (Number.isInteger(a[index]) || Number.isInteger(b[index])) {
        return !isDesc[0] ? b[index] - a[index] : a[index] - b[index]
      } else {
        const firstEng = string => /[a-zA-Z]/.test(string || '')
        const compare = (a, b, locale) =>
          (a || '').toLowerCase().localeCompare((b || '').toLowerCase(), locale)

        if (a[index]) {
          if (!isDesc[0]) {
            return firstEng(a[index]) && firstEng(b[index])
              ? compare(a[index], b[index], 'en')
              : compare(a[index], b[index], 'ua')
          } else {
            return firstEng(a[index]) && firstEng(b[index])
              ? compare(b[index], a[index], 'en')
              : compare(b[index], a[index], 'ua')
          }
        }
      }
    })
  }
}

function toFormatDate(date, dateOnly = false, curDay = false) {
  if (date && date[2] === '.') return date
  if (typeof date === 'string' && date.length !== 0) {
    let s = date
    s = `${s.substring(8, 10)}.${s.substring(5, 7)}.${s.substring(0, 4)}${
      s.length > 10 ? ' ' + s.substring(11, 16) : ''
    }`
    if (dateOnly) {
      s = `${s.substring(0, 10)}`
    }
    if (curDay) {
      const currentDate = new Date()
      const targetDate = new Date(backDate(s))
      const tomorrowDate = new Date()
      tomorrowDate.setDate(currentDate.getDate() + 1)
      if (
        targetDate.getDate() === tomorrowDate.getDate() &&
        targetDate.getMonth() === tomorrowDate.getMonth() &&
        targetDate.getFullYear() === tomorrowDate.getFullYear()
      ) {
        s = s.split('')
        s.splice(0, 10)
        s = 'Завтра' + s.join('')
      } else if (
        currentDate.getDate() === targetDate.getDate() &&
        currentDate.getMonth() === targetDate.getMonth() &&
        currentDate.getFullYear() === targetDate.getFullYear()
      ) {
        s = s.split('')
        s.splice(0, 10)
        s = 'Сьогодні' + s.join('')
      }
    }
    return s
  } else {
    return null
  }
}

function contactDetailsWithType(contacts, type) {
  if (!contacts || contacts.length === 0)
    return type === 'email' ? 'Відсутня' : 'Відсутній'
  return contacts
    .map(c => {
      const t = c.type ? `(${c.type.name.substring(0, 3)}.)` : ''
      return `${c[type]} ${t}`
    })
    .join(', ')
}

function toFormatArrWithDate(arr, ...fields) {
  fields.forEach(field => {
    arr = arr.map(el => {
      return { ...el, [field]: toFormatDate(el[field]) }
    })
  })
  return arr
}

function getResponsibleShortName(r) {
  return r ? `${r.surname} ${r.name[0]}.${r.patronymic[0]}.` : '---'
}

function getContactInitials(contact, params = {}) {
  if (!contact) return ''
  let c = { name: '', surname: '', patronymic: '' }
  if (typeof contact === 'object') c = { ...contact }

  const toShort = parts => {
    parts.name = parts.name ? `${parts.name[0].toUpperCase()}.` : ''
    parts.surname = parts.surname
      ? parts.surname[0].toUpperCase() + parts.surname.slice(1)
      : ''
    parts.patronymic = parts.patronymic
      ? `${parts.patronymic[0].toUpperCase()}.`
      : ''

    return `${parts.surname} ${parts.name}${parts.patronymic}`
  }

  if (params.byName && typeof contact === 'string') {
    ;[c.surname, c.name, c.patronymic] = contact.split(' ')
  }

  return params.isResponsible
    ? `${c.surname} ${c.name} ${c.patronymic}`
    : toShort(c)
}

function checkDuplicates(searchString, type = 'fullName') {
  if (searchString === null) {
    return Promise.resolve({ hasDuplicates: false, duplicate: [] })
  }
  const requestMap = {
    fullName: {
      url: urlGetContactsDuplicatesBySurname,
      key: 'phones',
      ruName: 'ПIБ',
      contactDetailsName: 'Телефон',
      contactDetailsType: 'phone',
    },
    email: {
      url: urtCheckExistEmail,
      key: 'emails',
      ruName: 'Пошті',
      contactDetailsName: 'Пошта',
      contactDetailsType: 'email',
    },
    phone: {
      url: urtCheckExistPhone,
      key: 'phones',
      ruName: 'Телефону',
      contactDetailsName: 'Телефон',
      contactDetailsType: 'phone',
    },
  }

  const requestData = requestMap[type]

  function getDuplicateNotifyHTML(contact) {
    const contactName = getContactInitials(contact.fullName, { byName: true })
    const responsibleName = getContactInitials(contact.responsible, {
      isResponsible: true,
    })
    const contactDetails = contactDetailsWithType(
      contact[requestData.key],
      requestData.contactDetailsType
    )

    return [
      {
        tag: 'div',
        text: `ПІБ: ${contactName}`,
        class: 'mb-1',
      },
      {
        tag: 'div',
        text: `Відповідальний: ${responsibleName}`,
        class: 'mb-1',
      },
      {
        tag: 'div',
        text: `${requestData.contactDetailsName}: ${contactDetails}`,
        class: 'mb-1',
      },
    ]
  }
  return new Promise((resolve, reject) => {
    axios
      .get(requestData.url(searchString))
      .then(res => {
        if (res.data.status === 200 || res.data.count === 0) {
          resolve({ hasDuplicates: false, duplicate: null })
        } else {
          let duplicate = []

          if (type === 'fullName' && res.data.count > 0) {
            delete res.data.count
            duplicate = Object.values(res.data)
          } else if (['email', 'phone'].includes(type)) {
            duplicate = [res.data]
          }

          const msgName = item =>
            `Ім'я: ${
              getContactInitials(item) || 'Імʼя не знайдено'
            },  Відповідальний: ${getContactInitials(item.responsible)}`
          const msgText = [
            {
              tag: 'div',
              text: `Знайдені збіги по ${requestData.ruName}`,
              class: 'mb-3',
            },
            {
              tag: 'ol',
              children: duplicate
                .map(item => {
                  return [
                    {
                      tag: 'li',
                      text: msgName(item),
                    },
                  ]
                })
                .flat(),
              class: 'mb-3',
            },
          ]

          setSnackbar({ text: msgText, color: 'warning' })

          return resolve({ hasDuplicates: true, duplicate })
        }
      })
      .catch(error => {
        reject(error)
      })
  })
}

function sortTableByDate(arr, field) {
  return arr.sort((a, b) => new Date(b[field]) - new Date(a[field]))
}

function checkPhoneValidation(phone) {
  return new Promise((resolve, reject) => {
    if (!phone) reject('Номер телефона не был предоставлен')

    const countryCodeRegex = /\+(\d{1,2})\s?\((\d{3})\)/
    const matches = phone.match(countryCodeRegex)

    if (!matches) reject('Неверный формат номера телефона')
    let operatorCode = matches[2]

    if (operatorCode.startsWith('0')) {
      operatorCode = operatorCode.substring(1)
    }

    const allowedFormat = [
      '68',
      '67',
      '77',
      '96',
      '97',
      '98',
      '50',
      '66',
      '75',
      '95',
      '99',
      '63',
      '73',
      '93',
      '91',
      '92',
      '89',
      '44',
    ]

    if (allowedFormat.includes(operatorCode)) {
      resolve(true)
    } else {
      // eslint-disable-next-line no-undef
      this.$setSnackbar({
        text: 'Вкажіть корректний номер телефону',
        color: 'warning',
      })
      reject(false)
    }
  })
}

function v$Notify(validation = {}, section, params = {}) {
  const title = [params.entity, validation._title]
    .filter(v => (typeof v === 'string' ? v.trim() : v))
    .join(' - ')
  console.info('validation', section, title, validation)
  const errorKeys = getErrorKeys(validation)
  console.log('errorKeys', errorKeys)
  function getErrorKeys(validation) {
    // const error = cloneDeep(validation)
    const error = Object.clone(validation) // TODO
    console.log('error', cloneDeep(error))
    for (const key in error) {
      if (
        key.startsWith('_') ||
        key.startsWith('$') ||
        (!error[key].$invalid && (!params.deep || !Array.isArray(error[key])))
      ) {
        delete error[key]
      }

      let subItem
      // if is another validation object
      if (
        params.deep &&
        typeof error[key] === 'object' &&
        Object.keys(error[key]).filter(
          itm =>
            !itm.startsWith('$') &&
            ![
              'required',
              'requiredIf',
              'email',
              'minValue',
              'maxValue',
              'minLength',
              'maxLength',
              'numeric',
              'sameAs',
            ].includes(itm)
        ).length
      ) {
        subItem = Object.clone(error[key])
        // Object.assign(
        //   error,
        //   mapKeys(subItem, (v, k) => `${key}.${k}`)
        // )
        delete error[key]
      }
      if (subItem) {
        const entity = [title, ErrorKeys[section][key]]
          .filter(e => {
            return e && typeof e === 'string'
          })
          .join(' - ')
        if (Array.isArray(subItem)) {
          subItem.forEach(err => {
            v$Notify(err, 'entity', { entity, deep: true })
          })
        } else {
          v$Notify(subItem, 'entity', { entity, deep: true })
        }
      }
    }
    return Object.keys(error)
  }

  function detailNotification() {
    const sectionResult =
      typeof ErrorKeys[section] === 'function'
        ? ErrorKeys[section](params.sectionPayload)
        : ErrorKeys[section]

    const text = [
      { tag: 'div', class: 'font-weight-bold mb-3', text: title },
      {
        tag: 'ul',
        children: errorKeys
          .map(v => get(sectionResult, v))
          .filter(Boolean)
          .map(e => {
            return { tag: 'li', text: e }
          }),
      },
    ]

    return setSnackbar({
      text: text,
      color: 'error',
      title: 'Помилка валідації',
    })
  }

  function simpleNotification() {
    return setSnackbar({ text: 'Помилка валідації', color: 'error' })
  }

  if (params.noNotify) return

  switch (true) {
    /* eslint-disable */
    case !!errorKeys?.length && !!ErrorKeys[section]:
      return detailNotification()
    case !!errorKeys?.length || !ErrorKeys[section]:
      return simpleNotification()
    /* eslint-enable */
  }
}

function isEmail(expr) {
  if (!expr) return false
  const pattern = new RegExp( // eslint-disable-next-line
    '^(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])$'
  )
  return pattern.test(expr)
}

function clearState(obj, exceptions, arr) {
  /*
    exceptions - should by array of object properties
    obj - object to null properties
    arr - additional array of items to null values */

  // exceptions provided
  if (obj && exceptions && exceptions.length)
    for (const i in obj) {
      exceptions.includes(i) ? false : (obj[i] = null)
      // whithout exceptions
    }
  else if (obj) {
    for (const i in obj) {
      // clear arrays
      if (Array.isArray(obj[i])) obj[i].splice(0)
      // clear properties
      obj[i] = null
    }
  }
  // in case if array of fields provided clear fields
  if (arr) arr.forEach(v => (v = null)) // eslint-disable-line
}

// Auto mapper
function mapObject(obj, mappedObject) {
  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === 'object') {
      mapObject(obj[key], mappedObject)
    } else {
      mappedObject[key] = obj[key]
    }
  })
}

function forEachObj(object, funcs = null, func = key => key, nested = []) {
  const obj = JSON.parse(JSON.stringify(object))
  Object.keys(obj).forEach(key => {
    const nestedStr = nested.join('.') + '.'
    const objName = `contactData.${nested.length ? nestedStr : ''}${key}`
    if (funcs)
      funcs[objName] = function (val) {
        console.log(`${objName} = ${val}`)
      }
    if (obj[key] && typeof obj[key] === 'object') {
      nested.push(key)
      obj[key] = func(obj[key])
      obj[key] = forEachObj(obj[key], funcs, func, nested)
      nested.splice(0)
    } else {
      obj[key] = func(obj[key])
    }
  })
  return obj
}

function getKeys(obj, nested = '') {
  const keys = []
  Object.keys(obj).forEach(key => {
    keys.push(`${nested}${key}`)
    if (obj[key] && typeof obj[key] === 'object') {
      keys.push(...getKeys(obj[key], `${nested}${key}[SEPARATOR]`))
    }
  })
  return keys
}

function watchObject(object, unwatch = []) {
  unwatch.forEach(f => f())
  unwatch.splice(0)
  const fields = getKeys(object, 'documents[SEPARATOR]')
  fields.forEach(field => {
    let u
    // eslint-disable-next-line prefer-const
    u = this.$watch(
      () => {
        const str = field.split('[SEPARATOR]')
        switch (str.length) {
          case 1:
            return this[str[0]]
          case 2:
            return this[str[0]][str[1]]
          case 3:
            return this[str[0]][str[1]][str[2]]
          case 4:
            return this[str[0]][str[1]][str[2]][str[3]]
          case 5:
            return this[str[0]][str[1]][str[2]][str[3]][str[4]]
          case 6:
            return this[str[0]][str[1]][str[2]][str[3]][str[4]][str[5]]
          case 7:
            return this[str[0]][str[1]][str[2]][str[3]][str[4]][str[5]][str[6]]
          case 8:
            return this[str[0]][str[1]][str[2]][str[3]][str[4]][str[5]][str[6]][
              str[7]
            ]
          case 9:
            return this[str[0]][str[1]][str[2]][str[3]][str[4]][str[5]][str[6]][
              str[7]
            ][str[8]]
          case 10:
            return this[str[0]][str[1]][str[2]][str[3]][str[4]][str[5]][str[6]][
              str[7]
            ][str[8]][str[9]]
          case 11:
            return this[str[0]][str[1]][str[2]][str[3]][str[4]][str[5]][str[6]][
              str[7]
            ][str[8]][str[9]][str[10]]
          case 12:
            return this[str[0]][str[1]][str[2]][str[3]][str[4]][str[5]][str[6]][
              str[7]
            ][str[8]][str[9]][str[10]][str[11]]
          case 13:
            return this[str[0]][str[1]][str[2]][str[3]][str[4]][str[5]][str[6]][
              str[7]
            ][str[8]][str[9]][str[10]][str[11]][str[12]]
          case 14:
            return this[str[0]][str[1]][str[2]][str[3]][str[4]][str[5]][str[6]][
              str[7]
            ][str[8]][str[9]][str[10]][str[11]][str[12]][str[13]]
          case 15:
            return this[str[0]][str[1]][str[2]][str[3]][str[4]][str[5]][str[6]][
              str[7]
            ][str[8]][str[9]][str[10]][str[11]][str[12]][str[13]][str[14]]
          case 16:
            return this[str[0]][str[1]][str[2]][str[3]][str[4]][str[5]][str[6]][
              str[7]
            ][str[8]][str[9]][str[10]][str[11]][str[12]][str[13]][str[14]][
              str[15]
            ]
        }
      },
      (val, oldVal) => {
        const old = JSON.stringify(oldVal)
        const cur = JSON.stringify(val)
        const changes = old === cur ? 'semi' : `${old} -> ${cur}`
        console.log(
          `${field.split('[SEPARATOR]').join('.')} was changed (${changes})`
        )
      }
    )
    unwatch.push(u)
  })
  return unwatch
}

function openDocument(doc) {
  const browserViewingFormats = [
    'apng',
    'avif',
    'gif',
    'jpg',
    'jpeg',
    'jfif',
    'pjpeg',
    'pjp',
    'png',
    'svg',
    'webp',
    'pdf',
  ]
  const microsoftViewingFormats = [
    'xlsx',
    'xlsb',
    'xls',
    'xlsm',
    'docx',
    'docm',
    'dotm',
    'dotx',
    'doc',
  ]
  const ext = doc.text.split('.').pop().toLowerCase()
  const url = doc.url.startsWith('http') ? doc.url : keepSlash(doc.url)

  if (browserViewingFormats.includes(ext)) {
    return openWithBrowser(url, doc.text)
  }
  if (microsoftViewingFormats.includes(ext)) {
    return openWithMicrosoft(url, doc.text)
  }
  return download(url, doc.text)
}

function openWithBrowser(fileLocation, name, config) {
  if (fileLocation.startsWith('http')) {
    window.open(fileLocation, name, config)
  } else {
    const domain = new URL(location.href).origin
    const defaultConfig =
      'menubar=no,location=no,resizable=yes,scrollbars=yes,status=yes,width=1100,height=750,left=100,top=20'
    window.open(
      `${domain}${keepSlash(fileLocation)}`,
      name,
      config || defaultConfig
    )
  }
}

function openWithMicrosoft(url, text, config, selector) {
  if (!url) {
    throw new Error('"url" - parameter required')
  }

  if (!url.startsWith('http')) {
    url = keepSlash(url)
  }

  const defaultConfig =
    'menubar=no,location=no,resizable=yes,scrollbars=yes,status=yes,width=1100,height=750,left=100,top=20'
  const microsoftIframe = `<iframe style="overflow:hidden;height:100%;width:100%" src="${urlOpenWithMicrosoft(
    url
  )}" allowfullscreen></iframe>`
  console.log(urlOpenWithMicrosoft(url))

  if (selector) {
    const el = document.querySelector(selector)
    if (!el) throw new Error(`invalid selector: ${el}`)
    el.innerHTML = microsoftIframe
    return el
  }
  const popUpWindow = window.open('', text, config || defaultConfig)
  popUpWindow.document.write(microsoftIframe)
  return popUpWindow
}

function fillSelects(store, actions) {
  actions.forEach(obj => {
    const paramsPreset = obj.params && obj.params.length
    paramsPreset &&
      obj.params.forEach(prop => {
        store.dispatch(obj.action, prop)
      })
    !paramsPreset && store.dispatch(obj.action)
  })
}

function sanityzeLetters(elementId) {
  const el = document.getElementById(elementId)
  const value = el.value
  const clearedValue = (value || '').replace(/[ёъыэA-Z]/gi, '')

  if (clearedValue !== value) {
    const event = new Event('input')
    el.value = clearedValue
    el.dispatchEvent(event)

    return Promise.resolve(el.value)
  }
  return Promise.resolve(el.value)
}

// Clear properties from object by array of props
function clearObject(obj, props) {
  for (const prop of props) {
    delete obj[prop]
  }
  return obj
}

// for application statuses
function statusColor(statusId, lead = false) {
  if (lead) {
    switch (statusId) {
      case 1:
        return '#62C14A'
      case 2:
        return '#0098EE'
      case 3:
        return '#FC7247'
      case 4:
        return '#EA4646'
      case 5:
        return '#EA4646'
      case 6:
        return '#EA4646'
      case 7:
        return '#6495ed'
    }
  }
  if (!lead) {
    switch (statusId) {
      case 1:
        return '#f6c143'
      case 2:
        return '#6495ed'
      case 3:
        return '#ff8c00'
      case 4:
        return '#4faeb3'
      case 5:
        return '#ca6e6e'
      case 6:
        return '#61C14B'
    }
  }
}

function getLOName(typeId) {
  return window?.leasingObjectTypes?.find(l => l.id === typeId)?.name
}

function LODescription(calculation) {
  const CRD = calculation?.calcRequestData || {}
  const RD = calculation?.requestData || {}
  const exprt = calculation?.exportData?.export || calculation?.exportData || {}
  // const CI = calculation?.resultData?.import || {}

  const leasingAmountDkp = RD.leasingAmountDkp
  const equipmentSumWithDiscount =
    (RD.addEquipmentSum || 0) -
    ((RD.addEquipmentSum || 0) / 100) * (RD.equipmentDiscount || 0)

  function getLoAdvance(exprt) {
    if (!exprt) return
    const amount = (() => {
      const a = formutSum(exprt['offer-advance'] || 0, true)
      return a === '0' ? '' : ` (${a} грн)`
    })()
    const advance = exprt['offer-advance-per'] || 0
    return `${advance}%${amount}`
  }

  function getTotalAmount() {
    const leasingAmountDkpUAH = leasingAmountDkp * RD.leasingCurrencyCourse
    const sum = leasingAmountDkpUAH + equipmentSumWithDiscount
    return getBeautyNum(sum, { float: 2 })
  }
  function getProviderSell() {
    const sum =
      (RD.leasingAmount - RD.leasingAmountDkp) * RD.leasingCurrencyCourse
    return `${getBeautyNum(RD.dkpDiscount, { float: 3 })}% (${formutSum(
      sum,
      true
    )} грн)`
  }

  function getName() {
    return RD.body?.typeId === 170
      ? 'Дрон'
      : `${RD?.leasedAssertMark?.name || ''} ${
          RD?.leasedAssertModel?.name || ''
        }`
  }

  return {
    state: RD?.isNew ? 'Новий' : 'Б.в.' || null,
    stateId: RD.isNew,
    type: getLOName(RD?.leasingObjectType?.id) || null,
    mark: RD?.leasedAssertMark?.name || null,
    model: RD?.leasedAssertModel?.name || null,
    engine: RD?.leasedAssertEngine
      ? RD?.leasedAssertEngine / 1000 + ' л'
      : null,
    leasingTerm: RD?.leasingTerm + ' мiс.' || null,
    insuranceProgram: CRD['insurance-program'] || null,
    // currency: calculation?.currencyId || null,
    inStock: calculation.inStock,
    productStatusText: calculation.productStatusText,
    providerPrepay: calculation.prepayToSupplier,
    financingCurrency: RD.currency === 'UAH' ? 'ГРН' : RD.currency,
    supplierСurrency: calculation.supplierСurrency,
    сontractСurrency: calculation.сontractСurrency,
    patrolType: RD?.patrolType?.name || null,
    kppType: RD?.kppType?.name || null,
    advance: getLoAdvance(exprt) || null,
    specification: RD?.carModification?.modification || '',
    amountDFL: getBeautyNum(exprt['offer-price-brutto'], { float: 2 }) + ' грн',
    amountDkp: getBeautyNum(exprt['offer-price-netto'], { float: 2 }) + ' грн',
    providerSale: getProviderSell(),
    year: RD['leasingObjectYear'] || null,
    patrolCardSupport: RD.patrolCardsSupport || null,
    totalAmount: getTotalAmount() + ' грн',
    // @ts-ignore
    additionalEquipSum: getBeautyNum(equipmentSumWithDiscount) + ' грн',
    name: getName(),
    description(params = {}) {
      const { isState = true, detailed = false } = params
      const state = isState ? this.state : ''

      if (detailed) {
        const getValue = (val, add = '') =>
          !val || val === 'NaN' ? '' : `${val}${add}`
        const getDescription = values => {
          let desc = values.filter(v => v).join(' ')
          if (desc.split('').at('-1') === ',')
            desc = desc.substring(0, desc.length - 1)
          return desc
        }
        const loType = getValue(RD?.leasingObjectType?.name)
        const mark = getValue(RD?.leasedAssertMark?.name)
        const model = getValue(RD?.leasedAssertModel?.name)
        const body = getValue(calculation?.body?.name, ',')
        const engine = getValue(
          (+RD?.leasedAssertEngine / 1000).toFixed(1),
          ' л,'
        )
        const patrol = getValue(RD.patrolType?.name, ',')
        const modification = getValue(RD?.modification?.name, ',')
        const specification = getValue(RD?.specification?.name, ',')
        const kpp = getValue(
          (() => {
            const kppMap = { 1: 'AT', 2: 'MT' }
            return kppMap[RD.kppTypeId]
          })(),
          ','
        )
        const year = getValue(RD.leasingObjectYear, ' р,')

        switch (true) {
          case this.calculation?.body?.typeId === 170 /* drone */:
            return getDescription([mark, model, body])
          case [1, 2, 6, 7].includes(RD.leasingObjectType?.id) &&
            !!RD?.modification:
            return getDescription([
              loType,
              mark,
              model,
              body,
              modification,
              specification,
              year,
            ])
          case [1, 2, 6, 7].includes(RD.leasingObjectType?.id) &&
            !RD?.modification:
            return getDescription([
              loType,
              body,
              mark,
              model,
              kpp,
              patrol,
              engine,
            ])
          case ![1, 2, 6, 7].includes(RD.leasingObjectType?.id):
            return getDescription([loType, body, mark, model, year])
          default:
            return getDescription([
              loType,
              mark,
              model,
              body,
              engine,
              patrol,
              kpp,
              year,
            ])
        }
      } else {
        return (
          [
            state,
            RD.body?.typeId === 170 /* drone */ ? 'Дрон' : this.type,
            this.mark,
            this.model,
            this.specification,
            RD.body?.typeId === 170 /* drone */ ? null : this.engine,
            this.patrolType,
            this.kppType,
            this.year,
          ]
            .filter(v => v)
            .join(' ') || null
        )
      }
    },
  }
}

function getAddress(arr) {
  if (!arr) return 'Адреса відсутня'
  if (arr?.canBeEmpty) return 'Реєстрація відсутня'

  const joinParties = (...args) =>
    args.filter(v => v !== null && v !== undefined && v !== '').join(', ')

  const setString = o =>
    joinParties(
      o.country?.name,
      o?.region?.region,
      o?.district?.district,
      o.index,
      o.address
    )

  const setStringSpec = a => {
    const streetType = a.street?.StreetType || ''
    const streetName =
      getAddressPartName(
        a.street?.Street,
        a.street?.StreetOld,
        a.street?.useOldStreet
      ) || ''
    const houseNum = a.house?.HouseNum || ''
    const index = a.house?.Index_ || ''

    return joinParties(
      a.country?.name,
      index,
      getAddressPartName(
        a.city?.Region,
        a.city?.RegionOld,
        a.city?.useOldRegion
      ) || '',
      getAddressPartName(a.city?.City, a.city?.CityOld, a.city?.useOldCity) ||
        '',
      streetType,
      streetName,
      houseNum
    )
  }

  if (Array.isArray(arr)) {
    if (!arr?.length) return 'Адреса відсутня'
    const ra = arr.find(a => a.type.id === 4)
    const a = arr[0]
    if (ra) {
      return typeof ra.country === 'string' ? setStringSpec(ra) : setString(ra)
    } else {
      return setString(a)
    }
  } else if (arr?.constructor?.name === 'Object' && Object.keys(arr).length) {
    const c = arr?.city || {}
    const h = arr?.house || {}
    const s = arr?.street || {}
    const ap = arr?.apartmentType || arr?.apartment_type || {}

    const country = arr?.country?.name
    const city = `${c.SettlementType || ''} ${getAddressPartName(
      c.City,
      c.CityOld,
      c.useOldCity
    )}`
    const region = c.IsOCentre
      ? null
      : getAddressPartName(c.Region, c.RegionOld, c.useOldRegion)
    const district = getAddressPartName(c.Area, c.AreaOld, c.useOldArea)
    const index = h.Index_
    const streetType = s.StreetType
    const streetName = getAddressPartName(s.Street, s.StreetOld, s.useOldStreet)
    const houseNum = [h.HouseNum, h.HouseNumAdd]
      .filter(v => v !== null && v !== undefined && v !== '')
      .join(' ')

    const apartment = h.apartmentNum
      ? `${ap?.shortName || ''} ${h.apartmentNum}`
      : null

    if (!arr?.city) return 'Адресу не вказано'
    return joinParties(
      country,
      index,
      region,
      district,
      city,
      streetType,
      streetName,
      houseNum,
      apartment
    )
  } else if (typeof arr === 'string') {
    return arr
  }
}

function Input() {}

Input.prototype.hideData = function (id) {
  const input = document.getElementById(id)
  if (!input) return

  // eslint-disable-next-line no-undef
  if (globalThis[id] instanceof Element) {
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      globalThis[id] = { el: input, value: input.value }
      input.value = null
    })
  }
}

Input.prototype.restoreData = function (id) {
  // eslint-disable-next-line no-undef
  const input = globalThis[id].el
  setTimeout(() => {
    if (input && !input.value) {
      // eslint-disable-next-line no-undef
      input.value = globalThis[id].value
      input.dispatchEvent(new Event('input'))
    }
    // eslint-disable-next-line no-undef
    delete globalThis[id]
  })
}

function setClientOfferCurrency(
  clientOffer,
  config = {
    currency: 'UAH',
    currencyRate: null,
    keepIntegers: false,
    fixedNum: 2,
    showImport: false,
    noFetchRate: false,
  }
) {
  function getCurrenciesList() {
    if (config.noFetchRate) return Promise.resolve([])
    if (config.currencyRate) {
      return Promise.resolve([
        {
          ...defaultCurrency(),
          cc: config.currency,
          rate: config.currencyRate,
        },
      ])
    }
    return axios.get(urlNbuCurrencyCourses()).then(async res => {
      const currencies = await res.data
      return currencies.map(v => ({
        ...v,
        cc: v.currency?.name,
      }))
    })
  }

  function getCurrency(currenciesList) {
    let currency = (currenciesList || []).find(currency => {
      return (
        (currency?.cc || currency?.currency?.name || '').toUpperCase() ===
        (config?.currency || '').toUpperCase()
      )
    })

    if (!currency) currency = defaultCurrency()

    return Promise.resolve(currency)
  }

  function defaultCurrency() {
    return {
      cc: 'ГРН',
      exchangedate: new Date().toLocaleString('ru-RU').substring(0, 10),
      r030: null,
      rate: 1,
      txt: 'Українська гривня',
    }
  }

  function getCurrencySign(currency) {
    switch (currency?.cc || currency?.currency?.name) {
      case 'EUR':
        return '€'
      case 'USD':
        return '$'
      case 'GBP':
        return '£'
    }
  }

  function setCurrency(currency) {
    const offer = clientOffer ? JSON.parse(JSON.stringify(clientOffer)) : []
    const currencyRate = getCurrencyRate(currency)
    const currencyText = ' ' + (getCurrencySign(currency) || 'ГРН')

    function setCurrencyRate(property, currencyRate) {
      if (
        (!property && property !== 0) ||
        (!currencyRate && currencyRate !== 0)
      )
        return

      switch (true) {
        case !config.keepIntegers:
          return parseFloat(
            (parseFloat(property) / currencyRate).toFixed(config.fixedNum)
          )
            .toLocaleString('ru-RU')
            .replace(/,/g, '.')
        case config.keepIntegers:
          return parseFloat(
            (parseFloat(property) / currencyRate).toFixed(config.fixedNum)
          )
      }
    }

    // keep only graphs
    for (const k in offer.resultData || {}) {
      if (
        k === 'requestId' ||
        k === 'source' ||
        (k === 'import' && !config.showImport)
      )
        delete offer.resultData[k]
    }

    // apply nums format for each graph iteration
    Object.keys(offer.resultData || {}).forEach(graph => {
      const g = offer.resultData[graph]
      g.selectedCurrency = { ...currency, shortTxt: currencyText }

      const setValue = (prop, rate) =>
        config.keepIntegers
          ? setCurrencyRate(prop, rate || currencyRate)
          : setCurrencyRate(prop, rate || currencyRate) +
            (rate ? ' ГРН' : currencyText)

      g['offer-month-payment'] = setValue(g['offer-month-payment'])
      g['offer-administrative-payment'] = setValue(
        g['offer-administrative-payment']
      )
      g['offer-advance'] = setValue(g['offer-advance'])
      g['offer-amount-of-financing'] = setValue(g['offer-amount-of-financing'])
      g['offer-price-brutto'] = setValue(g['offer-price-brutto'])
      g['offer-price-netto'] = setValue(g['offer-price-netto'])
      g['offer-notarization'] = setValue(g['offer-notarization'])
      g['offer-rest'] = setValue(g['offer-rest'])
      g['total-interest'] = setValue(g['total-interest'])
      g['total-payment'] = setValue(g['total-payment'])
      g['total-amount-rewardUSAID'] = setValue(g['total-amount-rewardUSAID'])
      g['total-promotion-paymentUSAID'] = setValue(
        g['total-promotion-paymentUSAID']
      )
      g['total-amount-compensationUSAID'] = setValue(
        g['total-amount-compensationUSAID']
      )
      // g['bonus'] = setValue(g['bonus'])
      g['total-payment-principal'] = setValue(g['total-payment-principal'])
      g['total-compensation-rewards'] = setValue(
        g['total-compensation-rewards']
      )
      g['total-commission-additional-services'] = setValue(
        g['total-commission-additional-services']
      )
      g['total-commission-additional-services'] = setValue(
        g['total-commission-additional-services']
      )
      g['monthPaymentUAH'] = setValue(g['monthPaymentUAH'])
      g['totalServicePayment'] = setValue(g['totalServicePayment'])

      // PROGRAM FSN
      g['paymentFSN'] = setValue(g['paymentFSN'])
      g['totalProgramCompensationRewardFSN'] = setValue(
        g['totalProgramCompensationRewardFSN']
      )
      g['totalAdditionalServicesCommissionFSN'] = setValue(
        g['totalAdditionalServicesCommissionFSN']
      )
      g['totalBaseRewardUirdIndexFSN'] = setValue(
        g['totalBaseRewardUirdIndexFSN']
      )
      g['totalLeasingObjectCompensationFSN'] = setValue(
        g['totalLeasingObjectCompensationFSN']
      )
      // oper maintenance
      // operMaintenance['totalServicePayment'] = setValue(
      //   operMaintenance['totalServicePayment']
      // )
      ;(g.graph || []).map(month => {
        // PROGRAM FSN
        month.paymentFSN = setCurrencyRate(month.paymentFSN, currencyRate)
        month.baseRewardUirdIndexFSN = setCurrencyRate(
          month.baseRewardUirdIndexFSN,
          currencyRate
        )
        month.additionalServicesCommissionFSN = setCurrencyRate(
          month.additionalServicesCommissionFSN,
          currencyRate
        )
        month.programCompensationRewardFSN = setCurrencyRate(
          month.programCompensationRewardFSN,
          currencyRate
        )
        month.leasingObjectCompensationFSN = setCurrencyRate(
          month.leasingObjectCompensationFSN,
          currencyRate
        )
        // PROGRAM FSN
        month.interest = setCurrencyRate(month.interest, currencyRate)
        month.payment = setCurrencyRate(month.payment, currencyRate)
        month.amountCompensationUSAID = setCurrencyRate(
          month.amountCompensationUSAID,
          currencyRate
        )
        month.amountRewardUSAID = setCurrencyRate(
          month.amountRewardUSAID,
          currencyRate
        )
        month.promotionPaymentUSAID = setCurrencyRate(
          month.promotionPaymentUSAID,
          currencyRate
        )
        month['commission-additional-services'] = setCurrencyRate(
          month['commission-additional-services'],
          currencyRate
        )
        month['payment-principal'] = setCurrencyRate(
          month['payment-principal'],
          currencyRate
        )
        month['compensation-rewards'] = setCurrencyRate(
          month['compensation-rewards'],
          currencyRate
        )
        month['servicePayment'] = setCurrencyRate(
          month['servicePayment'],
          currencyRate
        )
        month['premiumServicePayment'] = setCurrencyRate(
          month['premiumServicePayment'],
          currencyRate
        )
      })
    })

    return Promise.resolve(offer)
  }

  function getCurrencyRate(currency) {
    return currency?.rate || 0
  }

  function handleError(err) {
    // eslint-disable-next-line no-undef
    setSnackbar({ text: err, color: 'error' })
    console.warn(err)
    throw err
  }

  return getCurrenciesList()
    .then(getCurrency)
    .then(setCurrency)
    .catch(handleError)
}

function getCurrencySign(currency) {
  switch (currency) {
    case 'UAH':
      return '₴'
    case 'EUR':
      return '€'
    case 'USD':
      return '$'
    case 'GBP':
      return '£'
  }
}

function setErrHandler($v, msg = null) {
  if (!$v?.$error) return null
  else return msg || 'Обов`язкове поле'
}

function editCareer(item, params) {
  this.$store.commit('setDialog', {
    title: "Кар'єра",
    dialogItem: item,
    params,
    action: 'editCareer',
  })
}

function getFilterQueryString(filters = {}, params = {}) {
  const { firstSymbol = '&' } = params
  return Object.entries(filters)
    .filter(f => ![null, '', void [0]].includes(f[1]))
    .map((f, idx) => {
      const key = f[0]
      const value = f[1]
      const separator = idx === 0 ? firstSymbol : '&'
      return `${separator}${key}=${value}`
    })
    .join('')
}

function backDate(date) {
  if (!date) return ''
  if (date.includes('-')) return date
  const [day, month, year] = date.substring(0, 10).split('.')
  return `${year}-${month}-${day}`
}

function joinStr(...args) {
  return args.filter(str => str).join(' ')
}

function highLightErrors($v, selector) {
  $v.$anyError
  $v.$touch()
  let invalidElements
  const inputIdentifierClass = '.v-input.error--text'
  const section = document.querySelector(selector)
  const el = document.createElement('span')
  el.innerText = 'Заповнiть поле'
  el.classList.add('error-tooltip')

  setTimeout(() => {
    invalidElements = section.querySelectorAll(inputIdentifierClass)
    for (const element of invalidElements) {
      element.appendChild(el.cloneNode(true))
    }
  }, 0)
}

function getPassport(contact, params = {}) {
  if (!contact?.identityDocument) return '---'
  const p = contact.identityDocument
  const type = p.typeId
  const labelsMap = store.state.selectItems.passportLabels

  const identityDocument = {
    first: {
      label: labelsMap.identityDocFirst[type - 1],
      value: p.identityDocFirst || '---',
    },
    second: {
      label: labelsMap.identityDocSecond[type - 1],
      value: p.identityDocSecond
        ? `${type === 2 ? '№ ' : ''}${p.identityDocSecond}`
        : '---',
    },
    issuedBy: {
      label: type === 2 ? 'Виданий ТСЦ' : 'Виданий',
      value: p.issuedBy || '---',
    },
    issuedDate: {
      label: 'Дата видачі',
      value: p.issueDate ? `${toFormatDate(p.issueDate)} року` : '---',
    },
    sellBy: {
      label: labelsMap.sellBy[type - 1],
      value: p.sellBy ? `${toFormatDate(p.sellBy)} року` : '---',
    },
    getString() {
      const { first, second, issuedBy, issuedDate, sellBy } = this
      switch (true) {
        case [1, 4].includes(type):
          return [first, second, issuedBy, issuedDate]
            .map(p => `${p.label}: ${p.value}`)
            .join(' ')
        case [3].includes(type):
          return [second, issuedBy, issuedDate]
            .map(p => `${p.label}: ${p.value}`)
            .join(' ')
        case [2].includes(type):
          return [second, issuedBy, issuedDate, sellBy]
            .map(p => `${p.label}: ${p.value}`)
            .join(' ')
      }
    },
  }
  switch (true) {
    case !params.label:
      return identityDocument.getString()
    case [1, 2, 5].includes(type):
      return 'Паспорт'
    case [3, 4, 7, 8].includes(type):
      return 'Посвідка'
    case [6, 9].includes(type):
      return 'Посвідчення'
    case type === 10:
      return 'Інший документ'
  }
}

function highlightErrs($v, cb) {
  $v.$anyError
  $v.$touch()
  if (typeof cb === 'function')
    this.$setSnackbar({ text: 'Помилка валiдацii', color: 'error' })
  console.log($v)
}

function getPhone(entity /* contact/contractor */) {
  if (entity?.mainPhone) return entity.mainPhone
  else if (!entity?.phones?.length) return null
  else return entity.phones[0]
}

function deleteProp(obj, propPath) {
  const props = propPath.split('.')
  let currentObj = obj

  for (let i = 0; i < props.length - 1; i++) {
    const prop = props[i]
    // eslint-disable-next-line
    if (!currentObj.hasOwnProperty(prop)) {
      return // Свойство не существует, прекращаем выполнение
    }
    currentObj = currentObj[prop]
  }

  const lastProp = props[props.length - 1]
  // eslint-disable-next-line
  if (currentObj.hasOwnProperty(lastProp)) {
    delete currentObj[lastProp]
  }
}

function setCache(arr, exclude = []) {
  const o = Object.clone(arr)
  // if (!Array.isArray(o)) return
  exclude.forEach(path => {
    deleteProp(o, path)
  })
  // set values to null if it false, undefined, 0, '', []
  const clearValues = JSON.parse(
    JSON.stringify(o, (key, value) => {
      if (key === '') return value
      const isFalsy = v => {
        return ['', void [0]].includes(v) || (Array.isArray(v) && !v.length)
      }
      if (Array.isArray(value) && value.length) return value.sort()
      return isFalsy(value) ? null : value
    })
  )
  // delete empty properties like null, {}, []
  const sanitizeObj = obj => {
    const isEmpty = o => {
      return (
        (Array.isArray(o) && o.length === 0) ||
        (typeof o === 'object' && Object.keys(o).length === 0)
      )
    }
    for (const key in obj) {
      if (obj[key] === null || isEmpty(obj[key])) {
        delete obj[key]
      } else if (typeof obj[key] === 'object') {
        sanitizeObj(obj[key])
        if (isEmpty(obj[key])) delete obj[key]
      }
    }
    return obj
  }

  // return sanitized string
  return JSON.stringify(sanitizeObj(clearValues))
}

function syncOpendataQueds(opendataQueds, self, queds) {
  if (!opendataQueds?.length) return

  const openDataQuedCodeList = opendataQueds?.map(qued => qued.code)
  const openDataPrimaryQued = opendataQueds?.find(qued => qued.isPrimary)
  const uniqueQueds = queds?.filter(qued =>
    openDataQuedCodeList?.includes(qued.code.trim())
  )
  const uniqueQuedsId = uniqueQueds?.map(qued => qued.id)
  const primaryQued = uniqueQueds?.find(
    qued => qued.code.trim() === openDataPrimaryQued.code
  )

  self.queds?.splice(0)
  self.queds?.push(...uniqueQuedsId.splice(0, 10))
  self.mainQuedId = primaryQued?.id
}

function syncOpenDataContact(contact, self, mainContact, arrContact, cb) {
  if (!contact) return

  let main
  Array.isArray(contact) && (main = contact[0])
  typeof contact === 'string' && (main = contact)
  typeof cb === 'function' && (main = cb(main))

  if (mainContact === 'mainPhone' && (main || '').length !== 23) {
    if ((self[mainContact] || '').length !== 23) {
      self[mainContact] = null
    }

    return
  }

  self[mainContact] = main
  self[arrContact].splice(0)
  self[arrContact].push({ item: main, main: true })
}

function getEntityRoute(id, typeId) {
  switch (typeId) {
    case ET.type.PERSON:
    case ET.type.ENTEPRENEUR:
      return { name: 'individuals-form', params: { id } }
    case ET.type.LEAD:
      return { name: 'edit-lead', params: { id } }
    case ET.type.APPLICATION:
      return { name: 'edit-application', params: { id } }
    default:
      return { name: 'contractors-form', params: { id } }
  }
}
function getEntityName(entity) {
  if (typeof entity !== 'object') return '---'
  if (entity.fullName || entity.shortName) {
    return entity?.fullName || entity?.shortName
  } else if (entity?.contact || entity?.contractor) {
    return entity?.contractor?.shortName || entity?.contact?.fullName
  }
}
function tableRowColor(item) {
  switch (true) {
    case !!item.isAgreed:
      return 'green-row'
    case !!item.isSendOffer:
      return 'orange-row'
    default:
      return ''
  }
}

function getEmailStatus(statusId) {
  switch (statusId) {
    case 1:
      return 'Доставлено'
    case 2:
      return 'Відкрито'
    case 7:
      return 'Невірна почта'
    default:
      return '---'
  }
}

function getSafe(fn, defaultVal = null) {
  try {
    return fn()
  } catch (e) {
    // console.warn(e)
    return defaultVal
  }
}

function getSignatories(employees) {
  return employees.filter(e => e.isSignatureRight)
}

function getSignatureType(typeId) {
  const docs = store.state.selectItems.basisDocuments
  return docs.find(d => d.id === typeId)?.name || null
}

function toObject(fn) {
  try {
    const result = fn()
    if (
      typeof result === 'object' &&
      !Array.isArray(result) &&
      result !== null
    ) {
      return result
    }
    return {}
  } catch (e) {
    // eslint-disable-next-line no-undef
    this.$setSnackbar({ text: e, color: 'error' })
    console.error(e)
    return {}
  }
}

function toString(fn) {
  try {
    const result = fn()
    if (typeof result === 'string') {
      return result
    } else if (Number.isFinite(result)) {
      return result.toString()
    }
    return ''
  } catch (e) {
    // eslint-disable-next-line no-undef
    this.$setSnackbar({ text: e, color: 'error' })
    console.error(e)
    return ''
  }
}

function toArray(fn) {
  try {
    const result = fn()
    if (Array.isArray(result)) {
      return result
    }
    return []
  } catch (e) {
    // eslint-disable-next-line no-undef
    this.$setSnackbar({ text: e, color: 'error' })
    console.error(e)
    return []
  }
}

function toNumber(fn, defaultValue = 0, delimiter = '.') {
  function stringToNumber(string) {
    if (typeof string !== 'string') {
      return string
    }
    let nums = ''
    for (let k = 0; k <= string.length; k++) {
      const char = string[k]
      if (+char || char === delimiter) nums += string[k]
    }
    return parseFloat(nums)
  }

  try {
    const result = fn()
    const intResult = parseFloat(stringToNumber(result))
    if (Number.isFinite(result)) {
      return result
    } else if (Number.isFinite(intResult)) {
      return intResult
    }
    return defaultValue
  } catch (e) {
    // eslint-disable-next-line no-undef
    this.$setSnackbar({ text: e, color: 'error' })
    console.error(e)
    return defaultValue
  }
}

function toBoolean(fn) {
  try {
    const result = fn()
    if (typeof val === 'boolean') {
      return result
    }
    return Boolean(result)
  } catch (e) {
    // eslint-disable-next-line no-undef
    this.$setSnackbar({ text: e, color: 'error' })
    console.error(e)
    return false
  }
}

function keepArray(value) {
  return Array.isArray(value) ? value : [value].filter(Boolean)
}

function handleError(err, cb) {
  if (axios.isCancel(err)) {
    return
  }
  console.warn(err)
  if (!err.isAxiosError) {
    // eslint-disable-next-line no-undef
    setSnackbar({ text: err, color: 'error' })
  }
  typeof cb === 'function' && cb(err)
}

function contractorFilter(item, queryText, itemTitle) {
  return (
    (itemTitle || '').toUpperCase().includes((queryText || '').toUpperCase()) ||
    (item?.edrpou && item.edrpou.startsWith(queryText))
  )
}

function contactFilter(item, queryText, itemTitle) {
  return (
    (itemTitle || '').toUpperCase().includes((queryText || '').toUpperCase()) ||
    (item?.inn || '').startsWith(queryText)
  )
}

function getFilterValuesList(arr, field = null) {
  const l = []
  if (field) {
    arr.map(el => el[field]).forEach(el => !l.includes(el) && l.push(el))
  } else {
    arr.forEach(el => !l.includes(el) && l.push(el))
  }
  return l
}

function isValidUrl(string) {
  let url

  try {
    url = new URL(string)
  } catch (_) {
    return false
  }

  return url.protocol === 'http:' || url.protocol === 'https:'
}

function getResponsible(responsible) {
  if (!responsible) return '---'
  const r = responsible
  return `${r?.surname} ${r.name} ${r.patronymic}`
}

function getResponsibleById(responsibleId, allUsers) {
  if (!responsibleId) return '---'
  const r = (allUsers || []).find(u => u.id === responsibleId)
  if (!r) return '---'
  return `${r?.surname} ${r?.name}`
}

function getProjectGuarantors(
  guarantors /* typeId: 1 - contact || 2 - contractor */
) {
  if (!guarantors) return []
  return guarantors.map(g => {
    const entity = g.contractor ? 'contractor' : 'contact'
    const guarantorTypeId = g.contractor ? 2 : 1
    const guarantor = {
      ...g,
      guarantorTypeId,
      id: `${guarantorTypeId}${g[entity].id}`,
    }
    guarantor[`${entity}Id`] = g[entity].id
    return guarantor
  })
}
function getProjectSubtenants(
  subtenants /* typeId: 1 - contact || 2 - contractor */
) {
  if (!subtenants) return []
  return subtenants.map(g => {
    const entity = g.contractor ? 'contractor' : 'contact'
    const subtenantTypeId = g.contractor ? 2 : 1
    const subtenant = {
      ...g,
      subtenantTypeId,
      id: `${subtenantTypeId}${g[entity].id}`,
    }
    subtenant[`${entity}Id`] = g[entity].id
    return subtenant
  })
}

async function getMfoFromIban(event) {
  let mfo

  if (event && event.length >= 10) {
    mfo = parseInt(event.substring(4, 10))

    await store.dispatch('addMfo', mfo)
    const mfoExist = store.state.selectItems.mfoList.length

    if (mfoExist) {
      return store.state.selectItems.mfoList.find(b => +b.mfo === +mfo) || {}
    } else return {}
  } else {
    return {}
  }
}

function keepSlash(path) {
  if (typeof path !== 'string') return path
  return path[0] === '/' ? path : `/${path}`
}

function downloadPrintedForm(
  url /* String */,
  fileName /* String || Function */
) {
  return axios.get(url).then(res => {
    if (res.data.message) {
      // eslint-disable-next-line no-undef
      return this.$setSnackbar({
        text: res.data.message,
        color: 'warning',
      })
    }
    if (res.data.url) {
      let name =
        typeof fileName === 'function' ? fileName(res.data.url) : fileName
      if (!name) {
        name = res.data.url
      }
      download(res.data.url, name)
    }
  })
}

function download(path, fileName = 'noNamed') {
  if (typeof path !== 'string') return

  const link = document.createElement('a')
  link.href = keepSlash(path)
  link.setAttribute('download', fileName === null ? 'noNamed' : fileName)
  link.click()
  link.remove()
  window.URL.revokeObjectURL(keepSlash(path))
}
function getManagerPhoto(url) {
  console.log('url', url)

  if (!isValidHttpUrl(url)) {
    return Promise.reject(new Error('Invalid url: ' + url))
  }

  url = new URL(url)
  console.log('new url', url)

  return new Promise((resolve, reject) => {
    fetch(url.href)
      .then(res => res.blob())
      .then(blob => {
        if (!blob.type.includes('image')) resolve()

        const reader = new FileReader()
        reader.onload = e => resolve(e.target.result)
        reader.readAsDataURL(blob)
      })
      .catch(err => {
        console.log('err', err)

        if (err.code === 'ERR_NETWORK') {
          // default user photo (if photo undefined)
          fetch('/images/background.jpg')
            .then(response => {
              return response.blob()
            })
            .then(blob => {
              console.log('blob', blob)
              if (!blob.type.includes('image')) return resolve()

              const reader = new FileReader()
              reader.onload = e => resolve(e.target.result)
              reader.readAsDataURL(blob)
            })
            .catch(err => {
              console.log('Error loading default avatar:', err)
              reject(err)
            })
        } else {
          reject(err)
        }
      })
  })
}

function isValidHttpUrl(string) {
  let url

  try {
    url = new URL(string)
  } catch (_) {
    return false
  }

  return url.protocol === 'http:' || url.protocol === 'https:'
}

function getBeautyNum(number, params = {}) {
  number = String(number).replaceAll(' ', '')
  const n = Number(number)
  if (typeof n !== 'number' || Number.isNaN(n)) return number

  const { float = 0, round = false } = params
  const localFloat =
    float === 'auto' ? number.split('.')[1]?.length || 0 : float

  let formattedNumber = new Intl.NumberFormat('ru-RU', {
    minimumFractionDigits: localFloat,
    maximumFractionDigits: localFloat,
  })
    .format(number)
    .replace(',', '.')

  if (round && Math.abs(n - Math.round(n)) < Number.EPSILON) {
    formattedNumber = Math.round(n).toString()
  }

  return formattedNumber
}

function filterUsers(users = []) {
  if (!users) return []
  return users
    .filter(u => u.isActive)
    .sort((a, b) => {
      const x = getContactInitials(a, { isResponsible: true })
      const y = getContactInitials(b, { isResponsible: true })
      return x.localeCompare(y, 'uk')
    })
}

function generateId(length = 13) {
  return Math.random()
    .toString(16)
    .substring(2, Math.min(length + 2, 13))
}
function getPropByPath(obj, path) {
  const pathArr = path.split('.')
  let result = obj
  for (let i = 0; i < pathArr.length; i++) {
    result = result[pathArr[i]]
    if (result === undefined) {
      return undefined
    }
  }
  return result
}

function findSelectItems(listName, value, keys = {}) {
  const { itemValue = 'id', itemTitle = 'name' } = keys
  const searchedItem = store.state.selectItems[listName].find(item => {
    return String(item[itemValue]) === String(value)
  })
  return searchedItem ? searchedItem[itemTitle] : ''
}

function generateRandomNumber() {
  return Math.floor(Math.random() * 100000)
}

function playSound(url) {
  const myAudioElement = new Audio(url)
  myAudioElement.addEventListener('canplaythrough', () => {
    try {
      return myAudioElement.play()
    } catch (e) {
      console.warn(e)
    }
  })
}
function getRouteByEntityType(entityTypeId, ...id) {
  const routes = {
    1: id => ({ name: 'individuals-form', params: { id } }),
    2: id => ({ name: 'contractors-form', params: { id } }),
    3: applicationId => ({
      name: 'edit-application',
      params: { applicationId },
    }),
    4: id => ({ name: 'edit-lead', params: { id } }),
    5: id => ({ name: 'dealer-form', params: { id } }),
    6: projectId => ({ name: 'project', params: { projectId } }),
    7: (applicationId, calculationId) => ({
      name: 'application-edit-calculation',
      params: { applicationId, calculationId },
    }),
    8: id => ({ name: 'service-application-single', params: { id } }),
    9: id => ({ name: 'fd-project-verification', params: { id } }),
    10: id => ({ name: 'ss-project-verification', params: { id } }),
    11: id => ({ name: 'rd-project-verification', params: { id } }),
    12: applicationId => ({
      name: 'application-slider',
      params: { applicationId },
    }),
    13: id => ({ name: 'ld-project-verification', params: { id } }),
    14: id => ({
      name: 'security-scoring-single',
      params: { tabState: 'scoring', id },
    }),
    15: id => ({
      name: 'fin-scoring-single',
      params: { tabState: 'scoring', id },
    }),
    16: id => ({
      name: 'risk-scoring-single',
      params: { tabState: 'scoring', id },
    }),
  }

  return routes[entityTypeId](...id)
}

function getAuthorizedCapital() {
  const founders = this.contractor?.founders || []
  let amountCount = 0
  let amountPercent = 0

  for (let f = 0, len = founders.length; f < len; f++) {
    amountCount += founders[f].amount
  }
  for (let f = 0, len = founders.length; f < len; f++) {
    amountPercent += founders[f].amountPercent
  }

  const color = amountPercent >= 100 ? '#00a345' : '#f44336'
  const full = amountPercent >= 100 ? 'Повністю' : 'Не повністю'
  const percent = getBeautyNum(amountPercent || 0, { float: 6, round: true })
  const sum = getBeautyNum(amountCount || 0, { float: 2 })
  const text = `Сформовано: ${full} (${percent}%) / ${sum} грн`
  return `<div>
            <span class="label" style="font-size: 13px; color: ${color};">
              ${text}
            </span>
          </div>`
}

export {
  playSound,
  objClearEpmtyField,
  isEmail,
  clearState,
  statusColor,
  mapObject,
  clearObject,
  trimExceededLength,
  highLightErrors,
  fillSelects,
  syncOpendataQueds,
  syncOpenDataContact,
  getLOName,
  LODescription,
  highlightErrs,
  deleteProp,
  setCache,
  getAddress,
  setErrHandler,
  joinStr,
  getPassport,
  getPhone,
  contactDetailsWithType,
  backDate,
  getFilterQueryString,
  openWithBrowser,
  openWithMicrosoft,
  getEntityRoute,
  getEntityName,
  tableRowColor,
  getCurrencySign,
  sortItemsByText,
  v$Notify,
  getEmailStatus,
  Input,
  getSafe,
  handleError,
  contractorFilter,
  contactFilter,
  getFilterValuesList,
  isValidUrl,
  setClientOfferCurrency,
  sanityzeLetters,
  tableDateSort,
  toObject,
  toString,
  toArray,
  toNumber,
  toBoolean,
  getResponsible,
  getResponsibleShortName,
  getContactInitials,
  checkDuplicates,
  getResponsibleById,
  getProjectGuarantors,
  getProjectSubtenants,
  getMfoFromIban,
  getManagerPhoto,
  toFormatDate,
  toFormatArrWithDate,
  getSignatureType,
  getSignatories,
  editCareer,
  sortTableByDate,
  forEachObj,
  getKeys,
  watchObject,
  getBeautyNum,
  filterUsers,
  generateId,
  keepSlash,
  downloadPrintedForm,
  download,
  keepArray,
  openDocument,
  getPropByPath,
  findSelectItems,
  generateRandomNumber,
  imageUrlCheck,
  getRouteByEntityType,
  checkPhoneValidation,
  getAuthorizedCapital,
}
