<template>
  <div class="pb-4">
    <span style="font-size: 1rem">Копіювати в: </span>
    <loader
      v-if="
        $loading.isLoading(['contactAddressTypes', 'contractorAddressTypes'])
      "></loader>
    <div v-else>
      <div v-for="(address, key) in filteredAddressTypes" :key="key">
        <v-checkbox
          v-model="selectedAddressTypes"
          :value="address.id"
          :label="address.name"
          class="mt-1"
          color="#d24a43"
          dense
          hide-details>
        </v-checkbox>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Loader from '@/components/Loader.vue'
import { computed, ref } from 'vue'
import { getAddressTypes, removeExistAddressTypes } from './utils'
import { fillDirectory } from '@/plugins/directory'
import { urlAddressCreate } from '@/pages/request'
import axios from '@/plugins/axios.js'
import { Address } from '@/utils/types'

export default {
  name: 'CopyAddress',
  components: { Loader },
  props: {
    dialog: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const selectedAddressTypes = ref<number[]>([])
    const filteredAddressTypes = computed(() => {
      const addressTypes = getAddressTypes(props.dialog.dialogItem.entityTypeId)
      return removeExistAddressTypes(
        addressTypes,
        props.dialog.dialogItem.existAddresses
      )
    })

    if (props.dialog.dialogItem.entityTypeId === 1) {
      fillDirectory('contactAddressTypes')
    } else {
      fillDirectory('contractorAddressTypes')
    }

    const submit = () => {
      const requests = selectedAddressTypes.value.map(typeId => {
        return axios
          .post(urlAddressCreate(), {
            ...props.dialog.dialogItem.address,
            typeId,
          })
          .then((res: { data: Address }) => {
            return res.data
          })
      })

      return Promise.all(requests)
    }

    return { selectedAddressTypes, filteredAddressTypes, submit }
  },
}
</script>

<style scoped></style>
