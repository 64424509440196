import { defineDirectory } from 'best-modules/plugins/'
import { DirectoryName, GetterName } from './types'
import { yesOrNo, klpReworkTypes, meetStatuses } from './local'
const {
  DirectoryPlugin,
  fillDirectory,
  getDirectory,
  pushDirectoryItem,
  updateDirectoryItem,
  removeDirectoryItem,
  clearDirectory,
} = (() => {
  return defineDirectory<DirectoryName, GetterName>({
    carForSaleStatuses: '/json/carForSaleStatuses', // TODO the latest in filters
    carForSaleList: '/json/carForSale/list', // done
    calcTargets: '/json/getTargets', // done
    calcWorkplace: '/json/getWorkplaces', // done
    planTypes: '/json/getPlanTypes', // done
    planCategories: '/json/getLeasingObjectCategories', // TODO mayme not used
    planLeasingPrograms: '/json/getLeasingPrograms', // TODO mayme not used
    planSourceTypes: '/json/getSourcesTypes', // TODO mayme not used
    planActivityTypes: '/json/activity/getTypes', // done
    planValueTypes: '/json/getPlanValueTypes', // done
    apartmentTypes: '/json/getApartmentTypes', // done
    contactTypes: '/json/contact/type', // TODO the latest in filters
    gpsBeacons: '/json/getGpsBeaconTypes', // done
    contractorTypes: '/json/contractor/type', // TODO the latest in filters
    nationalityList: '/json/getNationality', // done
    segmentTypes: '/json/getSegments', // done
    influenceList: '/json/getTypeInfluence', // done
    signKbvList: '/json/getSignCBV', // done
    connectionsList: '/json/getConnections', // done
    genderList: '/json/getGender', // done
    careerPosts: '/json/contact/careerPost', // done
    careerDepartments: '/json/contact/careerDepartment', // done
    careerRoles: '/json/contact/careerRole', // done
    passportTypes: '/json/getIdentityDocumentType', // done
    currencies: '/json/getCurrencies', // done
    contractorAddressTypes: '/json/contractor/getContractorAddressTypes', // done
    contactAddressTypes: '/json/contact/getContactAddressTypes', // done
    contractorCategories: '/json/contractor/category', // done
    educationTypes: '/json/getContactEducationTypes', // done
    bankAccountTypes: '/json/getCurrentAccountTypes', // done
    analysisTypes: '/json/getAnalysisTypes', // TODO the latest in filters
    dkpTypes: '/json/getDkpTypes', // TODO maybe not used
    dkpOrderCalculations: '/json/getDkpOrderCalculations', // done
    nbuCurrencyCourses: '/json/getRate', // done
    financialRiskLevels: '/json/getFinancialRiskLevel', // TODO the latest in filters
    activityStatuses: '/json/activity/getStatuses', // TODO filters and axios usages
    projectStatuses: '/json/project/getStatuses', // TODO the latest in filters
    applicationStatuses: '/json/getApplicationStatuses', // TODO the latest in filters
    securityStatuses: '/json/getServiceStatuses', // TODO the latest in filters
    clientTypes: '/json/getClientTypes', // done
    clientNeeds: '/json/applications/clientNeeds', // TODO the latest in filters
    contactStatuses: '/json/getClientStatuses', // TODO the latest in filters
    dflStatuses: '/json/getContractStatuses', // TODO the latest in filters
    serviceContractStatuses: '/json/getServiceContractStatus', // TODO the latest in filters
    dkpStatuses: '/json/getDkpStatuses', // TODO the latest in filters
    gcStatuses: '/json/getPledgeContractStatuses', // done TODO maybe rename
    guaranteeStatuses: '/json/getGuaranteeContractStatuses', // done TODO maybe rename
    bookKeepingStatus: '/json/getAgentPaymentStatuses', // TODO the latest in filters
    businessRegions: '/json/getBusinessRegions', // done TODO maybe rename
    pledgeTypes: '/json/pledgeContract/getTypes', // done TODO maybe rename
    applicationComunicationMethods: '/json/applications/communicationMethods', // done TODO rename
    allUsers: '/json/getUsersList', // TODO skipped
    leasingPrograms: '/json/getLeasingPrograms', // TODO the latest in filters
    executiveAuthorities: '/json/getExecutiveAuthorities', // done
    contractorDirectorPosts: '/json/getContractorDirectorPosts', // done TODO maybe rename
    klpTypes: '/json/getKlpTypes', // TODO the latest in filters, maybe rename
    departments: '/json/department/getAll', // done
    allUserRoles: '/json/admin/roles/getAll', // done TODO maybe rename
    salesPlanTypes: '/json/getPlanSalesTypes', // done
    loyaltyPrograms: '/json/contractor/dealerLoyalties', // done
    dealerPointDirections: '/json/getDealerPointOfSaleMainDirections', // done
    dealerTypes: '/json/dealerPointOfSale/getTypes', // TODO the latest in filters
    otherLk: '/json/getOtherLeasingCompanies', // done TODO maybe rename
    directoryTableNames: '/json/history/directory/getTables', // done
    ticketThemes: '/json/getSupportTheme', // done
    ticketStatuses: '/json/getSupportStatuses', // done TODO maybe not used
    clientStatuses: '/json/getClientStatuses', // done
    leasingObjectStatuses: '/json/getLeasingObjectStatuses', // TODO the latest in filters
    dealerCategories: '/json/dealerPointOfSale/getCategories', // TODO the latest in filters
    applicationRejectStatuses: '/json/getApplicationRejections', // TODO the latest in filters
    ownershipsList: '/json/getFormOwnershipCompanies', // done
    familyStatuses: '/json/getFamilyStatuses', // done
    projectRefusalStatuses: '/json/getProjectRejections', // done
    callTypes: '/json/getTypeCalls', // TODO the latest in filters
    callStatus: '/json/getStatusCalls', // TODO the latest in filters
    callTelephony: '/json/getTelephonies', // TODO the latest in filters
    logsTableName: '/json/getTables', // TODO the latest in filters
    logsType: '/json/getLogActionTypes', // TODO the latest in filters
    campaignTypes: '/json/getAdvertisingCampaignTypes', // done
    klpSolutions: '/json/getKlpSolutions', // done
    serviceApplicationStatuses:
      '/json/serviceApplication/getListServiceApplicationStatus', // TODO the latest in filters
    serviceApplicationTypes:
      '/json/serviceApplication/getServiceApplicationType', // TODO the latest in filters
    leadStatuses: '/json/getLeadStatuses', // TODO the latest in filters
    insurancePrograms: '/json/getInsuranceProgramTypes', // done
    promotions: '/json/getStockTypes', // done TODO maybe rename
    replacementCarClasses: '/json/getReplacementCarClasses', // done TODO maybe rename
    insuranceFranchise: {
      url: '/json/getInsuranceFranchiseTypes',
      indexedKey: 'value',
    }, // done
    placeOfRegistration: '/json/getPlaceOfRegistrationTypes', // done TODO maybe rename
    servicePackages: '/json/getServicePackages', // done
    chartTypes: {
      url: '/json/getGraphTypes',
      indexedKey: 'value',
    }, // done
    gpsTrackerSelect: '/json/getGpsTrackerTypes', // done `TODO maybe rename
    months: '/json/getMonth', // done TODO maybe rename
    clientTypesFSN: '/json/getClientFSNTypes', // done TODO maybe rename
    customGraphicTypes: '/json/getCustomGraphicTypes', // done TODO maybe rename
    basisDocuments: '/json/getBasisDocuments', // done TODO maybe rename
    yesOrNo: { items: yesOrNo }, // done
    klpReworkTypes: { items: klpReworkTypes }, // done TODO maybe move to backend
    meetStatuses: { items: meetStatuses }, // done TODO maybe rename
    countryList: '/json/getCountries', // done TODO maybe rename
    employeePosts: '/json/contractor/employee/posts',
    employeeRoles: '/json/contractor/employee/roles',
    employeeDepartments: '/json/contractor/employee/departments',
  })
})()

export default DirectoryPlugin

export {
  fillDirectory,
  getDirectory,
  pushDirectoryItem,
  updateDirectoryItem,
  removeDirectoryItem,
  clearDirectory,
}
