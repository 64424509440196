<template>
  <div>
    <div>
      <!--      <div v-if="isNew" class="glass-wrapper">-->
      <!--        <div class="text">-->
      <!--          <span class="d-block font-weight-bold">🔐 Доступ обмежено</span>-->
      <!--          <span class="d-block">Для повного перегляду візьміть службу в роботу!</span>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="white-block" style="font-size: 14px">
        <v-row>
          <v-col :cols="12" :sm="3" class="pb-1">
            <div class="card-label">Назва компанії</div>
          </v-col>
          <v-col :cols="12" :sm="9" class="pb-1 text-start">
            <span class="card-value">
              <ALink
                :route="{
                  name: 'contractors-form',
                  params: { id: contractor.id },
                }"
                :text="contractor.companyFullName || contractor.shortName" />
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="12" :sm="3" class="pt-1 pb-1">
            <div class="card-label">ЄДРПОУ</div>
          </v-col>
          <v-col :cols="12" :sm="9" class="pt-1 pb-1 text-start">
            <span class="card-label">{{ contractor.edrpou || '---' }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="12" :sm="3" class="pt-1 pb-1">
            <div class="card-label">Статутний капітал</div>
          </v-col>
          <v-col :cols="12" :sm="9" class="pt-1 pb-1 text-start">
            <div v-html="getAuthorizedCapital()"></div>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="12" :sm="3" class="pt-1 pb-1">
            <div class="card-label">Країна резиденства</div>
          </v-col>
          <v-col :cols="12" :sm="9" class="pt-1 pb-1 text-start">
            <span class="card-label">{{
              contractor.residentCountry?.name || '---'
            }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="12" :sm="3" class="pt-1 pb-1">
            <div class="card-label">Сегмент</div>
          </v-col>
          <v-col :cols="12" :sm="9" class="pt-1 pb-1 text-start">
            <span class="card-value">{{
              contractor?.segment?.name || 'Відсутній'
            }}</span>
          </v-col>
        </v-row>

        <v-col :cols="12" class="pa-0">
          <v-row>
            <v-col :cols="12" :md="3">
              <span class="card-label">Місцезнаходження</span>
            </v-col>
            <v-col :cols="12" :md="9">
              <div class="d-flex align-center">
                <div class="card-sublable mr-1 align-center">
                  <AddressIcon class="mr-1" />
                  Фактична адреса:
                </div>
                <span class="card-label">{{
                  getAddress(contractor.addresses.find(a => a.typeId === 2)) ||
                  '---'
                }}</span>
              </div>
              <div class="d-flex align-center">
                <div class="card-sublable mr-1 align-center">
                  <AddressIcon class="mr-1" />
                  Юридична адреса:
                </div>
                <span class="card-label">{{
                  getAddress(contractor.addresses.find(a => a.typeId === 1)) ||
                  '---'
                }}</span>
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col :cols="12" :md="3" class="pt-0 pb-0"></v-col>
            <v-col :cols="12" :md="9" class="pt-0 pb-0">
              <slot name="main-info"></slot>
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="12" :md="3" class="pt-1 pb-1"></v-col>
            <v-col :cols="12" :md="9" class="pt-1 pb-1">
              <component
                :is="finDocsOpenable ? 'OpenableList' : 'OpenableList'"
                v-if="hasDoc('fin')"
                style="margin-bottom: 25px; margin-left: -5px"
                title="фiнансові документи">
                <FilesUploader
                  v-for="doc in finDocuments"
                  :key="doc.label"
                  :info="doc.info"
                  :label="doc.label"
                  :documents="doc.docs"
                  :list="false"
                  readonly />
                <template #append-title>
                  <AllDocsBtn :id="contractor.id" class="ml-2" contractor fin />
                </template>
              </component>
              <component
                :is="legalDocsOpenable ? 'OpenableList' : 'v-card-text'"
                v-if="hasDoc('legal')"
                style="margin-top: -25px; margin-left: -5px"
                title="юридичні документи">
                <FilesUploader
                  v-for="doc in legalDocuments"
                  :key="doc.label"
                  :label="doc.label"
                  :documents="doc.docs"
                  :list="false"
                  readonly />
                <slot name="append-legal-docs"></slot>
                <template #append-title>
                  <AllDocsBtn
                    :id="contractor.id"
                    class="ml-2"
                    contractor
                    legal />
                </template>
              </component>
            </v-col>
          </v-row>
        </v-col>

        <div v-if="contactDetails">
          <v-col :cols="12" class="pt-0 pb-0 pl-0">
            <v-row>
              <v-col :cols="12" :md="3" class="pt-0">
                <span class="card-label">Контактні дані</span>
              </v-col>
              <v-col :cols="12" :md="9" class="pt-0">
                <div class="pl-1 d-flex align-center">
                  <div class="card-sublable mr-1 align-center">
                    <EmailIcon :width="16" class="mr-1" />
                    Телефон:
                  </div>
                  <span class="card-label">{{
                    contactDetailsWithType(contractor.phones, 'phone')
                  }}</span>
                </div>

                <div class="pl-1 d-flex align-center">
                  <div class="card-sublable mr-1 align-center">
                    <PhoneIcon :width="16" class="mr-1" />
                    Email:
                  </div>
                  <span class="card-label">{{
                    contactDetailsWithType(contractor.emails, 'email')
                  }}</span>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </div>
      </div>

      <div v-if="executiveAuthorities.length">
        <div
          v-for="d in executiveAuthorities"
          :key="d.id"
          style="
            margin: 10px 0;
            background: #fff;
            padding: 13px;
            font-size: 14px;
          ">
          <div class="mt-0 section-card">
            <div>
              <v-row>
                <v-col :cols="12" :sm="3" class="pt-0 pb-1">
                  <span class="mb-2 d-block card-label">
                    {{ isCollegium ? d.post || '' : 'Уповноважені особи:' }}
                  </span>
                </v-col>
                <v-col :cols="12" :sm="9" class="pt-0 pb-1 text-start">
                  <div class="d-flex">
                    <ALink
                      class="mr-1"
                      :text="d.fullName + ` | `"
                      :route="{
                        name: 'contact-common-info',
                        params: { id: d.id },
                      }">
                    </ALink>
                    {{ d.fullPostName || d.post || ' Посада відсутня' }}
                  </div>
                </v-col>
                <v-col :cols="12" :sm="3" class="pt-0 pb-0"> </v-col>
                <v-col :cols="12" :sm="9" class="pt-0 pb-0 text-start">
                  <div class="d-flex">
                    <div class="card-sublable mr-1">Дата народження:</div>
                    <span class="card-label mr-1">{{
                      toFormatDate(d.birthday) || 'Відсутня'
                    }}</span>
                    <div class="card-sublable mr-1">Громадянство:</div>
                    <span class="card-label">{{
                      d.nationality?.name || 'Відсутня'
                    }}</span>
                  </div>
                </v-col>
                <v-col :cols="12" :sm="3" class="pt-0 pb-0"> </v-col>
                <v-col :cols="12" :sm="9" class="pt-0 pb-0 text-start">
                  <div class="d-flex">
                    <div class="card-sublable mr-1">IПН:</div>
                    <span class="card-label">{{ d.inn || 'Відсутній' }}</span>
                  </div>
                </v-col>
                <v-col :cols="12" :sm="3" class="pt-0"> </v-col>
                <v-col :cols="12" :sm="9" class="pt-0 text-start">
                  <div class="d-flex">
                    <div class="card-sublable mr-1">
                      {{ getPassport(d, { label: true }) }}:
                    </div>
                    <span class="card-label">{{ getPassport(d) }}</span>
                  </div>
                </v-col>

                <v-col :cols="12" class="pt-0 pb-0">
                  <v-row>
                    <v-col :cols="12" :md="3"> </v-col>
                    <v-col :cols="12" :md="9">
                      <div class="d-flex align-center">
                        <div
                          class="card-sublable mr-1 align-center"
                          style="width: 150px">
                          <AddressIcon class="mr-1" />
                          Мiсце народження:
                        </div>
                        <span class="card-label">{{
                          d.placeOfBirth || '---'
                        }}</span>
                      </div>

                      <div class="d-flex align-center">
                        <div
                          class="card-sublable mr-1 align-center"
                          style="width: 150px">
                          <AddressIcon class="mr-1" />
                          Фактична адреса:
                        </div>
                        <span v-if="d.addresses" class="card-label">{{
                          getAddress(d.addresses.find(a => a.typeId === 2)) ||
                          '---'
                        }}</span>
                        <span v-else class="card-label">{{
                          'Адреса відсутня'
                        }}</span>
                      </div>

                      <div class="d-flex align-center">
                        <div
                          class="card-sublable mr-1 align-center"
                          style="width: 150px">
                          <AddressIcon class="mr-1" />
                          Адреса реєстрацiї:
                        </div>
                        <span v-if="d.addresses" class="card-label">{{
                          getSafe(() =>
                            getAddress(d.addresses.find(a => a.typeId === 4))
                          )
                        }}</span>
                        <span v-else class="card-label">{{
                          'Адреса відсутня'
                        }}</span>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col :cols="12">
                  <v-row>
                    <v-col :cols="12" :md="3" class="pb-0"> </v-col>
                    <v-col :cols="12" :md="9" class="pb-0">
                      <div class="d-flex align-center">
                        <div class="card-sublable mr-1 align-center">
                          <EmailIcon :width="16" class="mr-1" />
                          Телефон:
                        </div>
                        <span class="card-label">{{
                          contactDetailsWithType(d.phones, 'phone')
                        }}</span>
                      </div>

                      <div class="d-flex align-center">
                        <div class="card-sublable mr-1 align-center">
                          <PhoneIcon :width="16" class="mr-1" />
                          Email:
                        </div>
                        <span class="card-label">{{
                          contactDetailsWithType(d.emails, 'email')
                        }}</span>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>

                <v-row>
                  <v-col :cols="12" :md="3"></v-col>
                  <v-col :cols="12" :md="9">
                    <OpenableList>
                      <FilesUploader
                        readonly
                        label="Паспорт"
                        :documents="
                          getSafe(() => d.identityDocument.document, null)
                        " />
                      <FilesUploader
                        v-model:documents="d.identificationNumber"
                        readonly
                        label="Ідентифiкацiйний номер" />
                    </OpenableList>
                  </v-col>
                </v-row>
              </v-row>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        style="
          background: #fff;
          padding: 13px;
          margin: 10px 0;
          font-size: 14px;
        ">
        <v-row>
          <v-col :cols="12" :sm="3" class="pt-1 pb-1">
            <div class="card-label">Директор:</div>
          </v-col>
          <v-col :cols="12" :sm="9" class="pt-1 pb-1 text-start">
            <span class="card-value">Дані Відсутні</span>
          </v-col>
        </v-row>
      </div>

      <div
        v-if="beneficiarOwners.length"
        class="white-block"
        style="font-size: 14px">
        <span
          class="card-label d-block mb-2"
          style="margin-bottom: -20px !important; display: block"
          >Бенефіціарні власники:</span
        >
        <div
          v-for="beneficiar in beneficiarOwners"
          :key="beneficiar.contact.inn"
          class="section-card">
          <div>
            <v-row>
              <v-col :cols="12" :sm="3" class="pt-0 pb-1"> </v-col>
              <v-col :cols="12" :sm="9" class="pt-0 pb-1 text-start">
                <ALink
                  :route="{
                    name: 'contact-common-info',
                    params: { id: beneficiar.contact.id },
                  }"
                  :text="beneficiar.contact.fullName" />
              </v-col>
              <v-col :cols="12" :sm="3" class="pt-0 pb-0"> </v-col>
              <v-col :cols="12" :sm="9" class="pt-0 pb-0 text-start">
                <span class="card-sublable">Ідентифiкацiйний номер:</span>
                <span class="card-label">{{
                  beneficiar.contact.inn || '---'
                }}</span>
              </v-col>
              <v-col :cols="12" :sm="3" class="pt-0 pb-0"> </v-col>
              <v-col :cols="12" :sm="9" class="pt-0 pb-0 text-start">
                <span class="card-sublable">Дата народження:</span>
                <span class="card-label mr-1">{{
                  toFormatDate(beneficiar.contact.birthday) || '---'
                }}</span>
                <span class="card-sublable">Громадянство:</span>
                <span class="card-label mr-1">{{
                  beneficiar.contact.nationality
                    ? beneficiar.contact.nationality?.name
                    : '---'
                }}</span>
              </v-col>
              <v-col :cols="12" :sm="3" class="pt-0"> </v-col>
              <v-col :cols="12" :sm="9" class="pt-0 text-start">
                <div class="d-flex">
                  <div class="card-sublable mr-1">
                    {{ getPassport(beneficiar.contact, { label: true }) }}:
                  </div>
                  <span class="card-label">{{
                    getPassport(beneficiar.contact)
                  }}</span>
                </div>
              </v-col>
              <v-col :cols="12" :sm="3" class="pt-0 pb-0"> </v-col>
              <v-col :cols="12" :sm="9" class="pt-0 pb-0 text-start">
                <div class="d-flex">
                  <div class="d-flex">
                    <span class="card-sublable mr-1">Ознака КБВ:</span>
                    <span class="card-label mr-1">{{
                      beneficiar?.signKbv?.name || '---'
                    }}</span>
                    <span class="card-sublable mr-1">Тип впливу:</span>
                    <span class="card-label mr-1">
                      {{ beneficiar?.influence?.name || '---' }}</span
                    >
                  </div>
                  <div class="d-flex align-center">
                    <span class="card-sublable mr-1">Доля володіння:</span>
                    <CircleDiagram :percent="beneficiar.percent" class="mr-1" />
                    <span class="card-label"
                      >{{
                        getBeautyNum(beneficiar?.percent, {
                          float: 6,
                          round: true,
                        })
                      }}%</span
                    >
                  </div>
                </div>
              </v-col>
              <v-col :cols="12" :sm="3" class="pt-0 pb-0"></v-col>
              <v-col :cols="12" :sm="9" class="pt-0 pb-0 text-start">
                <div class="d-flex align-center">
                  <div class="card-sublable mr-1 align-center">
                    <AddressIcon class="mr-1" />
                    Фактична адреса:
                  </div>
                  <span
                    v-if="beneficiar.contact && beneficiar.contact.addresses"
                    class="card-label"
                    >{{
                      getAddress(
                        beneficiar.contact.addresses.find(a => a.typeId === 2)
                      )
                    }}</span
                  >
                  <span v-else class="card-label">{{ 'Адреса відсутня' }}</span>
                </div>

                <div class="d-flex align-center">
                  <div class="card-sublable mr-1 align-center">
                    <AddressIcon class="mr-1" />
                    Адреса реєстрацiї:
                  </div>
                  <span
                    v-if="beneficiar.contact && beneficiar.contact.addresses"
                    class="card-label"
                    >{{
                      getAddress(
                        beneficiar.contact?.addresses.find(a => a.typeId === 4)
                      )
                    }}</span
                  >
                  <span v-else class="card-label">{{ 'Адреса відсутня' }}</span>
                </div>
              </v-col>
              <v-row>
                <v-col :cols="12" :md="3"></v-col>
                <v-col :cols="12" :md="9">
                  <OpenableList v-if="showKbvDocs">
                    <FilesUploader
                      label="Паспорт"
                      :documents="beneficiar.contact.identityDocument.document"
                      readonly />
                    <FilesUploader
                      label="Iдентифiкацiйний номер"
                      :documents="beneficiar.contact.identificationNumber"
                      readonly />
                  </OpenableList>
                </v-col>
              </v-row>
            </v-row>
          </div>
        </div>
        <v-divider class="mt-2" dark></v-divider>
      </div>
      <div v-else class="white-block" style="font-size: 14px">
        <v-row>
          <v-col :cols="12" :sm="3" class="pt-1 pb-1">
            <div class="card-label">Бенефіціарні власники:</div>
          </v-col>
          <v-col :cols="12" :sm="9" class="pt-1 pb-1 text-start">
            <span class="card-value">Дані відсутні</span>
          </v-col>
        </v-row>
      </div>

      <div
        v-if="showSignatories"
        style="
          font-size: 14px;
          margin: 10px 0;
          padding: 13px;
          background: #fff;
        ">
        <div v-if="signatories.length">
          <span
            class="card-label d-block"
            style="margin-bottom: -20px !important"
            >Підписанти:</span
          >
          <div
            v-for="signatory in signatories"
            :key="signatory.contact.inn"
            class="mt-0 section-card">
            <div>
              <v-row>
                <v-col :cols="12" :sm="3" class="pt-0 pb-1"> </v-col>
                <v-col :cols="12" :sm="9" class="pt-0 pb-1 text-start">
                  <ALink
                    :route="{
                      name: 'contact-common-info',
                      params: { id: signatory.contact.id },
                    }"
                    :text="signatory.contact.fullName" />
                </v-col>
                <v-col :cols="12" :sm="3" class="pt-0 pb-0"> </v-col>
                <v-col :cols="12" :sm="9" class="pt-0 pb-0 text-start">
                  <span class="card-value"
                    >IПН: {{ signatory.contact.inn }}</span
                  >
                </v-col>
                <v-col :cols="12" :sm="3" class="pt-0 pb-0"> </v-col>
                <v-col :cols="12" :sm="9" class="pt-0 pb-0 text-start">
                  <span class="card-value">Дата народження:</span>
                  <span class="card-label mr-1">{{
                    toFormatDate(signatory.contact.birthday)
                  }}</span>
                  <span class="card-value">Місце народження:</span>
                  <span class="card-label mr-1">{{
                    signatory.contact.placeOfBirth
                  }}</span>
                </v-col>
                <v-col :cols="12" :sm="3" class="pt-0 pb-0"> </v-col>
                <v-col :cols="12" :sm="9" class="pt-0 pb-0 text-start">
                  <div>
                    <v-col :cols="12" :sm="9" class="pl-0 pt-1 pb-1 text-start">
                      <span class="card-value">
                        {{ getPassport(signatory.contact, { label: true }) }}
                      </span>
                      <span class="card-label">{{
                        getPassport(signatory.contact)
                      }}</span>
                    </v-col>
                  </div>
                </v-col>

                <v-col :cols="12" :sm="3" class="pt-0 pb-0"></v-col>
                <v-col :cols="12" :sm="9" class="pt-0 pb-0 text-start">
                  <div class="d-flex align-center">
                    <div class="card-sublable mr-1 align-center">
                      <AddressIcon class="mr-1" />
                      Фактична адреса:
                    </div>
                    <span
                      v-if="signatory.contact && signatory.contact.addresses"
                      class="card-label"
                      >{{
                        getAddress(
                          signatory.contact?.addresses.find(a => a.typeId === 2)
                        ) || 'Відсутня'
                      }}</span
                    >
                    <span v-else class="card-label">{{
                      'Адреса відсутня'
                    }}</span>
                  </div>

                  <div class="d-flex align-center">
                    <div class="card-sublable mr-1 align-center">
                      <AddressIcon class="mr-1" />
                      Адреса реєстрацiї:
                    </div>
                    <span
                      v-if="signatory.contact && signatory.contact.addresses"
                      class="card-label"
                      >{{
                        getAddress(
                          signatory.contact?.addresses.find(a => a.typeId === 4)
                        ) || 'Відсутня'
                      }}</span
                    >
                    <span v-else class="card-label">{{
                      'Адреса відсутня'
                    }}</span>
                  </div>
                </v-col>

                <v-col :cols="12" :sm="3" class="pt-0 pb-0"></v-col>
                <v-col :cols="12" :sm="9" class="pt-0 pb-0 text-start">
                  <OpenableList title="документи" style="padding-left: -5px">
                    <div>
                      <FilesUploader
                        v-if="!signatory.contact.identityDocument"
                        label="Паспорт"
                        list
                        :documents="[]"
                        readonly />
                      <FilesUploader
                        v-if="signatory.contact.identityDocument"
                        label="Паспорт"
                        list
                        :documents="signatory.contact.identityDocument.document"
                        readonly />
                      <FilesUploader
                        label="Ідентифiкацiйний номер"
                        :documents="signatory.contact.identificationNumber"
                        readonly />
                      <FilesUploader
                        label="Довіреність та інші док-ти про повноваження"
                        :documents="signatory.contact.documentsPowers"
                        readonly />
                    </div>
                  </OpenableList>
                </v-col>
              </v-row>
            </div>
          </div>
          <v-divider class="mt-2" dark></v-divider>
        </div>
        <div v-else>
          <v-row>
            <v-col :cols="12" :sm="3" class="pt-1 pb-1">
              <div class="card-label">Підписанти:</div>
            </v-col>
            <v-col :cols="12" :sm="9" class="pt-1 pb-1 text-start">
              <span class="card-value">Данi Відсутні</span>
            </v-col>
          </v-row>
        </div>
      </div>
      <div>
        <div
          v-if="founders.length"
          style="
            background: #fff;
            padding: 13px;
            margin-top: 10px;
            font-size: 14px;
          ">
          <span class="card-label d-block" style="margin-bottom: -20px"
            >Засновники:</span
          >
          <div v-for="d in founders" :key="d.id" class="mb-5">
            <v-row>
              <v-col :cols="12" :md="3" class="pb-1 pt-0"></v-col>
              <v-col :cols="12" :md="9" class="pb-1 pt-0">
                <span>{{ d.name }}</span>
                |
                <span v-if="d.role || d.founder_role">{{
                  d.role?.name || d.founder_role?.name
                }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col :cols="12" :md="3" class="pt-0 pb-0"></v-col>
              <v-col :cols="12" :md="9" class="pt-0 pb-0">
                <div class="d-flex align-center">
                  <div class="d-flex align-center mr-1">
                    <span class="card-sublable mr-1">Частка:</span>
                    <CircleDiagram :percent="d.amountPercent" class="mr-1" />
                    <span
                      >{{
                        getBeautyNum(d.amountPercent || 0, {
                          float: 6,
                          round: true,
                        })
                      }}%</span
                    >
                  </div>
                  <div class="d-flex align-center">
                    <span class="card-sublable d-block mr-1"
                      >Сума капіталу:</span
                    >
                    <span class="d-block mr-1"
                      >{{ formutSum(d.amount) }} грн</span
                    >
                    <span class="card-sublable d-block mr-1">ЄДРПОУ:</span>
                    <span class="d-block">{{ d.code }}</span>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col :cols="12" :md="3" class="pt-0 pb-0"></v-col>
              <v-col :cols="12" :md="9" class="pt-0 pb-0">
                <div class="d-flex align-center">
                  <span class="card-sublable mr-1">Країна резиденства:</span>
                  <span class="card-label">{{
                    d.residentCountry?.name || '---'
                  }}</span>
                </div>
              </v-col>
              <v-col :cols="12" :md="3" class="pt-0 pb-0"></v-col>
              <v-col :cols="12" :md="9" class="pt-0 pb-0">
                <div class="d-flex align-center">
                  <span class="card-sublable mr-1">Країна реєстрації:</span>
                  <span class="card-label">{{
                    d.registrationCountry?.name || '---'
                  }}</span>
                </div>
              </v-col>
              <v-col :cols="12" :md="3" class="pt-0 pb-0"></v-col>
              <v-col :cols="12" :md="9" class="pt-0 pb-0">
                <div class="d-flex align-center">
                  <span class="card-sublable mr-1">Адреса засновника:</span>
                  <span class="card-label">{{ d.location || '---' }}</span>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
        <div
          v-else
          style="
            background: #fff;
            padding: 13px;
            margin: 10px 0;
            font-size: 14px;
          ">
          <v-row>
            <v-col :cols="12" :sm="3" class="pt-1 pb-1">
              <div class="card-label">Засновники:</div>
            </v-col>
            <v-col :cols="12" :sm="9" class="pt-1 pb-1 text-start">
              <span class="card-value">Дані Відсутні</span>
            </v-col>
          </v-row>
        </div>
      </div>
      <div>
        <div
          v-if="employees.length"
          style="
            background: #fff;
            padding: 13px;
            margin-top: 10px;
            font-size: 14px;
          ">
          <span class="card-label d-block" style="margin-bottom: -20px">
            Співробітники:
          </span>
          <div v-for="d in employees" :key="d.contact.id" class="mb-4">
            <v-row>
              <v-col :cols="12" :md="3" class="pt-0"></v-col>
              <v-col :cols="12" :md="9" class="pt-0 pb-1">
                <span>
                  <ALink
                    :route="{
                      name: 'contact-common-info',
                      params: { id: d.contact.id },
                    }"
                    :text="d.contact.fullName + ' | '" />
                  {{
                    d.fullPostName ||
                    (d.post ? d.post.name : false) ||
                    'Посада відсутня'
                  }}
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col :cols="12" :md="3" class="pt-0 pb-0"></v-col>
              <v-col :cols="12" :md="9" class="pt-0 pb-0">
                <div class="d-flex align-center">
                  <div class="card-sublable mr-1 align-center">
                    <PhoneIcon :width="16" class="mr-1" />
                    Телефон:
                  </div>
                  <span>
                    {{ contactDetailsWithType(d.contact.phones, 'phone') }}
                  </span>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col :cols="12" :md="3" class="pt-0 pb-0"></v-col>
              <v-col :cols="12" :md="9" class="pt-0 pb-0">
                <div class="d-flex align-center">
                  <div class="card-sublable mr-1 align-center">
                    <EmailIcon :width="16" class="mr-1" />
                    Email:
                  </div>
                  <span>
                    {{ contactDetailsWithType(d.contact.emails, 'email') }}
                  </span>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
        <div
          v-else
          style="
            background: #fff;
            padding: 13px;
            margin-top: 10px;
            font-size: 14px;
          ">
          <v-row>
            <v-col :cols="12" :sm="3" class="pt-1 pb-1">
              <div class="card-label">Cпiвробiтники:</div>
            </v-col>
            <v-col :cols="12" :sm="9" class="pt-1 pb-1 text-start">
              <span class="card-value">Дані відсутні</span>
            </v-col>
          </v-row>
        </div>
      </div>
      <slot name="before-docs"></slot>
      <slot name="after">
        <!-- Additional content content -->
      </slot>
    </div>
  </div>
</template>

<script>
import { useSelect } from '@/utils/mixins/useSelect'
import CircleDiagram from '@/components/CircleDiagram.vue'
import AddressIcon from '@/assets/svg/Address-icon.vue'
import PhoneIcon from '@/assets/svg/phone-icon.vue'
import EmailIcon from '@/assets/svg//social/email-icon.vue'
import FilesUploader from './FilesUploader.vue'
import OpenableList from './OpenableList.vue'
import ALink from './Link.vue'
import AllDocsBtn from './AllDocsBtn.vue'
import { mapState } from 'vuex'
import {
  getAddress,
  getPassport,
  contactDetailsWithType,
  getContactInitials,
  getBeautyNum,
  toFormatDate,
  getSafe,
  getAuthorizedCapital,
} from '@/utils/helperFunc'
import { formutSum } from '@/utils/formatFunc'
import { contractor as C } from '@/type-ids'
import LeasingParticipant from '@/pages/contracts/components/leasingParticipant/LeasingParticipant.vue'

export default {
  components: {
    LeasingParticipant,
    AddressIcon,
    PhoneIcon,
    EmailIcon,
    FilesUploader,
    OpenableList,
    ALink,
    AllDocsBtn,
    CircleDiagram,
  },
  setup() {
    return {
      ...useSelect(),
    }
  },
  props: {
    contractor: { type: Object },
    contactDetails: { type: Boolean, default: false },
    showKbvDocs: { type: Boolean, default: false },
    finDocs: { type: [Boolean, Array], default: false },
    finDocsOpenable: { type: Boolean, default: true },
    legalDocs: { type: [Boolean, Array], default: false },
    legalDocsOpenable: { type: Boolean, default: true },
    showSignatories: { type: Boolean, default: false },
    isNew: { type: Boolean, default: false },
  },
  data() {
    return {
      C: C,
      beneficiarsTHead: [
        {
          title: 'ПІБ',
          key: 'contact.name',
          align: 'start',
          sortable: false,
        },
        { title: 'Посада', key: 'contact', align: 'start', sortable: false },
        {
          title: 'Тип впливу',
          key: 'influenceId',
          align: 'start',
          sortable: false,
        },
        {
          title: 'Ознака КБВ',
          key: 'signKbvId',
          align: 'start',
          sortable: false,
        },
        {
          title: 'Доля володіння %',
          key: 'percent',
          align: 'start',
          sortable: false,
        },
        {
          title: 'Дата народження',
          key: 'contact.birthday',
          align: 'center',
          sortable: false,
        },
        {
          title: 'Місце народження',
          key: 'placeOfBirth',
          align: 'center',
          sortable: false,
        },
        {
          title: 'Паспорт',
          key: 'passport',
          align: 'center',
          sortable: false,
        },
        {
          title: 'Адрес реєстрації',
          key: 'contact.addresses',
          align: 'center',
          sortable: false,
        },
        {
          title: 'Контактний телефон',
          key: 'phone',
          align: 'center',
          sortable: false,
        },
      ],
      employeesTHead: [
        {
          title: 'ПІБ',
          key: 'contact.name',
          align: 'start',
          sortable: false,
        },
        {
          title: 'Посада',
          key: 'fullPostName',
          align: 'center',
          sortable: false,
        },
        {
          title: 'Телефон',
          key: 'contact.phones',
          align: 'center',
          sortable: false,
        },
        {
          title: 'Пошта',
          key: 'contact.emails',
          align: 'center',
          sortable: false,
        },
      ],
      signatoriesTHead: [],
      participantTHead: [
        {
          title: 'ПІБ/Назва компанії',
          key: 'founder',
          align: 'start',
          sortable: false,
        },
        {
          title: 'Частка %',
          key: 'amountPercent',
          align: 'center',
          sortable: false,
        },
        {
          title: 'сума (капіталу)',
          key: 'amount',
          align: 'center',
          sortable: false,
        },
        {
          title: 'ІПН/ЄДРПОУ',
          key: 'code',
          align: 'center',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    executiveAuthorities() {
      return (
        this.contractor.employees
          ?.filter(e => {
            return e.isExecutiveAuthority
          })
          .map(e => {
            return { ...e.contact, post: e.post?.name }
          }) || []
      )
    },
    isCollegium() {
      return this.contractor.executiveAuthorityId === C.authorityType.COLLEGIUM
    },
    beneficiarOwners() {
      return this.contractor.beneficiaryOwners || []
    },
    signatories() {
      return this.employees.filter(e => e.isSignatureRight)
    },
    employees() {
      return (
        this.contractor.employees?.filter(e => !e.isExecutiveAuthority) || []
      )
    },
    founders() {
      return this.contractor.founders || []
    },
    finDocuments() {
      const docs = {
        balanceF1AndF2: {
          label: 'Баланс - Ф1 і Ф2',
          docs: this.contractor.balanceF1AndF2,
        },
        generalTurnoverBalanceSheet: {
          label: 'Загальна оборотно-сальдова відомість',
          docs: this.contractor.generalTurnoverBalanceSheet,
        },
        copiesOfContracts: {
          label: 'Копії договорів',
          info: 'Копії договорів, за якими передбачається отримання доходу в 2023 р',
          docs: this.contractor.copiesOfContracts,
        },
        certificatesOfObligations: {
          label: "Довідки про наявність / відсутність зобов'язань",
          docs: this.contractor.certificatesOfObligations,
        },
        declaration: {
          label: 'Декларація за розрахунком податку на прибуток',
          docs: this.contractor.declaration,
        },
        turnoverBalanceSheet: {
          label: 'Оборотно-сальдові відомості по рахунку (36,37 і 63, 68 рах.)',
          docs: this.contractor.turnoverBalanceSheet,
        },
        certificatesOfAccountTurnover: {
          label: 'Довідки про обороти по рахунках з обслуговуючих банків',
          docs: this.contractor.certificatesOfAccountTurnover,
        },
        otherFinancialDocuments: {
          label: 'Інші фінансові документи',
          docs: this.contractor.otherFinancialDocuments,
        },
      }
      Array.isArray(this.finDocs) && this.filterDocs(docs, this.finDocs)
      return Object.values(docs)
    },
    legalDocuments() {
      const docs = {
        statute: {
          label: 'Статут (остання редакція)',
          docs: this.contractor.statute,
        },
        codeAccessStatute: {
          label: 'Опис документів із зазначенням коду доступу до Статуту',
          docs: this.contractor.codeAccessStatute,
        },
        extract: {
          label: 'Виписка / витяг з ЄДРЮО, ФОПтаГФ',
          docs: this.contractor.extract,
        },
        protocolFounders: {
          label: 'Протокол засновників про обрання Керівника',
          docs: this.contractor.protocolFounders,
        },
        orderAppointment: {
          label: 'Наказ про призначення на посаду Керівника',
          docs: this.contractor.orderAppointment,
        },
        // applicationForm: { label: 'Заявка анкета (підписаний)', docs: this.contractor.applicationForm },
        // questionnaire: { label: 'Опитувальник (підписаний)', docs: this.contractor.questionnaire },
        // consentToProcessingOfPersonalData: { label: 'Згода на обробку персональних даних (підписаний)', docs: this.contractor.consentToProcessingOfPersonalData },
        // ownershipStructureSigned: { label: 'Структура власності (підписаний)', docs: this.contractor.ownershipStructureSigned },
        otherLegalDocuments: {
          label: 'Інші юридичні документи',
          docs: this.contractor.otherLegalDocuments,
        },
      }
      Array.isArray(this.legalDocs) && this.filterDocs(docs, this.legalDocs)
      return Object.values(docs)
    },
  },
  methods: {
    getAuthorizedCapital,
    getSafe,
    toFormatDate,
    getAddress,
    getPassport,
    contactDetailsWithType,
    formutSum,
    getBeautyNum,
    getContactInitials,
    filterDocs(docs, includes) {
      Object.keys(docs).forEach(
        doc => !includes.includes(doc) && delete docs[doc]
      )
    },
    hasDoc(entity) {
      return Array.isArray(this[`${entity}Docs`])
        ? !!Object.keys(this[`${entity}Documents`]).length
        : this[`${entity}Docs`]
    },
  },
}
</script>

<style scoped>
.card-label {
  color: #000 !important;
}
.card-value {
  color: #838282 !important;
}
.card-sublable {
  color: #838282;
  font-weight: 500;
  font-size: 13px;
}
</style>
