<template>
  <div>
    <b-select
      v-model="localSignatureBasis.basisDocumentId"
      label="На підставі"
      :items="
        $directory
          .get('basisDocuments')
          .filter(b => [1, 2, 8, 9].includes(b.id as number))
      "
      :loading="$loading.isLoading('basisDocuments')"
      :error-messages="getErrorMessages(v$.basisDocumentId)"
      hide-details
      @blur="v$.basisDocumentId.$touch()"></b-select>
    <v-scroll-x-transition hide-on-leave>
      <b-input
        v-show="hasDocumentComment"
        v-model="localSignatureBasis.documentComment"
        label="Назва"
        hide-details
        placeholder="введіть значення"
        :error-messages="getErrorMessages(v$.documentComment)"
        @blur="v$.documentComment.$touch()"></b-input>
    </v-scroll-x-transition>
    <v-btn variant="plain" class="mt-3" @click="submit">
      <span v-if="localSignatureBasis.id">Редагувати</span>
      <span v-else>Додати</span>
    </v-btn>
  </div>
</template>

<script lang="ts">
import axios from '@/plugins/axios.js'
import {
  urlSignatureBasisCreate,
  urlSignatureBasisUpdate,
} from '@/pages/request'
import { SignatureBasis } from '@/utils/types'
import { computed, PropType, reactive, watch } from 'vue'
import { handleAsync, setSnackbar } from 'best-modules/plugins'
import { required, requiredIf } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { getErrorMessages } from 'best-modules/utils'
import { fillDirectory } from '@/plugins/directory'

export default {
  name: 'SignatureBasisForm',
  props: {
    signatureBasis: {
      type: Object as PropType<SignatureBasis>,
      required: true,
    },
    employeeId: {
      type: Number,
    },
  },
  methods: { getErrorMessages },
  emits: ['create', 'update', 'update:model-value'],
  setup(props, { emit }) {
    const localSignatureBasis = reactive<
      SignatureBasis & { employeeId: number }
    >({
      employeeId: props.employeeId,
      id: null,
      basisDocumentId: null,
      documentComment: null,
    } as SignatureBasis & { employeeId: number })
    watch(
      computed(() => props.signatureBasis),
      v => {
        if (v) {
          localSignatureBasis.id = v.id
          localSignatureBasis.basisDocumentId = v.basisDocumentId
          localSignatureBasis.documentComment = v.documentComment
        }
      },
      { deep: true, immediate: true }
    )
    const hasDocumentComment = computed(() => {
      return [2, 8, 9].includes(localSignatureBasis.basisDocumentId)
    })
    const rules = {
      basisDocumentId: { required: required },
      documentComment: { required: requiredIf(() => hasDocumentComment.value) },
    }
    const v$ = useVuelidate(rules, localSignatureBasis)

    const createSignatureBasis = () => {
      v$.value.$validate()
      if (v$.value.$invalid) {
        return
      }
      return handleAsync('createSignatureBasis', () => {
        return axios
          .post(urlSignatureBasisCreate(), localSignatureBasis)
          .then(res => {
            localSignatureBasis.id = null
            localSignatureBasis.basisDocumentId = null
            localSignatureBasis.documentComment = null

            v$.value.$reset()

            setSnackbar({ text: 'Підставу успішно додано' })
            emit('create', res.data)
          })
      })
    }
    const updateSignatureBasis = () => {
      v$.value.$validate()
      if (v$.value.$invalid) {
        return
      }
      return handleAsync('updateSignatureBasis', () => {
        return axios
          .post(
            urlSignatureBasisUpdate(localSignatureBasis.id),
            localSignatureBasis
          )
          .then(res => {
            localSignatureBasis.id = null
            localSignatureBasis.basisDocumentId = null
            localSignatureBasis.documentComment = null

            v$.value.$reset()

            setSnackbar({ text: 'Дані успішно оновлено' })
            emit('update', res.data)
            emit('update:model-value', res.data)
          })
      })
    }

    const submit = () => {
      if (localSignatureBasis.id) {
        return updateSignatureBasis()
      } else {
        return createSignatureBasis()
      }
    }

    watch(hasDocumentComment, v => {
      if (!v) {
        localSignatureBasis.documentComment = null
      }
    })

    fillDirectory('basisDocuments')

    return {
      localSignatureBasis,
      createSignatureBasis,
      updateSignatureBasis,
      v$,
      hasDocumentComment,
      submit,
    }
  },
}
</script>

<style scoped></style>
