<template>
  <div class="pt-3">
    <v-tabs
      v-if="!routePaymentPlan"
      v-model="currentTab"
      background-color="grey darken-2"
      :direction="$vuetify.breakpoint?.xs ? 'vertical' : 'horizontal'"
      :class="{ 'v-tabs--mobile': $vuetify.breakpoint?.xs }"
      show-arrows
      color="white"
      dark
      dense
      slider-color="#00000002"
      :height="$vuetify.breakpoint?.xs ? null : 38">
      <v-tab
        :disabled="pageLoad"
        replace
        :to="{
          name: `${branch}-calculator-leasing-object`,
          params: {
            applicationId: $route.params.applicationId,
            calculationId: $route.params.calculationId,
          },
        }">
        Предмет лізингу
      </v-tab>
      <v-tab
        :disabled="pageLoad"
        replace
        :to="{
          name: `${branch}-calculator-leasing-terms`,
          params: {
            applicationId: $route.params.applicationId,
            calculationId: $route.params.calculationId,
          },
        }">
        Умови лізингу
      </v-tab>
      <v-tab
        :disabled="pageLoad"
        replace
        :to="{
          name: `${branch}-calculator-additional-terms`,
          params: {
            applicationId: $route.params.applicationId,
            calculationId: $route.params.calculationId,
          },
        }">
        Додаткові умови
      </v-tab>
      <v-tab
        v-show="suitableLeasingObject"
        :disabled="pageLoad"
        replace
        :to="{
          name: `${branch}-calculator-service`,
          params: {
            applicationId: $route.params.applicationId,
            calculationId: $route.params.calculationId,
          },
        }">
        Сервіс
      </v-tab>
    </v-tabs>
    <div style="position: relative" class="px-2 mt-5">
      <v-fade-transition hide-on-leave>
        <keep-alive>
          <RouterView
            v-show="!pageLoad"
            v-model:applyCorp="applyCorp"
            v-model:editCommisionLk="editCommisionLk"
            v-model:editIncomeLk="editIncomeLk"
            v-model:editCommisionAb="editCommisionAb"
            v-model:commisionLkPresent="commisionLkPresent"
            v-model:grnTotalPrice="grnTotalPrice"
            v-model:applyBenefits="applyBenefits"
            v-model:applyProgramFsn="applyProgramFsn"
            v-model:localNbuDeposit="localNbuDeposit"
            :pageLoad="pageLoad"
            :applicationName="calculation.applicationName"
            :class="{
              'pa-0':
                routeApplicationNewPaymentPlan ||
                routeApplicationEditPaymentPlan,
            }"
            :calculation="calculation"
            :calculationGeneralObject="calculationGeneralObject"
            :routeEditCalculation="routeEditCalculation"
            :readOnly="readonly"
            :standardType="standardType"
            :hasPatrolKpp="hasPatrolKpp"
            :hasGpsTracker="hasGpsTracker"
            :canDisableGpsTracker="canDisableGpsTracker"
            :car="car"
            :truck="truck"
            :agricultural="agricultural"
            :busShort="bus"
            :special="special"
            :equipment="equipment"
            :waterTransport="waterTransport"
            :airTransport="airTransport"
            :railwayTransport="railwayTransport"
            :commercialProperty="commercialProperty"
            :withoutInsurance="withoutInsurance"
            :busMoreTwenty="busMoreTwenty"
            :moto="moto"
            :isNew="isNew"
            :loading="loading"
            :calcLoading="loading"
            :carObjType="carObjType"
            :seasonsGraphLoading="seasonsGraphLoading"
            :filteredLP="filteredLP"
            :marksLpActive="marksLpActive"
            :corporativeLP="corporativeLP"
            :corporativeST="corporativeST"
            :truckMassThreeMore="truckMassThreeMore"
            :truckMassUpToThree="truckMassUpToThree"
            :application="application"
            :incomeLkPresent="incomeLkPresent"
            :commisionAbPresent="commisionAbPresent"
            :truckMoreThenThreeTons="truckMoreThenThreeTons"
            :insuranceFranchises="insuranceFranchises"
            :carFsnProgramMinSum="carFsnProgramMinSum"
            :carFsnMinSum="carFsnMinSum"
            :agriculturalFsnMinSum="agriculturalFsnMinSum"
            :agriculturalFsnProgramMinSum="agriculturalFsnProgramMinSum"
            :equipmentFsnProgramSum="equipmentFsnProgramSum"
            :equipmentFsnMinSum="equipmentFsnMinSum"
            :specialFsnMinSum="specialFsnMinSum"
            :airDronFsnMinSum="airDronFsnMinSum"
            :specialFsnProgramSum="specialFsnProgramSum"
            :truckFsnProgramMinSum="truckFsnProgramMinSum"
            :airDronFsnProgramSum="airDronFsnProgramSum"
            :truckFsnProgramSum="truckFsnProgramSum"
            :airDron="airDron"
            :airDronId="airDronId"
            :setFsnDefaultValues="setFsnDefaultValues"
            :lP="lP"
            :v="v$"
            :isEditCalculation="routeEditCalculation"
            :isCreateCalculation="routeNewCalculation"
            @clear-fsn="clearFsn"
            @shift-graph-types="calculation.graphTypes.shift()"
            @get-financing-amount="getFinancingAmount"
            @get-nbu-deposit="getNbuDeposit"
            @change-l-p="changeLP">
          </RouterView>
        </keep-alive>
      </v-fade-transition>
      <div :class="{ 'pa-0': !calculationRoute }" class="mt-5">
        <div v-if="readonly" class="glass-container" style="z-index: 99999">
          sdfdsaf
        </div>
        <div v-if="calculationRoute && !pageLoad" class="mt-6 ml-2">
          <ActionButtons
            v-if="!readonly"
            class="mt-10"
            :confirm="submit"
            confirmBtnText="Розрахувати"
            :confirmLoading="loading"
            :cancel="getUserCalculation"
            :cancelDisable="
              loading || calculationCache === cache || !!!calculationId
            "
            :confirmDisable="loading || calculationCache === cache">
          </ActionButtons>
        </div>
        <SectionLoader
          v-show="
            (routeApplicationEditCalculation ||
              routeEditCalculation ||
              routeRiskManagerCalculation) &&
            pageLoad
          ">
        </SectionLoader>
      </div>
    </div>
  </div>
</template>
<script>
import ActionButtons from '@/components/action-buttons.vue'
import SectionLoader from '@/components/section-loader.vue'
import { setCache, v$Notify, getBeautyNum } from '@/utils/helperFunc'
import { required, minLength } from '@vuelidate/validators'
import { objClearEpmtyField } from '@/utils/helperFunc'
import { keepDigit, formutSum, humanDate } from '@/utils/formatFunc'
import { highLightErrors as hlErrors } from '@/utils/helperFunc'
import { toInt, toClientInt } from '@/pages/calculations/object-properties'
import { mapState } from 'vuex'
import {
  calculationGet,
  calculationCreate,
  urlGetNbuDeposit,
} from '@/pages/request'
import {
  CAR,
  MOTO,
  WATER_TRANSPORT,
  SPECIAL,
  TRUCK,
  BUS,
  AUTO_HOUSE,
  AIR_TRANSPORT,
  AGRICULTURAL,
  RAILWAY_TRANSPORT,
  COMMERCIAL_PROPERTY,
  EQUIPMENT,
} from './object-types'
import { get, update } from 'lodash'
import { useVuelidate } from '@vuelidate/core'
import { fillDirectory, getDirectory } from '@/plugins/directory/index.ts'
export default {
  components: { SectionLoader, ActionButtons },
  emits: ['calculate'],
  setup() {
    return { v$: useVuelidate() }
  },
  props: {
    readOnly: { type: Boolean, default: false },
    calculationData: { type: Object },
    applicationData: { type: Object },
    calculateUrl: { type: String },
  },
  validations() {
    const commentCommissionLk =
      (this.calculation.hiddenCommision &&
        this.calculation.hiddenCommision !== '0') ||
      (this.calculation.hiddenCommisionGr &&
        this.calculation.hiddenCommisionGr !== '0')
        ? { required }
        : {}
    const commentIncomeLk =
      (this.calculation.commissionLkPr &&
        this.calculation.commissionLkPr !== '0') ||
      (this.calculation.commissionLk && this.calculation.commissionLk !== '0')
        ? { required }
        : {}
    const commentAB =
      (this.calculation.additionalAbPr &&
        this.calculation.additionalAbPr !== '0') ||
      (this.calculation.additionalAb && this.calculation.additionalAb !== '0')
        ? { required }
        : {}
    const customPlaceOfReg =
      this.calculation.placeOfRegistration === 4
        ? {
            registration: { required },
            annualExpenses: { required },
            removalRegistration: { required },
          }
        : {}
    const truck = this.truck
      ? {
          truckMass: { required },
          liftingCapacity:
            this.calculation.truckMass === 2 ? { required } : true,
        }
      : {}
    const bus = this.bus ? { isOver20Seats: { required } } : {}
    const placeOfRegistration =
      this.special ||
      this.agricultural ||
      this.waterTransport ||
      this.airTransport ||
      this.equipment
        ? { placeOfRegistration: { required } }
        : {}
    const leasedAssertEngine = this.standardType
      ? { leasedAssertEngine: { required } }
      : {}
    const operLeasing = this.calculation.leasingType === 'oper-leasing'
    const even =
      this.calculation.graphTypes.includes('even') && !operLeasing
        ? {
            gainEvenGraphicMonths: { required },
            gainEvenGraphicPercent: { required },
          }
        : {}
    const annuity =
      this.calculation.graphTypes.includes('annuity') && !operLeasing
        ? { unsprMonth: { required } }
        : {}
    const annuityPlus =
      this.calculation.graphTypes.includes('annuity-plus') && !operLeasing
        ? { unpga: { required } }
        : {}
    const common = !operLeasing ? { graphTypes: { required } } : {}
    const customGraphicType = this.calculation.graphTypes.includes('irregular')
      ? { customGraphicType: { required } }
      : {}
    const customUniversal =
      this.calculation.graphTypes.includes('irregular') && this.universalGain
        ? { customUniversalOption: { required } }
        : {}
    const customStep =
      this.calculation.graphTypes.includes('irregular') && this.stepGain
        ? {
            customStepOptionFirst: { required },
            customStepOptionMiddle: { required },
          }
        : {}
    // const decreaseAnnuity = this.calculation.graphTypes.includes('annuity')
    //   ? { decreaseAnnuity: { required } } : {}
    const carAndTruck = !this.equipment
      ? {
          vehicleOwnerTax: { required },
          gpsTracker: { required },
          patrolCardsSupport: { required },
        }
      : {}
    const patrolCardQuantity =
      this.calculation.patrolCardsSupport === 1 &&
      !this.special &&
      !this.equipment &&
      !this.commercialProperty &&
      !this.railwayTransport &&
      !this.airTransport &&
      !this.waterTransport
        ? { patrolCardQuantity: { required } }
        : {}
    const replacementCarSupport = this.calculation.maintenanceRequest
      ?.replacementCarSupport
      ? {
          replacementCarClass: { required },
          numberDays: { required },
        }
      : {}
    const tire = this.calculation.maintenanceRequest?.hasTire
      ? {
          tireSize: { required },
          tireSets: { required },
          tiresCatalog: { required },
          tireCost: { required },
          tireFitting: { required },
          tireStorage: { required },
        }
      : {}

    const hasMaintenance = this.calculation.hasMaintenance
      ? {
          scheduledMileage: { required },
          servicePackage: { required },
          paymentsBind: { required },
          contractTerm: { required },
          additionalCosts: { required },
          ...tire,
          ...replacementCarSupport,
        }
      : {}
    const beaconAmount =
      [1, 2].includes(this.calculation.gpsBeacon) && !this.commercialProperty
        ? { beaconAmount: { required } }
        : {}

    const programFSN = this.applyProgramFsn
      ? {
          customerTypeObject: { required },
          uird: { required },
          workplaceType: { required },
          targetType: { required },
          newCreated: { required },
        }
      : {}

    const agentId = this.calculation.isAgent ? { agentId: { required } } : {}

    const generation = this.standardType ? { required } : {}
    const modification = this.standardType ? { required } : {}

    // const equepmentSum = this.calculation.additionalEquipment
    //   ? { addEquipmentSum: { required, minLength: minLength(0) } }
    //   : {}
    // const equipmentDiscount = this.calculation.additionalEquipment
    //   ? { equipmentDiscount: { required, minLength: minLength(0) } }
    //   : {}

    // const gpsTrackerValidator = (value) => this.hasGpsTracker ? value !== 2 : value === 2

    return {
      calculation: {
        ...agentId,
        ...customPlaceOfReg,
        ...truck,
        ...placeOfRegistration,
        ...leasedAssertEngine,
        ...bus,
        ...even,
        ...annuity,
        ...annuityPlus,
        ...common,
        ...customGraphicType,
        ...customUniversal,
        ...customStep,
        ...carAndTruck,
        ...patrolCardQuantity,
        ...beaconAmount,
        ...programFSN,
        maintenanceRequest: hasMaintenance,
        addEquipmentSum: this.calculation.additionalEquipment
          ? { required }
          : {},
        equipmentDiscount: this.calculation.additionalEquipment
          ? { required }
          : {},
        leasingAmount: { required },
        leasingCurrency: { required },
        leasingQuantity: { required },
        advance: { required },
        leasingTerm: { required },
        leasingRest: { required },
        assistService: { required },
        leasingStartDate: { required, minLength: minLength(10) },
        prenumerando: { required },
        holidays: { required },
        stock: { required },
        insuranceProgram: { required },
        insuranceFranchise: { required },
        insuranceCascoManipulate: { required },
        insuranceTerm: { required },
        rateManipulate: { required },
        infoDiscount: { required },
        rateReduction: { required },
        commisionManipulate: { required },
        hiddenCommisionGr: { required },
        commissionLk: { required },
        hiddenCommision: { required },
        commissionLkPr: { required },
        agentCommision: { required },
        additionalAb: { required },
        additionalAbPr: { required },
        gpsTracker: { required },
        gpsBeacon: { required },
        oneTimeCommission: { required },
        preFinancingDays: { required },
        preFinancingAmount: { required },
        currency: { required },
        isNew: { required },
        leasingObjectType: { required },
        leasedAssertMark: { required },
        leasedAssertModel: { required },
        leasingObjectYear: { required },
        generation: generation,
        body: { required },
        modification: modification,
        specification: {},
        leasingProgram: { required },
        leasingCurrencyCourse: { required },
        'сomment-income-lk': commentCommissionLk,
        'comment-commission-lk': commentIncomeLk,
        commentAB: commentAB,
      },
    }
  },
  data: () => ({
    calculation: {
      userId: null,
      leasingClientType: null,
      applicationId: null,
      applicationName: null,
      requestSource: null,
      commentManager: null,
      name: null,
      surname: null,
      patronymic: null,
      calculationId: null,
      leasingType: 'Фiнансовий',
      currency: 'UAH',
      leasingObjectType: { name: 'Легковий автомобіль', id: 1 },
      leasedAssertMark: null,
      leasedAssertModel: null,
      leasingObjectYear: null,
      body: null,
      generation: null,
      modification: null,
      specification: null,
      weight: null,
      truckMass: 1,
      engine: null,
      transmission: null,
      tire: null,
      tires: [],
      liftingCapacity: null,
      leasedAssertEngine: '',
      isOver20Seats: null,
      comment: null,
      placeOfRegistration: 1,
      placeOfRegistrationType: { id: 2, name: 'ТСЦ', value: 1 },
      registration: null,
      removalRegistration: null,
      annualExpenses: null,
      isNew: true,
      paymentPf: 1,
      leasingProgram: 1,
      programFSN: false,
      customerTypeObject: null,
      // compensation: null,
      // increaseAmount: null,
      workplaceType: null,
      uird: null,
      targetType: null,
      newCreated: false,
      advance: '30',
      leasingTerm: '12',
      addEquipmentSum: 0,
      equipmentDiscount: 0,
      equipSumWithDiscount: 0,
      leasingRest: '0',
      assistService: 1,
      vehicleOwnerTax: 2,
      leasingStartDate: null,
      maintenance: 1,
      maintenanceRequest: {},
      beaconAmount: '1',
      patrolCardsSupport: 2,
      leasingAmount: null,
      leasingCurrency: 'UAH',
      leasingQuantity: '1',
      leasingCurrencyCourse: '1.000',
      leasingAmountDkp: null,
      graphTypes: ['even', 'annuity'],
      gainEvenGraphicMonths: '0',
      gainEvenGraphicPercent: '0',
      unsprMonth: '8',
      unpga: '12',
      preFinancingDays: '0',
      preFinancingAmount: '0',
      prenumerando: 2,
      holidays: 2,
      notarized: 2,
      stock: 1,
      gpsBeacon: 1,
      gpsBeaconType: { name: 'Звичайний', value: 1, id: 2 },
      gpsTrackerType: {
        name: 'Antenor з блокуванням',
        value: 1,
        id: 1,
        isActual: true,
      },
      gpsTracker: 1,
      customGraphicType: 4,
      customUniversalOption: null,
      customStepOptionFirst: null,
      customStepOptionMiddle: null,
      decreaseAnnuity: null,
      seasonMnthArr: [],
      infoDiscount: '0',
      rateReduction: '0',
      rateManipulate: '0',
      commisionManipulate: '0',
      hiddenCommisionGr: '0',
      hiddenCommision: '0',
      commissionLk: '0',
      commissionLkPr: '0',
      agentCommision: '0',
      insuranceProgram: 1,
      insuranceFranchise: 4,
      insuranceCascoManipulate: '0',
      insuranceTerm: 1,
      proposalCurrency: 'UAH',
      proposalLang: 'Українська',
      financingAmount: null,
      manual: [],
      hasMaintenance: false,
      requestId: null,
      isAgreed: null,
      isSendOffer: null,
      dkpDiscount: '0',
      additionalEquipment: false,
      patrolCardQuantity: '0',
      oneTimeCommission: 1,
      preliminaryFinancing: 0,
      'сomment-income-lk': null,
      'comment-commission-lk': null,
      commentAB: null,
      isAgent: false,
      agentId: null,
      additionalAb: '0',
      additionalAbPr: '0',
      isVat: true,
    },
    calculationGeneralObject: {},
    grnTotalPrice: null,
    editCommisionLk: false,
    editIncomeLk: false,
    editCommisionAb: false,
    application: {},
    oldLPId: [12, 13],
    oldWorkLPId: [1, 12],
    corporativeID: 17,
    standartID: 1,
    currentTab: 0,
    loading: false,
    pageLoad: true,
    applyCorp: false,
    applyBenefits: false,
    applyProgramFsn: false,
    seasonsGraphLoading: false,
    uCSeven: { years: 7, programId: 12 },
    uCTwelve: { years: 12, programId: 13 },
    cache: null,
    carFsnMinSum: 600000,
    agriculturalFsnMinSum: 1000000,
    truckFsnProgramMinSum: 600000,
    equipmentFsnMinSum: 1000000,
    specialFsnMinSum: 1000000,
    airDronFsnMinSum: 440000,
    localNbuDeposit: null,
  }),
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
      user: state => state.user,
    }),
    calculationCache() {
      return setCache([this.calculation])
    },
    routeDu() {
      return this.$route.name.includes('du')
    },
    routeReadOnly() {
      return this.$route.query.frame === 'true'
    },
    readonly() {
      return (
        this.readOnly ||
        this.routeReadOnly ||
        (this.calculationGeneralObject.isExtraAgreement &&
          this.calculationGeneralObject.readOnly) ||
        (!this.calculationGeneralObject.isExtraAgreement &&
          !!this.calculation.isAgreed)
      )
    },
    standardType() {
      return this.car || this.truck || this.moto || this.bus
    },
    hasPatrolKpp() {
      return (
        !this.special &&
        !this.agricultural &&
        !this.airTransport &&
        !this.railwayTransport &&
        !this.equipment &&
        !this.commercialProperty &&
        !this.waterTransport &&
        !this.moto
      )
    },
    hasGpsTracker() {
      return (
        !this.equipment &&
        !this.commercialProperty &&
        !this.railwayTransport &&
        !this.airTransport &&
        ![
          172, 228, 244, 171, 174, 175, 176, 177, 178, 179, 181, 182, 183, 184,
          185, 186, 187, 188, 189, 190, 191, 192, 193, 194, 195, 196, 197, 198,
          199, 200, 201, 202, 203, 204, 206, 209, 210, 214, 215, 217, 218, 220,
          221, 223, 225, 226, 229, 230, 232, 233, 234, 235, 236, 237, 238, 239,
          240, 241, 242, 243, 345, 246, 247, 248, 249, 251, 256,
        ].includes(this.calculation.body?.typeId)
      )
    },
    canDisableGpsTracker() {
      const c = this.calculation
      const validLoType =
        c.leasingObjectType.id === 1 ||
        (c.leasingObjectType.id === 6 && c.truckMass === 1) ||
        (c.leasingObjectType.id === 6 &&
          c.truckMass === 2 &&
          c.liftingCapacity === 1)
      return c.isNew && validLoType && +c.advance >= 20
    },
    suitableLeasingObject() {
      return (
        !this.agricultural &&
        !this.special &&
        !this.airTransport &&
        !this.waterTransport &&
        !this.railwayTransport &&
        !this.equipment &&
        !this.commercialProperty &&
        !this.moto
      )
    },
    calculationRoute() {
      return (
        [
          this.routeEditCalculation,
          this.routeApplicationCreateCalculation,
          this.routeNewCalculation,
          this.routeApplicationEditCalculation,
          this.routeRiskManagerCalculation,
          this.newRoutes, // temporary
        ].includes(true) &&
        !this.routeApplicationNewPaymentPlan &&
        !this.routeApplicationEditPaymentPlan
      )
    },
    branch() {
      switch (true) {
        case this.routeDu:
          return 'du-edit'
        case this.routeEditCalculation:
          return 'edit'
        default:
          'create'
      }
      return this.matchedRoutes.includes('application-create-calculation')
        ? 'create'
        : 'edit'
    },
    isNew() {
      return this.calculation.isNew
    },
    harrow() {
      return this.calculation.body?.typeId === 172
    },
    cultivator() {
      return this.calculation.body?.typeId === 215
    },
    diskPeeler() {
      return this.calculation.body?.typeId === 217
    },
    trailedSprayers() {
      return this.calculation.body?.typeId === 226
    },
    plow() {
      return this.calculation.body?.typeId === 228
    },
    sowingComplex() {
      return this.calculation.body?.typeId === 232
    },
    balePickUpPress() {
      return this.calculation.body?.typeId === 233
    },
    pickUpPress() {
      return this.calculation.body?.typeId === 234
    },
    rollBaler() {
      return this.calculation.body?.typeId === 235
    },
    receivingHopper() {
      return this.calculation.body?.typeId === 236
    },
    springHarrow() {
      return this.calculation.body?.typeId === 237
    },
    seeder() {
      return this.calculation.body?.typeId === 244
    },
    car() {
      return this.calculation.leasingObjectType.id === CAR
    },
    moto() {
      return this.calculation.leasingObjectType.id === MOTO
    },
    waterTransport() {
      return this.calculation.leasingObjectType.id === WATER_TRANSPORT
    },
    special() {
      return this.calculation.leasingObjectType.id === SPECIAL
    },
    truck() {
      return this.calculation.leasingObjectType.id === TRUCK
    },
    airDronId() {
      return 170
    },
    airDron() {
      return this.calculation.body?.typeId === this.airDronId
    },
    truckMassThreeMore() {
      return this.calculation.truckMass === 2
    },
    truckMassUpToThree() {
      return this.calculation.truckMass === 1
    },
    busMoreTwenty() {
      return !!this.calculation.isOver20Seats
    },
    bus() {
      return (
        this.calculation.leasingObjectType.id === BUS ||
        this.calculation.leasingObjectType.id === 14
      )
    },
    autoHouse() {
      return this.calculation.leasingObjectType.id === AUTO_HOUSE
    },
    airTransport() {
      return this.calculation.leasingObjectType.id === AIR_TRANSPORT
    },
    agricultural() {
      return this.calculation.leasingObjectType.id === AGRICULTURAL
    },
    railwayTransport() {
      return this.calculation.leasingObjectType.id === RAILWAY_TRANSPORT
    },
    equipment() {
      return this.calculation.leasingObjectType.id === EQUIPMENT
    },
    commercialProperty() {
      return this.calculation.leasingObjectType.id === COMMERCIAL_PROPERTY
    },
    stepGain() {
      return this.calculation.customGraphicType === 3
    },
    universalGain() {
      return this.calculation.customGraphicType === 5
    },
    carObjType() {
      const id = this.calculation.leasingObjectType.id
      return id === CAR || id === TRUCK || id === BUS
    },
    calculateApplication() {
      return (
        !this.routeApplicationEditCalculation &&
        !this.routeApplicationCreateCalculation
      )
    },
    calculationId() {
      return parseInt(this.$route.params.calculationId)
    },
    calculationIdToApplication() {
      return parseInt(this.$route.params.applicationId) ?? this.applicationId
    },
    lPforUkrAuto() {
      if (
        [
          123, 131, 138, 41, 36, 37, 12, 285, 8, 53, 371, 16, 34, 46, 29, 136,
          134, 34, 451,
        ].includes(this.calculation?.leasedAssertMark?.id) &&
        (this.car || this.truck)
      ) {
        return true
      }
      return false
    },
    insuranceFranchises() {
      const franchises = getDirectory('insuranceFranchise')
      const taxi = this.calculation.insuranceProgram === 3
      const careful = this.calculation.insuranceProgram === 2
      const standart = this.calculation.insuranceProgram === 1
      const threeMoreTons = this.calculation.truckMass === 2
      const upToThreeTons = this.calculation.truckMass === 1

      switch (true) {
        case this.isNew && this.car && standart:
          return franchises.filter(f => [1, 3, 4, 9].includes(f.value))
        case this.isNew && this.car && taxi:
          return franchises.filter(f => [4].includes(f.value))
        case this.isNew && this.car && careful:
          return franchises.filter(f => [1, 3, 4].includes(f.value))
        case this.isNew && this.truck && standart && upToThreeTons:
          return franchises.filter(f => [1, 3, 4, 9].includes(f.value))
        case this.isNew && this.truck && careful && upToThreeTons:
          return franchises.filter(f => [1, 3, 4].includes(f.value))
        case this.isNew && this.truck && standart && threeMoreTons:
          return franchises.filter(f => [1, 3, 4, 9].includes(f.value))
        case this.isNew && this.truck && careful && threeMoreTons:
          return franchises.filter(f => [1, 3, 4].includes(f.value))
        case this.isNew && this.truck && taxi:
          return franchises.filter(f => [4].includes(f.value))
        case this.isNew && this.special && standart:
          return franchises.filter(f => [1, 3, 4, 9].includes(f.value))
        // case this.isNew && this.carTrailer && standart:
        //   return franchises.filter(f => [1, 3, 4, 9].includes(f.value))
        case this.isNew && this.bus && standart:
          return franchises.filter(f => [1, 3, 4, 9].includes(f.value))
        case this.isNew && this.railwayTransport && standart:
          return franchises.filter(f => [4].includes(f.value))
        case this.isNew && this.airTransport && standart:
          return franchises.filter(f => [4].includes(f.value))
        case this.isNew && this.waterTransport && standart:
          return franchises.filter(f => [10].includes(f.value))
        case this.isNew && this.commercialProperty && standart:
          return franchises.filter(f => [3, 4, 5, 7, 9].includes(f.value))
        case this.isNew && this.equipment && standart:
          return franchises.filter(f => [4].includes(f.value))
        case this.isNew && this.moto && standart:
          return franchises.filter(f => [4].includes(f.value))
        case this.car && standart:
          return franchises.filter(f => [1, 3, 4, 9].includes(f.value))
        case this.car && taxi:
          return franchises.filter(f => [4].includes(f.value))
        case this.car && careful:
          return franchises.filter(f => [3, 4, 9].includes(f.value))
        case this.truck && standart && upToThreeTons:
          return franchises.filter(f => [1, 3, 4, 9].includes(f.value))
        case this.truck && careful && upToThreeTons:
          return franchises.filter(f => [3, 4].includes(f.value))
        case this.truck && standart && threeMoreTons:
          return franchises.filter(f => [1, 3, 4, 9].includes(f.value))
        case this.truck && careful && threeMoreTons:
          return franchises.filter(f => [3, 4].includes(f.value))
        case this.truck && taxi:
          return franchises.filter(f => [4].includes(f.value))
        case this.agricultural && standart:
          return franchises.filter(f => [1, 3, 4, 9].includes(f.value))
        case this.special && standart:
          return franchises.filter(f => [1, 3, 4, 9].includes(f.value))
        // case this.carTrailer && standart:
        //   return franchises.filter(f => [1, 3, 4, 9].includes(f.value))
        case this.bus && standart:
          return franchises.filter(f => [1, 3, 4, 9].includes(f.value))
        case this.railwayTransport && standart:
          return franchises.filter(f => [4].includes(f.value))
        case this.commercialProperty && standart:
          return franchises.filter(f => [3, 4, 5, 7, 9].includes(f.value))
        case this.equipment && standart:
          return franchises.filter(f => [4].includes(f.value))
        default:
          return franchises
      }
    },
    routeApplicationCreateCalculation() {
      return this.matchedRoutes.includes('application-create-calculation')
    },
    routeEditCalculation() {
      return this.$route.name.includes('edit-calculator')
    },
    routeApplicationEditCalculation() {
      return this.matchedRoutes.includes('application-edit-calculation')
    },
    routeNewCalculation() {
      return this.$route.name.includes('create-calculator')
    },
    routeApplicationNewPaymentPlan() {
      return this.$route.name === 'application-new-payment-plan'
    },
    routeApplicationEditPaymentPlan() {
      return this.$route.name === 'application-payment-plan'
    },
    routeRiskManagerCalculation() {
      return this.$route.name === 'risk-department-calculation'
    },
    routePaymentPlan() {
      return (
        this.matchedRoutes.includes('application-new-payment-plan') ||
        this.matchedRoutes.includes('application-payment-plan')
      )
    },
    withoutInsurance() {
      return this.calculation.insuranceProgram === 5
    },
    newRoutes() {
      // temporary
      return [
        'edit-calculator-leasing-object',
        'edit-calculator-leasing-terms',
        'edit-calculator-additional-terms',
        'edit-calculator-service',
        'create-calculator-leasing-object',
        'create-calculator-leasing-terms',
        'create-calculator-additional-terms',
        'create-calculator-service',
      ].includes(this.$route.name)
    },
    calculationForm() {
      return (
        this.routeNewCalculation ||
        this.routeApplicationEditCalculation ||
        this.routeEditCalculation ||
        this.routeApplicationCreateCalculation ||
        this.routeRiskManagerCalculation
      )
    },
    matchedRoutes() {
      return this.$route.matched.map(r => r.name)
    },
    writable() {
      return !this.isSendOffer && !this.isAgreed
    },
    vuexUserId() {
      return this.$store.state.user.id
    },
    lpWithUkrAuto() {
      return getDirectory('leasingPrograms').filter(
        v => v.value === this.calculation.leasingProgram
      )
    },
    filteredLP() {
      if (this.calculation.leasingProgram === 37) return this.lpForProgramFsn
      if (this.calculation.leasingProgram === 34) return this.lpForBenefist
      if (this.corporativeST) return [this.corporativeLP]
      if (this.calculation.isNew && this.lPforUkrAuto) {
        const filtered = [
          ...this.lPforNewOL,
          ...getDirectory('leasingPrograms').filter(v => v.programId === 35),
        ]
        return filtered
      }

      if (this.oldWorkCarLOSt) return this.oldWorkCarLP
      // if (this.oldSpecialLPSt) return this.oldSpecialLP
      if (this.commercialProperty) return this.lPforNewOL
      // if (this.agricultural && !this.calculation.isNew) return [this.standardLP]
      if (!this.calculation.isNew) return this.oldCarLP
      if (this.newCarLOSt) return this.lPforNewOL
      if (this.newMotoLPSt) return this.lPforNewOL
      if (this.newEquipmentLOSt) return this.lPforNewOL
      if (this.truckLPSt && this.truckMoreThenThreeTons) {
        const filtered = this.lPforNewOL.filter(p => p.programId === this.newLO)
        filtered.push({ name: 'Стандарт', programId: 1 })
        // eslint-disable-next-line
        // !!this.newLO && (this.calculation.leasingProgram = 1)
        return filtered
      }
      if (this.truckLPSt) return this.lPforNewOL
      if (this.newAgriculturalSt) return this.newAgriculturalLP
      if (this.busesLPSt) return this.newBusLP
      if (this.newSpecialLPSt) return this.newSpecialLP
      if (this.newWaterLPSt) return this.lPforNewOL
      return this.lPforNewOL
    },
    marksLP() {
      if (!this.calculation?.leasedAssertMark?.name) return {}
      const mark = this.calculation.leasedAssertMark.name.toUpperCase()
      const currency = this.calculation.currency

      return {
        toyota: {
          state: mark.includes('TOYOTA'),
          programs: getDirectory('leasingPrograms')
            .filter(p => [5, 18].includes(p.programId))
            .filter(p => (currency !== 'UAH' ? p.programId === 5 : true)),
        },
      }
    },
    marksLpActive() {
      return !!Object.keys(this.marksLP).filter(
        m => this.marksLP[m].state && this.marksLP[m].programs.length
      ).length
    },
    lP() {
      if (this.applyCorp) return 17 // corp
      if (this.applyProgramFsn) return 37 // fsn
      if (this.applyBenefits) return 34 // benefit
      if (this.calculation.isNew && this.lPforUkrAuto) return 35 // ukrAuto
      if (this.commercialProperty) return this.newLO
      // if (this.agricultural && !this.calculation.isNew) return this.standardLP.programId
      if (!this.calculation.isNew) return this.oldCarLPV
      if (this.newCarLOSt) return this.newLO
      if (this.bus) return this.busMoreTwenty ? this.standartID : this.newLO
      if (this.newAirSt) return this.newLO
      if (this.newEquipmentLOSt) return this.newLO
      if (this.truckLPSt) return this.newLO
      if (this.newSpecialLPSt) return this.newLO
      if (this.newAgriculturalSt) return this.newLO
      if (this.newMotoLPSt) return this.newLO
      if (this.newWaterLPSt) return this.newYamahaLO
      return this.standardLP.programId
    },
    oldCarLPV() {
      // old car leasing program value
      if (
        [44, 29, 138].includes(this.calculation?.leasedAssertMark?.id) &&
        (this.car || this.truck)
      )
        return 36
      if (this.usedCarsSeven) return this.uCSeven.programId
      if (this.usedCarsTwelve) return this.uCTwelve.programId
      return 1
    },
    usedCarsSeven() {
      if (this.oldWorkCarLOSt) return this.yearsAmong > 10
      return this.yearsAmong <= /* used car 7 max value */ this.uCSeven.years
    },
    usedCarsTwelve() {
      if (this.oldWorkCarLOSt) return false
      return this.yearsAmong > /* used car greater then 7 */ this.uCSeven.years
    },
    currentYear() {
      return new Date().getFullYear()
    },
    // differece between current year and selected year + leasing term
    yearsAmong() {
      return (
        this.currentYear -
        this.calculation.leasingObjectYear +
        this.leasingTermYears
      )
    },
    leasingTermYears() {
      return Math.ceil(parseInt(this.calculation.leasingTerm || 0) / 12)
    },
    newCarLOSt() {
      return this.calculation.isNew && this.car
    },
    newAirSt() {
      return this.calculation.isNew && this.airTransport
    },
    newBusLpSt() {
      return this.calculation.isNew && this.bus
    },
    newWaterLPSt() {
      return this.calculation.isNew && this.waterTransport
    },
    newEquipmentLOSt() {
      // Leasing object-type states
      return this.calculation.isNew && this.equipment
    },
    truckLPSt() {
      return this.truck && this.calculation.isNew
    },
    newAgriculturalSt() {
      return this.calculation.isNew && this.agricultural
    },
    newMotoLPSt() {
      return this.calculation.isNew && this.moto
    },
    busesLPSt() {
      return this.bus && this.calculation.isNew
    },
    newSpecialLPSt() {
      return this.calculation.isNew && this.special
    },
    oldSpecialLPSt() {
      return !this.calculation.isNew && this.special
    },
    oldCarLP() {
      if (
        [44, 29, 138].includes(this.calculation?.leasedAssertMark?.id) &&
        (this.car || this.truck)
      ) {
        return getDirectory('leasingPrograms').filter(v =>
          [12, 13, 36].includes(v.programId)
        )
      } else
        return getDirectory('leasingPrograms').filter(v =>
          this.oldLPId.includes(v.programId)
        )
    },
    oldWorkCarLP() {
      return getDirectory('leasingPrograms').filter(v =>
        this.oldWorkLPId.includes(v.programId)
      )
    },
    newBusLP() {
      if (!this.busMoreTwenty) {
        return this.lPforNewOL
      }
      return getDirectory('leasingPrograms').filter(
        v =>
          v.programId === this.standartID ||
          v.name.toUpperCase() === (this.leasingAMTxt || '').toUpperCase()
      )
    },
    newYamahaLO() {
      const yamahaMarks = ['Yamaha', 'Yamarin', 'Yamarin Cross', 'Buster'].map(
        m => m.toUpperCase()
      )
      const mark = (
        this.calculation?.leasedAssertMark?.name || ''
      ).toUpperCase()
      let program

      if (yamahaMarks.includes(mark)) {
        program = getDirectory('leasingPrograms').find(
          p => p.name.toUpperCase() === yamahaMarks[0]
        )
      }
      return program?.programId || this.newLO
    },
    corporativeST() {
      return (
        this.applyCorp || this.calculation.leasingProgram === this.corporativeID
      )
    },
    corporativeLP() {
      return getDirectory('leasingPrograms').find(
        v => v.programId === this.corporativeID
      )
    },
    lpForBenefist() {
      return getDirectory('leasingPrograms').filter(v => v.programId === 34)
    },
    lpForProgramFsn() {
      return getDirectory('leasingPrograms').filter(v => v.programId === 37)
    },
    lPforNewOL() {
      // leasing programs for new leasing objects
      if (this.marksLP?.toyota?.state) return this.marksLP.toyota.programs
      if (this.marksLP?.toyota?.state) return this.marksLP.toyota.programs

      if (this.calculation.isNew && this.lPforUkrAuto) {
        return getDirectory('leasingPrograms').filter(
          v => v.programId === this.newLO
        )
      }
      return getDirectory('leasingPrograms').filter(
        v => !this.oldLPId.includes(v.programId)
      )
    },
    standardLP() {
      return getDirectory('leasingPrograms').find(
        v => v.programId === this.standartID
      )
    },
    fastLpSt() {
      return (this.leasingAMTxt || '').toUpperCase() === 'Fast'.toUpperCase()
    },
    newAgriculturalLP() {
      if (this.fastLpSt) {
        return getDirectory('leasingPrograms').filter(
          p => p.programId === this.newLO
        )
      }

      return getDirectory('leasingPrograms').filter(v =>
        this.selectItems.newSpecialId.includes(v.programId)
      )
    },
    hydromekLpSt() {
      return (
        (this.leasingAMTxt || '').toUpperCase() === 'Hidromek'.toUpperCase()
      )
    },
    hydromekLp() {
      return getDirectory('leasingPrograms').filter(
        p => p.name.toUpperCase() === 'Hidromek'.toUpperCase()
      )
    },
    currentUsedLp() {
      return getDirectory('leasingPrograms').filter(
        p => p.programId === this.oldCarLPV
      )
    },
    newSpecialLP() {
      if (this.hydromekLpSt) {
        return this.hydromekLp
      }

      return getDirectory('leasingPrograms').filter(v =>
        this.selectItems.newSpecialId.includes(v.programId)
      )
    },
    oldSpecialLP() {
      if (this.hydromekLpSt && !this.calculation.isNew) {
        return [...this.hydromekLp, ...this.currentUsedLp]
      }

      return this.oldCarLP
    },
    oldWorkCarLOSt() {
      return (
        !this.calculation.isNew &&
        (this.agricultural || this.truckMassThreeMore || this.special)
      )
    },
    newTrailerLO() {
      return this.standardLP?.programId
    },
    leasingAMTxt() {
      // leasing assert mark (string)
      return this.calculation.leasedAssertMark?.name || false
    },
    newLO() {
      if (!this.leasingAMTxt) return this.standardLP?.programId
      const lo = getDirectory('leasingPrograms').find(
        v => v.name === this.leasingAMTxt
      )
      return lo?.programId || this.standardLP?.programId
    },
    truckMoreThenThreeTons() {
      return this.calculation.truckMass === 2
    },
    totalPriceSum() {
      return parseFloat(keepDigit(this.grnTotalPrice || '0'))
    },
    carFsnProgramMinSum() {
      return this.totalPriceSum >= this.carFsnMinSum
    },
    agriculturalFsnProgramMinSum() {
      return this.totalPriceSum >= this.agriculturalFsnMinSum
    },
    equipmentFsnProgramSum() {
      return this.totalPriceSum >= this.equipmentFsnMinSum
    },
    truckFsnProgramSum() {
      return this.totalPriceSum >= this.truckFsnProgramMinSum
    },
    specialFsnProgramSum() {
      return this.totalPriceSum >= this.specialFsnMinSum
    },
    airDronFsnProgramSum() {
      return this.totalPriceSum >= this.airDronFsnMinSum
    },
    commisionLkPresent() {
      return (
        (!!this.calculation.commissionLkPr &&
          this.calculation.commissionLkPr !== '0') ||
        (!!this.calculation.commissionLk &&
          this.calculation.commissionLk !== '0')
      )
    },
    incomeLkPresent() {
      return (
        (!!this.calculation.hiddenCommision &&
          this.calculation.hiddenCommision !== '0') ||
        (!!this.calculation.hiddenCommisionGr &&
          this.calculation.hiddenCommisionGr !== '0')
      )
    },
    commisionAbPresent() {
      return (
        (!!this.calculation.additionalAbPr &&
          this.calculation.additionalAbPr !== '0') ||
        (!!this.calculation.additionalAb &&
          this.calculation.additionalAb !== '0')
      )
    },
  },
  methods: {
    hlErrors,
    keepDigit,
    formutSum,
    humanDate,
    objClearEpmtyField,
    calculationGet,
    calculationCreate,
    setBreadScrumb() {
      const lastRoute = this.$route.matched[this.$route.matched.length - 1]

      this.routeApplicationCreateCalculation &&
        this.$store.commit(
          'setBreadScrumbPart',
          [null, this.calculation.applicationName, null],
          false
        )
      this.routeApplicationEditCalculation &&
        this.$store.commit(
          'setBreadScrumbPart',
          [
            null,
            this.calculation.applicationName,
            lastRoute ? this.calculation.requestId : null,
          ],
          false
        )
    },
    setGpsTracker(typeId) {
      this.calculation.gpsTracker = typeId || this.calculation.gpsTracker
    },
    setStartPaymentDate() {
      if (!this.calculation?.leasingStartDate) {
        let day = new Date().getDate()
        let month = new Date().getMonth() + 1
        const year = new Date().getFullYear().toString()
        day > 28 && (day = 28)
        day < 10 && (day = '0' + day)
        month < 10 && (month = '0' + month)
        this.calculation.leasingStartDate = `${year}-${month}-${day}`
      }
    },
    changeLP(val) {
      setTimeout(() => {
        console.log('changeLP', val, this.lP)
        this.calculation.leasingProgram = val || this.lP
      }, 100)
    },
    submit() {
      if (this.v$.$invalid) {
        this.v$.$anyError
        this.v$.$touch()
        v$Notify(
          { ...this.v$.calculation, ...this.v$.calculation.maintenanceRequest },
          'calculation'
        )
      } else this.calculate()
    },
    getFinancingAmount() {
      if (this.v$.$invalid) {
        this.v$.$anyError
        this.v$.$touch()
        v$Notify(this.v$.calculation, 'calculation')
        return
      }
      const object = this.parseObject(this.calculation)
      this.$store.commit('setDialog', {
        title: 'Сезонний графiк',
        dialogItem: {
          localNbuDeposit: this.localNbuDeposit,
          calculationGeneralObject: this.calculationGeneralObject,
          calculation: {
            ...object,
            grnTotalPrice: this.grnTotalPrice,
          },
        },
        disabled: true,
        params: {
          dialogWidth: 950,
          submit: this.calculate,
          $data: this.calculation,
          cb: data => data,
        },
        action: 'splitAmountOfFunding',
      })
    },
    calculate(object) {
      const curGpsTrackerType = +this.calculation.gpsTracker
      const gpsTrackerArr = getDirectory(
        'gpsTrackerSelect',
        this.calculation.gpsTrackerType,
        {
          type: 'collection',
        }
      )
      const selectedTracker = gpsTrackerArr.getItem(curGpsTrackerType)

      if (!selectedTracker?.isActual) {
        return this.$setSnackbar({
          text: 'Замініть Gps-Tracker в калькуляції на актуальний',
          color: 'warning',
        })
      }
      this.loading = true
      const obj = this.parseObject(this.calculation)

      if (!this.writable) {
        obj.requestId = null
        obj.calculationId = null
      }

      if (obj.hasMaintenance) {
        obj.maintenanceRequest.maintenanceId = obj.modification.maintenanceId
        obj.maintenanceRequest.intervalStep =
          obj.modification.maintenance.intervalStep
        if (!obj.maintenanceRequest.hasTire) {
          delete obj.maintenanceRequest.tireSize
          delete obj.maintenanceRequest.tireSets
          delete obj.maintenanceRequest.tiresCatalog
          delete obj.maintenanceRequest.tireCost
          delete obj.maintenanceRequest.tireFitting
          delete obj.maintenanceRequest.tireStorage
        }
        if (!obj.maintenanceRequest.replacementCarSupport) {
          delete obj.maintenanceRequest.replacementCarClass
          delete obj.maintenanceRequest.numberDays
        }
      } else {
        obj.maintenanceRequest = null
      }

      obj.calculationId = this.calculationGeneralObject.id
      obj.gpsTracker = this.calculation.gpsTrackerType?.id
      if (this.applyProgramFsn) {
        obj.uird = this.localNbuDeposit
        obj.workplace = this.calculation.workplaceType?.id
        obj.target = this.calculation.targetType?.id
        obj.customerType = this.calculation.customerTypeObject?.id
      } else {
        delete obj.uird
      }

      if (!this.hasGpsTracker) {
        obj.gpsTracker = 2
      }

      return this.$axios
        .post(this.calculateUrl || this.calculationCreate(), object || obj)
        .then(res => {
          this.loading = false
          this.assignData(res)
          this.$setSnackbar({
            text: `Калькуляцiю успiшно ${
              this.routeEditCalculation ? 'оновлено' : 'створено'
            } `,
          })
          setTimeout(() => {
            if (!this.routeDu) {
              this.$router.push({
                name: this.getRouteToHistory(),
                params: { calculationId: res.data.id },
              })
            } else {
              this.$emit('calculate', res.data)
            }
          }, 1000)
          return res
        })
        .catch(err => this.$err(err, () => (this.loading = false)))
    },
    getRouteToHistory() {
      // if (this.matchedRoutes.includes('application-edit-calculation')) return 'application-new-payment-plan'
      if (this.matchedRoutes.includes('application-edit-calculation'))
        return 'application-payment-plan'
      else if (this.matchedRoutes.includes('application-create-calculation'))
        return 'application-payment-plan'
      else return 'new-payment-plan'
    },
    parseObject(dataObj, init) {
      const obj = this.objClearEpmtyField(dataObj)
      this.handleProperties(
        obj,
        init ? this.parseToString : this.parseToInt,
        init ? toClientInt : toInt
      )
      return obj
    },
    parseToInt(prop) {
      return parseFloat(this.keepDigit(prop))
    },
    parseToString(prop, float) {
      return getBeautyNum(prop, { float: float || 'auto' })
    },
    handleProperties(o, cb, propsToType) {
      if (typeof cb !== 'function') return
      for (const prop of propsToType) {
        const propName = prop.name || prop
        const value = get(o, propName)
        if (value || [0, '0'].includes(value)) {
          update(o, propName, v => cb(v, prop.float))
        }
      }
      return o
    },
    highLightErr(v) {
      if (!this.$refs[v]) return
      console.log({ [v]: this.$refs[v].v$ })
      // eslint-disable-next-line
      this.$refs[v].hasOwnProperty('showInputs')
        ? (this.$refs[v].showInputs = true)
        : false

      setTimeout(() => {
        this.hlErrors(this.$refs[v].v$, '#' + v)
      }, 0)
    },
    getUserCalculation() {
      if (this.calculationData) {
        return this.assignData({ data: this.calculationData })
      }
      const id = this.calculationId
      if (!id) return

      this.loading = true

      return this.$axios
        .get(this.calculationGet(id))
        .then(this.assignData)
        .catch(err => this.$err(err, () => (this.loading = false)))
    },
    setOldModification() {
      const engine = (
        (this.calculation.leasedAssertEngine || 0) / 1000
      ).toFixed(1)
      const patrol = this.calculation.engine?.type?.name
      const kpp = this.calculation.transmission?.name
      this.calculation.modification = {
        id: 0,
        name: [engine, patrol, kpp].filter(Boolean).join(' '),
      }
    },
    assignData(res) {
      this.setStartPaymentDate()
      this.calculationGeneralObject = res.data
      res.data.requestData.maintenanceRequest ??= {}
      Object.assign(
        this.calculation,
        this.parseObject(res.data.requestData, true)
      )
      this.calculation.requestId = res.data.requestId
      this.calculation.isSendOffer = res.data.isSendOffer
      this.calculation.isAgreed = res.data.isAgreed
      if (!this.calculation.modification && this.standardType) {
        this.setOldModification()
      }
      this.loading = false
      this.changeLP(this.calculation.leasingProgram)
      this.syncWithApplication(res.data.application)
      if (this.calculation.leasingProgram === 17) {
        this.applyCorp = true
      }
      if (this.calculation.leasingProgram === 34) {
        this.applyBenefits = true
      }
      if (this.calculation.leasingProgram === 37) {
        this.applyProgramFsn = true
      }

      this.cache = setCache([this.calculation])
      return res
    },
    setUserId() {
      if (!this.vuexUserId) return
      this.calculation.userId = this.vuexUserId
    },
    syncWithApplication(application) {
      Object.assign(this.application, application.$object || application)
      this.calculation.requestSource = this.application.sourceTypeId
      this.calculation.applicationName = this.application.applicationName
      this.calculation.commentManager = this.application.comment
      this.calculation.name = this.application.name
      this.calculation.surname = this.application.surname
      this.calculation.patronymic = this.application.patronymic
      if (this.routeNewCalculation && this.application.sourceTypeId === 1) {
        this.calculation.isAgent = true
        this.calculation.agentId = this.application.sourceLinkAgentId
        this.calculationGeneralObject.agent = this.application.agent
      }
      if (this.routeEditCalculation || this.routeApplicationEditCalculation)
        this.calculation.calculationId = parseInt(
          this.$route.params.calculationId
        )
      if (!this.routePaymentPlan) {
        this.setBreadScrumb()
      }
    },
    clearFsn() {
      if (!this.routeApplicationCreateCalculation && this.applyProgramFsn) {
        console.log('clear')
        this.calculation.customerTypeObject = null
        // this.calculation.uird = null
        this.calculation.workplaceType = null
        this.calculation.targetType = null
        this.calculation.newCreated = false
      }
    },
    setFsnDefaultValues() {
      this.calculation.uird = this.localNbuDeposit
      this.calculation.workplaceType = null
      this.calculation.customerTypeObject = null
      this.calculation.targetType = null
      this.calculation.newCreated = false
      this.calculation.leasingTerm = '12'
      this.calculation.graphTypes.splice(0)
      this.calculation.graphTypes.push('irregular', 'even', 'annuity')
      this.calculation.customGraphicType = 4
      this.calculation.customUniversalOption = null
      this.calculation.insuranceProgram = 1
      this.calculation.stock = 1
      this.calculation.oneTimeCommission = 1
      this.calculation.unsprMonth = '8'
      this.calculation.agentCommision = '0'
      this.calculation.dkpDiscount = '0'
    },
    getNbuDeposit() {
      return this.$axios
        .get(urlGetNbuDeposit(), {
          headers: { 'X-API-SECRET': import.meta.env.VITE_ERM_API_SECRET },
        })
        .then(res => {
          if (res.data) {
            if (this.routeNewCalculation) {
              this.calculation.uird = res.data['3month']
            }
            this.localNbuDeposit = res.data['3month']
          }
        })
    },
  },
  watch: {
    'calculation.customGraphicType': function (val) {
      if (val && (val === 7 || val?.id === 7)) {
        this.calculation.unsprMonth === '12'
      }
    },
    'calculation.hasMaintenance': function (val) {
      if (val) {
        this.calculation.maintenance = 2
      } else if (this.carObjType) {
        this.calculation.maintenance = 1
      }
    },
    'calculation.gpsBeaconType': function (val) {
      if (val) {
        this.calculation.gpsBeacon = val?.value
      }
    },
    carObjType(v) {
      if (!v && this.calculation.hasMaintenance) {
        this.calculation.hasMaintenance = false
      }
    },
    suitableLeasingObject: function (state) {
      if (!state) {
        this.calculation.hasMaintenance = false
        this.$route.name === 'create-calculator-service' && this.$router.go(-1)
      }
    },
    '$route.name': function () {
      this.setBreadScrumb()
    },
    vuexUserId(val) {
      if (val) this.setUserId()
    },

    canDisableGpsTracker(val) {
      !val &&
        this.hasGpsTracker &&
        this.calculation.gpsTracker === 2 &&
        this.setGpsTracker(null)
    },
    commisionLkPresent: function (val) {
      if (!val) this.calculation['comment-commission-lk'] = null
      this.editCommisionLk = val
    },
    incomeLkPresent: function (val) {
      if (!val) this.calculation['сomment-income-lk'] = null
      this.editIncomeLk = val
    },
    commisionAbPresent: function (val) {
      if (!val) this.calculation.commentAB = null
      this.editCommisionAb = val
    },
  },
  async created() {
    try {
      this.calculation.applicationId = parseInt(
        this.$route.params.applicationId
      )
      this.getNbuDeposit()
      if (this.routeNewCalculation) {
        if (!this.applicationData?.id && this.$route.params.applicationId) {
          return await this.$router.push({
            name: 'edit-application',
            params: { applicationId: this.$route.params.applicationId },
          })
        }
        this.applicationData && this.syncWithApplication(this.applicationData)
      }
      await Promise.all([
        fillDirectory([
          'leasingPrograms',
          'gpsTrackerSelect',
          'insuranceFranchise',
        ]),
        this.$store.commit('setCustomBreadScrumbState', true),
        this.getUserCalculation(),
      ]).then(async () => {
        this.setUserId()
        this.$watch('calculation.leasingObjectType', () => {
          this.hasGpsTracker ? this.setGpsTracker(1) : this.setGpsTracker(2)
          if (this.carObjType && !this.calculation.hasMaintenance) {
            this.calculation.maintenance = 1
          } else this.calculation.maintenance = 2
        })
        this.$watch(
          'calculation.body',
          () =>
            this.hasGpsTracker ? this.setGpsTracker(1) : this.setGpsTracker(2),
          { deep: true }
        )
      })
      if (!this.calculation?.leasingStartDate) {
        this.setStartPaymentDate()
      }

      this.cache = setCache([this.calculation])
      this.pageLoad = false
    } catch (err) {
      this.$err(err)
    }
  },
  mounted() {
    this.setBreadScrumb()
  },
}
</script>
<style lang="scss" scoped>
.section-header {
  padding-top: 1.2rem;
  padding-bottom: 0.5rem;
  font-size: 0.8rem;
  color: #000000b4;
  font-weight: bold;
  text-decoration: underline;
}
button.v-btn.v-tab-item--selected.v-tab--selected.v-theme--light.text-white.v-btn--density-default.rounded-0.v-btn--size-default.v-btn--variant-text.v-tab {
  background: #f2f5fa;
  border: 1px solid #1c1d21;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  border-radius: 4px !important;
  color: #09487a !important;
  font-weight: 800;
}
button.v-btn.v-theme--light.v-btn--density-default.rounded-0.v-btn--size-default.v-btn--variant-text.v-tab {
  margin-right: 25px;
  border: 1px solid #eaeaea;
  background: #eaeaea;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
}
.v-tabs {
  & a.v-tab {
    margin-right: 25px;
    border: 1px solid #eaeaea;
    background: #eaeaea;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
  }
  &--mobile {
    padding-right: 12px;
    a.v-tab {
      margin-right: 0;
      margin-bottom: 12px;
      min-width: 100%;
    }
  }
}
</style>
