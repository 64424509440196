<template>
  <v-fade-transition hide-on-leave>
    <div v-if="!loading" class="content-wrapper verification-card">
      <div
        class="d-flex align-center justify-space-between white-block"
        style="margin-bottom: 10px">
        <div
          class="d-flex align-center justify-space-between"
          style="
            background: #fff;
            margin-bottom: 10px;
            margin-top: -20px;
            padding-top: 25px;
          ">
          <div class="pb-0 d-flex">
            <div class="data-card">
              <span style="font-size: 13px; color: #838282" class="pb-1 d-block"
                >Тип звернення</span
              >
              <span class="subtitle" style="font-size: 14px !important"
                >{{
                  sourceTypeId === 3
                    ? 'Повторне звернення'
                    : 'Первинне звернення'
                }}
              </span>
            </div>
            <div class="data-card">
              <span style="font-size: 13px; color: #838282" class="pb-1 d-block"
                >Дата звернення</span
              >
              <span class="subtitle" style="font-size: 14px !important">{{
                humanDate(formatDate(FDAnalyze?.created_at, true))
              }}</span>
            </div>
            <div v-if="showBenefit" class="data-card">
              <v-tooltip bottom text="BEST BENEFITS">
                <template #activator="{ props }">
                  <Benefit width="44px" height="44px" v-bind="props" />
                </template>
                <span>BEST BENEFITS</span>
              </v-tooltip>
            </div>
          </div>
        </div>
        <div class="d-flex align-center">
          <div v-if="FDAnalyze.histories" class="mr-4">
            <v-tooltip left text="Попередні рішення по клієнту">
              <template #activator="{ props }">
                <v-btn
                  icon
                  size="small"
                  v-bind="props"
                  @click="toggleHistoryDialog">
                  <v-icon style="color: rgb(252, 114, 71)">{{
                    'mdi-badge-account-horizontal'
                  }}</v-icon>
                </v-btn>
              </template>
              <span>Попередні рішення по клієнту</span>
            </v-tooltip>

            <v-dialog v-model="historyDialog" width="450">
              <v-card>
                <div class="d-flex justify-end">
                  <v-btn size="small" icon @click="toggleHistoryDialog">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      viewBox="0 0 18 19"
                      fill="none">
                      <path
                        d="M11.7693 12.2692L6.23084 6.73078M6.23084 12.2692L11.7693 6.73078M17.3078 9.50001C17.3078 4.91348 13.5866 1.19232 9.00007 1.19232C4.41354 1.19232 0.692383 4.91348 0.692383 9.50001C0.692383 14.0866 4.41354 17.8077 9.00007 17.8077C13.5866 17.8077 17.3078 14.0866 17.3078 9.50001Z"
                        stroke="#C0C1C7"
                        stroke-linecap="round" />
                    </svg>
                  </v-btn>
                </div>
                <v-card-text>
                  <div
                    v-for="h in FDAnalyze.histories"
                    :key="h.id"
                    style="cursor: pointer"
                    class="d-flex align-center mb-3">
                    <LinkedIcon class="mr-2" />
                    <span class="hyper-link" @click="handleHistoryClick(h)">
                      {{
                        `Перевірка клієнта за ${toFormatDate(h.created_at, {
                          dateOnly: true,
                        })}`
                      }}
                    </span>
                  </div>
                  <span v-if="!FDAnalyze.histories.length"
                    >Попередніх рішень немає</span
                  >
                </v-card-text>
              </v-card>
            </v-dialog>
          </div>
          <TableCrud
            data-readonly="hide"
            :actionsArray="[
              {
                action: takeToWork,
                text: 'Взяти в роботу',
                icon: 'mdi-timer-outline',
                disabled: [2, 3, 4].includes(FDAnalyze.statusId),
              },
              {
                action: revision,
                text: 'Відправити на доопрацювання',
                icon: 'mdi-timer-off-outline',
                disabled: [1, 3, 4].includes(FDAnalyze.statusId),
              },
            ]">
          </TableCrud>
        </div>
      </div>
      <component
        :is="contractorType ? 'ContractorCard' : 'ContactCard'"
        :contact="contact"
        :contractor="contractor"
        :isNew="FDAnalyze.status?.id === 1 && roleAccess"
        legalDocs
        marriedDocs
        finDocs
        showKbvDocs
        showSignatories
        :showEmployees="false"
        :showAuthorizedCapitalFull="false">
        <template #append-legal-docs>
          <FilesUploader
            v-model:documents="slider.ownershipStructure"
            label="Структура власності у вигляді окремої схеми"
            single
            readonly>
          </FilesUploader>
        </template>
        <template #after>
          <FilesUploader
            v-model:documents="slider.applicationForm"
            label="Заявка-анкета"
            single
            readonly>
          </FilesUploader>
          <FilesUploader
            v-model:documents="slider.questionnaire"
            label="Опитувальник"
            single
            readonly>
          </FilesUploader>
        </template>
      </component>
      <div class="white-block" style="margin-top: 10px">
        <span class="card-label d-block" style="margin-bottom: -20px"
          >Відповідальні:</span
        >
        <div class="section-card">
          <div>
            <v-row>
              <v-col :cols="12" :sm="3"> </v-col>
              <v-col :cols="12" :sm="9" class="text-start">
                <span class="card-sublable mr-1">Фронт менеджер:</span>
                <span class="card-value">{{ frontManager }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col :cols="12" :sm="3"> </v-col>
              <v-col :cols="12" :sm="9" class="text-start">
                <span class="card-sublable mr-1">Мiдл менеджер:</span>
                <span class="card-value">{{ middleManager }}</span>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>

      <div class="white-block" style="margin-top: 10px">
        <v-row class="align-center">
          <v-col :cols="12" :sm="3">
            <div>
              <svg
                class="mr-2"
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none">
                <path
                  d="M3.34961 9.18075C3.23568 9.18075 3.1263 9.15796 3.02148 9.11239C2.91667 9.06681 2.82096 9.00301 2.73438 8.92098L0.25293 6.43953C0.0843099 6.27091 0 6.06584 0 5.8243C0 5.70125 0.0227865 5.58732 0.0683594 5.4825C0.113932 5.37769 0.175456 5.28654 0.25293 5.20907C0.330404 5.13159 0.423828 5.07007 0.533203 5.0245C0.642578 4.97892 0.75651 4.95614 0.875 4.95614C0.99349 4.95614 1.10514 4.97664 1.20996 5.01766C1.31478 5.05867 1.41048 5.12248 1.49707 5.20907L3.97168 7.68367C4.05371 7.76571 4.11523 7.85913 4.15625 7.96395C4.19727 8.06877 4.22005 8.1827 4.22461 8.30575C4.22461 8.42423 4.20182 8.53589 4.15625 8.64071C4.11068 8.74552 4.04915 8.83895 3.97168 8.92098C3.89421 9.00301 3.80078 9.06681 3.69141 9.11239C3.58203 9.15796 3.4681 9.18075 3.34961 9.18075ZM8.29883 4.23153C8.1849 4.23153 8.07552 4.20874 7.9707 4.16317C7.86589 4.11759 7.77018 4.05379 7.68359 3.97176L5.20898 1.49715C5.03581 1.32397 4.94922 1.11662 4.94922 0.875081C4.94922 0.752034 4.97201 0.638102 5.01758 0.533284C5.06315 0.428467 5.12467 0.337321 5.20215 0.259847C5.27962 0.182373 5.37305 0.118571 5.48242 0.0684408C5.5918 0.0183105 5.70573 -0.00447591 5.82422 8.13802e-05C6.06576 8.13802e-05 6.27083 0.0843913 6.43945 0.253011L8.9209 2.73446C9.00293 2.81649 9.06445 2.90991 9.10547 3.01473C9.14648 3.11955 9.16927 3.23348 9.17383 3.35653C9.17383 3.47502 9.15104 3.58667 9.10547 3.69149C9.0599 3.7963 8.99837 3.88973 8.9209 3.97176C8.84342 4.05379 8.75 4.11759 8.64062 4.16317C8.53125 4.20874 8.41732 4.23153 8.29883 4.23153ZM4.74414 6.90438L2.26953 4.42977L4.42969 2.26961L6.9043 4.74422L4.74414 6.90438ZM13.248 12.6329C13.3346 12.7195 13.3779 12.822 13.3779 12.9405C13.3779 13.059 13.3346 13.1615 13.248 13.2481C13.1615 13.3347 13.0589 13.378 12.9404 13.378C12.8219 13.378 12.7194 13.3347 12.6328 13.2481L6.13184 6.75399L6.75391 6.13192L13.248 12.6329ZM0 13.1251H7V14.0001H0V13.1251ZM0.875 11.3751H6.125V12.2501H0.875V11.3751Z"
                  fill="#08487A" />
              </svg>
              <span
                style="
                  color: rgb(8, 72, 122);
                  font-weight: 500;
                  font-size: 15px;
                "
                >Висновок</span
              >
            </div>
          </v-col>
          <v-col :cols="12" :sm="9" class="text-start">
            <span style="color: #838282; font-weight: 500; font-size: 13px"
              >Рівень ризику</span
            >
            <v-row>
              <v-col :cols="12" :md="4">
                <v-select
                  v-model="FDAnalyzeData.financialRiskLevelId"
                  :error-messages="financialRiskLevelIdErr"
                  :items="
                    $directory.get(
                      'financialRiskLevels',
                      FDAnalyzeData.financialRiskLevel
                    )
                  "
                  item-title="name"
                  item-value="id"
                  hide-details
                  placeholder="Оберіть зi списку"
                  :readonly="!isInWork"
                  :disabled="!isInWork"
                  :loading="$loading.isLoading('financialRiskLevels')"
                  @focus="$directory.fill('financialRiskLevels')"
                  @blur="v.FDAnalyze.financialRiskLevelId.$touch()">
                </v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>

      <div class="white-block" style="margin-top: 10px">
        <v-row class="align-center">
          <v-col cols="12" md="3" sm="12">
            <span class="card-label">Санкційні документи:</span>
          </v-col>
          <v-col cols="12" md="9" sm="12">
            <span class="card-sublable d-block mb-2" style="font-size: 13px"
              >Підтвердження наявністі/відсутністі в санкційних списках:</span
            >
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <div class="d-flex flex-column">
                  <span
                    class="card-sublable mr-3"
                    style="font-size: 13px; color: #000"
                    >Клієнт</span
                  >
                  <FilesUploader
                    v-model:documents="FDAnalyzeData.clientSanctionDocument"
                    style="width: 100%"
                    label="Документ"
                    required
                    :disabled="[1, 3, 4].includes(FDAnalyze.statusId)"
                    :readonly="[1, 3, 4].includes(FDAnalyze.statusId)">
                  </FilesUploader>
                </div>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <div class="d-flex flex-column">
                  <span
                    class="card-sublable mr-3"
                    style="font-size: 13px; color: #000"
                    >Постачальник</span
                  >
                  <FilesUploader
                    v-model:documents="FDAnalyzeData.providerSanctionDocument"
                    style="width: 100%"
                    label="Документ"
                    required
                    :disabled="[1, 3, 4].includes(FDAnalyze.statusId)"
                    :readonly="[1, 3, 4].includes(FDAnalyze.statusId)">
                  </FilesUploader>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>

      <div class="white-block" style="margin-top: 10px">
        <v-row>
          <v-col :cols="12" :sm="3">
            <span class="card-label">Виявлені критерії ризику:</span>
          </v-col>
          <v-col :cols="12" :sm="9" class="text-start">
            <v-textarea
              v-model="FDAnalyzeData.financialRiskAssessment"
              class="pt-0"
              :rows="3"
              hide-details
              placeholder="Введіть текст"
              :readonly="!isInWork">
            </v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="12" :sm="3">
            <span class="card-label">Коментарі:</span>
          </v-col>
          <v-col :cols="12" :sm="9" class="text-start">
            <v-textarea
              v-model="FDAnalyzeData.financialRiskAssessmentNote"
              class="pt-0"
              :rows="3"
              hide-details
              placeholder="Введіть текст"
              :readonly="!isInWork">
            </v-textarea>
          </v-col>
        </v-row>
      </div>

      <v-card-actions class="d-flex justify-start pb-5 pt-3">
        <v-btn
          data-readonly="hide"
          variant="outlined"
          color="green"
          :loading="submitLoading || loadingRef"
          :disabled="[1, 3, 4].includes(FDAnalyze.statusId)"
          @click="submit">
          Завершити аналіз
        </v-btn>
      </v-card-actions>
    </div>
  </v-fade-transition>
</template>

<script>
import ContactCard from '@/components/ContactCard.vue'
import ContractorCard from '@/components/ContractorCard.vue'
import FilesUploader from '@/components/FilesUploader.vue'

import {
  urlTakeToWorkFinancialMonitoring,
  urlSetFinancingMonitoringStatus,
  urlSetFinancingMonitoringResult,
  urlUploadFinancingMonitoringSanctionDocument,
} from '@/pages/request'
import { setErrHandler, v$Notify, toFormatDate } from '@/utils/helperFunc'

import { formatDate, humanDate } from '@/utils/formatFunc'
import oneCIcon from '@/assets/svg/oneCIcon.vue'
import EyeIcon from '@/assets/svg/eye-icon.vue'
import OpenableList from '@/components/OpenableList.vue'
import ALink from '@/components/Link.vue'
import LinkedIcon from '@/assets/svg/linked-icon.vue'
import Benefit from '@/assets/svg/benefit.vue'
import { mapState } from 'vuex'
import { useSelect } from '@/utils/mixins/useSelect'
import TableCrud from '@/components/table-crud.vue'

export default {
  emits: ['get'],
  components: {
    TableCrud,
    Benefit,
    LinkedIcon,
    ALink,
    OpenableList,
    EyeIcon,
    oneCIcon,
    FilesUploader,
    ContactCard,
    ContractorCard,
  },
  setup() {
    return {
      ...useSelect(),
    }
  },
  props: {
    loading: { type: Boolean, default: false },
    submitLoading: { type: Boolean, default: false },
    acceptDocumentsLoading: { type: Boolean, default: false },
    documentsAccepted: { type: Boolean, default: false },
    FDAnalyze: { type: Object },
    financialRiskAssessment: { type: [Object, String] },
    financialRiskLevelCache: { type: Number },
    id: { type: String },
    v: { type: Object },
  },
  data: () => ({
    loadingRef: false,
    historyDialog: false,
    changedDocuments: {},
  }),
  computed: {
    ...mapState({
      user: state => state.user,
    }),
    roleAccess() {
      return [2, 12, 19].includes(this.user?.role?.id)
    },
    project() {
      return this.FDAnalyze.project
    },
    slider() {
      return this.FDAnalyze?.project?.slider
    },
    contact() {
      return this.client
    },
    isInWork() {
      return this.FDAnalyze?.statusId === 2
    },
    FDAnalyzeData() {
      return this.FDAnalyze
    },
    sourceTypeId() {
      return this.FDAnalyze?.project?.slider?.application?.sourceTypeId
    },
    client() {
      return this.FDAnalyze?.project?.lessee
    },
    contractor() {
      return {
        ...(this.client || {}),
        ownershipStructure: this.slider?.ownershipStructure,
      }
    },
    contractorType() {
      return this.FDAnalyze?.project?.leasingClientType === 2
    },
    financialRiskLevelIdErr() {
      return this.setErrHandler(this.v.FDAnalyze.financialRiskLevelId)
    },
    frontManager() {
      const m = this.project?.frontManagerResponsible.fullName
      if (!m) return
      return m
    },
    middleManager() {
      const m = this.project?.middleManagerResponsible.fullName
      if (!m) return
      return m
    },
    showBenefit() {
      if (
        this.FDAnalyze?.project &&
        this.FDAnalyze?.project?.slider?.calculations
      ) {
        return this.FDAnalyze?.project?.slider?.calculations.some(
          t => t.leasingProgram === 34
        )
      }
      return false
    },
  },
  methods: {
    toFormatDate,
    formatDate,
    humanDate,
    setErrHandler,
    urlTakeToWorkFinancialMonitoring,
    urlSetFinancingMonitoringStatus,
    revision() {
      const serviceObj = {
        url: this.urlSetFinancingMonitoringStatus(this.FDAnalyze.id),
        name: 'finance',
      }
      return this.$store.commit('setDialog', {
        title: 'Відправити на доопрацювання',
        dialogItem: { serviceObj },
        params: {
          cb: () => this.$emit('get'),
        },
        action: 'sendToRevision',
      })
    },
    toggleHistoryDialog() {
      this.historyDialog = !this.historyDialog
    },
    handleHistoryClick(h) {
      this.$store.commit('refreshPage')

      this.$router.push({
        name: 'fd-project-verification',
        params: { id: `${h.financialMonitoringId}` },
      })
    },
    takeToWork() {
      return this.$axios
        .get(this.urlTakeToWorkFinancialMonitoring(this.id))
        .then(res => {
          if (res.data.message) {
            this.$setSnackbar({ text: res.data.message, color: 'warning' })
          } else {
            this.$setSnackbar({ text: 'Статус: "В роботi"', color: 'warning' })
            this.$emit('get')
          }
          return res
        })
        .catch(this.$err)
    },
    uploadSanctionDocuments(changedDocuments) {
      const req = {}

      if ('clientSanctionDocument' in changedDocuments) {
        req.clientSanctionDocument = changedDocuments.clientSanctionDocument
      }

      if ('providerSanctionDocument' in changedDocuments) {
        req.providerSanctionDocument = changedDocuments.providerSanctionDocument
      }

      return this.$axios
        .post(
          urlUploadFinancingMonitoringSanctionDocument(this.FDAnalyze.id),
          req
        )
        .then(res => {
          if (res.data.message) {
            this.$setSnackbar({ text: res.data.message, color: 'warning' })
          } else return this.$setSnackbar({ text: 'Документи відредаговано' })
        })
        .catch(this.$err)
    },
    submit() {
      if (this.v.$invalid) {
        this.v.$anyError
        this.v.$touch()
        v$Notify(this.v.FDAnalyze, 'financialDepartmentVerification')
        return
      }
      this.loadingRef = true
      const req = {
        financialRiskLevelId: this.FDAnalyze.financialRiskLevelId,
        financialRiskAssessment: this.FDAnalyze.financialRiskAssessment,
        financialRiskAssessmentNote: this.FDAnalyze.financialRiskAssessmentNote,
      }
      return this.$axios
        .post(urlSetFinancingMonitoringResult(this.FDAnalyze.id), req)
        .then(res => {
          if (!res.data.message) {
            this.$setSnackbar({ text: 'Аналіз завершено' })
          }
          this.loadingRef = false
          return this.$store.commit('refreshPage')
        })
    },
  },
  watch: {
    'FDAnalyzeData.clientSanctionDocument': {
      handler(newDocument) {
        this.changedDocuments.clientSanctionDocument = newDocument
        this.uploadSanctionDocuments(this.changedDocuments)
      },
      deep: true,
    },
    'FDAnalyzeData.providerSanctionDocument': {
      handler(newDocument) {
        this.changedDocuments.providerSanctionDocument = newDocument
        this.uploadSanctionDocuments(this.changedDocuments)
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.accept-documents {
  &__item {
    display: flex;
  }
}
.data-card {
  display: flex;
  flex-direction: column;
  margin-right: 25px;
}
.card-label {
  color: #000 !important;
}
.card-sublable {
  color: #838282;
  font-weight: 500;
  font-size: 13px;
}
.subtitle {
  color: #000;
}
.hyper-link {
  text-decoration: none;
  color: #08487a;
  font-weight: 400;
  font-size: 13px !important;
  &:hover {
    text-decoration: underline;
  }
}
</style>
