<template>
  <div>
    <v-card-title class="pt-3" style="font-size: 1.1rem">
      Прикріплення документів
    </v-card-title>
    <v-card-text style="min-height: 200px">
      <FilesUploader
        v-model:documents="contractData.signedDkp"
        label="Договір купiвлi продажу (пiдписаний)"
        :iconSize="26"
        single
        :readonly="readonly">
      </FilesUploader>
      <FilesUploader
        v-model:documents="contractData.otherDocuments"
        label="Необов`язкові документи"
        icon="mdi-file-document-multiple-outline"
        :readonly="readonly">
      </FilesUploader>
    </v-card-text>
  </div>
</template>
<script>
import FilesUploader from '@/components/FilesUploader.vue'
import { mapState } from 'vuex'

export default {
  components: { FilesUploader },
  props: {
    contract: { type: Object },
  },
  data: () => ({}),
  computed: {
    ...mapState({
      user: state => state.user,
    }),
    readonly() {
      return ![1, 5, 6].includes(this.user.roleId)
    },
    contractData() {
      return this.contract
    },
  },
}
</script>
