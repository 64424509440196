<template>
  <div>
    <v-row>
      <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
        <span class="label">Контакт</span>
        <b-server-autocomplete
          v-model="agent.contactId"
          :url="{
            url: urlContactsGetAll(),
            query: {
              isAgent: false,
            },
          }"
          :search-min-length="3"
          :default-item="agent?.contact"
          :error-messages="contactIdErr"
          item-title="fullName"
          item-value="id"
          :menu-props="{ offset: '5px', zIndex: 1000000000 }"
          placeholder="Введіть текст (мін. 3 символи)"
          hide-details
          dense
          :readonly="deleteAgent"
          @update:model-value="getContractorId($event)"
          @blur="getSafe(() => v$.agent.contactId.$touch())">
        </b-server-autocomplete>
      </v-col>
      <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
        <div class="mt-3 d-flex align-center">
          <span style="color: #09487a">Зв'язки</span>
          <v-tooltip bottom text="Додати торгову точку">
            <template #activator="{ props }">
              <div v-bind="props">
                <v-btn icon size="small" @click="openDealerList">
                  <v-icon>{{
                    dealerDialogList ? 'mdi-close' : 'mdi-plus'
                  }}</v-icon>
                </v-btn>
              </div>
            </template>
          </v-tooltip>
        </div>
      </v-col>
      <v-col
        v-if="dealerDialogList"
        cols="12"
        md="12"
        sm="12"
        class="pt-0 pb-0">
        <span class="label">Торгова точка</span>
        <div class="d-flex align-center">
          <v-autocomplete
            v-model="agent.dealerPointId"
            :items="
              addAgent
                ? selectItems.dealerOutlets
                : directoryItems('dealerOutlets', agent.dealerPoint)
            "
            item-value="id"
            hide-details
            item-title="name"
            placeholder="Оберіть зi списку"
            :readonly="deleteAgent"
            @update:model-value="getContractorIdByDealer($event)">
          </v-autocomplete>
          <v-btn
            v-if="agent.dealerPointId"
            style="margin-top: 10px"
            size="small"
            icon
            @click="openDealerList">
            <v-icon size="small">{{ 'mdi-close' }}</v-icon>
          </v-btn>
        </div>
      </v-col>
      <v-col
        v-if="dealerContractor"
        cols="12"
        md="12"
        sm="12"
        class="pt-0 pb-0">
        <span class="label">Контрагент</span>
        <v-text-field
          placeholder="Введіть текст"
          :model-value="dealerContractor"
          readonly
          disabled>
        </v-text-field>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { required } from '@vuelidate/validators'
import { mapState } from 'vuex'
import {
  urlContactsGetAll,
  urlCreateAgent,
  urlDeleteAgent,
  urlGetContractorByContact,
} from '@/pages/request'
import { getSafe, setErrHandler } from '@/utils/helperFunc'
import { phoneMask } from '@/utils/masks'
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'
import router from '@/router/router'
import { BServerAutocomplete } from 'best-modules/components'

export default {
  components: { BServerAutocomplete },
  setup() {
    return { v$: useVuelidate(), ...useSelect() }
  },
  props: { dialog: { type: Object } },
  validations() {
    const agent = this.addAgent
      ? {
          agent: {
            contactId: { required },
          },
        }
      : {}

    return agent
  },
  data: () => ({
    dealerDialogList: false,
    agent: {
      dealerPointId: null,
      contractorId: null,
      contactId: null,
      dealerPoint: null,
      contact: null,
    },
  }),
  computed: {
    ...mapState({ selectItems: state => state.selectItems }),
    addAgent() {
      return this.dialog.action === 'addAgent'
    },
    deleteAgent() {
      return this.dialog.action === 'deleteAgent'
    },
    dealerContractor() {
      if (this.addAgent) {
        return (
          this.selectItems.dealerOutlets.find(
            d => d.id === this.agent.dealerPointId
          )?.contractor?.shortName || null
        )
      } else return this.agent.dealerPoint?.contractor?.shortName
    },
    validation() {
      return { v$: this.v$.agent, name: 'agent' }
    },
    contactIdErr() {
      return setErrHandler(this.v$?.agent?.contactId)
    },
  },
  methods: {
    urlContactsGetAll,
    getSafe,
    phoneMask,
    async getContractorId(contactId) {
      if (this.dealerContractor) return
      const { data } = await this.$axios.get(
        urlGetContractorByContact(contactId)
      )
      this.agent.contractorId = (await data[0]?.id) || null
    },
    getContractorIdByDealer(id) {
      const dealer = this.selectItems.dealerOutletsList.find(d => d.id === id)
      this.agent.contractorId = dealer?.contractorId || null
    },
    submit() {
      switch (true) {
        case this.addAgent:
          return this.add()
        case this.deleteAgent:
          return this.delete()
        default:
          return this.$setSnackbar({ text: 'No handler', color: 'warning' })
      }
    },
    openDealerList() {
      this.dealerDialogList = !this.dealerDialogList
    },
    add() {
      return this.$axios.post(urlCreateAgent(), this.agent).then(res => {
        if (res.data) {
          router.push({
            name: 'agent-form',
            params: { id: res.data.id },
          })
        }
        return res
      })
    },
    delete() {
      return this.$axios.delete(urlDeleteAgent(this.agent?.id))
    },
  },
  created() {
    Object.assign(this.agent, this.dialog.dialogItem)
    if (this.addAgent) {
      this.$store.dispatch('addDealerOutlets')
    }
  },
}
</script>
