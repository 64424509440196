<template>
  <div>
    <div class="mb-3 d-flex align-center gap-4">
      <div v-if="typeContractor" class="d-flex flex-column">
        <span class="d-block scoring-label mr-1">Назва клієнта:</span>
        <span class="d-block">{{
          isReapplication
            ? dialog.dialogItem.lessee.contractor.fullName ||
              dialog.dialogItem.lessee.contractor.shortName
            : dialog.dialogItem.application.companyName || 'Відсутня'
        }}</span>
      </div>
      <div class="d-flex align-center">
        <div v-if="typeContractor" class="d-flex mr-2 flex-column">
          <span class="d-block scoring-label mr-1">ЄДРПОУ:</span>
          <span class="d-block">{{
            dialog.dialogItem.application.edrpou || 'Відсутнє'
          }}</span>
        </div>
        <div v-if="!typeContractor" class="d-flex flex-column">
          <span class="d-block scoring-label mr-1">IНН:</span>
          <span class="d-block">{{
            dialog.dialogItem.application.inn || 'Відсутнє'
          }}</span>
        </div>
      </div>
      <div v-if="!typeContractor" class="d-flex flex-column">
        <span class="d-block scoring-label mr-1">ПIБ:</span>
        <span class="d-block">{{
          isReapplication
            ? dialog.dialogItem.lessee.contact.fullName
            : dialog.dialogItem.fio || 'Відсутнє'
        }}</span>
      </div>
    </div>
    <!-- person -->
    <div v-if="typePerson">
      <v-row>
        <v-col :md="4" :cols="12">
          <span class="label">Роботодавець/Замовник</span>
          <v-text-field
            v-model="scoring.employerOrCustomer"
            class="label-top"
            placeholder="Введіть текст"
            required
            hide-details
            dense></v-text-field>
        </v-col>
        <v-col :md="4" :cols="12">
          <span class="label">Посада/вид робіт</span>
          <v-text-field
            v-model="scoring.position"
            class="label-top"
            placeholder="Введіть текст"
            required
            hide-details
            dense></v-text-field>
        </v-col>
        <v-col :md="4" :cols="12">
          <span class="label">Трудовий стаж, міс</span>
          <v-text-field
            id="c-ld-book-pass-num"
            v-model.number="scoring.workExperience"
            class="label-top"
            placeholder="Введіть текст"
            required
            dense
            hide-details
            @input="maxNumLength('c-ld-book-pass-num', 4)"></v-text-field>
        </v-col>
      </v-row>
      <span class="scoring-label">Доходи</span>
      <v-row>
        <v-col cols="12" md="4" sm="12">
          <div class="d-flex align-center">
            <span class="label">Заробітна плата</span>
            <v-tooltip
              color="grey darken-3"
              text="Заробітна плата, оплата по цивільно-правовим договорам">
              <template #activator="{ props }">
                <span style="font-size: 14px; color: black">
                  <v-icon
                    style="z-index: 7"
                    class="ml-1"
                    size="17"
                    v-bind="props">
                    {{ 'mdi-information' }}
                  </v-icon>
                </span>
              </template>
              <span
                >Заробітна плата, оплата по цивільно-правовим договорам</span
              >
            </v-tooltip>
          </div>
          <v-text-field
            id="c-ld-income"
            v-model.number="scoring.incoming.targetIncome"
            class="label-top"
            placeholder="Введіть текст"
            required
            hide-details
            dense
            @input="maxNumLength('c-ld-income', 20)"></v-text-field>
        </v-col>
        <v-col cols="12" md="4" sm="12">
          <div class="d-flex align-center">
            <span class="label">Оплата другого із подружжя</span>
            <v-tooltip
              color="grey darken-3"
              text="Заробітна плата, оплата по цивільно-правовим договорам з іншого
                подружжя">
              <template #activator="{ props }">
                <span style="font-size: 14px; color: black">
                  <v-icon
                    style="z-index: 7"
                    size="17"
                    class="ml-1"
                    v-bind="props">
                    {{ 'mdi-information' }}
                  </v-icon>
                </span>
              </template>
            </v-tooltip>
          </div>
          <v-text-field
            id="c-ld-income-money"
            v-model.number="scoring.incoming.lawIncome"
            class="label-top"
            placeholder="Введіть текст"
            required
            hide-details
            dense
            @input="maxNumLength('c-ld-income-money', 20)"></v-text-field>
        </v-col>
        <v-col cols="12" md="4" sm="12">
          <span class="label">Інші доходи</span>
          <v-text-field
            id="c-ld-income-statement"
            v-model.number="scoring.incoming.moreIncome"
            style="margin-top: 10px"
            class="label-top"
            placeholder="Введіть текст"
            required
            hide-details
            dense
            @input="maxNumLength('c-ld-income-statement', 20)"></v-text-field>
        </v-col>
      </v-row>
      <span class="scoring-label">Витрати</span>
      <v-row>
        <v-col cols="12" md="4" sm="12">
          <div class="d-flex align-center">
            <span class="label">Погашення кредитів</span>
            <v-tooltip
              color="grey darken-3"
              top
              text="Погашення кредитів/ лізингу/ розстрочки/ % за кредитними
                картками">
              <template #activator="{ props }">
                <span style="font-size: 14px; color: black">
                  <v-icon
                    style="z-index: 7"
                    class="ml-1"
                    size="17"
                    v-bind="props">
                    {{ 'mdi-information' }}
                  </v-icon>
                </span>
              </template>
              <span
                >Погашення кредитів/ лізингу/ розстрочки/ % за кредитними
                картками</span
              >
            </v-tooltip>
          </div>
          <v-text-field
            id="c-ld-income"
            v-model.number="scoring.cost.credit"
            class="label-top"
            placeholder="Введіть текст"
            required
            hide-details
            dense
            @input="maxNumLength('c-ld-income', 20)"></v-text-field>
        </v-col>
        <v-col cols="12" md="4" sm="12">
          <div class="d-flex align-center">
            <span class="label">Сумарні щомісячні витрати</span>
            <v-tooltip
              color="grey darken-3"
              top
              text="Сумарні щомісячні витрати (включаючи сім'ю)">
              <template #activator="{ props }">
                <span style="font-size: 14px; color: black">
                  <v-icon
                    style="z-index: 7"
                    class="ml-1"
                    size="17"
                    v-bind="props">
                    {{ 'mdi-information' }}
                  </v-icon>
                </span>
              </template>
              <span>Сумарні щомісячні витрати (включаючи сім'ю)</span>
            </v-tooltip>
          </div>
          <v-text-field
            id="c-ld-income"
            v-model.number="scoring.cost.monthCost"
            class="label-top"
            placeholder="Введіть текст"
            required
            hide-details
            dense
            @input="maxNumLength('c-ld-income', 20)"></v-text-field>
        </v-col>
      </v-row>
    </div>
    <!-- fop -->
    <div v-if="typeFop">
      <v-row>
        <v-col cols="12" md="6" sm="12">
          <span class="label">Основний вид діяльності ФОП</span>
          <v-autocomplete
            v-model="scoring.mainQuedId"
            class="mb-3"
            :items="directoryItems('activityTypes')"
            :item-title="item => `${item.code} ${item.title}`"
            item-value="id"
            placeholder="Оберіть зi списку"
            dense
            hide-details
            @focus="asyncAction('addActivities')">
            <template #append-item
              ><Loader
                v-if="
                  asyncLoading('addActivities') &&
                  directoryItems('activityTypes').length
                "
            /></template>
            <template v-if="asyncLoading('addActivities')" #no-data
              ><Loader
            /></template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="6" sm="12">
          <span class="label">Термін (активної) роботи ФОП, міс</span>
          <v-text-field
            id="c-ld-book-pass-nums"
            v-model.number="scoring.termWork"
            class="label-top"
            hide-details
            placeholder="Введіть текст"
            required
            dense
            @input="maxNumLength('c-ld-book-pass-nums', 4)"></v-text-field>
        </v-col>
      </v-row>
      <span class="scoring-label">Доходи</span>
      <v-row>
        <v-col cols="12" md="4" sm="12">
          <div class="d-flex align-center">
            <span class="label">Від підприємницької діяльності</span>
            <v-tooltip
              color="grey darken-3"
              top
              text="Від підприємницької діяльності згідно декларації">
              <template #activator="{ props }">
                <span style="font-size: 14px; color: black">
                  <v-icon
                    style="z-index: 7"
                    class="ml-1"
                    size="17"
                    v-bind="props">
                    {{ 'mdi-information' }}
                  </v-icon>
                </span>
              </template>
              <span>Від підприємницької діяльності згідно декларації</span>
            </v-tooltip>
          </div>
          <v-text-field
            id="c-ld-book-pass-nums"
            v-model.number="scoring.incoming.quedPayment"
            class="label-top"
            placeholder="Введіть текст"
            required
            dense
            hide-details
            @input="maxNumLength('c-ld-book-pass-nums', 20)"></v-text-field>
        </v-col>
        <v-col cols="12" md="4" sm="12">
          <span class="label">Інші доходи</span>
          <v-text-field
            id="c-ld-book-pass-nums"
            v-model.number="scoring.incoming.moreIncomeFop"
            class="label-top"
            placeholder="Введіть текст"
            required
            hide-details
            dense
            @input="maxNumLength('c-ld-book-pass-nums', 20)"></v-text-field>
        </v-col>
        <v-col cols="12" md="4" sm="12">
          <div class="d-flex align-center">
            <span class="label">Оплата другого із подружжя</span>
            <v-tooltip
              color="grey darken-3"
              top
              text="Заробітна плата, оплата по цивільно-правовим договорам другого
                із подружжя">
              <template #activator="{ props }">
                <span style="font-size: 14px; color: black">
                  <v-icon
                    style="z-index: 7"
                    class="ml-1"
                    size="17"
                    v-bind="props">
                    {{ 'mdi-information' }}
                  </v-icon>
                </span>
              </template>
              <span
                >Заробітна плата, оплата по цивільно-правовим договорам другого
                із подружжя</span
              >
            </v-tooltip>
          </div>
          <v-text-field
            id="c-ld-book-pass-nums"
            v-model.number="scoring.incoming.lawIncomeFop"
            class="label-top"
            placeholder="Введіть текст"
            required
            hide-details
            dense
            @input="maxNumLength('c-ld-book-pass-nums', 20)"></v-text-field>
        </v-col>
      </v-row>
      <span class="scoring-label">Витрати</span>
      <v-row>
        <v-col cols="12" md="4" sm="12">
          <span class="label">Пов'язані з підприємницькою діяльністю</span>
          <v-text-field
            id="c-ld-book-pass-nums"
            v-model.number="scoring.cost.businessIncome"
            class="label-top"
            placeholder="Введіть текст"
            required
            hide-details
            dense
            @input="maxNumLength('c-ld-book-pass-nums', 20)"></v-text-field>
        </v-col>
        <v-col cols="12" md="4" sm="12">
          <div class="d-flex align-center">
            <span class="label">Виплати по кредитам</span>
            <v-tooltip
              color="grey darken-3"
              top
              text="Виплати по кредитам/лізингу/розстрочки">
              <template #activator="{ props }">
                <span style="font-size: 14px; color: black">
                  <v-icon
                    style="z-index: 7"
                    class="ml-1"
                    size="17"
                    v-bind="props">
                    {{ 'mdi-information' }}
                  </v-icon>
                </span>
              </template>
              <span>Виплати по кредитам/лізингу/розстрочки</span>
            </v-tooltip>
          </div>
          <v-text-field
            id="c-ld-book-pass-nums"
            v-model.number="scoring.cost.payout"
            class="label-top"
            placeholder="Введіть текст"
            required
            hide-details
            dense
            @input="maxNumLength('c-ld-book-pass-nums', 20)"></v-text-field>
        </v-col>
        <v-col cols="12" md="4" sm="12">
          <div class="d-flex align-center">
            <span class="label">Ведення домашнього господарства</span>
            <v-tooltip
              color="grey darken-3"
              top
              text="Ведення домашнього господарства (включаючи витрати на
                сім'ю)">
              <template #activator="{ props }">
                <span style="font-size: 14px; color: black">
                  <v-icon
                    style="z-index: 7"
                    class="ml-1"
                    size="17"
                    v-bind="props">
                    {{ 'mdi-information' }}
                  </v-icon>
                </span>
              </template>
              <span
                >Ведення домашнього господарства (включаючи витрати на
                сім'ю)</span
              >
            </v-tooltip>
          </div>
          <v-text-field
            id="c-ld-book-pass-nums"
            v-model.number="scoring.cost.homeIncome"
            class="label-top"
            placeholder="Введіть текст"
            required
            hide-details
            dense
            @input="maxNumLength('c-ld-book-pass-nums', 20)"></v-text-field>
        </v-col>
      </v-row>
    </div>

    <OpenableSection>
      <template #default>
        <FilesUploader
          v-model:documents="scoring.passportDocument"
          :label="typeContractor ? 'Паспорт директора' : 'Паспорт'"
          required />
        <FilesUploader
          v-model:documents="scoring.innDocument"
          :label="typeContractor ? 'ІПН директора' : 'ІПН'"
          required />
        <FilesUploader
          v-if="typeContractor || typeFop"
          v-model:documents="scoring.financialDocument"
          :label="typeContractor ? 'Баланс - Ф1 і Ф2' : 'Податкова декларація'"
          required />
      </template>
    </OpenableSection>

    <v-row>
      <v-col cols="12" md="12" sm="12">
        <v-textarea
          v-model="scoring.comment"
          :rows="3"
          hide-details
          placeholder="Напишіть коментар">
        </v-textarea>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { maxNumLength } from '@/utils/masks'
import FilesUploader from '@/components/FilesUploader.vue'
import { urlScoringCreate } from '@/pages/request'
import { required, minLength } from '@vuelidate/validators'
import Loader from '@/components/Loader.vue'
import { mapState } from 'vuex'
import OpenableSection from '@/components/OpenableSection.vue'
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'
export default {
  name: 'ScoringDialog',
  components: { OpenableSection, Loader, FilesUploader },
  setup() {
    return { v$: useVuelidate(), ...useSelect() }
  },
  props: {
    dialog: { type: Object },
  },
  validations() {
    return {
      scoring: {
        financialDocument:
          this.typeContractor || this.typeFop
            ? {
                required,
                minLength: minLength(1),
              }
            : {},
        passportDocument: { required, minLength: minLength(1) },
        innDocument: { required, minLength: minLength(1) },
        employerOrCustomer: this.typePerson
          ? { required, minLength: minLength(3) }
          : {},
        position: this.typePerson ? { required, minLength: minLength(3) } : {},
        workExperience: this.typePerson
          ? { required, minLength: minLength(1) }
          : {},
        mainQuedId: this.typeFop ? { required, minLength: minLength(1) } : {},
        termWork: this.typeFop ? { required, minLength: minLength(1) } : {},
        incoming: {
          moreIncome: this.typePerson
            ? { required, minLength: minLength(1) }
            : {},
          lawIncome: this.typePerson
            ? { required, minLength: minLength(1) }
            : {},
          targetIncome: this.typePerson
            ? { required, minLength: minLength(1) }
            : {},
          quedPayment: this.typeFop
            ? { required, minLength: minLength(1) }
            : {},
          moreIncomeFop: this.typeFop
            ? { required, minLength: minLength(1) }
            : {},
          lawIncomeFop: this.typeFop
            ? { required, minLength: minLength(1) }
            : {},
        },
        cost: {
          credit: this.typePerson ? { required, minLength: minLength(1) } : {},
          monthCost: this.typePerson
            ? { required, minLength: minLength(1) }
            : {},
          businessIncome: this.typeFop
            ? { required, minLength: minLength(1) }
            : {},
          payout: this.typeFop ? { required, minLength: minLength(1) } : {},
          homeIncome: this.typeFop ? { required, minLength: minLength(1) } : {},
        },
      },
    }
  },
  data() {
    return {
      fieldsPerson: {
        passportDocument: null,
        innDocument: null,
        employerOrCustomer: null,
        position: null,
        workExperience: null,
        incoming: {
          moreIncome: 0,
          lawIncome: 0,
          targetIncome: 0,
        },
        cost: {
          credit: 0,
          monthCost: 0,
        },
      },
      fieldsFop: {
        financialDocument: null,
        passportDocument: null,
        innDocument: null,
        mainQuedId: null,
        termWork: null,
        incoming: {
          quedPayment: 0,
          moreIncomeFop: 0,
          lawIncomeFop: 0,
        },
        cost: {
          businessIncome: 0,
          payout: 0,
          homeIncome: 0,
        },
      },
      fieldsContractor: {
        financialDocument: null,
        passportDocument: null,
        innDocument: null,
      },
      scoring: {},
    }
  },
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
    }),
    validation() {
      return { v$: this.v$.scoring, name: 'scoring' }
    },
    requestObj() {
      return {
        sliderId: this.dialog.params.sliderId,
        ...this.scoring,
      }
    },
    typeFop() {
      return this.dialog.params.clientTypeId === 3
    },
    typeContractor() {
      return this.dialog.params.clientTypeId === 2
    },
    typePerson() {
      return this.dialog.params.clientTypeId === 1
    },
    isReapplication() {
      return this.dialog.dialogItem.application.sourceTypeId === 3
    },
  },
  methods: {
    maxNumLength,
    submit() {
      return this.$axios.post(urlScoringCreate(), this.requestObj)
    },
  },
  created() {
    this.scoring = (() => {
      switch (true) {
        case this.typeFop:
          return this.fieldsFop
        case this.typePerson:
          return this.fieldsPerson
        case this.typeContractor:
          return this.fieldsContractor
        default:
          throw new Error('incorrect client type')
      }
    })()
  },
}
</script>

<style lang="scss" scoped>
.scoring-label {
  color: #000;
  font-weight: 600;
}
</style>
