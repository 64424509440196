<template>
  <div>
    <div>
      <v-data-table
        :headers="agentPaymentHeaders"
        :items="agent.payments || []"
        :hide-default-header="!agent.payments"
        mobileBreakpoint="750"
        :items-per-page="-1"
        class="custom-table head-line mt-4"
        dense>
        <!-- eslint-disable-next-line -->
        <template #no-data>
          <NoData></NoData>
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.status="{ item }">
          <span> {{ item.status?.name || 'Новий' }}</span>
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.lessee="{ item }">
          <ALink
            style="font-size: 12px !important"
            :text="getEntityName(item.contract.client)"
            :route="getEntityRoute(item)" />
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.contract="{ item }">
          <ALink
            style="font-size: 12px !important"
            :text="item.contract.number"
            :route="{
              name: 'dfl-contract',
              params: { id: item.contract.id },
            }" />
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.leasingObjectName="{ item }">
          <span>{{ item.contract.leasingObjectName }}</span>
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.amountOl="{ item }">
          <span>{{ getBeautyNum(item.amountOl) + ' грн' }}</span>
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.amountAB="{ item }">
          <span>{{ getBeautyNum(item.amountAB) + ' грн' }}</span>
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.actions="{ item }">
          <TableCrud
            style="margin-right: -10px !important"
            :actionsArray="[
              {
                action: () => openActCard(item),
                text: 'Переглянути',
                icon: 'mdi-cloud-print-outline',
              },
            ]">
          </TableCrud>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { getBeautyNum, getEntityName, getSafe } from '@/utils/helperFunc'
import ALink from '@/components/Link.vue'
import TableCrud from '@/components/table-crud.vue'
import { agentPaymentHeaders } from '@/utils/tableHeaders'
import NoData from '@/components/no-table-data.vue'

export default {
  name: 'ActPayments',
  components: { NoData, TableCrud, ALink },
  props: {
    agent: { type: Object },
  },
  data() {
    return {
      agentPaymentHeaders,
      expandedItems: [],
      documents: null,
    }
  },
  methods: {
    getSafe,
    getEntityName,
    getBeautyNum,
    getEntityRoute(item) {
      const typeContractor = item.entityTypeId === 2
      const name = typeContractor ? 'contractors-form' : 'individuals-form'
      const id = item.contract.client.id
      return { name, params: { id } }
    },
    openActCard(item) {
      return this.$store.commit('setDialog', {
        title: 'Акт на виплату',
        dialogItem: {
          act: item,
          paymentThroughs: this.agent?.paymentThroughs,
        },
        params: {
          hideCancel: true,
          dialogWidth: 800,
          dialogHeight: 650,
          btnWidth: '22%',
          flexEnd: true,
          submitText: 'Зробити виплату',
        },
        action: 'paymentActCard',
      })
    },
  },
}
</script>

<style scoped></style>
