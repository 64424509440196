<template>
  <div style="width: 100%" data-readonly-wrapper="false">
    <div
      class="d-flex align-center justify-space-between gap-3 mobileInputListSearch">
      <div class="d-flex align-center gap-3 wrapper__inputListSearch">
        <v-text-field
          v-if="'search' in filters"
          v-model="f.search.value"
          style="width: 500px; margin-bottom: 10px"
          placeholder="Пошук"
          hide-details
          dense
          class="inputListSearch"
          @input="$emit('filter')">
          <template #prepend-inner>
            <find-icon :width="17" :height="17" />
          </template>
          <template #append>
            <v-tooltip v-if="!searchOnly" bottom text="Фільтр">
              <template #activator="{ props }">
                <v-btn
                  icon
                  size="x-small"
                  class="rounded-circle"
                  v-bind="props"
                  @click.stop="filter = !filter">
                  <filterIcon />
                </v-btn>
              </template>
              <span>Фільтр</span>
            </v-tooltip>
          </template>
        </v-text-field>
        <v-tooltip v-else bottom text="Фільтр">
          <template #activator="{ props }">
            <v-btn
              icon
              size="x-small"
              class="rounded-circle"
              v-bind="props"
              @click.stop="filter = !filter">
              <filterIcon />
            </v-btn>
          </template>
          <span>Фільтр</span>
        </v-tooltip>
        <span
          v-if="(dialog ? !filter : true) && hasSelectedFilters"
          class="link"
          @click="clearFilters"
          >Очистити</span
        >
      </div>
      <div>
        <slot name="header-action"></slot>
      </div>
    </div>
    <component
      :is="dialog ? 'v-dialog' : 'Transition'"
      v-model="filter"
      :max-width="930"
      hide-on-leave>
      <div v-show="filter" :class="{ dialog: dialog }">
        <v-card-text
          class="pt-5"
          style="position: relative"
          :class="dialog ? null : 'pl-0'">
          <v-row v-for="(row, idx) in rows" :key="idx" class="align-center">
            <v-col v-for="col in row" :key="col" :cols="12" :lg="3" :md="4">
              <component
                :is="f[col].component"
                v-if="showComponent(col)"
                :id="col"
                :ref="col"
                v-model="f[col].value"
                :loading="asyncLoading(col)"
                v-bind="prepareAttrs(col)"
                hide-details
                dense
                :menu-props="{ offset: '5px' }"
                @focus="handleEvent(col, 'focus', $event)"
                @input="handleEvent(col, 'input', $event)">
                <template v-if="asyncLoading(col)" #no-data
                  ><Loader
                /></template>
                <template
                  v-if="
                    [
                      'v-select',
                      'v-autocomplete',
                      'v-text-field',
                      'v-checkbox',
                    ].includes(f[col].component)
                  "
                  #append>
                  <v-btn
                    v-show="f[col].value"
                    icon
                    density="compact"
                    size="x-small"
                    @click.stop="f[col].value = null">
                    <v-icon class="custom-select-icon" size="small">
                      {{ 'mdi-close' }}
                    </v-icon>
                  </v-btn>
                </template>
              </component>
            </v-col>
          </v-row>
          <ActionButtons
            v-if="dialog"
            class="mt-5"
            confirm-btn-text="Застосувати"
            cancel-btn-text="Скасувати"
            :confirm="
              () => {
                $emit('filter')
                closeFilter(false)
              }
            "
            :cancel="closeFilter" />
        </v-card-text>
      </div>
    </component>
  </div>
</template>

<script>
import filterIcon from '@/assets/svg/filter-icon.vue'
import DatePicker from '@/components/DatePicker.vue'
import Transition from '@/components/Transition.vue'
import Loader from '@/components/Loader.vue'
import findIcon from '@/assets/svg/find-bold.vue'
import ActionButtons from '@/components/action-buttons.vue'
import { mapState } from 'vuex'
import {
  VSelect,
  VAutocomplete,
  VTextField,
  VDialog,
  VCheckbox,
} from 'vuetify/components'
import { useSelect } from '@/utils/mixins/useSelect'
export default {
  emits: ['filter', 'update:filters'],
  components: {
    Loader,
    VSelect,
    VAutocomplete,
    VTextField,
    VCheckbox,
    VDialog,
    Transition,
    filterIcon,
    findIcon,
    DatePicker,
    ActionButtons,
  },
  props: {
    filters: { type: Object },
    dialog: { type: Boolean },
  },
  setup() {
    return {
      ...useSelect(),
    }
  },
  data() {
    return {
      filter: false,
    }
  },
  computed: {
    ...mapState({
      selectItems: state => state.selectItems,
    }),
    f: {
      get() {
        return this.filters
      },
      set(val) {
        this.$emit('update:filters', val)
      },
    },
    hasSelectedFilters() {
      let res = false
      for (const f in this.f) {
        if (this.f[f].value) {
          res = true
          break
        }
      }
      return res
    },
    rows() {
      const rows = []
      const row = []

      const colsLength = window.innerWidth >= 1264 ? 4 : 3
      Object.keys(this.f)
        .filter(f => f !== 'search')
        .forEach((filter, idx, arr) => {
          row.push(filter)
          if (row.length === colsLength || idx === arr.length - 1) {
            rows.push(Object.clone(row))
            row.splice(0)
          }
        })

      return rows
    },
    searchOnly() {
      const filters = Object.keys(this.filters)
      return filters.length === 1 && filters[0] === 'search'
    },
  },
  methods: {
    closeFilter(clear = true) {
      this.filter = false
      if (clear) {
        this.clearFilters()
      }
    },
    clearFilters() {
      this.filter = false
      const hasSelectedFilters = this.hasSelectedFilters
      for (const f in this.f) {
        if (this.f[f].value) this.f[f].value = null
      }
      if (hasSelectedFilters) this.$emit('filter')
    },
    // compute v-if value for filter component
    showComponent(col) {
      if (this.f[col].show) {
        const currentFilter = this.f[col]
        const conditionFilter = this.f[this.f[col].show.filterName]
        switch (true) {
          case currentFilter.show.acceptedValue === 'any':
            return !!conditionFilter.value
          case Array.isArray(currentFilter.show.acceptedValue):
            return currentFilter.show.acceptedValue.includes(
              conditionFilter.value
            )
        }
      } else {
        return true
      }
    },
    prepareAttrs(col) {
      const { itemTitle = 'name', itemValue = 'id' } = this.f[col].attrs || {}
      return {
        ...(this.f[col].attrs || {}),
        items: this.directoryItems(this.f[col].attrs?.items),
        itemTitle,
        itemValue,
      }
    },
    async handleEvent(col, eventName /* focus, change, input */, event) {
      const typeFunc = typeof this.f[col][eventName] === 'function'
      const typeObject =
        typeof this.f[col][eventName] === 'object' && !!this.f[col][eventName]
      const action = (() => {
        switch (true) {
          case typeFunc:
            return () => this.f[col][eventName](this.f[col].value, event) // function call and pass as an argument - the value of the field
          case typeObject:
            return () =>
              this.$store.dispatch(
                this.f[col][eventName].action,
                this.f[col][eventName].payload
              )
          case eventName === 'focus' &&
            this.f[col] &&
            this.f[col].attrs &&
            typeof this.f[col].attrs.items === 'string':
            return () =>
              this.asyncAction(`add${this.f[col].attrs.items.capitalize()}`) // example: col.attrs.items - 'projectStatuses', call store action - 'addProjectStatuses'
          default:
            return () => false
        }
      })()

      return this.asyncAction(col, null, async () => await action())
    },
  },
  created() {
    for (const f in this.f) {
      this.$watch(
        `f.${f}.value`,
        () => {
          this.handleEvent(f, 'change')
        },
        { deep: true }
      )
    }
  },
}
</script>

<style lang="scss" scoped>
.dialog {
  background: #fff;
}
</style>
