<template>
  <div>
    <div class="w-33">
      <b-select
        v-model="executiveAuthorityData.executiveAuthorityId"
        label="Виконавчий орган"
        :items="
          $directory.get(
            'executiveAuthorities',
            dialog.dialogItem.contractor.executiveauthority
          )
        "
        :error-messages="getErrorMessages(v$.executiveAuthorityId)"
        :loading="$loading.isLoading('executiveAuthorities')"
        @update:model-value="
          $event === 1 && executiveAuthorityData.employees.splice(1)
        "
        @focus="$directory.fill('executiveAuthorities')"
        @blur="v$.executiveAuthorityId.$touch()">
      </b-select>
    </div>
    <v-data-table
      v-model="executiveAuthorityData.employees"
      :items="employees"
      :headers="employeesDialogHeaders"
      :select-strategy="
        executiveAuthorityData.executiveAuthorityId === 1 ? 'single' : 'all'
      "
      show-select>
      <template #item.isSignatureRight="{ item }">
        <v-checkbox
          v-model="item.isSignatureRight"
          readonly
          hide-details
          dense
          width="20px">
        </v-checkbox>
      </template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import { reactive } from 'vue'
import { minLength, required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { urlExecutiveAuthoritySet } from '@/pages/request.js'
import { employeesDialogHeaders } from '@/utils/tableHeaders'
import axios from '@/plugins/axios.js'
import { getErrorMessages } from 'best-modules/utils'
import { Employee } from '@/utils/types'

export default {
  name: 'ExecutiveAuthorities',
  props: {
    dialog: {
      type: Object,
    },
  },
  data: () => {
    return { employeesDialogHeaders }
  },
  computed: {
    validation() {
      return { v$: this.v$, name: 'executiveAuthority' }
    },
  },
  methods: { getErrorMessages },
  setup(props) {
    const employees: Employee[] =
      props.dialog.dialogItem.contractor.employees || []
    const executiveAuthorityData = reactive<{
      employees: number[]
      executiveAuthorityId: number
    }>({
      employees:
        props.dialog.dialogItem.contractor.employees
          ?.filter(e => e.isExecutiveAuthority)
          .map(e => e.id) || [],
      executiveAuthorityId:
        props.dialog.dialogItem.contractor.executiveAuthorityId,
    })

    const rules = {
      employees: { required: required, minLength: minLength(1) },
      executiveAuthorityId: { required: required },
    }
    const v$ = useVuelidate(rules, executiveAuthorityData)

    const submit = () => {
      return axios.post(urlExecutiveAuthoritySet(), {
        contractorId: props.dialog.dialogItem.contractor.id,
        employeeId: executiveAuthorityData.employees,
        executiveAuthorityId: executiveAuthorityData.executiveAuthorityId,
      })
    }

    return { v$, submit, executiveAuthorityData, employees }
  },
}
</script>

<style scoped></style>
