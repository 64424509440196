<template>
  <div>
    <v-card-title class="pt-5" style="font-size: 1.1rem">
      Прикріплення документів
    </v-card-title>
    <v-card-text style="min-height: 200px">
      <FilesUploader
        v-model:documents="contractData.scanServiceDocument"
        label="Договір сервісу (пiдписаний)"
        :iconSize="26"
        single
        :readonly="readonly">
      </FilesUploader>
      <FilesUploader
        v-model:documents="contractData.otherDocument"
        label="Необов`язкові документи"
        icon="mdi-file-document-multiple-outline"
        :readonly="readonly">
      </FilesUploader>
    </v-card-text>
  </div>
</template>
<script>
import FilesUploader from '@/components/FilesUploader.vue'
import { mapState } from 'vuex'
import { urlSetDocumentServiceContract } from '@/pages/request'

export default {
  components: { FilesUploader },
  props: {
    serviceContract: { type: Object },
  },
  computed: {
    ...mapState({
      user: state => state.user,
    }),
    readonly() {
      return ![1, 5, 6].includes(this.user.roleId)
    },
    contractData() {
      return this.serviceContract
    },
  },
  methods: {
    setDocument(document) {
      return this.$axios
        .post(urlSetDocumentServiceContract(this.serviceContract.id), document)
        .then(() => {
          return this.$setSnackbar({ text: 'Документ оновлено' })
        })
    },
  },
  watch: {
    'contractData.scanServiceDocument': {
      deep: true,
      handler(newValue) {
        this.setDocument({ scanServiceDocument: newValue })
      },
    },
    'contractData.otherDocument': {
      deep: true,
      handler(newValue) {
        this.setDocument({ otherDocument: newValue })
      },
    },
  },
}
</script>
