<template>
  <div class="dkp-contract">
    <SectionLoader v-if="loading"> </SectionLoader>
    <v-fade-transition hide-on-leave>
      <div v-if="!loading" style="background: #fff">
        <v-card-text class="pb-1">
          <v-row>
            <v-col cols="12" md="10" sm="12">
              <Fields
                :fields="fields"
                :type="2"
                :direction="$vuetify.breakpoint?.xs ? 'column' : 'row'">
                <template #number_field>
                  <EditableField
                    v-model="contract.number"
                    label="№ Договору"
                    style="margin-top: -5px"
                    :readonly="readonly"
                    :invalid="!!v$.contract.number.$error"
                    @blur="v$.contract.number.$touch()" />
                </template>
              </Fields>
            </v-col>
            <v-col cols="12" md="2" sm="12">
              <div class="align-center d-flex gap-3 justify-end">
                <v-checkbox
                  v-if="![5, 7].includes(contract.statusId)"
                  :model-value="contract.isCancelContract"
                  color="#fc7247"
                  hide-details
                  :readonly="(!admin && readonly) || contract.isCancelContract"
                  dense
                  :loading="true"
                  :true-value="true"
                  :false-value="false"
                  @update:model-value="setCancel">
                  <template #append>
                    <div class="label ml-6">
                      {{
                        contract.isCancelContract ? 'Анульовано' : 'Анулювати'
                      }}
                    </div>
                  </template>
                </v-checkbox>
                <div>
                  <v-tooltip
                    v-if="!contract.isTerminated && !contract.isCancelContract"
                    bottom
                    text="Розірвати договір">
                    <template #activator="{ props }">
                      <div v-bind="props">
                        <v-btn
                          color="#fd7149"
                          density="comfortable"
                          class="rounded-circle"
                          icon
                          @click="terminateContract">
                          <v-icon>mdi-file-remove</v-icon>
                        </v-btn>
                      </div>
                    </template>
                  </v-tooltip>
                  <v-menu v-if="contract.isTerminated">
                    <template #activator="{ props }">
                      <div v-bind="props">
                        <v-btn
                          v-tooltip:bottom="'Деталі розривання'"
                          color="#fd7149"
                          density="comfortable"
                          class="rounded-circle"
                          icon>
                          <v-icon>mdi-file-remove</v-icon>
                        </v-btn>
                      </div>
                    </template>

                    <v-card>
                      <v-card-text>
                        <div class="d-flex align-center mb-3">
                          <div class="text-disabled">Додаткова угода:</div>
                          <div>
                            <InputPusher
                              :routeObject="{
                                name: 'du-dkp-agreement',
                                params: {
                                  id: contract.terminate?.terminateAgreement.id,
                                },
                              }"
                              text="Перейти до Додаткової угоди">
                            </InputPusher>
                            <ALink
                              :text="
                                contract.terminate?.terminateAgreement.number
                              "
                              :route="{
                                name: 'du-dkp-agreement',
                                params: {
                                  id: contract.terminate?.terminateAgreement.id,
                                },
                              }" />
                          </div>
                        </div>
                        <div>
                          <span class="text-disabled">Дата розривання:</span>
                          {{ toFormatDate(contract.terminate?.terminateDate) }}
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </div>
                <div>
                  <v-tooltip bottom text="Додаткова угода">
                    <template #activator="{ props }">
                      <div v-bind="props">
                        <TableCrud
                          :actionsArray="
                            [
                              {
                                action: () => createExtraAgreement(),
                                text: 'Створити новий договір ДУ',
                                icon: 'mdi-plus',
                              },
                              {
                                actions: selectExtraAgreement,
                                text: 'Перейти в уже створений',
                                icon: 'mdi-arrow-right-bold-box',
                              },
                            ].filter((a, idx) => {
                              return (
                                idx !== 1 ||
                                !!contract.extraAgreementsDkp?.length
                              )
                            })
                          "
                          custom-icon="AgreementIcon">
                        </TableCrud>
                      </div>
                    </template>
                    <span>Додаткова угода</span>
                  </v-tooltip>
                </div>
                <TableCrud
                  :actionsArray="[
                    {
                      action: () =>
                        downloadPrintedForm(
                          urlGetDKPPrintData(contract.id),
                          contract.number
                        ),
                      text: 'Договір купівлі-продажу',
                      icon: 'mdi-file',
                      disabled:
                        !contract.providerEmployeeId ||
                        !contract.dkpProviderBasisId,
                    },
                  ]"
                  customIcon="Printer">
                </TableCrud>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <StatusBar
          class="mb-2"
          :currentStatus="contract.statusId"
          :submit="setDkpStatus"
          :statuses="filteredStatuses"
          spreadItems
          size="small">
        </StatusBar>
        <v-tabs
          v-model="currentTab"
          show-arrows
          :direction="$vuetify.breakpoint?.xs ? 'vertical' : 'horizontal'"
          :height="$vuetify.breakpoint?.xs ? null : 38"
          class="dkpTabs ml-3"
          color="white"
          dark
          slider-color="#fc7247"
          dense>
          <v-tab
            replace
            :to="{ name: 'parties-details', params: { id: $route.params.id } }">
            Реквізити сторін
          </v-tab>
          <v-tab
            replace
            :to="{ name: 'sale-terms', params: { id: $route.params.id } }">
            Умови продажу
          </v-tab>
          <v-tab
            replace
            :to="{
              name: 'buyback-schedule',
              params: { id: $route.params.id },
            }">
            Графiк зворотнього викупу
          </v-tab>
          <v-tab
            replace
            :to="{ name: 'agreements', params: { id: $route.params.id } }">
            Договори
          </v-tab>
          <v-tab
            replace
            :to="{ name: 'links', params: { id: $route.params.id } }">
            Зв`язки
          </v-tab>
          <v-tab
            replace
            :to="{ name: 'dkp-history', params: { id: $route.params.id } }">
            Історія
          </v-tab>
        </v-tabs>
        <RouterView
          :contract="contract"
          :endorsement="endorsement"
          :readonly="readonly">
        </RouterView>
        <v-card-actions v-show="!loading">
          <ActionButtons
            :confirm="submit"
            :cancel="getContract"
            :confirmLoading="asyncLoading('submit')"
            :cancelDisable="asyncLoading('submit') || contractState === cache"
            :confirmDisable="contractState === cache">
          </ActionButtons>
        </v-card-actions>
      </div>
    </v-fade-transition>
  </div>
</template>
<script>
import ActionButtons from '@/components/action-buttons.vue'
import SectionLoader from '@/components/section-loader.vue'
import TableCrud from '@/components/table-crud.vue'
import StatusBar from '@/components/StatusBar.vue'
import Fields from '@/components/Fields.vue'
import EditableField from '@/components/EditableField.vue'
import { mapState } from 'vuex'
import { required, minLength } from '@vuelidate/validators'
import {
  urlUpdateDkpContract,
  urlGetDKPPrintData,
  urlSetDkpStatus,
  urlSetCancelContractDkp,
  urlCreateExtraAgreementDkp,
} from '@/pages/request.js'
import {
  LODescription as getLoDescription,
  setCache,
  v$Notify,
  backDate,
  toFormatDate,
  downloadPrintedForm,
  getEntityName,
  setErrHandler,
} from '@/utils/helperFunc'
import { useVuelidate } from '@vuelidate/core'
import { useSelect } from '@/utils/mixins/useSelect'
import { confirm } from 'best-modules/plugins'
import InputPusher from '@/components/InputPusher.vue'
import ALink from '@/components/Link.vue'
import { fillDirectory, getDirectory } from '@/plugins/directory/index.ts'

export default {
  components: {
    ALink,
    InputPusher,
    SectionLoader,
    ActionButtons,
    TableCrud,
    StatusBar,
    Fields,
    EditableField,
  },
  setup() {
    return { v$: useVuelidate(), ...useSelect() }
  },
  validations() {
    const guaranteesMonthValidation = this.contract.isGuarantees
      ? { required }
      : {}
    return {
      contract: {
        typeId: { required },
        number: { required },
        date: { required, minLength: minLength(10) },
        orderCalculationId: { required },
        dkpBuyerBasisId: { required },
        signatoryId: { required },
        providerSignatories: {
          required,
          minLength: minLength(1),
          $each: {
            contactId: { required },
            basisId: { required },
          },
        },
        quantityMonthGuarantees: guaranteesMonthValidation,
      },
    }
  },
  data: () => ({
    DKPPrintData: {},
    currentTab: 0,
    cancelTimeout: null,
    loadingRequest: false,
  }),
  computed: {
    ...mapState({
      user: state => state.user,
      contract: state => state.contracts.dkpContract,
      loading: state => state.contracts.dkpContractLoading,
      selectItems: state => state.selectItems,
      calculation: state => state.contracts.dkpContract.calculation,
      cache: state => state.contracts.dkpCache,
      project: state => state.contracts.dkpContract.project,
      provider: state => state.contracts.dkpContract.provider,
      buyer: state => state.contracts.dkpContract.buyer,
    }),
    fields() {
      return [
        { label: 'Тип договору', value: this.contract.type?.name },
        {
          label: 'Дата договору',
          value: toFormatDate(this.contract.date, true),
        },
        { label: '№ Договору', slot: 'number' },
        {
          label: 'Проект',
          value: `${this.project.number} - ${this.project.lesseeName}`,
          class: { link: true },
          click: () => {
            this.$router.push({
              name: 'project',
              params: { projectId: this.project.id },
            })
          },
        },
      ]
    },
    admin() {
      return this.user.roleId === 1
    },
    endorsement() {
      return this.contract.statusId === 3
    },
    providerName() {
      return (
        (getEntityName(this.provider) || '---') + ' - ' + this.contract.number
      )
    },
    contractId() {
      return this.$route?.params?.id
    },
    LO() {
      return this.getLoDescription(this.calculation)
    },
    contractState() {
      return this.setCache([this.contract])
    },
    lesseeName() {
      if (!this.contract || !this.contract.contract) {
        return '---'
      }

      const lessee = this.contract.contract.lessee
      const lesseeName =
        lessee?.contact?.fullName || lessee?.contractor?.shortName || '---'
      const contractNumber = this.contract.number

      return `${lesseeName} - ${contractNumber}`
    },
    /* validations */
    typeIdErr() {
      return setErrHandler(this.v$.contract.typeId)
    },
    route() {
      return this.$route.name
    },
    readonly() {
      return this.contract?.readOnly
    },
    filteredStatuses() {
      const filteredStatuses = getDirectory('dkpStatuses').filter(item => {
        if (item.id === 6) {
          return this.contract.isCancelContract
        }
        if (item.id === 7) {
          return this.contract.isTerminated
        }
        return true
      })
      return filteredStatuses.map(s => {
        let showCheckIcon

        if ([6, 7].includes(this.contract.statusId)) {
          showCheckIcon = s.id === this.contract.statusId
        }

        return { ...s, showCheckIcon }
      })
    },
    selectExtraAgreement() {
      return (
        this.contract?.extraAgreementsDkp?.map(obj => ({
          action: () => this.openAgreementSingle(obj.id),
          text: `Додаткова угода ${obj.number}`,
          icon: 'mdi-text-box-check',
        })) || []
      )
    },
  },
  methods: {
    setCache,
    getLoDescription,
    urlSetCancelContractDkp,
    urlUpdateDkpContract,
    urlGetDKPPrintData,
    urlSetDkpStatus,
    downloadPrintedForm,
    backDate,
    toFormatDate,
    terminateContract() {
      if (
        this.contract.statusId !== 5 ||
        !this.contract.extraAgreementsDkp?.some(a => {
          return a.statusId === 5
        })
      ) {
        return this.$setSnackbar({
          text: 'Для розривання договір та додаткова угода мають бути в статусі "Підписаний"',
          color: 'warning',
        })
      }
      this.$store.commit('setDialog', {
        title: 'Розірвання договору',
        dialogItem: {
          contractId: this.contract.id,
          agreements: this.contract.extraAgreementsDkp.filter(a => {
            return a.statusId === 5
          }),
        },
        params: {
          cb: this.getContract,
        },
        action: 'terminateDkp',
      })
    },
    openAgreementSingle(id) {
      this.$router.push({ name: 'du-dkp-agreement', params: { id } })
    },
    setBreadScrumb(res) {
      this.$store.commit('setBreadScrumbPart', [null, null, this.providerName])
      return res
    },
    setCancel() {
      confirm({
        text: 'Ви дійсно бажаєте анулювати договір?',
      }).then(() => {
        this.loadingRequest = true
        return this.$axios
          .post(urlSetCancelContractDkp(this.contract.id), {
            isCancelContract: true,
          })
          .then(res => {
            this.$setSnackbar({ text: 'Дані оновлено' })
            Object.assign(this.contract, res.data)
            this.setCache([this.contract])
            this.setStoreCache()
            this.loadingRequest = false

            return res
          })
      })
    },
    submit() {
      if (this.v$.$invalid) {
        this.v$.$anyError
        this.v$.$touch()
        return Promise.resolve(v$Notify(this.v$.contract, 'dkp'))
      } else if (!this.calculation.specificationDocuments?.length) {
        return Promise.resolve(
          this.$setSnackbar({
            text: 'Вкажіть документи специфікації',
            color: 'warning',
          })
        )
      }

      const requestObj = JSON.parse(JSON.stringify(this.contract))

      if (requestObj.providerSignatories)
        requestObj.providerSignatories = requestObj.providerSignatories.map(
          s => ({
            contactId: s.contactId,
            basisId: s.basisId,
          })
        )

      requestObj.currencyCourse = +requestObj.currencyCourse

      return this.asyncAction('submit', null, () => {
        return this.$axios
          .post(this.urlUpdateDkpContract(this.contractId), requestObj)
          .then(res => {
            if (res.data.message) throw new Error(res.data.message)
            this.$setSnackbar({ text: 'Оновлено' })
            this.setCache([this.contract])
            this.setStoreCache()
          })
          .catch(err => this.$err(err))
      })
    },
    setStoreCache(res) {
      this.$store.commit('setDkpContractCache', this.setCache([this.contract]))
      return res
    },
    async setDkpStatus(statusId) {
      if (this.v$.$invalid) {
        return Promise.reject(v$Notify(this.v$.contract, 'dkp'))
      }

      const currentStatus = this.contract.statusId

      if (currentStatus === 5) {
        return this.$setSnackbar({
          text: 'Договір з статусу пiдписаного не може бути змiнено',
          color: 'warning',
        })
      }
      if (
        [4, 5].includes(statusId) &&
        this.contract.agreements?.some(a => !a.isAgreed)
      ) {
        return this.$setSnackbar({
          text: 'Договір повинен бути завізований всiма учасниками',
          color: 'warning',
        })
      }
      if (statusId < currentStatus || statusId > currentStatus + 1) {
        return this.$setSnackbar({ text: 'Дія неможлива', color: 'error' })
      }

      if ([2, 3].includes(statusId)) {
        try {
          const statusNames = {
            2: 'Підписано Постачальником',
            3: 'Візування',
          }
          await confirm({
            text: `Ви впевнені що хочете перевести договір в статус "${statusNames[statusId]}"?`,
          })
        } catch (e) {
          return
        }
      }

      try {
        const response = await this.$axios.post(
          this.urlSetDkpStatus(this.contract.id),
          { statusId }
        )
        if (response.data.message) {
          this.$setSnackbar({ text: response.data.message, color: 'warning' })
        } else {
          this.$setSnackbar({ text: 'Статус успiшно змiнено' })
        }
        this.getContract()
        return response
      } catch (error) {
        this.$err(error)
      }
    },
    getContract() {
      if (this.v$.$reset) {
        this.v$.$reset()
      }
      fillDirectory('dkpStatuses')
      this.$store.dispatch('addDkpTypes')
      this.$store
        .dispatch('getDkpContract', this.contractId)
        .then(this.setStoreCache)
        .then(this.setBreadScrumb)
        .catch(err => {
          this.$setSnackbar({ text: err, color: 'error' })
          this.$store.commit('setDkpContractState', false)
          throw err
        })
    },
    createExtraAgreement() {
      this.asyncAction('createExtraAgreement', null, () => {
        return this.$axios
          .post(urlCreateExtraAgreementDkp(), {
            dkpId: this.contract.id,
          })
          .then(res => {
            this.$setSnackbar({ text: 'Додаткову угоду успішно створено' })
            this.$router.push({
              name: 'du-dkp-agreement',
              params: { id: res.data.id },
            })

            return res
          })
          .catch(this.$err)
      })
    },
  },
  watch: {
    route() {
      this.setBreadScrumb()
    },
    v$: {
      deep: true,
      handler(v) {
        this.$store.commit('setDkpContractValidation', v)
      },
    },
  },
  created() {
    this.getContract()
  },
  beforeUnmount() {
    this.$store.commit('clearDkpContract')
  },
}
</script>

<style>
.dkpTabs .v-slide-group__wrapper {
  background: #fff !important;
}
</style>

<style scoped>
a.v-tab-item--selected.v-tab {
  color: #08487a !important;
  font-weight: 700 !important;
  font-size: 14px;
  letter-spacing: normal;
  padding-left: 0;
  padding-right: 0;
}
a.v-tab {
  margin-right: 20px;
  padding-left: 0;
  padding-right: 0;
  color: #838282 !important;
}
.v-slide-group__wrapper {
  background: #fff !important;
}
</style>
