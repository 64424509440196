<template>
  <div>
    <v-dialog v-model="zip.dialog" max-width="380" persistent>
      <v-card>
        <v-toolbar
          color="rgb(210, 74, 67)"
          class="mb-6"
          :elevation="2"
          :height="49"
          dark>
          <v-toolbar-title style="font-size: 1rem"
            >Завантаження файлу</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn size="small" icon @click="clearZip">
            <v-icon size="19">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <SectionLoader v-if="zip.loading" height="150px" />
          <div v-else>
            <v-checkbox
              v-model="zip.zipped"
              label="Помістити в архів"
              :trueValue="1"
              :falseValue="0"
              @update:model-value="event => !event && (zip.fileName = null)">
            </v-checkbox>
            <v-text-field
              v-if="zip.zipped"
              v-model="zip.fileName"
              label="Назва архіву"
              :error-messages="zipNameErr"
              @blur="v$.zip.fileName.$touch()">
            </v-text-field>
            <ActionButtons
              confirm-btn-text="Завантажити"
              cancel-btn-text="Скасувати"
              :confirm="setZip"
              :cancel="clearZip" />
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div class="document-list">
      <div>
        <div v-if="label" class="file-block">
          <v-btn
            v-if="computedDocuments.length"
            icon
            style="margin-right: 5px"
            density="comfortable"
            size="small"
            class="rounded-circle"
            @click="openList = !openList">
            <FileOpened v-if="openList" />
            <FileHiden v-else />
          </v-btn>
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;}">
            <span class="doc-desc-part">
              <slot name="label-prepend"></slot>
              <span v-show="required" class="required-star">*</span>
              {{ label }}
              <v-tooltip v-if="info" color="grey darken-3" top :text="info">
                <template #activator="{ props }">
                  <span style="font-size: 14px; color: black">
                    <v-icon size="17" v-bind="props">
                      {{ 'mdi-information' }}
                    </v-icon>
                  </span>
                </template>
                <span>{{ info }}</span>
              </v-tooltip>
            </span>
            <div>
              <span v-if="showDelete">
                <v-tooltip bottom text="Видалити документ">
                  <template #activator="{ props }">
                    <v-btn
                      icon
                      density="comfortable"
                      size="small"
                      class="rounded-circle"
                      v-bind="props"
                      @click="$emit('deleteField')">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none">
                        <path
                          d="M4.9375 24C4.21563 24 3.59787 23.75 3.08425 23.2499C2.56975 22.7491 2.3125 22.1472 2.3125 21.4444V4.83333C1.94063 4.83333 1.62869 4.71109 1.37669 4.46661C1.12556 4.22128 1 3.91759 1 3.55556C1 3.19352 1.12556 2.88983 1.37669 2.6445C1.62869 2.40002 1.94063 2.27778 2.3125 2.27778H7.5625C7.5625 1.91574 7.6885 1.61206 7.9405 1.36672C8.19163 1.12224 8.50313 1 8.875 1H14.125C14.4969 1 14.8088 1.12224 15.0608 1.36672C15.3119 1.61206 15.4375 1.91574 15.4375 2.27778H20.6875C21.0594 2.27778 21.3709 2.40002 21.622 2.6445C21.874 2.88983 22 3.19352 22 3.55556C22 3.91759 21.874 4.22128 21.622 4.46661C21.3709 4.71109 21.0594 4.83333 20.6875 4.83333V21.4444C20.6875 22.1472 20.4307 22.7491 19.9171 23.2499C19.4026 23.75 18.7844 24 18.0625 24H4.9375ZM4.9375 4.83333V21.4444H18.0625V4.83333H4.9375ZM7.5625 17.6111C7.5625 17.9731 7.6885 18.2764 7.9405 18.5209C8.19163 18.7662 8.50313 18.8889 8.875 18.8889C9.24687 18.8889 9.55881 18.7662 9.81081 18.5209C10.0619 18.2764 10.1875 17.9731 10.1875 17.6111V8.66667C10.1875 8.30463 10.0619 8.00094 9.81081 7.75561C9.55881 7.51113 9.24687 7.38889 8.875 7.38889C8.50313 7.38889 8.19163 7.51113 7.9405 7.75561C7.6885 8.00094 7.5625 8.30463 7.5625 8.66667V17.6111ZM12.8125 17.6111C12.8125 17.9731 12.9385 18.2764 13.1905 18.5209C13.4416 18.7662 13.7531 18.8889 14.125 18.8889C14.4969 18.8889 14.8088 18.7662 15.0608 18.5209C15.3119 18.2764 15.4375 17.9731 15.4375 17.6111V8.66667C15.4375 8.30463 15.3119 8.00094 15.0608 7.75561C14.8088 7.51113 14.4969 7.38889 14.125 7.38889C13.7531 7.38889 13.4416 7.51113 13.1905 7.75561C12.9385 8.00094 12.8125 8.30463 12.8125 8.66667V17.6111ZM4.9375 4.83333V21.4444V4.83333Z"
                          fill="#FC7247" />
                      </svg>
                    </v-btn>
                  </template>
                  <span>Видалити документ</span>
                </v-tooltip>
              </span>

              <span
                v-if="
                  !readonly &&
                  !(single && getSafe(() => !!computedDocuments.length, false))
                ">
                <v-tooltip bottom text="Завантажити документ">
                  <template #activator="{ props }">
                    <v-btn
                      data-readonly="hide"
                      icon
                      class="rounded-circle"
                      density="comfortable"
                      size="small"
                      :disabled="disabled || editOnly"
                      v-bind="props"
                      @click="handleClick('selectFile')">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none">
                        <path
                          d="M12 18.6667C12.3778 18.6667 12.6947 18.5387 12.9507 18.2827C13.2058 18.0276 13.3333 17.7111 13.3333 17.3333V13.3333H17.3333C17.7111 13.3333 18.0276 13.2053 18.2827 12.9493C18.5387 12.6942 18.6667 12.3778 18.6667 12C18.6667 11.6222 18.5387 11.3053 18.2827 11.0493C18.0276 10.7942 17.7111 10.6667 17.3333 10.6667H13.3333V6.66667C13.3333 6.28889 13.2058 5.972 12.9507 5.716C12.6947 5.46089 12.3778 5.33333 12 5.33333C11.6222 5.33333 11.3058 5.46089 11.0507 5.716C10.7947 5.972 10.6667 6.28889 10.6667 6.66667V10.6667H6.66667C6.28889 10.6667 5.972 10.7942 5.716 11.0493C5.46089 11.3053 5.33333 11.6222 5.33333 12C5.33333 12.3778 5.46089 12.6942 5.716 12.9493C5.972 13.2053 6.28889 13.3333 6.66667 13.3333H10.6667V17.3333C10.6667 17.7111 10.7947 18.0276 11.0507 18.2827C11.3058 18.5387 11.6222 18.6667 12 18.6667ZM2.66667 24C1.93333 24 1.30533 23.7391 0.782667 23.2173C0.260889 22.6947 0 22.0667 0 21.3333V2.66667C0 1.93333 0.260889 1.30533 0.782667 0.782667C1.30533 0.260889 1.93333 0 2.66667 0H21.3333C22.0667 0 22.6947 0.260889 23.2173 0.782667C23.7391 1.30533 24 1.93333 24 2.66667V21.3333C24 22.0667 23.7391 22.6947 23.2173 23.2173C22.6947 23.7391 22.0667 24 21.3333 24H2.66667ZM2.66667 21.3333H21.3333V2.66667H2.66667V21.3333Z"
                          fill="#FC7247" />
                      </svg>
                    </v-btn>
                  </template>
                  <span>Завантажити документ</span>
                </v-tooltip>
              </span>
            </div>
          </div>
        </div>
        <v-fade-transition hide-on-leave>
          <div
            v-if="
              !loading &&
              computedDocuments &&
              getSafe(() => !!computedDocuments.length, false) &&
              !rerender
            "
            class="pb-1">
            <div>
              <ul
                v-for="(v, key) in computedDocuments"
                :key="key"
                class="document-line-wrapper"
                style="list-style-type: none">
                <li v-if="v && openList" style="text-align: left">
                  <div class="document-item">
                    <div class="d-flex align-center">
                      <div class="mr-2">
                        <div
                          v-if="
                            [
                              'png',
                              'jpg',
                              'jpeg',
                              'pjpeg',
                              'webp',
                              'svg',
                              'gif',
                            ].includes(v.url.split('.').at(-1))
                          "
                          class="file-img">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none">
                            <rect
                              width="22"
                              height="22"
                              rx="4"
                              fill="#F4F6F9" />
                            <path
                              d="M5 6C5 5.44772 5.44772 5 6 5H16C16.5523 5 17 5.44772 17 6V16C17 16.5523 16.5523 17 16 17H6C5.44772 17 5 16.5523 5 16V6Z"
                              fill="#3FABE8" />
                            <path
                              d="M10.5554 14.3993C10.2158 14.5785 9.80369 14.5473 9.49484 14.3193L6.59404 12.1771C5.9339 11.6897 5 12.1609 5 12.9816V16C5 16.5523 5.44772 17 6 17H16C16.5523 17 17 16.5523 17 16V12.6581C17 11.9052 16.1994 11.4223 15.5335 11.7736L10.5554 14.3993Z"
                              fill="#8AE274" />
                            <path
                              d="M15 7C15 7.55228 14.5523 8 14 8C13.4477 8 13 7.55228 13 7C13 6.44772 13.4477 6 14 6C14.5523 6 15 6.44772 15 7Z"
                              fill="#FFEA7C" />
                          </svg>
                        </div>
                        <div
                          v-if="
                            ['docx', 'doc'].includes(v.url.split('.').at(-1))
                          "
                          class="file-img">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none">
                            <rect
                              width="22"
                              height="22"
                              rx="4"
                              fill="#F4F6F9" />
                            <path
                              d="M13.3333 14.3333H12.3333L11 9.33333L9.66667 14.3333H8.66667L7.06667 7.66667H8.2L9.22667 12.6667L10.5333 7.66667H11.4667L12.78 12.6667L13.8 7.66667H14.9333M15.6667 5H6.33333C5.59333 5 5 5.59333 5 6.33333V15.6667C5 16.0203 5.14048 16.3594 5.39052 16.6095C5.64057 16.8595 5.97971 17 6.33333 17H15.6667C16.0203 17 16.3594 16.8595 16.6095 16.6095C16.8595 16.3594 17 16.0203 17 15.6667V6.33333C17 5.97971 16.8595 5.64057 16.6095 5.39052C16.3594 5.14048 16.0203 5 15.6667 5Z"
                              fill="#2F9CDA" />
                          </svg>
                        </div>
                        <div
                          v-if="
                            ['xls', 'xlsx'].includes(v.url.split('.').at(-1))
                          "
                          class="file-img">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none">
                            <rect
                              width="22"
                              height="22"
                              rx="4"
                              fill="#F4F6F9" />
                            <path
                              d="M12.6361 10.875L8 10V16.4656C8.00005 16.5358 8.01302 16.6054 8.03816 16.6702C8.0633 16.7351 8.10013 16.794 8.14652 16.8437C8.19292 16.8933 8.24799 16.9326 8.30859 16.9595C8.36918 16.9863 8.43411 17.0001 8.49967 17H16.4995C16.5651 17.0002 16.6301 16.9865 16.6908 16.9597C16.7515 16.9329 16.8067 16.8936 16.8532 16.844C16.8997 16.7943 16.9366 16.7354 16.9618 16.6704C16.9869 16.6055 16.9999 16.5359 17 16.4656V14.0833L12.6361 10.875Z"
                              fill="#185C37" />
                            <path
                              d="M12.6361 6H8.49967C8.43411 5.99995 8.36918 6.01227 8.30859 6.03626C8.24799 6.06026 8.19292 6.09545 8.14652 6.13983C8.10013 6.18422 8.0633 6.23693 8.03816 6.29495C8.01302 6.35297 8.00005 6.41517 8 6.47799V8.6087L12.6361 11.2174L15.0909 12L17 11.2174V8.6087L12.6361 6Z"
                              fill="#21A366" />
                            <path d="M8 9H13V11H8V9Z" fill="#107C41" />
                            <path
                              opacity="0.1"
                              d="M11.4761 8H8V15H11.4761C11.6146 14.9993 11.7473 14.9451 11.8453 14.8491C11.9434 14.753 11.999 14.6229 12 14.487V8.51305C11.999 8.37707 11.9434 8.24697 11.8453 8.15094C11.7473 8.05491 11.6146 8.00068 11.4761 8Z"
                              fill="black" />
                            <path
                              opacity="0.2"
                              d="M11.4358 9H8V15H11.4358C11.585 14.9994 11.7278 14.9529 11.8334 14.8706C11.939 14.7883 11.9989 14.6768 12 14.5602V9.43975C11.9989 9.3232 11.939 9.21169 11.8334 9.12937C11.7278 9.04706 11.585 9.00058 11.4358 9Z"
                              fill="black" />
                            <path
                              opacity="0.2"
                              d="M11.4358 9H8V15H11.4358C11.585 14.9994 11.7278 14.9488 11.8334 14.8594C11.939 14.7699 11.9989 14.6487 12 14.522V9.47799C11.9989 9.35131 11.939 9.2301 11.8334 9.14062C11.7278 9.05115 11.585 9.00063 11.4358 9Z"
                              fill="black" />
                            <path
                              opacity="0.2"
                              d="M10.5416 9H8V15H10.5416C10.6628 14.9994 10.7789 14.9488 10.8647 14.8594C10.9505 14.7699 10.9991 14.6487 11 14.522V9.47799C10.9991 9.35131 10.9505 9.2301 10.8647 9.14062C10.7789 9.05115 10.6628 9.00063 10.5416 9Z"
                              fill="black" />
                            <path
                              d="M5.50007 9H10.4999C10.6324 8.99989 10.7594 9.05246 10.8532 9.14617C10.947 9.23987 10.9998 9.36704 11 9.49972V14.5003C10.9998 14.633 10.947 14.7601 10.8532 14.8538C10.7594 14.9475 10.6324 15.0001 10.4999 15H5.50007C5.43447 15.0001 5.3695 14.9873 5.30886 14.9622C5.24822 14.9372 5.19311 14.9004 5.14667 14.854C5.10022 14.8075 5.06337 14.7524 5.0382 14.6917C5.01304 14.6311 5.00005 14.566 5 14.5003V9.49972C5.00005 9.43401 5.01304 9.36895 5.0382 9.30826C5.06337 9.24757 5.10022 9.19245 5.14667 9.14604C5.19311 9.09963 5.24822 9.06285 5.30886 9.03779C5.3695 9.01273 5.43447 8.99989 5.50007 9Z"
                              fill="url(#paint0_linear_1554_37135)" />
                            <path
                              d="M7 12.9988L8.08701 11.4952L7.09134 10H7.89091L8.4342 10.9574C8.48442 11.048 8.52078 11.1154 8.53723 11.1603H8.54459C8.58009 11.0875 8.61775 11.0174 8.65714 10.9489L9.2381 10.0015H9.97403L8.95281 11.4882L10 13H9.21688L8.58918 11.9504C8.56003 11.9053 8.53527 11.858 8.51515 11.8091H8.50476C8.48646 11.8568 8.46205 11.9024 8.43203 11.945L7.78571 12.9988H7Z"
                              fill="white" />
                            <path
                              d="M16.5416 6H13V9H17V6.54969C16.9999 6.47741 16.9881 6.40584 16.965 6.33909C16.9419 6.27233 16.9081 6.2117 16.8656 6.16065C16.823 6.1096 16.7725 6.06913 16.7169 6.04157C16.6613 6.014 16.6017 5.99988 16.5416 6Z"
                              fill="#33C481" />
                            <path d="M13 11H17V14H13V11Z" fill="#107C41" />
                            <defs>
                              <linearGradient
                                id="paint0_linear_1554_37135"
                                x1="6.04453"
                                y1="8.60727"
                                x2="9.96613"
                                y2="15.3866"
                                gradientUnits="userSpaceOnUse">
                                <stop stop-color="#18884F" />
                                <stop offset="0.5" stop-color="#117E43" />
                                <stop offset="1" stop-color="#0B6631" />
                              </linearGradient>
                            </defs>
                          </svg>
                        </div>
                        <div
                          v-if="v.url.split('.').at(-1) === 'pdf' && 'doc'"
                          class="file-img">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none">
                            <rect
                              width="22"
                              height="22"
                              rx="4"
                              fill="#F4F6F9" />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M7.39247 4.8125H14.9624C15.4643 4.8125 15.9456 4.97547 16.3005 5.26557C16.6555 5.55566 16.8548 5.94912 16.8548 6.35938V15.6406C16.8548 16.0509 16.6555 16.4443 16.3005 16.7344C15.9456 17.0245 15.4643 17.1875 14.9624 17.1875H7.39247C6.89056 17.1875 6.4092 17.0245 6.05429 16.7344C5.69938 16.4443 5.5 16.0509 5.5 15.6406V6.35938C5.5 5.94912 5.69938 5.55566 6.05429 5.26557C6.4092 4.97547 6.89056 4.8125 7.39247 4.8125ZM7.5486 13.837C7.63376 13.9762 7.76624 14.1023 7.96305 14.161C8.15892 14.219 8.3529 14.192 8.51187 14.1378C8.81277 14.0373 9.11273 13.8006 9.38809 13.5299C9.70318 13.2198 10.0344 12.8129 10.3542 12.362C10.9716 12.2124 11.6039 12.1073 12.2438 12.048C12.5277 12.3442 12.821 12.5995 13.1049 12.7828C13.3698 12.9529 13.6755 13.0945 13.9887 13.1053C14.1593 13.1122 14.3282 13.0748 14.4713 12.9986C14.6179 12.9204 14.7268 12.8075 14.8062 12.6768C14.8914 12.5368 14.9434 12.3906 14.9368 12.2414C14.9308 12.0942 14.8641 11.953 14.7476 11.8407C14.5337 11.6319 14.1836 11.5314 13.8392 11.4811C13.4214 11.4282 12.9974 11.4152 12.576 11.4424C12.22 11.0311 11.9097 10.5947 11.6486 10.1384C11.8852 9.62792 12.0622 9.1453 12.1407 8.75084C12.1748 8.58223 12.1927 8.42136 12.1861 8.27595C12.1849 8.13162 12.1439 7.98937 12.0659 7.85984C12.021 7.78836 11.9573 7.72594 11.8792 7.67711C11.8011 7.62828 11.7107 7.59427 11.6146 7.57754C11.4234 7.54428 11.2266 7.57754 11.0459 7.63709C10.6892 7.75311 10.5009 8.00061 10.4299 8.27363C10.3608 8.5366 10.392 8.84288 10.4734 9.15226C10.5567 9.46627 10.6986 9.80813 10.8803 10.1539C10.5897 10.7446 10.2541 11.3199 9.8754 11.8763C9.38775 12.0017 8.91769 12.1689 8.47308 12.3752C8.12297 12.5453 7.81166 12.7464 7.6243 12.9839C7.42559 13.236 7.36409 13.5361 7.5486 13.837Z"
                              fill="#EB7575" />
                          </svg>
                        </div>
                        <div
                          v-if="v.url.split('.').at(-1) === 'zip' && 'rar'"
                          class="file-img">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none">
                            <rect
                              width="22"
                              height="22"
                              rx="4"
                              fill="#F4F6F9" />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M15 5H7C6.46957 5 5.96086 5.15804 5.58579 5.43934C5.21071 5.72064 5 6.10218 5 6.5V15.5C5 15.8978 5.21071 16.2794 5.58579 16.5607C5.96086 16.842 6.46957 17 7 17H15C15.5304 17 16.0391 16.842 16.4142 16.5607C16.7893 16.2794 17 15.8978 17 15.5V6.5C17 6.10218 16.7893 5.72064 16.4142 5.43934C16.0391 5.15804 15.5304 5 15 5ZM9.5 12.0785V11.375C9.5 11.1761 9.60536 10.9853 9.79289 10.8447C9.98043 10.704 10.2348 10.625 10.5 10.625H11.5C11.7652 10.625 12.0196 10.704 12.2071 10.8447C12.3946 10.9853 12.5 11.1761 12.5 11.375V12.0785L12.9 13.2778C12.9499 13.4277 12.9369 13.5856 12.8626 13.7298C12.7883 13.874 12.6564 13.9974 12.485 14.0833L11.555 14.5483C11.3908 14.6303 11.1978 14.6741 11.0005 14.6741C10.8032 14.6741 10.6102 14.6303 10.446 14.5483L9.516 14.0833C9.3444 13.9975 9.21232 13.8741 9.13785 13.7299C9.06339 13.5857 9.05016 13.4278 9.1 13.2778L9.5 12.0785ZM9.5 7.25H10.5V6.5H9.5V5.75H11V6.5H12V7.25H11V8H12V8.75H11V9.5H12V10.25H10.5V9.5H9.5V8.75H10.5V8H9.5V7.25ZM11.5 11.375V12.0785C11.5 12.1399 11.5101 12.2012 11.53 12.2607L11.93 13.4593L11 13.9243L10.07 13.4593L10.47 12.2607C10.4899 12.2012 10.5 12.1399 10.5 12.0785V11.375H11.5Z"
                              fill="#FCB433" />
                          </svg>
                        </div>
                      </div>
                      <div
                        style="display: flex; align-items: center"
                        @click="openDocument(v, key)">
                        <ALink
                          :text="v.text || label"
                          style="font-size: 0.78rem"></ALink>
                        <v-tooltip
                          v-if="v.signed || v.isSigned"
                          bottom
                          text="Завірена копiя">
                          <template #activator="{ props }">
                            <v-btn
                              icon
                              class="rounded-circle ml-2"
                              size="x-small"
                              v-bind="props">
                              <v-icon size="small" color="red">
                                {{ 'mdi-check' }}
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Завірена копiя</span>
                        </v-tooltip>
                      </div>
                    </div>
                    <div class="d-flex align-center">
                      <div style="margin-right: 40px !important">
                        <span v-if="v.size && v.addedBy" style="color: #888">{{
                          `${toFormatDate(v.date, true)} / ${(
                            v.size / 1000
                          ).toFixed(2)} kb / ${v.addedBy}`
                        }}</span>
                      </div>
                      <v-btn
                        icon
                        size="small"
                        class="rounded-circle"
                        density="comfortable"
                        @click="downloadDocument(key)">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="18"
                          viewBox="0 0 14 18"
                          fill="none">
                          <path
                            d="M1 17H13M7 1V13M7 13L10.5 9.5M7 13L3.5 9.5"
                            stroke="#838282"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round" />
                        </svg>
                      </v-btn>
                      <v-btn
                        v-if="
                          isAdmin &&
                          !editOnly &&
                          !(
                            readonly ||
                            disabled ||
                            (disableDeleteAction &&
                              compareServerClientDocs(v.text))
                          )
                        "
                        size="small"
                        icon
                        class="rounded-circle"
                        density="comfortable"
                        @click="deleteDoc(v, key)">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="18"
                          viewBox="0 0 16 18"
                          fill="none">
                          <path
                            d="M3 18C2.45 18 1.97933 17.8043 1.588 17.413C1.196 17.021 1 16.55 1 16V3C0.716667 3 0.479 2.90433 0.287 2.713C0.0956668 2.521 0 2.28333 0 2C0 1.71667 0.0956668 1.479 0.287 1.287C0.479 1.09567 0.716667 1 1 1H5C5 0.716667 5.096 0.479 5.288 0.287C5.47933 0.0956666 5.71667 0 6 0H10C10.2833 0 10.521 0.0956666 10.713 0.287C10.9043 0.479 11 0.716667 11 1H15C15.2833 1 15.5207 1.09567 15.712 1.287C15.904 1.479 16 1.71667 16 2C16 2.28333 15.904 2.521 15.712 2.713C15.5207 2.90433 15.2833 3 15 3V16C15 16.55 14.8043 17.021 14.413 17.413C14.021 17.8043 13.55 18 13 18H3ZM3 3V16H13V3H3ZM5 13C5 13.2833 5.096 13.5207 5.288 13.712C5.47933 13.904 5.71667 14 6 14C6.28333 14 6.521 13.904 6.713 13.712C6.90433 13.5207 7 13.2833 7 13V6C7 5.71667 6.90433 5.479 6.713 5.287C6.521 5.09567 6.28333 5 6 5C5.71667 5 5.47933 5.09567 5.288 5.287C5.096 5.479 5 5.71667 5 6V13ZM9 13C9 13.2833 9.096 13.5207 9.288 13.712C9.47933 13.904 9.71667 14 10 14C10.2833 14 10.521 13.904 10.713 13.712C10.9043 13.5207 11 13.2833 11 13V6C11 5.71667 10.9043 5.479 10.713 5.287C10.521 5.09567 10.2833 5 10 5C9.71667 5 9.47933 5.09567 9.288 5.287C9.096 5.479 9 5.71667 9 6V13ZM3 3V16V3Z"
                            fill="#838282" />
                        </svg>
                      </v-btn>
                      <v-btn
                        v-if="!readonly && replace && !hideEdit"
                        data-readonly="hide"
                        icon
                        size="small"
                        class="rounded-circle"
                        density="comfortable"
                        :disabled="disabled"
                        @click="handleClick('updateFile')">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none">
                          <path
                            d="M1.75 18.25H18.25M10.2035 4.34233L12.7958 1.75L17.3333 6.2875L14.741 8.87983M10.2035 4.34233L5.06375 9.48208C4.89183 9.65395 4.79522 9.88707 4.79517 10.1302V14.2882H8.95317C9.19626 14.2881 9.42938 14.1915 9.60125 14.0196L14.741 8.87983M10.2035 4.34233L14.741 8.87983"
                            stroke="#838282"
                            stroke-width="1.7"
                            stroke-linecap="round"
                            stroke-linejoin="round" />
                        </svg>
                      </v-btn>
                    </div>
                  </div>
                  <FakeLink
                    ref="link"
                    :url="Array.isArray(v.url) ? v.url[0] : v.url"
                    :fileName="v.text || label"></FakeLink>
                </li>
              </ul>
            </div>
            <span
              v-if="fileLoading"
              style="position: fixed; z-index: 1000; bottom: 5%; right: 5%"
              class="loader"></span>
            <v-btn
              v-if="fileLoading"
              :loading="true"
              icon
              class="rounded-circle ml-2"
              :width="14"
              :height="14">
            </v-btn>
          </div>
          <SectionLoader v-else-if="loading" height="30px" />
          <!-- <span v-else style="color: #888; padding-left: 40px; cursor: default">Документ відсутній</span> -->
        </v-fade-transition>
      </div>
    </div>
    <div>
      <input
        :id="id"
        ref="inputRef"
        class="hidden-input"
        type="file"
        :accept="acceptedExt"
        :multiple="!single"
        @focus="clearDocument(id)"
        @change="listenFileInput(id)" />
      <input
        :id="`replace_${id}`"
        ref="replaceInputRef"
        class="hidden-input"
        type="file"
        :accept="acceptedExt"
        :multiple="!single"
        @focus="clearDocument(`replace_${id}`)"
        @change="listenFileInput(`replace_${id}`, true)" />
    </div>
  </div>
</template>
<script>
import ActionButtons from './action-buttons.vue'
import ALink from '@/components/Link.vue'
import FakeLink from '@/components/fake-link.vue'
import SectionLoader from './section-loader.vue'
import {
  setErrHandler,
  toFormatDate,
  openDocument,
  getSafe,
} from '@/utils/helperFunc'
import { mapState } from 'vuex'
import { required } from '@vuelidate/validators'
import FileOpened from '@/assets/svg/FileOpened.vue'
import FileHiden from '@/assets/svg/FileHiden.vue'
import { useVuelidate } from '@vuelidate/core'
import { ref } from 'vue'

export default {
  components: {
    FileHiden,
    FileOpened,
    FakeLink,
    SectionLoader,
    ALink,
    ActionButtons,
  },
  emits: [
    'deleteField',
    'selectFile',
    'updateFile',
    'delete',
    'update:documents',
    'loaded',
  ],
  setup(props, { emit }) {
    const inputRef = ref(null)
    const replaceInputRef = ref(null)
    const id = ref(`file-upload-${Math.random().toString(36).substr(2, 9)}`)

    const handleClick = action => {
      if (action === 'selectFile') {
        if (props.disableDefaultListener) {
          emit('selectFile')
        } else {
          inputRef.value.click()
        }
      }
      if (action === 'updateFile') {
        if (props.disableDefaultListener) {
          emit('updateFile')
        } else {
          replaceInputRef.value.click()
        }
      }
    }
    return { v$: useVuelidate(), inputRef, handleClick, id, replaceInputRef }
  },
  props: {
    replace: { type: Boolean, default: true },
    info: { type: String },
    createProp: { type: Boolean, default: false },
    dkp: { type: Boolean, default: false },
    pdv: { type: Boolean, default: false },
    leaseReverse: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    editOnly: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean },
    label: { type: [String, Boolean], default: false },
    fileName: { type: String, default: null },
    documents: { type: [Array, Object] },
    required: { type: Boolean },
    signed: { type: String },
    single: { type: Boolean, default: false },
    maxSize: { type: Number, default: 20000000 },
    acceptedExt: { type: String },
    hideEdit: { type: Boolean },
    disableDeleteAction: { type: Boolean, default: false },
    zipped: { type: Number, default: 1 },
    uploadPath: {
      type: String,
      default: '/json/document/uploadFiles',
    },
    icon: {
      type: String,
      default: 'mdi-file-document-outline',
    },
    showDelete: { type: Boolean },
    disableDefaultListener: { type: Boolean },
  },
  validations() {
    const zipNameValidator = this.zip.zipped ? { required } : {}
    return {
      zip: {
        fileName: zipNameValidator,
      },
    }
  },
  data: () => ({
    fileLoading: false,
    rerender: false,
    count: 0,
    cachedDocuments: [],
    openList: false,
    zip: {
      loading: false,
      dialog: false,
      fileName: null,
      zipped: 0,
      tempFiles: [],
    },
  }),
  computed: {
    ...mapState({
      user: state => state.user,
    }),
    isAdmin() {
      return this.user.roleId === 1
    },
    computedDocuments() {
      let documentsArray = []

      if (this.documents) {
        if (!Array.isArray(this.documents)) {
          documentsArray.push(this.documents)
        } else {
          documentsArray = this.documents
        }
      }

      return documentsArray
        .filter(d => !!d)
        .sort((a, b) => new Date(a?.date) - new Date(b?.date))
    },
    zipPath() {
      return (this.uploadPath || '').includes('uploadFiles') || this.dkp
    },
    singlePath() {
      return this.uploadPath === '/json/document/upload'
    },
    zipNameErr() {
      return setErrHandler(this.v$.zip.fileName)
    },
    fileImg() {
      if (
        this.documents &&
        this.documents.contact &&
        this.documents.contact.fop &&
        this.documents.contact.fop.extractOrExcerpt
      ) {
        return this.documents.contact.fop.extractOrExcerpt
          .map(t => t.url)
          .split('.')
          .slice(-1)
      } else {
        return null
      }
    },
  },
  methods: {
    getSafe,
    openDocument,
    toFormatDate,
    clearZip() {
      this.zip.dialog = false
      this.zip.fileName = null
      this.zip.zipped = 0
      this.zip.loading = false
    },
    getDocName(document, url) {
      const doc = Array.isArray(document) ? document[0] : document
      const name =
        this.zip.fileName ||
        doc.name
          .split('.')
          .filter((el, idx, arr) => idx !== arr.length - 1)
          .join('.')
      const ext = url.split('.').pop()
      return `${name}.${ext}`
    },
    downloadDocument(index) {
      return this.$refs.link[index].$el.click()
    },
    compareServerClientDocs(text) {
      return this.cachedDocuments.map(d => d.text).includes(text)
    },
    deleteDoc(d, key) {
      const tempDocs = JSON.parse(JSON.stringify(this.computedDocuments))
      const docs =
        this.single ||
        !Array.isArray(this.documents) ||
        (Array.isArray(this.documents) && this.documents.length === 1)
          ? null
          : tempDocs.filter((v, k) => k !== key)
      this.rerender = true
      if (this.dkp) {
        this.$store.dispatch('deleteDkp', d.id)
      }
      this.$emit('delete', d)
      if (!this.createProp) {
        this.$emit('update:documents', docs)
      }
      setTimeout(() => {
        this.rerender = false
      }, 0)
      document.getElementById(`${this.id}`).value = ''
    },
    handleUploads(uploads = []) {
      return Promise.all(uploads)
        .then(res => {
          let d = JSON.parse(JSON.stringify(this.documents || null))
          if (!d) d = []
          const documents = [...(Array.isArray(res[0]) ? res[0] : res)]
          this.single ? documents.push(...d) : documents.push(...d)
          if (!this.createProp)
            this.$emit('update:documents', this.single ? documents : documents)
          this.fileLoading = false
          return res
        })
        .catch(err => this.$err(err, () => (this.fileLoading = false)))
    },
    listenFileInput(id, replace = false) {
      this.fileLoading = true
      replace && this.$emit('update:documents', null)

      const files = document.getElementById(id).files
      const arrFiles = []

      for (const f of files) {
        if (f.size < this.maxSize) {
          arrFiles.push(f)
        }
      }
      if (arrFiles.length === 0) {
        this.$setSnackbar(
          `Помилка розмiр файлу не повинен перевищувати ${(
            this.maxSize / 1000000
          ).toFixed(0)} mb`
        )
        return
      }
      // arrFiles = arrFiles.filter(el => el.size <= this.maxSize)

      switch (true) {
        case this.zipPath && arrFiles.length === 1:
          return this.handleUploads([this.uploadDoc(arrFiles)])
        case this.zipPath && arrFiles.length > 1:
          return (() => {
            this.zip.tempFiles = arrFiles
            this.zip.dialog = true
          })()
        case this.singlePath:
          return this.handleUploads(arrFiles.map(this.uploadDoc))
      }
    },
    async setZip() {
      // this.v$.$touch()
      if (this.v$.$invalid) return this.v$.$touch()
      this.zip.loading = true
      await this.handleUploads([await this.uploadDoc(this.zip.tempFiles)])

      return this.clearZip()
    },
    uploadDoc(doc) {
      if (
        doc?.size >= this.maxSize ||
        doc.reduce((sum, cur) => sum + cur.size, 0) >= this.maxSize
      ) {
        this.$setSnackbar(
          `Помилка розмiр файлу не повинен перевищувати ${(
            this.maxSize / 1000000
          ).toFixed(0)} mb`
        )
        this.clearDocument()
        return
      }

      const formData = new FormData()
      const appendDoc = (document, i) => {
        if (this.dkp) {
          formData.append('file', document)
        } else {
          const prop = i || i === 0 ? 'doc[]' : 'doc'
          formData.append([prop], document)
        }
      }
      Array.isArray(doc) ? doc.forEach(appendDoc) : appendDoc(doc)
      formData.append('isZipArchive', this.zip.zipped)

      return this.$axios
        .post(this.uploadPath, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then(res => {
          if (Array.isArray(res.data.url)) {
            return res.data.url.map((url, i) => ({
              url: url,
              text: this.getDocName(doc[i], url),
              size: doc?.size || doc[i].size || null,
              addedBy: `${this.user.surname} ${this.user.name}`,
              date: new Date()
                .toLocaleString('ru-RU')
                .substring(0, 17)
                .replace(',', ''),
            }))
          } else {
            return {
              url: res.data.url || res.data.fileName,
              text: this.getDocName(doc, res.data.url || res.data.fileName),
              size: doc?.size || doc[0].size || null,
              addedBy: `${this.user.surname} ${this.user.name}`,
              date: new Date()
                .toLocaleString('ru-RU')
                .substring(0, 17)
                .replace(',', ''),
            }
          }
        })
        .then(res => {
          if (this.dkp)
            this.$store.dispatch('createBlDkpTable', {
              fileName: res.url,
              pdv: this.pdv,
              leaseReverse: this.leaseReverse,
            })
          this.$emit('loaded', res)
          return res
        })
        .catch(err => this.$err(err, this.clearDocument))
        .finally(() => (this.fileLoading = false))
    },
    clearDocument(id) {
      const el = document.getElementById(id)
      if (el) el.value = null
    },
    cacheServerDocs(d) {
      if (this.count === 1) {
        const docs = Array.isArray(d) ? d : d ? [d] : []
        this.cachedDocuments.push(...docs)
      }
    },
  },
  watch: {
    documents(d) {
      this.count++
      this.cacheServerDocs(d)
      ;(!d || !d?.length) && this.clearDocument()
    },
  },
  mounted() {
    this.inputRef = this._uid
    this.computedDocuments
  },
}
</script>
<style lang="scss" scoped>
.loader {
  width: 64px;
  height: 64px;
  position: relative;
  background-image: linear-gradient(#09487a 16px, transparent 0),
    linear-gradient(#fc7247 16px, transparent 0),
    linear-gradient(#fc7247 16px, transparent 0),
    linear-gradient(#174f7f 16px, transparent 0);
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-position: left top, left bottom, right top, right bottom;
  animation: rotate 1s linear infinite;
}
@keyframes rotate {
  0% {
    width: 64px;
    height: 64px;
    transform: rotate(0deg);
  }
  50% {
    width: 30px;
    height: 30px;
    transform: rotate(180deg);
  }
  100% {
    width: 64px;
    height: 64px;
    transform: rotate(360deg);
  }
}

.file-img {
  display: flex;
  align-items: center;
}
.document-list div {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  transition: color 0.3s ease;
}
.document-list div:hover {
  color: #4d4c4c;
}
.document-line-wrapper {
  background: white;
  box-shadow: 0 2px 8px rgb(0 0 0 / 4%);
  border-radius: 4px;
  padding: 0 10px;
  font-size: 0.85rem;
  .document-delete-icon {
    opacity: 0;
    transition: opacity 0.2s ease-in;
  }
  &:hover {
    .document-delete-icon {
      opacity: 1;
    }
  }
}
.hidden-input {
  visibility: hidden;
  position: absolute;
  left: 0;
  top: 0;
}
.required-star {
  color: red;
  font-size: 1.05rem;
}
.doc-desc-part {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 13px;
  color: #1c1d21;
}
.name-column {
  width: 60%;
}
.responsible-column {
  width: 20%;
}
</style>
<style>
.small-font {
  font-size: 0.75rem !important;
}
.document-item {
  display: flex;
  padding: 10px 3px;
  transition: 0.5s all;
  align-items: center;
  justify-content: space-between;
}
.document-item:hover {
  background: #f4f4f4;
}
.file-block {
  display: flex !important;
  align-items: center !important;
  background: #ffffff !important;
  border-bottom: 1px solid #c0c1c7 !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04) !important;
  border-radius: 4px !important;
  padding: 5.5px 8px !important;
  justify-content: space-between !important;
}
</style>
